// Abos action types and creators

export const INITIALIZE_PAYMENT = 'routes/AbosContainer/initializePayment';
export const SET_NOVALNET_DATA = 'routes/AbosContainer/setNovalnetData';
export const SET_ABO_ID = 'routes/AbosContainer/setAboId';

export const initializePayment = (productId, paymentData) => ({
    type: INITIALIZE_PAYMENT,
    productId,
    payload: paymentData,
});

export const setNovalnetData = (novalnetData) => ({
    type: SET_NOVALNET_DATA,
    payload: novalnetData,
});

export const setAboId = (aboId) => ({
    type: SET_ABO_ID,
    payload: aboId,
});

/* eslint-disable max-len */
export default {
    de: {
        abos: {
            pageTitle: 'Abos & Preise - Online-LernCenter',
            metaDescription: 'Abos und Preise im LernCenter der Schülerhilfe ➔ Wir haben günstige Abos und Direktkauf-Optionen ➔ Erhalte Zugriff zu allen Online-Nachhilfe-Angeboten!',
            mainHeading: 'Abos und Preise für das Schülerhilfe LernCenter',
            paymentOptions: 'Zahlungsoptionen',
            paymentOptionsHint: '* gilt nicht für Abo-Buchungen',
            guarantee: 'Wir garantieren Ihnen',
            secureTransmission: ' Verschlüsselte Übertragung aller Daten',
            securePayment: ' Sichere Zahlung über Bankeinzug, Kredikarte oder PayPal ',
            instantAccess: ' Sofortige Nutzung nach der Buchung',
            userData: 'Daten des Benutzers',
            userEmailHint: 'Die hier angegebene E-Mail-Adresse wird für das Online-LernCenter freigeschaltet.',
            paymentAddress: 'Rechnungsadresse',
            street: 'Straße',
            postalcode: 'Postleitzahl',
            city: 'Ort',
            country: 'Land',
            buyLabel: 'Weiter zur Zahlung',
            abortLabel: 'Abbrechen',
            paymentMethod: 'Zahlungsmethode',
            boxText: 'Fragen zur Buchung? Wir helfen gerne weiter, einfach hier klicken.',
            paymentFormTitle: 'Rechnungsadresse',
            paymentFormText: 'Sollten Sie nicht ihre 30-tägige Testphase kündigen, so erhalten Sie alle anfallenden Rechnungen per E-Mail.',
            paymentFormTextNoTrial: 'Sie erhalten alle anfallenden Rechnungen per E-Mail.',
            paymentFormInstruction: 'Geben Sie jetzt Ihre Zahlungsdaten ein, um ihren <b>kostenlosen 30-Tage-Test zu starten.</b>',
            paymentFormInstructionNoTrial: 'Geben Sie jetzt Ihre Zahlungsdaten ein, um ihr <b>Abonnement zu aktivieren</b>.',
            paymentExplanationHeader: 'Zahlungsart',
            paymentExplanationContentText: 'Wir benötigen diese Information um sicherzustellen, dass Sie eine echte Person sind und um Missbrauch zu verhindern. Keine Sorge:',
            paymentExplanationFactsFirst: '<b>Wir berechnen Ihnen nichts</b>, bis Ihre kostenlose Testphase endet.',
            paymentExplanationFactsSecond: '{text} geht schnell und einfach. {link}',
            paymentExplanationFactsThird: '<b>Sie haben volle 30 Tage Zeit</b>, das Online-LernCenter gemeinsam mit Ihrem Kind in Ruhe auszuprobieren.',
            paymentExplanationExtra: 'Wir werden ihr Zahlungsmittel nicht belasten, bis ihre 30-Tage-Testphase beendet ist.',
            noChosenProduct: 'Abo nicht ausgewählt. Bitte vorher auf https://www.schuelerhilfe.de ein Abo wählen!',
        },
        activate: {
            mainHeading: 'Zugang zum Online-LernCenter jetzt freischalten',
            pageTitle: 'Zugang zum Online-LernCenter jetzt freischalten',
            deactivatedText: 'Dein Zugang für das Online-LernCenter ist leider abgelaufen. Um ihn wieder '
                           + 'freizuschalten und alle Funktionen des Online-LernCenters nutzen zu können, bieten wir '
                           + 'dir vier Möglichkeiten an:',
            becomeCustomerHeader: 'Kunde werden',
            becomeCustomer: 'Werde Kunde in einem unserer Standorte und erhalte den Zugang zum Online-LernCenter '
                          + 'kostenlos.',
            becomeCustomerLabel: 'Unsere Standorte',
            buyAboHeader: 'Abo kaufen',
            buyAbo: 'Dein Zugang wird sofort wieder freigeschaltet und du kannst direkt weiter lernen.',
            questions: 'Fragen zur Buchung?',
            weCanHelp: 'Wir helfen gerne weiter, einfach hier klicken.',
            reactivate: 'Reaktivieren',
            reactivateText: '- Mitgliedschaft reaktivieren und direkt weiter lernen.',
            reactivateSuccessMessage: 'Vielen Dank für die Reaktivierung Ihres Accounts. Ihr Abonnement ist - einem '
                + 'gültigen Zahlungseingang vorausgesetzt - ab dem {DATUM} um {UHRZEIT} wieder für Sie nutzbar. '
                + 'Bitte stellen Sie sicher, dass Ihre in Ihrem Konto hinterlegten Zahlungsdaten aktuell sind.',
            reactivateSuccessMessageFallBack: 'Vielen Dank für die Reaktivierung Ihres Accounts. Ihr Abonnement ist - einem '
            + 'gültigen Zahlungseingang vorausgesetzt und kann nach Eingang der Zahlung wieder genutzt werden. '
            + 'Bitte stellen Sie sicher, dass Ihre in Ihrem Konto hinterlegten Zahlungsdaten aktuell sind.',
            needSupport: 'Du brauchst mehr Unterstützung?',
            trialLesson: 'Sicher Dir jetzt gratis Probestunden!',
        },
        aiTutor: {
            title: 'Kira - die KI Lernassistentin',
            mathWelcomeMessage: 'Hallo! Ich bin Kira, deine Mathematik-Tutorin. Ich bin hier, um dir zu helfen, Mathematik besser zu verstehen und zu meistern. Wenn du spezifische Fragen hast oder bei einem Problem feststeckst, zögere nicht, es mir zu zeigen. Denk daran, dass Fehler ein wichtiger Teil des Lernprozesses sind, und ich bin hier, um dich dabei zu unterstützen, diese zu überwinden. Welche mathematische Herausforderung hast du heute für mich?',
            germanWelcomeMessage: 'Hallo! Mein Name ist Kira, und ich bin hier, um dir dabei zu helfen, Deutsch besser zu meistern. Ich freue mich darauf, dich bei deinen Deutschkenntnissen zu unterstützen, sei es in Grammatik, Literatur oder anderen sprachlichen Aspekten. Zögere nicht, mir spezifische Fragen zu stellen oder Texte zu präsentieren, an denen du arbeitest. Denke daran, dass Fehler ein wichtiger Teil des Lernprozesses sind, und ich bin hier, um dir zu helfen, diese zu überwinden. Wie kann ich dir heute helfen?',
            englishWelcomeMessage: 'Hallo! Mein Name ist Kira, und ich bin hier, um dir dabei zu helfen, dein Englisch zu verbessern. Egal, ob du Hilfe bei Grammatik, Wortschatz, Textanalyse oder etwas anderem brauchst, zögere nicht, mir deine Fragen zu stellen. Ich werde dir helfen, die Konzepte zu verstehen und deine Fähigkeiten zu verbessern. Fehler sind ein wichtiger Teil des Lernprozesses, also lass uns gemeinsam daran arbeiten, sie zu überwinden. Wie kann ich dir heute helfen?',
            latinWelcomeMessage: 'Hallo! Mein Name ist Kira, und ich bin hier, um dir zu helfen, Latein besser zu verstehen und zu meistern. Wenn du spezifische Fragen hast oder an einem bestimmten Text arbeitest, lass es mich wissen. Gemeinsam können wir die Herausforderungen angehen und Lösungen finden. Denk daran, dass Fehler ein wichtiger Teil des Lernprozesses sind, und ich bin hier, um dich zu unterstützen. Wie kann ich dir heute helfen?',
            frenchWelcomeMessage: 'Hallo! Mein Name ist Kira, und ich bin hier, um dir zu helfen, dein Französisch zu verbessern. Egal, ob du Fragen zur Grammatik, zu Textanalysen oder zu anderen Aspekten der französischen Sprache hast, ich stehe dir zur Verfügung. Erzähle mir doch ein bisschen von dir: Woran arbeitest du gerade im Französischunterricht? Denke daran, dass Fehler ein wichtiger Teil des Lernprozesses sind, und ich bin hier, um dich dabei zu unterstützen, sie zu überwinden. Lass uns gemeinsam daran arbeiten, dass du dich im Französischen sicherer fühlst!',
            dataPrivacyConsent: 'Die <a href={link1} target="_blank">Datenschutzerklärung</a> für Kira - die KI Lernassistentin habe ich zur Kenntnis genommen und bin mit deren Inhalt einverstanden.',
            policiesSubmitLabel: 'Akzeptieren',
            loginError: 'Der automatische Login bei Kira ist fehlgeschlagen.',
            registrationError: 'Die automatische Registrierung bei Kira ist fehlgeschlagen.',
            feedbackMessage: 'Bei Feedback, Fragen und Anmerkungen gerne hier melden: <a href="mailto:support-kira@schuelerhilfe.de">support-kira@schuelerhilfe.de</a>',
            faqMessage: 'Informationen zu Kira',
        },
        openActivation: {
            mainHeading: 'Vielen Dank für deine Registrierung',
            pageTitle: 'Vielen Dank für deine Registrierung',
            deactivatedText: 'Die Registrierung wird gerade abgeschlossen und dein Zugang für dich freigeschaltet. '
                           + 'Spätestens in 15 Minuten kannst du dich einloggen. Viel Spaß bei der Nutzung des '
                           + 'Online-LernCenters!',
            loginHeader: 'Einloggen',
            login: 'Hier geht es direkt zum Login.',
            questions: 'Fragen zur Buchung?',
            weCanHelp: 'Wir helfen gerne weiter, einfach hier klicken.',
            becomeCustomerHeader: 'Kunde werden',
            becomeCustomer: 'Du hast Probleme in der Schule? Lass dich kostenlos beraten zur Profi-Nachhilfe der '
                          + 'Schülerhilfe!',
        },
        exams: {
            pageTitle: 'Deine interaktiven Aufgaben - Online-LernCenter',
            exam: 'Interaktive Aufgabe',
            examsContentBoxLabel: 'Interaktive Aufgaben im Fach ',
            examsFilterContentBoxLabel: 'Interaktive Aufgaben anzeigen für',
            examsInitContentBoxLabel: 'Interaktive Aufgabe verschicken',
            examsTableEmptyMessage: 'Keine interaktiven Aufgaben gefunden.',
            coursesFilterContentBoxLabel: 'Kurs auswählen',
            courseButtonLabel: 'Kurs starten',
            detailsTableEmptyMessage: 'Keine Einträge gefunden.',
            resetLabel: 'neu starten',
            downloadLabel: 'Download',
            student: 'Schüler',
            submitLabel: 'Senden',
            resetSuccess: 'Die interaktive Aufgabe wurde erfolgreich zurückgesetzt.',
            confirmResetDialogTitle: 'Neu starten',
            confirmResetDialogText: 'Soll diese interaktive Aufgabe wirklich neu gestartet werden?',
            adminButtonLabel: 'Interaktive Aufgabe verschicken',
            linkBoxTitle: 'Interaktive Aufgaben',
            linkBoxText: 'Kostenlose Wissens-Checks für alle Klassen in Mathe, Deutsch, Englisch, Französisch und '
                       + 'Latein.',
        },
        examsAdmin: {
            pageTitle: 'Interaktive Aufgabe verschicken - Online-LernCenter',
            sendExamsContentBoxLabel: 'Interaktive Aufgabe verschicken',
            backButtonLabel: 'zurück',
            subject: 'Fach',
            center: 'Standort',
            classlevel: 'Klassenstufe / Lernjahr',
            examination: 'Interaktive Aufgabe',
            level: 'Niveau',
            student: 'Schüler',
            submitLabel: 'Senden',
            previewButtonLabel: 'Vorschau anzeigen',
            addStudentSuccess: 'Die interaktive Aufgabe wurde erfolgreich verschickt.',
        },
        favorites: {
            confirmDeleteDialogText: 'Soll dieser Favorit wirklich entfernt werden?',
            deleteLabel: 'Entfernen',
        },
        course: {
            title: 'Dein nächster Online-Unterricht:',
            allCompleted: 'Alle heutigen Kurse sind bereits beendet',
            courseLink: 'Jetzt zum Kurs',
            link: 'Kursübersicht',
            description: 'Der Link zum Unterrichtsraum ist 5 Minuten vor Kursbeginn in deiner Kursübersicht '
            + 'verfügbar. Gehe dazu auf „Kursübersicht" und Klicke zur Teilnahme auf den anstehenden Kurs.',
        },
        courses: {
            title: 'Deine Kursübersicht',
            subTitle: 'Der Link zum Unterrichtsraum ist frühstens 5 Minuten vor Start der Unterrichtsstunde verfügbar. '
            + 'Um teilzunehmen, klicke auf den anstehenden Kurs hier in der Übersicht.',
            noCoursesFound: 'Für den heutigen Tag wurden keine Online Kurse gefunden.',
        },
        loginForm: {
            mainHeading: 'Logge dich hier ein',
            formDescriptionText: 'Einfach E-Mail Adresse und Passwort eingeben und schon geht es los!',
            email: 'E-Mail-Adresse',
            password: 'Passwort',
            passwordLost: 'Passwort vergessen?',
            requiredFieldsNotice: '*Pflichtfelder',
            submitLabel: 'Einloggen',
            secureTransmission: ' Verschlüsselte Übertragung aller Daten',
        },
        logout: {
            pageTitle: 'Logout - Online-LernCenter',
            mainHeading: 'Du wurdest erfolgreich ausgeloggt',
            metaDescription: 'Vielen Dank für die Nutzung des Online-LernCenters. Wir hoffen, du hast erfolgreich '
                           + 'gelernt und freuen uns auf deinen nächsten Besuch.',
            description: 'Vielen Dank für die Nutzung des Online-LernCenters. Wir freuen uns auf deinen nächsten Besuch.',
        },
        infoMessages: {
            courseStartPending: 'Der Link zum Unterrichtsraum ist 5 Minuten vor Kursbeginn verfügbar.',
        },
        errorMessages: {
            error: 'Fehler',
            invalidDate: 'Bitte geben Sie ein gültiges Datum im Format TT.MM.JJJJ ein.',
            networkError: 'Es ist ein unbekannter Netzwerk-Fehler aufgetreten.',
            saveOpinionError: 'Fehler beim Speichern des Beitrags. Bitte versuchen Sie es erneut.',
            addStudentToExaminationError: 'Fehler beim Verschicken des Fachchecks. Bitte versuchen Sie es erneut.',
            createOlatTeacherError: 'Fehler bei der Einrichtung des Zugangs zur Lernplattform.',
            addParticipantToWebinarError: 'Dieses Webinar ist bereits ausgebucht.',
            resetUserExamError: 'Fehler beim Zurücksetzen des Fachchecks. Bitte versuchen Sie es erneut.',
            getUserXOlatTokenError: 'Fehler bei der Authentifizierung des OpenOlat-Zugangs.',
            passwordConfirmation: 'Passwort und Passwort (Wiederholung) stimmen nicht überein.',
            passwordRules: 'Das gewählte Passwort entspricht nicht den Passwort-Regeln',
            requiredFields: 'Bitte füllen Sie alle Pflichtfelder aus.',
            serverError: 'Es ist ein unbekannter Fehler aufgetreten.',
            terms: 'Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen und die Datenschutzerklärung.',
            invalidPasswordConfirmation: 'Passwort und Passwort (Wiederholung) stimmen nicht überein.',
            noUserId: 'Anmeldeinformationen konnten nicht gelesen werden',
            invalidEmail: 'Diese E-Mail Adresse ist ungültig.',
            resultsPdfDownloadError: 'Die Datei konnte nicht gefunden werden.',
            userDataError: 'Benutzerdaten konnten nicht abgerufen werden.',
        },
        start: {
            pageTitle: 'OLC Relaunch',
            calendar: 'Kalender',
            excuse: 'Unterricht absagen',
            grades: 'Notenentwicklung',
            callTitle: 'Schülerhilfe anrufen',
            messagesTitle: 'Nachrichten',
            contractDetailsTitle: 'Vertragsdetails',
            rateTitle: 'Bewerten Sie uns!',
            yourCenter: 'Online-LernCenter',
            centerImageAlt: 'Online-LernCenter',
            phoneCallLabel: 'Anruf',
            messageLabel: 'Nachrichten',
            navigationLabel: 'Route',
            centerPhoneLabel: 'Tel.:',
        },
        dashboard: {
            pageTitle: 'Schülerhilfe - Online-LernCenter',
            title: 'Deine digitalen Lernhilfen im Online-LernCenter',
            profileModalText: 'Bitte ergänze folgende Angaben zu deinem Profil bevor es weiter geht:',
            acceptTerms: 'Die <a href={link1}>Allgemeinen Geschäftsbedingungen</a> sowie die '
                       + '<a href={link2}>Datenschutzerklärung</a> habe ich zur Kenntnis genommen und bin mit deren '
                       + 'Inhalt einverstanden.',
            acceptContact: 'Ich bin damit einverstanden, dass die ZGS Bildungs-GmbH und/oder die Schülerhilfe '
                         + 'GmbH & Co. KG zu mir Kontakt per E-Mail und Telefon aufnimmt, damit ich über exklusive '
                         + 'Angebote der Schülerhilfe (Werbung) informiert werden kann. Diese Einwilligung kann ich '
                         + 'jederzeit mit Wirkung für die Zukunft per Abmeldelink am Ende eines jeden Newsletters oder '
                         + 'per E-Mail an <a href={link1}>olc@schuelerhilfe.de</a> widerrufen. Für die '
                         + 'Datenverarbeitung gilt unsere allgemeine <a href={link1}>Datenschutzerklärung</a>.',
        },
        contentbox: {
            odhTitle: 'Online-Hausaufgabenhilfe',
            odhImageAlt: 'Online-Hausaufgabenhilfe',
            odhText: 'Stelle deine Frage an einen Nachhilfelehrer und erhalte individuelle Unterstützung in Mathe, '
                   + 'Deutsch und Englisch: täglich von 16 bis 20 Uhr!',
            examTitle: 'Interaktive Aufgaben',
            lisaExamTitle: 'Deine Interaktiven Aufgaben',
            examImageAlt: 'Interaktive Aufgaben',
            examText: 'Mache jetzt einen Wissens-Check in Mathe, Deutsch, Englisch, Französisch oder Latein und '
                    + 'teste deinen Lernstand in vielen unterschiedlichen Themen!',
            lisaExamText: 'Hier gelangst du zu deinen <strong>angefangenen</strong> oder <strong>abgeschlossenen</strong> Interaktiven Aufgaben.',
            coursesTitle: 'Digitale Lernpfade',
            coursesImageAlt: 'Digitale Lernpfade',
            coursesText: 'Entdecke das Englisch-Sprachtraining und geführte Lernkurse mit interaktiven Aufgaben zum intensiven Lernen für Mathe, '
                    + 'Deutsch und Englisch.',
            webinarTitle: 'Webinar-Kalender',
            webinarImageAlt: 'Webinar-Kalender',
            webinarText: 'Nimm an Live Vorträgen zu den wichtigsten Fächerthemen teil und stelle während des '
                       + 'Vortrages über den Chat Fragen! Hier findest du die aktuellen Termine.',
            aiTutorTitle: 'Kira - die KI Lernassistentin',
            aiTutorImageAlt: 'Kira - die KI Lernassistentin',
            aiTutorText: 'Stell unserer KI Lernassistentin Kira rund um die Uhr Fragen zu den Fächern Mathe, Deutsch und Englisch. Kira hilft dir, den Lernstoff zu verstehen und zeigt passende Lösungswege.',
            flipbooksTitle: 'Digitale Bücher',
            flipbooksImageAlt: 'Digitale Bücher',
            flipbooksText: 'Wirf einen Blick in unsere „Gute Noten“- und Abiturvorbereitungsreihe“- der Fächer Mathematik, Deutsch & Englisch.',
            kiquizTitle: 'KI Quiz',
            kiquizImageAlt: 'KI Quiz',
            kiquizText: 'Klicke hier, um zu deinem persönlichen Online-Training zu gelangen. Viel Spaß beim Trainieren!',
            testsiegerImageAlt: 'Testen Sie den Testsieger: Jetzt beim Testsieger Lerninhalte aufholen und Wissenslücken schließen',
        },
        banner: {
            imageAlt: 'Sommerangebot 2020',
        },
        activation: {
            successMessage: 'Ihr Zugang wurde erfolgreich freigeschaltet!',
            errorMessage: 'Es ist ein Fehler aufgetreten!',
            notActivatedMessage: 'Ihr Zugang konnte nicht aktiviert werden.',
            alreadyActivatedMessage: 'Ihr Zugang wurde bereits aktiviert',
            loginLabel: 'Zum Login',
        },
        authentication: {
            pageTitle: 'Dein Zugang zum Online-LernCenter der Schülerhilfe',
            caption: 'Logge dich hier ein',
            loginError: 'Fehler bei der Anmeldung! Bitte überprüfen Sie Ihre Daten!',
            error: 'Fehler bei der Kommunikation mit dem Server!',
            emailLabel: 'E-Mail-Adresse',
            passwordLabel: 'Passwort',
            loginLabel: 'Einloggen',
            stayLoggedInLabel: 'Angemeldet bleiben',
            passwordLostLabel: 'Passwort vergessen?',
            newHereLabel: 'Neu hier?',
            registerLabel: 'Jetzt registrieren!',
            storageWarning: 'Um das Online-LernCenter nutzen zu können, musst Du der Speicherung von Website-Daten '
                + 'zustimmen. Aktuell wird dies durch Deinen Browser blockiert. Bitte ändere die Einstellungen Deines'
                + 'Browsers entsprechend.',
        },
        authenticationLisa: {
            pageTitle: 'Dein Zugang zum Online-LernCenter der Schülerhilfe',
            caption: 'Logge dich hier ein',
            loginError: 'Fehler bei der Anmeldung! Bitte überprüfen Sie Ihre Daten!',
            error: 'Fehler bei der Kommunikation mit dem Server!',
            usernameLabel: 'Benutzername',
            passwordLabel: 'Passwort',
            loginLabel: 'Einloggen',
            policiesAcceptedLabel: 'Ich akzeptiere die {link} des Online-LernCenters.',
            termsOfUse: 'Nutzungsbedingungen',
            notice: '<strong>Hinweis:</strong> bei vergessenem Passwort ist ein neuer Benutzer-Account erforderlich!',
        },
        vidisAuthentication: {
            pageTitle: 'Online-LernCenter der Schülerhilfe und VIDIS',
            caption: 'Logge dich via VIDIS ein',
            loginError: 'Die Authentifizierung mit VIDIS ist fehlgeschlagen! Bitte erneut überprüfen!',
            error: 'Fehler bei der Kommunikation mit dem Server!',
            description: 'Um das Online-LernCenter nutzen zu können, muss du dich mit deinem <strong>VIDIS-Account</strong> anmelden.'
                + ' Anschließend wirst du automatisch zum Online-LernCenter weitergeleitet.'
                + ' Dort stehen dir alle Inhalte zur Verfügung.',
        },
        common: {
            subject: {
                ALL: 'Alle Fächer',
                ALE: 'Arbeitslehre',
                BIO: 'Biologie',
                CHE: 'Chemie',
                DEU: 'Deutsch',
                ENG: 'Englisch',
                FRZ: 'Französisch',
                GES: 'Geschichte',
                ITA: 'Italienisch',
                LAT: 'Latein',
                MAT: 'Mathematik',
                POL: 'Politik',
                RUS: 'Russisch',
                SPA: 'Spanisch',
                WIR: 'Wirtschaftslehre BWL VWL',
                AGR: 'Altgriechisch',
                DYK: 'Dyskalkulie',
                ERD: 'Erdkunde',
                INF: 'Informatik',
                JAP: 'Japanisch',
                LEG: 'Legasthenie',
                MUS: 'Musik',
                NAW: 'Naturwissenschaft',
                NED: 'Niederländisch',
                PHY: 'Physik',
                REW: 'Rechnungswesen',
                SAC: 'Sachkunde',
                TEC: 'Technik',
            },
        },
        contactForm: {
            pageTitle: 'Online-LernCenter - Kontakt aufnehmen',
            kindOfRequest: 'Bitte wählen Sie Ihr Anliegen',
            request: 'Anliegen',
            excuseChild: 'Unterricht absagen',
            catchUpLessons: 'Nachholstunden vereinbaren',
            changeContractData: 'Fragen zum Vertrag',
            changeContactData: 'Kontaktdaten ändern',
            olcSupportRequest: 'Online-LernCenter Supportanfrage',
            consultation: 'Elterngespräch vereinbaren',
            feedback: 'Feedback geben',
            examination: 'Prüfungstermin melden',
            addMark: 'Note eintragen',
            other: 'Sonstiges Anliegen',
            requestSubmittedMessage: 'Ihre Nachricht wurde erfolgreich übermittelt.',
            requestCouldNotBeSubmittedMessage: 'Beim Übermitteln Ihrer Nachricht ist ein Fehler aufgetreten.',
            forms: {
                salutation: 'Sehr geehrte Schülerhilfe-Leitung',
                salutationOlc: 'Sehr geehrtes Support-Team',
                subjectLabel: 'Betreff',
                submitLabel: 'Senden',
                excuseChild: {
                    from: 'von',
                    to: 'bis',
                    cause: 'Grund',
                    description: 'Beschreibung',
                    illness: 'Krankheit',
                    schoolEvent: 'Schulveranstaltung',
                    internship: 'Praktikum',
                    other: 'Sonstiger Grund',
                    subject: 'Fach',
                    messageIntro: 'hiermit sage ich für mein Kind {name} ({icasId}) den Unterricht für den folgenden '
                                + 'Zeitraum ab',
                },
                catchUpLesson: {
                    infoText: 'Bitte rufen Sie zur Terminvereinbarung einer Nachholstunde '
                            + 'in Ihrer Schülerhilfe vor Ort an! Vielen Dank.',
                    phoneCallLabel: 'Jetzt anrufen!',
                },
                changeContractData: {
                    infoText: 'Bitte rufen Sie zur Mitteilung von Änderungen in Ihrer Schülerhilfe vor Ort an! Vielen '
                            + 'Dank.',
                    phoneCallLabel: 'Jetzt anrufen!',
                },
                changeContactData: {
                    infoText: 'Bitte beschreiben Sie die gewünschte Änderung Ihrer Kontaktdaten:',
                    messageIntro: 'hiermit möchte ich Ihnen die folgende Änderung der Kontaktdaten '
                                + 'für mein Kind {name} ({number}) mitteilen',
                },
                olcSupportRequest: {
                    infoText: 'Bitte beschreiben Sie die Angelegenheit, in der Sie Unterstützung benötigen:',
                    messageIntro: 'ich benötige in folgender Angelegenheit Ihre Unterstützung',
                },
                consultation: {
                    date: 'Wunschtermin',
                    closureDaysInfo: 'Achtung, der Standort ist an folgenden Wochentagen geschlossen:',
                    timeframe: 'Zeitfenster',
                    message: 'Nachricht (optional)',
                    messageIntro: 'hiermit möchte ich ein Elterngespräch zu meinem Kind {name} ({icasId}) vereinbaren',
                },
                feedback: {
                    infoText: 'Bitte beschreiben Sie die Angelegenheit, zu der Sie Feedback geben möchten:',
                    messageIntro: 'hiermit möchte ich Ihnen Feedback zu folgender Angelegenheit geben',
                },
                reportExam: {
                    date: 'Datum',
                    subject: 'Fach',
                    topic: 'Thema',
                    messageIntro: 'hiermit möchte ich Ihnen einen Prüfungstermin '
                                + 'für mein Kind {name} ({icasId}) mitteilen',
                },
                addMark: {
                    date: 'Datum',
                    mark: 'Note',
                    kindOfMark: 'Art der Note',
                    exam: 'Klausur',
                    test: 'Test',
                    other: 'Sonstige',
                    certificate: 'Zeugnis',
                    examination: 'Prüfung',
                    application: 'Anmeldenote',
                    examinationKinds: 'Prüfung/Klausur/Test',
                    subject: 'Fach',
                    topic: 'Thema',
                    messageIntro: 'hiermit möchte ich Ihnen eine Note meines Kindes {name} ({icasId}) mitteilen',
                    submitLabel: 'Note eintragen',
                },
                other: {
                    infoText: ' ',
                    messageIntro: 'die folgende Nachricht zum Kind {name} ({number}) wurde über die Eltern-App '
                        + 'gesendet',
                },
            },
        },
        testAccountRegisterForm: {
            mainHeading: 'Nur noch <b>wenige Schritte</b>, um das Online-LernCenter kostenlos zu testen.',
            mainHeadingNoTrial: 'Nur noch <b>wenige Schritte</b>, um das Online-LernCenter zu verwenden.',
            formDescritpionText: 'Es ist ganz einfach: Formular mit den Daten des Nutzers ausfüllen, absenden, Bestätigungslink in der E-Mail anklicken und einloggen. Fertig!',
            firstname: 'Vorname',
            lastname: 'Nachname',
            email: 'E-Mail-Adresse',
            password: 'Passwort',
            requiredFieldsNotice: '*Pflichtfelder',
            sendLabel: 'Weiter',
            forwardToLogin: 'Zum Loginbereich',
            registerSuccess: '<h4>Registrierung erfolgreich!</h4>Bitte prüfe deinen Posteingang. Wir haben dir eine E-Mail mit weiteren Schritten geschickt.',
            registerImportantHint: '<h4>Wichtiger Hinweis:</h4>Solltest du nach einigen Minuten noch keine E-Mail erhalten haben, stelle bitte sicher, dass unsere E-Mail nicht in deinem Spamordner gelandet ist und kontaktiere uns mit unserem <a href=\'https://www.schuelerhilfe.de/lerncenter/feedback\'>Feedbackformular</a>.',
            freeTrialBenefitsFirst: '30 Tage alle Inhalte kostenlos nutzen & Hilfe zu allen Schulthemen erhalten.',
            noTrialBenefitsFirst: 'Alle Inhalte nutzen & Hilfe zu allen Schulthemen erhalten.',
            freeTrialBenefitsSecond: 'Nicht zufrieden? Du kannst jederzeit online kündigen.',
            terms: 'AGBs und Datenschutz*',
            promotionTitle: 'Lerntipps und Werbeangebote',
            promotion: 'Ich möchte Lerntipps und Werbeangebote per E-Mail erhalten. Diese Einwilligung kann ich jederzeit mit Wirkung für die Zukunft per Abmeldelink am Ende eines jeden Newsletters oder per E-Mail an <a href="mailto:olc@schuelerhilfe.de">olc@schuelerhilfe.de</a> widerrufen.',
            accountAlreadyExists: 'Haben Sie bereits einen Account?',
        },
        testAccountRegisterFormContainer: {
            pageTitle: '30 Tage gratis testen: Das Online-LernCenter der Schülerhilfe',
            metaDescription: '30 Tage gratis Mathe, Deutsch, Englisch, Latein und Französisch online lernen ➔ Einfach das Schülerhilfe LernCenter testen ✓ Leistungen durch das LernCenter verbessern ✓',
            hotlineBoxText: 'Fragen zur Buchung? Wir helfen gerne weiter, einfach hier klicken.',
        },
        specialRegisterFormContainer: {
            pageTitle: 'Online-LernCenter der Schülerhilfe',
            metaDescription: 'Mathe, Deutsch, Englisch, Latein und Französisch online lernen ➔ Einfach das Schülerhilfe LernCenter testen ✓ Leistungen durch das LernCenter verbessern ✓',
            specialStateOfferPageTitle: 'Online-LernCenter der Schülerhilfe',
            specialStateOfferMetaDescription: 'Bis zum 14.03.2022 gratis Mathe, Deutsch, Englisch, Latein und Französisch online lernen ➔ Einfach das Schülerhilfe LernCenter testen ✓ Leistungen durch das LernCenter verbessern ✓',
        },
        knowledgeCheckRegisterFormContainer: {
            default: {
                pageTitle: 'Online-LernCenter der Schülerhilfe',
                metaDescription: 'Herzlich Willkommen zum Abschlusstest',
            },
            consultAndSellTest: {
                pageTitle: 'Online-LernCenter der Schülerhilfe || "Beraten und Verkaufen"',
                metaDescription: 'Herzlich Willkommen zum Abschlusstest „Beraten und Verkaufen“. Einfach das Schülerhilfe LernCenter testen ✓ Leistungen durch das LernCenter verbessern ✓',
            },
            freshUpConsultAndSellTest: {
                pageTitle: 'Online-LernCenter der Schülerhilfe || "Fresh Up – Beraten und Verkaufen"',
                metaDescription: 'Herzlich Willkommen zum Abschlusstest „Beraten und Verkaufen“. Einfach das Schülerhilfe LernCenter testen ✓ Leistungen durch das LernCenter verbessern ✓',
            },
            employeeTest: {
                pageTitle: 'Online-LernCenter der Schülerhilfe || "Einstellungstest"',
                metaDescription: 'Herzlich Willkommen zum Abschlusstest „Einstellungstest“. Einfach das Schülerhilfe LernCenter testen ✓ Leistungen durch das LernCenter verbessern ✓',
            },
            customerPupilParentTest: {
                pageTitle: 'Online-LernCenter der Schülerhilfe || "Kundengespräche, Schülergespräche und Elterngespräche!"',
                metaDescription: 'Herzlich Willkommen zum Abschlusstest „Kundengespräche, Schülergespräche und Elterngespräche“. Einfach das Schülerhilfe LernCenter testen ✓ Leistungen durch das LernCenter verbessern ✓',
            },
            freshUpCustomerPupilParentTest: {
                pageTitle: 'Online-LernCenter der Schülerhilfe || "Fresh Up – Kundengespräche"',
                metaDescription: 'Herzlich Willkommen zum Abschlusstest „Kundengespräche, Schülergespräche und Elterngespräche“. Einfach das Schülerhilfe LernCenter testen ✓ Leistungen durch das LernCenter verbessern ✓',
            },
            nhlRecruitmentOnboardingTest: {
                pageTitle: 'Online-LernCenter der Schülerhilfe || "NHL Suche, Einstellung und Einarbeitung Test"!',
                metaDescription: 'Herzlich Willkommen zum Abschlusstest „Kundengespräche, Schülergespräche und Elterngespräche“. Einfach das Schülerhilfe LernCenter testen ✓ Leistungen durch das LernCenter verbessern ✓',
            },
            startUpTrainingTest: {
                pageTitle: 'Online-LernCenter der Schülerhilfe || "Startschulung für neue Franchise-Partner*innen Test"!',
                metaDescription: 'Herzlich Willkommen zum Abschlusstest „Startschulung für neue Franchise-Partner*innen Test“. Einfach das Schülerhilfe LernCenter testen ✓ Leistungen durch das LernCenter verbessern ✓',
            },
        },
        lisaRegisterFormContainer: {
            pageTitle: 'Online-LernCenter Sachsen-Anhalt',
            metaDescription: 'Herzlich willkommen zum Online-LernCenter Sachsen-Anhalt',
        },
        knowledgeCheckRegisterForm: {
            firstname: 'Vorname',
            lastname: 'Nachname',
            email: 'E-Mail-Adresse',
            password: 'Passwort',
            requiredFieldsNotice: '*Pflichtfelder',
            sendLabel: 'Weiter',
            register: 'Anmelden',
            forwardToLogin: 'Zum Loginbereich',
            registerSuccess: '<h4>Registrierung erfolgreich!</h4>Sie erhalten die Zugangsdaten zum Wissenscheck auf die hier hinterlegte E-Mail Adresse.',
            registerImportantHint: '<h4>Wichtiger Hinweis:</h4>Sollten Sie nach einigen Minuten noch keine E-Mail erhalten haben, stellen Sie bitte sicher, dass unsere E-Mail nicht in ihrem Spamordner gelandet ist. Sollten technische Probleme auftauchen, wenden Sie sich gerne an den Support unter <a href="mailto:test-support@schuelerhilfe.de">test-support@schuelerhilfe.de</a>.',
            terms: 'AGBs und Datenschutz*',
            promotionTitle: 'Lerntipps und Werbeangebote',
            promotion: 'Ich möchte Lerntipps und Werbeangebote per E-Mail erhalten. Diese Einwilligung kann ich jederzeit mit Wirkung für die Zukunft per Abmeldelink am Ende eines jeden Newsletters oder per E-Mail an <a href="mailto:olc@schuelerhilfe.de">olc@schuelerhilfe.de</a> widerrufen.',
            accountAlreadyExists: 'Haben Sie bereits einen Account?',
            default: {
                header: 'Online-LernCenter der Schülerhilfe',
                description: 'Herzlich Willkommen zum Abschlusstest',
                imageName: 'consultAndSellTest',
            },
            consultAndSellTest: {
                header: 'Beraten und Verkaufen',
                description: 'Herzlich Willkommen zum Wissenscheck<Strong> „Beraten und Verkaufen“.</Strong><br /><br />Nutzen Sie die Chance zu sehen, welche Bereiche Sie direkt verinnerlicht haben und wo Sie evt. noch Unterstützung benötigen. Nach Abschluss des Tests, bekommen Sie eine Auswertung im PDF Format.'
                            + '<br /><br /><Strong>Achtung:</Strong> Sollten Sie als Regional- oder Gebietsleitung für uns tätig sein, dann wählen Sie bitte einen Standort aus Ihrem Gebiet.',
                imageName: 'consultAndSellTest',
            },
            freshUpConsultAndSellTest: {
                header: 'Fresh Up - Beraten und Verkaufen',
                description: 'Herzlich Willkommen zum Wissenscheck<Strong> „Fresh up - Beraten und Verkaufen“.</Strong><br /><br />Nutzen Sie die Chance zu sehen, welche Bereiche Sie direkt verinnerlicht haben und wo Sie evtl. noch Unterstützung benötigen. Nach Abschluss des Tests können Sie sich Ihr Testergebnis im PDF-Format downloaden und mit Ihrem Vorgesetzen besprechen.',
                imageName: 'consultAndSellTest',
            },
            employeeTest: {
                header: 'Einstellungstest',
                description: 'Herzlich Willkommen zum Wissenscheck <Strong>„Einstellungstest“</Strong> für neue Mitarbeiter/-innen unserer Zentrale!<br /><br />Planen Sie bitte ca. 45 Minuten Zeit für sich ein, in der Sie ungestört die Fragen beantworten können. Die Themengebiete sind Mathematik, Deutsch, Englischkenntnisse und Logik. '
                            + 'Nach Abschluss des Tests bekommen Sie und wir eine Zusammenfassung der Ergebnisse als PDF.<br /><br />Wir wünschen viel Erfolg beim Beantworten der Fragen und melden uns in Kürze wieder bei Ihnen!'
                            + '<br /><br /><Strong>Achtung:</Strong> Hier bitte Ihre personenbezogene (vorname.nachname@schuelerhilfe.de) und keine Standort E-Mail-Adresse verwenden.',
                imageName: 'employeeTest',
            },
            customerPupilParentTest: {
                header: 'Kundengespräche, Schülergespräche und Elterngespräche',
                description: 'Herzlich Willkommen zum Wissenscheck<Strong> „Kundengespräche, Schülergespräche und Elterngespräche“.</Strong><br /><br />Nutzen Sie die Chance zu sehen, welche Bereiche Sie direkt verinnerlicht haben und wo Sie evt. noch Unterstützung benötigen. Nach Abschluss des Tests, bekommen Sie eine Auswertung im PDF Format.'
                            + '<br /><br /><Strong>Achtung:</Strong> Sollten Sie als Regional- oder Gebietsleitung für uns tätig sein, dann wählen Sie bitte einen Standort aus Ihrem Gebiet.',
                imageName: 'customerPupilParentTest',
            },
            freshUpCustomerPupilParentTest: {
                header: 'Fresh Up - Kundengespräche',
                description: 'Herzlich Willkommen zum Wissenscheck<Strong> „Kundengespräche“.</Strong><br /><br />Nutzen Sie die Chance zu sehen, welche Bereiche Sie direkt verinnerlicht haben und wo Sie evtl. noch Unterstützung benötigen. Nach Abschluss des Tests können Sie sich Ihr Testergebnis im PDF-Format downloaden und mit Ihrem Vorgesetzen besprechen.',
                imageName: 'customerPupilParentTest',
            },
            nhlRecruitmentOnboardingTest: {
                header: 'NHL Suche, Einstellung und Einarbeitung',
                description: 'Herzlich Willkommen zum Wissenscheck<Strong> „NHL Suche, Einstellung und Einarbeitung“.</Strong><br /><br />Nutzen Sie die Chance zu sehen, welche Bereiche Sie direkt verinnerlicht haben und wo Sie evt. noch Unterstützung benötigen. Nach Abschluss des Tests, bekommen Sie eine Auswertung im PDF Format.'
                            + '<br /><br /><Strong>Achtung:</Strong> Sollten Sie als Regional- oder Gebietsleitung für uns tätig sein, dann wählen Sie bitte einen Standort aus Ihrem Gebiet.',
                imageName: 'nhlRecruitmentOnboardingTest',
            },
            startUpTrainingTest: {
                header: 'Wissenscheck Startschulung für neue Franchise-Partner*innen',
                description: 'Herzlich Willkommen zum Wissenscheck<Strong> „Startschulung für neue Franchise-Partner*innen“.</Strong><br /><br />Nutzen Sie die Chance zu sehen, welche Bereiche Sie direkt verinnerlicht haben und wo Sie evtl. noch Unterstützung benötigen. Nach Abschluss des Tests können Sie sich Ihr Testergebnis im PDF-Format downloaden.'
                            + '<br /><br /><Strong>Achtung:</Strong> Sollten Sie als Regional- oder Gebietsleitung für uns tätig sein, dann wählen Sie bitte einen Standort aus Ihrem Gebiet.',
                imageName: 'startUpTrainingTest',
            },
        },
        lisaRegisterForm: {
            description: 'Erstelle hier ganz einfach <Strong>dein persönliches Passwort</Strong> und du erhältst im Anschluss einen Benutzernamen, mit dem Du dich im Online-LernCenter anmelden kannst.',
            password: 'Passwort',
            passwordConfirmation: 'Passwort wiederholen',
            requiredFieldsNotice: '*Pflichtfelder',
            acceptTerms: 'Ich akzeptiere die <a href={link}>Nutzungsbedingungen</a> des Online-LernCenters.',
            submitLabel: 'Weiter',
            registerSuccess: {
                description: '<Strong>Registrierung erfolgreich <span>&#10003;</span> </Strong><br /><br />Du kannst dich ab sofort mit dem Benutzernamen <Strong>{username}</Strong> und dem von dir gewählten Passwort anmelden.<br /><br />',
                toLoginButton: 'Jetzt direkt loslegen',
                registerImportantHint: 'Bitte speichere deinen Benutzernamen und dein Passwort gut ab.<br /><br />'
                                    + 'Es gibt keine Möglichkeit, ein neues Passwort zu erstellen.<br /><br />'
                                    + 'Wenn du dich drei Monate lang nicht im Online-LernCenter angemeldet hast, wird der Account gelöscht.<br /><br />'
                                    + 'Du kannst dir aber jederzeit einen neuen Account anlegen.',
            },
        },
        specialRegisterForm: {
            mainHeading: 'Testen Sie unser Online-LernCenter kostenlos: Bis zum 11.04.2021 gratis online lernen!',
            mainHeadingSixtyDays: 'Exklusives Angebot für Lidl Plus-Kunden:<br/>2 Monate gratis online lernen!',
            mainHeadingSachsenAnhalt: 'Unser Angebot für die Schüler*innen in Sachsen-Anhalt:',
            specialStateOfferMainHeadingForeign: 'Exklusives Angebot für Schüler und Lehrer:<br />Bis zum {summerVacationDate} gratis online lernen!',
            specialStateOfferMainHeading: 'Exklusives Angebot für Schüler und Lehrer in <span class="noWrapOnHyphen">{nationState}:</span> Bis zum {promotionEndDate} gratis online lernen!',
            specialStateOfferNWeeks: 'Überzeugen Sie sich von der Qualität des Online-Lerncenters!',
            formDescritpionText: 'Es ist ganz einfach: Formular mit den Daten des Nutzers ausfüllen, absenden, Bestätigungslink in der E-Mail anklicken und einloggen. Fertig!',
            formDescriptionTextSachsenAnhalt: 'Einfach Vor- und Nachname sowie die E-Mail-Adresse angeben und das Online LernCenter bis zum 21.09.2021 kostenlos nutzen.<br /><br />Das OLC – aktuell einzusetzen für die Fächer Mathematik, Deutsch, Englisch, Französisch und Latein – ist für '
                                            + 'jede Schulform und jede Jahrgangsstufe in Sachsen-Anhalt bestens geeignet und in der Praxis bewährt. Bitte beachten Sie auch die Live-Webinare, die wir für diesen Zeitraum für Sie zusätzlich durchführen.<br /><br />Viel Spaß mit dem Online LernCenter wünscht<br /><br />Ihr Schülerhilfe Team',
            firstname: 'Vorname',
            lastname: 'Nachname',
            email: 'E-Mail-Adresse',
            password: 'Passwort',
            requiredFieldsNotice: '*Pflichtfelder',
            sendLabel: 'Weiter',
            forwardToLogin: 'Zum Loginbereich',
            registerSuccess: '<h4>Registrierung erfolgreich!</h4>Bitte prüfe deinen Posteingang. Wir haben dir eine E-Mail mit weiteren Schritten geschickt.',
            registerImportantHint: '<h4>Wichtiger Hinweis:</h4>Solltest du nach einigen Minuten noch keine E-Mail erhalten haben, stelle bitte sicher, dass unsere E-Mail nicht in deinem Spamordner gelandet ist. Sollten technische Probleme auftauchen, wenden Sie sich gerne an den Support unter <a href="mailto:test-support@schuelerhilfe.de">test-support@schuelerhilfe.de</a>.',
            terms: 'AGBs und Datenschutz*',
            promotionTitle: 'Lerntipps und Werbeangebote',
            promotion: 'Ich möchte Lerntipps und Werbeangebote per E-Mail erhalten. Diese Einwilligung kann ich jederzeit mit Wirkung für die Zukunft per Abmeldelink am Ende eines jeden Newsletters oder per E-Mail an <a href="mailto:olc@schuelerhilfe.de">olc@schuelerhilfe.de</a> widerrufen.',
            accountAlreadyExists: 'Haben Sie bereits einen Account?',
        },
        registerForm: {
            mainHeading: 'Testen Sie unser Online-LernCenter kostenlos: Bis zum 11.04.2021 gratis online lernen!',
            mainHeadingSixtyDays: 'Exklusives Angebot für Lidl Plus-Kunden:<br/>2 Monate gratis online lernen!',
            specialStateOfferMainHeadingForeign: 'Exklusives Angebot für Schüler und Lehrer:<br />Bis zum {summerVacationDate} gratis online lernen!',
            specialStateOfferMainHeading: 'Exklusives Angebot für Schüler und Lehrer in <span class="noWrapOnHyphen">{nationState}:</span> Bis zum {promotionEndDate} gratis online lernen!',
            formDescritpionText: 'Es ist ganz einfach: Formular mit den Daten des Nutzers ausfüllen, absenden, Bestätigungslink in der E-Mail anklicken und einloggen. Fertig!',
            firstname: 'Vorname',
            lastname: 'Nachname',
            email: 'E-Mail-Adresse',
            password: 'Passwort',
            phone: 'Mobilnummer',
            validationCode: 'Validierungscode',
            getValidationCode: 'Code anfordern',
            requiredFieldsNotice: '*Pflichtfelder',
            sendLabel: 'Weiter',
            forwardToLogin: 'Zum Loginbereich',
            registerSuccess: '<h4>Registrierung erfolgreich!</h4>Bitte prüfe deinen Posteingang. Wir haben dir eine E-Mail mit weiteren Schritten geschickt.',
            registerImportantHint: '<h4>Wichtiger Hinweis:</h4>Solltest du nach einigen Minuten noch keine E-Mail erhalten haben, stelle bitte sicher, dass unsere E-Mail nicht in deinem Spamordner gelandet ist und kontaktiere uns mit unserem <a href=\'https://www.schuelerhilfe.de/online-lernen/feedback\'>Feedbackformular</a>.',
            terms: 'AGBs und Datenschutz*',
            promotionTitle: 'Lerntipps und Werbeangebote',
            promotion: 'Ich möchte Lerntipps und Werbeangebote per E-Mail erhalten. Diese Einwilligung kann ich jederzeit mit Wirkung für die Zukunft per Abmeldelink am Ende eines jeden Newsletters oder per E-Mail an <a href="mailto:olc@schuelerhilfe.de">olc@schuelerhilfe.de</a> widerrufen.',
            accountAlreadyExists: 'Haben Sie bereits einen Account?',
        },
        specialFourteenDaysForm: {
            mainHeading: 'Online-LernCenter',
            description: 'Jetzt 30 Tage kostenlos testen',
            firstname: 'Vorname',
            lastname: 'Nachname',
            email: 'E-Mail-Adresse',
            termsAndPrivacyPolicy: 'Die {termsAndConditions} sowie die {privacyPolicy} habe ich zur Kenntnis genommen und bin mit deren Inhalt einverstanden.',
            termsAndConditions: 'Allgemeine Geschäftsbedingungen',
            privacyPolicy: 'Datenschutzerklärung',
            registerButton: 'Weiter',
            registerSuccess: '<h4>Registrierung erfolgreich!</h4>Bitte prüfe deinen Posteingang. Wir haben dir eine E-Mail mit weiteren Schritten geschickt.',
        },
        chooseAbo: {
            title: 'Das Schülerhilfe Online-LernCenter<br>30 Tage kostenlos testen',
            badge: 'Meistgebucht',
            premium: 'Premium',
            premiumPlus: 'Premium+',
            basic: 'Basis',
            only: 'nur',
            durationOfContract: '12 Monate',
            month: '/ Monat.',
            nonBindingTestPhase: 'Sichere und unverbindliche Testphase',
            directAccess: 'Sofort Zugriff erhalten auf:',
            forwardBasic: 'Weiter mit Basis',
            forwardPremium: 'Weiter mit Premium',
            benefitVideo: 'Lernvideos',
            benefitExercises: 'Übungsaufgaben',
            benefitWorksheet: 'Arbeitsblätter',
            benefitVocabularyTrainer: 'Vokabeltrainer Englisch & Französisch',
            benefitODH: 'Online-Hausaufgabenhilfe',
            benefitWebinars: 'Webinare',
            benefitKira: 'Kira - die KI-Lernassistentin',
        },
        dateInput: {
            dayLabel: 'Tag',
            monthLabel: 'Monat',
            yearLabel: 'Jahr',
            dayError: 'Bitte wählen Sie einen Tag!',
            monthError: 'Bitte wählen Sie einen Monat!',
            yearError: 'Bitte wählen Sie ein Jahr!',
        },
        faq: {
            pageTitle: 'Häufig gestellte Fragen rund ums Online-LernCenter der Schülerhilfe',
            back: 'Zurück',
        },
        footer: {
            home: 'Startseite',
            pricing: 'Preise',
            agbs: 'AGB',
            center: 'Schülerhilfe',
            calendar: 'Kalender',
            grades: 'Notenentwicklung',
            imprint: 'Impressum',
            more: 'Mehr',
            privacy: 'Datenschutz',
            copyright: '&copy {year} Schülerhilfe',
            shLogoAlt: 'Schülerhilfe logo',
            faq: 'FAQ',
            cookies: 'Cookie-Einstellungen',
            contact: 'Kontakt',
            downloads: 'Kündigung',
        },
        header: {
            start: 'Startseite',
            favourites: 'Favoriten',
            account: 'Konto',
            logout: 'Ausloggen',
            calendar: 'Kalender',
            contactForm: 'Kontakt aufnehmen',
            contractDetails: 'Vertragsdetails',
            newCustomer: 'Noch kein Kunde der Schülerhilfe?',
            conversationDetails: 'Nachrichtenverlauf',
            messages: 'Nachrichten',
            deleteConversation: 'Nachrichten löschen',
            more: 'Mehr',
            profile: 'Profil',
            profileIconAlt: 'Profil',
            response: 'Antworten',
            settings: 'Einstellungen',
            shLogoAlt: 'Schülerhilfe Logo',
            changePassword: 'Passwort ändern',
            markProgress: 'Notenentwicklung',
            center: 'Online-LernCenter',
            help: 'Hilfe zur App',
            olc: 'Online-LernCenter',
            imprint: 'Impressum',
            register: 'Registrieren',
            requestPasswordReset: 'Passwort vergessen?',
            addContract: 'Vertrag hinzufügen',
            activate: 'Account aktivieren',
            termsOfUse: 'Nutzungsbedingungen',
        },
        search: {
            search: 'Suchen',
            searchFor: 'Suche nach...',
            searchBarLabel: 'Suche z.B. nach Lorem',
            placeholder: 'Suchbegriff eingeben',
        },
        imprint: {
            pageTitle: 'Impressum - Online-LernCenter',
            title: 'Impressum',
        },
        inbox: {
            pageTitle: 'Online-LernCenter - Nachrichten',
            noConversationsText: 'Keine Nachrichten vorhanden',
            newMessageLabel: 'Neue Nachricht verfassen',
            getConversationsByUserIdError: 'Es ist ein Fehler beim Laden Ihrer Nachrichten aufgetreten.',
        },
        deleteConversations: {
            pageTitle: 'Online-LernCenter - Nachrichten löschen',
            noConversationsText: 'Es sind noch keine Nachrichten vorhanden.',
            delete: 'Ausgewählte Nachrichten löschen',
            conversationNotResolved: 'Diese Nachricht kann nicht gelöscht werden, da sie noch nicht '
            + 'gelöst/beantwortet wurde und nicht älter als 4 Wochen ist.',
            error: 'Fehler beim Löschen der Nachricht.',
        },
        profile: {
            pageTitle: 'Konto - Online-LernCenter',
            profileDataContentBoxLabel: 'Persönliche Daten',
            profileDataContentBoxDescription: 'Hier kannst du alle Daten zu deinem Profil angeben oder verändern.',
            email: 'E-Mail',
            firstname: 'Vorname',
            lastname: 'Name',
            classLevel: 'Klassenstufe',
            typeOfSchool: 'Schulform',
            phone: 'Telefonnummer',
            postalCode: 'Postleitzahl',
            birthDate: 'Geburtsdatum',
            voucherCode: 'Aktions-Code',
            password: 'Passwort',
            oldPassword: 'Altes Passwort',
            passwordConfirmation: 'Passwort (Wiederholung)',
            submitLabel: 'Speichern',
            passwordFormDescriptionText: 'Wähle hier ein sicheres Passwort (mindestens 8 Zeichen lang) für deinen '
                                       + 'Zugang.',
            passwordChangeContentBoxLabel: 'Passwort ändern',
            voucherContentBoxLabel: 'Aktions-Code einlösen',
            voucherContentBoxDescription: 'Wenn du einen Aktions-Code besitzt, kannst du ihn hier einlösen.',
            voucherSubmitLabel: 'Einlösen',
            cancelSubscriptionModalText: 'Vertrag kündigen',
            cancelSubscriptionText: 'Hier können Sie Ihren Vertrag im Online-LernCenter kündigen.',
            reactivateSubscriptionModalText: 'Vertrag reaktivieren',
            reactivateSubscriptionText: 'Hier können Sie Ihren Vertrag im Online-LernCenter reaktivieren.',
        },
        paymentDetails: {
            currentSubscription: 'Aktuelle Mitgliedschaft:',
            subscriptionStart: 'Mitglied seit:',
            subscriptionEnd: 'Mitgliedschaftendet am:',
            nextpayment: 'Nächste Zahlung:',
            paymentMethod: 'Zahlungsmethode:',
            cancelSubscription: 'Mitgliedschaft Kündigen',
            notEntryFound: 'Kein Eintrag gefunden!',
            reactivateSubscription: 'Mitgliedschaft reaktivieren',
        },
        languageSwitcher: {
            germanLabel: 'DE',
            englishLabel: 'EN',
        },
        library: {
            quickFinder: 'Schnellfinder',
            subjectLabel: '1. Fach wählen',
            gradeLabel: '2. Klasse wählen',
            stateLabel: '3. Bundesland wählen',
            mainTopicLabel: '4. Thema wählen',
            betweenTopicLabel: '5. Zwischenthema wählen',
            underTopicLabel: '6. Unterthema wählen',
            exerciseLabel: 'Arbeitsblätter',
            exerciseLevel: 'Schwierigkeitsgrad',
            exerciseNumber: 'Arbeitsblatt-Nr.',
            examLabel: 'Interaktive Aufgaben',
            examDescription: 'Wähle deine interaktive Aufgabe. Nach der Bearbeitung kannst du deine Ergebnisse '
                           + 'einsehen, ausdrucken und herunterladen.<br />'
                           + 'Du kannst alle deine Ergebnisse auch jederzeit auf der '
                           + `<a href="${process.env.REACT_APP_BASENAME}/interaktive-aufgaben"`
                           + 'class="examsLink">Übersichtsseite</a> erneut abrufen.',
            downloadLabel: 'Download',
            showSolutionLabel: 'Lösung anzeigen',
            showExerciseLabel: 'Aufgaben anzeigen',
            similarTopicsLabel: 'Verwandte Themen',
            similarTopicsSeoLabel: 'Weitere Themen',
            videosLabel: 'Videos',
            linkBoxType: {
                exam: 'Interaktive Aufgabe',
                topic: 'Thema',
                page: 'Thema',
                grade: 'Lernfeld',
                exercise: 'Aufgabe',
                video: 'Video',
            },
            searchHits: 'Suchergebnisse',
            noSearchHits: 'Leider haben wir zu Deinem eingegebenen Suchbegriff keine Ergebnisse gefunden. Probiere es '
                        + 'mal mit einem anderen Suchbegriff oder schaue Dich einfach so in der Bibliothek um.',
            noSearchHitsShort: 'Keine Ergebnisse gefunden',
            noSearchHitsBackToLibButton: 'Zurück',
            showMore: 'Mehr anzeigen',
            showLess: 'Weniger anzeigen',
            chosenSubjectDescription: 'Du möchtest erfolgreich {chosenSubject} lernen, aber bitte mit Spaß? Egal, '
                                    + 'welches Thema, welche Klasse oder Schulform: Wir haben die besten Erklärungen '
                                    + 'zu allen {chosenSubject}themen!',
            TopicsNotFoundTitle: 'Zu diesem Thema gibt es leider noch keine Inhalte.',
            TopicsNotFoundText: 'Leider haben wir zu diesem Thema keine Inhalte gefunden. Wenn du dieses Thema '
                              + 'interessant findest und dazu mehr Inhalte sehen möchtest, dann schreibe uns doch '
                              + 'einfach.',
            contact: 'Kontakt',
            feedback: 'Rückmeldung',
            notFoundPageTitle: 'Schülerhilfe.de Seite nicht gefunden',
            notFoundMainHeading: 'Leider wurde deine gesuchte Seite nicht gefunden',
            notFoundImgAlt: 'Schülerhilfe 404 - Seite nicht gefunden',
            notFoundDescription: 'Upps... Diese Seite existiert leider nicht. Probiere doch mal einen anderen Link '
                               + 'oben in der Navigation.',
            notLoggedInAppeal: 'Mache jetzt einen Wissens-Check und teste deinen Lernstand direkt online.',
            notLoggedInHint: 'Du kannst diesen Inhalt sehen, wenn du eingeloggt bist. Hier geht es zum '
                           + '{loginLink}. Wenn du noch keinen Zugang hast, kannst du dich jetzt hier '
                           + '{registerLink}.',
            loginText: 'Login',
            registerText: 'registrieren',
            courseSupportContactButton: 'Du hast Probleme in deinen Kurs zu kommen? Hier den Support kontaktieren',
        },
        messageModal: {
            understoodLabel: 'Verstanden',
            closeLabel: 'Schließen',
        },
        modal: {
            closeLabel: 'Schließen',
            centerPhoneCallInfoModal: {
                introduction: 'Sie möchten Ihre Schülerhilfe anrufen?',
                centerPhoneLabel: 'Tel.:',
                phoneCallBtnLabel: 'Anrufen',
                pleaseClickText: 'Dann klicken Sie bitte auf folgenden Button:',
            },
            supportPhoneCallInfoModal: {
                introduction: 'Sie möchten sich von uns telefonisch beraten lassen?',
                phoneLabel: 'Tel.:',
                phoneCallBtnLabel: 'Anrufen',
                pleaseClickText: 'Dann klicken Sie bitte auf folgenden Button:',
            },
            registrationInfoModal: {
                introductionText: 'Hier finden Sie die Informationen:',
                additionalIntroductionText: 'Bei einer Voranmeldung finden Sie die E-Mail-Adresse des Anmelders an '
                                          + 'folgender Stelle:',
                helperMarkerImageAlt: 'Hilfe für Kunden, um die richtige E-Mail-Adresse hinzuzufügen',
                additionalHelperMarkerImageAlt: 'Hilfe für Kunden, um die richtige E-Mail-Adresse bei Voranmeldung '
                                              + 'hinzuzufügen',
            },
            registerCallToActionModal: {
                introduction: 'Jetzt Zugang zum Online-LernCenter sichern!',
                additionalIntroductionText: 'Online lernen schon ab<br /><span className="bannerPrice">9 €</span><br />pro Monat¹',
                labels: {
                    firstname: 'Vorname*',
                    lastname: 'Nachname*',
                    email: 'E-Mail-Adresse*',
                    mandatory: '*Pflichtfeld',
                },
                links: {
                    agb: 'Allgemeinen Geschäftsbedingungen',
                    data: 'Datenschutzerklärung',
                    login: 'Jetzt anmelden',
                },
                checkbox: 'Die {agbLink} sowie die {dataLink} habe ich zur Kenntnis genommen und bin mit deren Inhalt einverstanden.',
                button: 'Kostenlos 30 Tage testen!',
                alreadyCustomer: 'Sie sind bereits Kunde? {loginLink}.',
                footnote: '¹ Das Basis-Abo kostet 9 Euro im Monat und das Premium-Abo 19 Euro im Monat.',
            },
            schulaktionOfferModal: {
                title: 'Wir schenken Dir 2 Nachhilfestunden gratis in einem userer 1.000 Schülerhilfen oder für unsere Online Video Nachhilfe mit persönlichem Nachhilfelehrer.',
                labels: {
                    phone: 'Bitte rufen Sie mich an um einen termin für die 2 gratis Nachhilfestunden zu vereinbaren',
                    phoneNumber: 'Telefonnummer',
                    submitButton: 'Jetzt 2 Nachhilfestunden gratis sichern',
                    consents: 'Ich bin damit einverstanden, dass mich die Schülerhilfe (ZGS Bildungs-GmbH) kontaktiert und über Werbeaktionen informiert',
                },
            },
            courseSupportContactModal: {
                headline: 'Support-Kontakt',
                headlineDescription: 'Schreibe uns über folgendes Kontaktformular und wir melden uns schnellstmöglich bei dir:',
                placeholder: {
                    subject: 'Betreff*',
                    message: 'Nachricht*',
                },
                mandatory: '*Pflichtfelder',
                submitBtnLabel: 'Support kontaktieren',
                success: 'Vielen Dank für deine Anfrage!',
                error: 'Beim Absenden deiner Support-Anfrage ist ein Fehler aufgetreten, bitte versuche es später erneut.',
            },
        },
        moreMenu: {
            pageTitle: 'Online-LernCenter - Mehr',
            news: 'Aktuelles',
            facebook: 'Facebook',
            instagram: 'Instagram',
            olc: 'Online-LernCenter',
            settings: 'Einstellungen',
            help: 'Hilfe zur App',
            imprint: 'Impressum',
            privacy: 'Datenschutz',
        },
        notFound: {
            message: 'Uuups, sieht so aus als ob da ein Wurm drin ist!',
            tryAgain: 'Erneut versuchen',
            imgAlt: 'Bild nicht gefunden',
        },
        olcInfo: {
            pageTitle: 'Online-LernCenter - Online-LernCenter',
            olcLinkBtnLabel: 'Zum Online-LernCenter',
        },
        registration: {
            pageTitle: 'Online-LernCenter - Registrierung',
            infoText: 'Bitte registrieren Sie sich mit Ihrem Vor- und Nachnamen.<br/><br/>'
                    + 'Legen Sie zudem eine E-Mail-Adresse und ein dazugehöriges Passwort zur Anmeldung fest. '
                    + 'Beachten Sie, dass die E-Mail-Adresse mit der bei Vertragsabschluss hinterlegten '
                    + 'Anmelder-E-Mail-Adresse übereinstimmen muss!',
            dataLocation: 'Wo finde ich die Anmelder-E-Mail-Adresse?',
            agbs: 'Allgemeine Geschäftsbedingungen',
            next: 'Weiter',
            back: 'Zurück',
            ageStepTitle: 'Wie alt bist Du?',
            ltSixteen: 'Ich bin unter 16 Jahre alt',
            geSixteen: 'Ich bin 16 Jahre oder älter',
            ageStepHint: 'Datenschutz ist uns sehr wichtig. Wenn du unter 16 Jahre alt bist, benötigen wir die Zustimmung deiner Eltern, dass du die Online-Hausaufgabenhilfe der Schülerhilfe nutzen darfst. Hier findest du unsere {link1}.',
            phoneNumberStepTitle: 'Verifizierung',
            phoneNumberStepHint: 'Wir schicken dir einen Code per SMS, um deinen Account zu verifizieren. Bitte gib dafür deine Mobilnummer an.',
            phoneValidationStepTitle: 'SMS-Code eingeben',
            phoneValidationStepHint: 'Bitte gib hier den Code ein, den wir dir per SMS an die angegebene Mobilnummer geschickt haben.',
            personalDataStepTitle: 'Persönliche Daten',
            personalDataStepHint: 'Wir möchten dich gerne persönlich ansprechen',
            loginDataStepTitle: 'Logindaten',
            loginDataStepHint: 'Deine zukünftigen Logindaten legst du hier fest',
            registeredStepTitle: 'Deine Registrierung war erfolgreich!',
            registeredStepHint: 'Herzlichen Glückwunsch! Deine Registrierung war erfolgreich. Jetzt brauchst du nur noch deine E-Mail-Adresse zu bestätigen. Dazu klicke bitte auf den Link in der E-Mail, die wir dir gerade gesendet haben. Danach kannst du direkt loslegen und das Online-LernCenter 14 Tage kostenlos nutzen!',
            firstnameLabel: 'Vorname',
            lastnameLabel: 'Nachname',
            emailLabel: 'E-Mail-Adresse',
            emailConfirmationLabel: 'E-Mail-Adresse bestätigen',
            passwordLabel: 'Passwort',
            termsOfUse: 'Nutzungsbedingungen',
            privacyStatement: 'Datenschutzerklärung',
            passwordConfirmationLabel: 'Passwort bestätigen',
            policiesAcceptedLabel: 'Die {link1} sowie die '
                                   + '{link2} habe ich zur Kenntnis '
                                   + 'genommen und bin mit deren Inhalt einverstanden.',
            contactAllowedLabel: 'Ich bin über 18 Jahre alt und damit einverstanden, dass die ZGS-Bildungs GmbH und/oder die '
                                 + 'Schülerhilfe GmbH & Co. KG und/oder der Inhaber meines Schülerhilfe-Standortes '
                                 + 'zu mir Kontakt per E-Mail und Telefon aufnimmt, damit ich über exklusive Angebote '
                                 + 'der Schülerhilfe (Werbung) informiert werden kann. Für '
                                 + 'die Datenverarbeitung gilt unsere allgemeine '
                                 + '{link1}.',
            submitLabel: 'Senden',
            passwordRules: 'Das Passwort muss aus mindestens 8 Zeichen bestehen, darunter mindestens '
                         + 'ein Großbuchstabe, ein Kleinbuchstabe und eine Ziffer (0 bis 9).',
            activationMailSendMessage: 'Eine Aktivierungs-E-Mail wurde an die von Ihnen angegebene '
                + 'E-Mail-Adresse gesendet!',
            alreadyRegisteredMessage: 'Sie sind bereits registriert!',
            alreadyRegisteredUnconfirmedMessage: 'Ihre Daten wurden bereits erfasst! Bitte aktivieren Sie Ihren '
                + 'Zugang!',
        },
        emailVerification: {
            pageTitle: 'E-Mail Verifizierung.',
            title: 'E-Mail Verifizierung. Bitte warten..',
            verificationFailed: 'Verifizierung fehlgeschlagen!',
            verificationSucceeded: 'Erfolgreich verifiziert!',
            moveToOlc: 'Weiter zum Online-LernCenter',
        },
        requestPasswordReset: {
            pageTitle: 'Online-LernCenter - Neues Passwort anfordern',
            infoText: '<p>Bitte geben Sie die folgenden Daten an, damit wir Ihnen einen Link zum Zurücksetzen des '
                + 'Passwortes zusenden können.</p>',
            supportText: '<p>Bei Fragen wenden Sie sich bitte an <a href="mailto:support-lerncenter@schuelerhilfe.de" '
                + 'class="requestPasswordResetMailTo">support-lerncenter@schuelerhilfe.de</a></p>',
            emailLabel: 'E-Mail-Adresse',
            submitLabel: 'Senden',
            successMessage: 'Eine Mail mit dem Link zum Zurücksetzen Ihres Passwortes wurde an die von Ihnen '
                + 'angegebene E-Mail-Adresse gesendet!',
            errorMessage: 'Fehler beim Anfordern der E-Mail zum Zurücksetzen des Passwortes!',
        },
        resetPassword: {
            pageTitle: 'Online-LernCenter - Neues Passwort',
            infoText: 'Bitte vergeben Sie ein (neues) Passwort. Dieses muss aus mindestens 8 Zeichen '
                + 'bestehen, darunter mindestens ein Großbuchstabe, ein Kleinbuchstabe und eine Ziffer (0 bis 9).',
            passwordLabel: 'Passwort',
            passwordConfirmationLabel: 'Passwort bestätigen',
            submitLabel: 'Senden',
            passwordRules: 'Das Passwort muss aus mindestens 8 Zeichen bestehen, darunter mindestens '
                         + 'ein Großbuchstabe, ein Kleinbuchstabe und eine Ziffer (0 bis 9).',
            errorMessage: 'Fehler beim Zurücksetzen des Passwortes!',
            successMessage: 'Ihr Passwort wurde erfolgreich geändert!',
        },
        initPassword: {
            pageTitle: 'Online-LernCenter - Passwort setzen',
            errorMessage: 'Fehler beim Setzen des Passwortes!',
            successMessage: 'Ihr Passwort wurde erfolgreich gesetzt!',
        },
        response: {
            pageTitle: 'Online-LernCenter - Antworten',
            caption: 'Antwort',
            templateLabel: 'Textvorlage',
            submitLabel: 'Senden',
            getConversationByIdError: 'Beim Abrufen der Nachricht ist ein Fehler aufgetreten.',
            submitSuccessMessage: 'Ihre Antwort wurde erfolgreich gesendet. Sie können diese Seite nun schließen.',
            responseCouldNotBeSubmittedMessage: 'Beim Senden der Antwort ist ein Fehler aufgetreten.',
        },
        sectionHeader: {
            showEverything: 'alles anzeigen',
        },
        termsOfUse: {
            pageTitle: 'Online-LernCenter - Nutzungsbedingungen',
            heading: 'Nutzungsbedingungen Eltern-App',
            first: 'Diese „Eltern-App“ (im Folgenden „App“) stellt eine Service-Leistung der ZGS Bildungs-GmbH '
                 + '(im  Folgenden  „ZGS“) gegenüber ihren jeweiligen Vertragspartnern bzw. den Vertragspartnern '
                 + 'der Schülerhilfe-Standortbetreiber (im Folgenden „Standortbetreiber“) dar.',
            second: 'Die Nutzung ist daher nur solchen Personen gestattet, welche mit der ZGS und / oder einem '
                  + 'Standortbetreiber wenigstens ein Vertragsverhältnis über die Erbringung von Nachhilfe- und '
                  + 'Bildungsleistungen unterhalten.',
            third: 'Durch die Nutzung dieser App wird kein neues Vertragsverhältnis begründet. Insbesondere ist die '
                 + 'ZGS nicht verpflichtet, den Betrieb dieser App aufrecht zu erhalten. Sie behält sich hiermit '
                 + 'ausdrücklich vor, die Funktionsweise der App zu verändern oder den Betrieb der App gänzlich '
                 + 'einzustellen.',
            fourth: 'Die Haftung der ZGS für Schäden, die aufgrund der Nutzung dieser App entstehen, wird hiermit '
                  + 'ausgeschlossen.<br/><br/>Dies gilt jedoch nicht für Ansprüche aufgrund der Verletzung des '
                  + 'Körpers, des Lebens oder der Gesundheit sowie für Ansprüche aufgrund einer vorsätzlichen oder '
                  + 'grob fahrlässigen Pflichtverletzung.',
        },
        termsOfUseLisa: {
            pageTitle: 'Online-LernCenter - Nutzungsbedingungen',
            heading: 'NUTZUNGSBEDINGUNGEN FÜR DIE NUTZUNG DES ONLINE-LERNCENTERS DURCH DIE LISA-NUTZER',
            firstPoint: '<strong>I. ALLGEMEINES - GELTUNGSBEREICH</strong><br/><br/>',
            secondPoint: '<strong>II. TECHNISCHE VORAUSSETZUNGEN</strong><br/><br/>',
            thirdPoint: '<strong>III. PFLICHTEN DES NUTZERS</strong><br/><br/>',
            fourthPoint: '<strong>IV. MÄNGELHAFTUNG, SCHADENSERSATZ UND HAFTUNGSAUSSCHLUSS</strong><br/><br/>',
            fifthPoint: '<strong>V. URHEBERRECHTE</strong><br/><br/>',
            sixthPoint: '<strong>VII. ALTERNATIVE STREITBEILEGUNG</strong><br/><br/>',
            seventhPoint: '<strong>VIII. STREITSCHLICHTUNG</strong><br/><br/>',
            eightPoint: '<strong>IV. MÄNGELHAFTUNG, SCHADENSERSATZ UND HAFTUNGSAUSSCHLUSS</strong><br/><br/>',
            ninthPoint: '<strong>IX. SCHLUSSBESTIMMUNGEN</strong><br/><br/>',
            first: 'Die nachfolgenden Nutzungsbedingungen der ZGS Bildungs-GmbH, Ludwig-Erhard-Str. 2, 45891 Gelsenkirchen (im Folgenden „ZGS“ genannt) gelten für die Nutzung sämtlicher '
                + 'auf der Webseite <a href=\'https://www.schuelerhilfe.de/lerncenter\'>www.schuelerhilfe.de/lerncenter</a> '
                + 'im Online-LernCenter angebotenen Dienstleistungen durch diejenigen Nutzer, die durch das Landesinstitut für Schulqualität und Lehrerbildung Sachsen-Anhalt (LISA) autorisiert wurden (im Folgenden „Nutzer“ genannt).<br/><br/>',
            second: '1. Die ZGS weist darauf hin, dass zur Nutzung der von der ZGS angebotenen Dienstleistungen des Online-LernCenters vom Nutzer bestimmte technische Voraussetzungen zu schaffen sind. Zur Ermöglichung des Zugangs bedarf der Nutzer '
                + 'eines internetfähigen Computers oder entsprechenden Geräts, eines Internetanschlusses bzw. -zugangs sowie bestimmter (Fremd-) Software zum Abspielen von Videos und zum Lesen von Dateien. <br/><br/>'
                + '2. Der Nutzer trägt die Verantwortung dafür, dass die technischen Voraussetzungen für den Zugang zum Online-LernCenter geschaffen sind und die notwendige (Fremd-)Software verwendet wird. Durch die Verwendung von Fremd-Software kommt '
                + 'lediglich ein Vertragsverhältnis zwischen dem Nutzer und dem jeweiligen Anbieter der Fremd-Software zustande. '
                + 'Der Anbieter der Fremd-Software handelt nicht im Auftrag der ZGS. <br/><br/>'
                + '3. Die ZGS behält sich ausdrücklich vor, die im Rahmen des Online-LernCenters angebotenen Dienstleistungen weiterzuentwickeln, zu ändern und zu ergänzen. Sofern dafür weitere technische Voraussetzungen zu schaffen sind und/oder es '
                + 'bestimmter zusätzlicher (Fremd-) Software bedarf, wird der Nutzer frühzeitig und vorher darauf hingewiesen.<br/><br/>'
                + '4. Die ZGS weist ausdrücklich darauf hin, dass die Nutzung des Internets sowie die Übertragung von Daten im Internet Sicherheitslücken beinhalten können. Ein lückenloser Schutz ist diesbezüglich nicht möglich. Es obliegt dem Nutzer, '
                + 'die zum Schutz vor Zugriffen Dritter notwendigen Schutzvorkehrungen für sein System zu treffen.'
                + ' Dies beinhaltet insbesondere die Nutzung der gängigen Sicherheitseinstellungen der vom Nutzer verwendeten Browsersoftware sowie die Verwendung einer Firewall und einer aktuellen Schutzsoftware zur Abwehr von Computerviren.<br/><br/>',
            third: '1. Zum Bezug der jeweiligen Dienstleistung ist lediglich der vom LISA autorisierte Nutzer berechtigt. Eine Weitergabe der Nutzungsdaten an Dritte ist untersagt. Im Falle eines Verstoßes kann der Zugang gesperrt werden. '
                + 'Eine Nutzung erfolgt nur zu privaten Zwecken. Sofern eine gewerbliche oder kommerzielle Nutzung oder eine öffentliche Vorführung erfolgen soll, '
                + 'sind diese nur mit vorheriger, ausdrücklicher und schriftlicher Einwilligung der ZGS möglich.<br/><br/>'
                + '2. Der Nutzer verpflichtet sich, die von der ZGS im Rahmen des Online-LernCenters in Form von PDF- und Video-Dateien angebotenen Leistungen nicht weiter zu verbreiten oder Dritten zugänglich zu machen. Im Falle eines Verstoßes behält '
                + 'sich die ZGS mögliche Schritte gegenüber dem Nutzer vor.<br/><br/>'
                + '3. Unbeschadet anderer gesetzlicher Vorschriften ist die ZGS im Falle von Missbrauch (z.B. mehrfach Anmeldungen oder Hinweise auf die Nutzung eines LernCenter-Zugangs durch mehrere Nutzer) oder wenn ernstzunehmende '
                + 'Hinweise auf die Vornahme rechtswidriger Handlungen durch den Nutzer vorliegen, berechtigt, den Zugang des Nutzers zum Online-LernCenter unter <a href=\'https://www.schuelerhilfe.de/lerncenter\'>www.schuelerhilfe.de/lerncenter</a> zu sperren.<br/><br/>'
                + '4. Der Nutzer stellt die ZGS und ihre Erfüllungsgehilfen von sämtlichen Ansprüchen Dritter frei, die infolge einer schuldhaften Verletzung der in diesen Nutzungsbedingungen aufgeführten Verpflichtungen und/oder infolge anderer schuldhafter '
                + 'schädigender Handlungen des Nutzers oder eines ihm zurechenbaren Dritten gegen die ZGS geltend gemacht werden. Darüber hinaus leistet der Nutzer Ersatz für zusätzlich dadurch entstehende Schäden einschließlich der Kosten für eine eventuell '
                + 'erforderliche Rechtsverfolgung und -verteidigung. Dem Nutzer steht es frei nachzuweisen, dass der Schaden tatsächlich geringer ist.<br/><br/>'
                + '5. Nach Übermittlung des Registrierungslinks hat der Nutzer ein anonymes Profil anzulegen. Hierzu wird ihm ein automatisch generierter, anonymer Benutzername zugewiesen. Es ist vom Nutzer auch ein Passwort für den Zugang zu seinem Account '
                + 'anzulegen. Der Nutzer ist dafür verantwortlich, dass das Passwort geheim gehalten und keinem Dritten der Zugang zum persönlichen Account des Nutzers gewährt wird.<br/><br/>',
            fourth: '1. Mängelhaftung und Schadensersatz<br/><br/>'
                + 'Die ZGS haftet gegenüber dem Nutzer sowie den angemeldeten Personen bei Vorsatz und grober Fahrlässigkeit nach Maßgabe der gesetzlichen Bestimmungen. In sonstigen Fällen – soweit nicht nachfolgend abweichend geregelt – haftet die ZGS nur '
                + 'bei Verletzung einer sogenannten Kardinalpflicht (das ist eine Vertragspflicht, deren Erfüllung die ordnungsgemäße Durchführung der Nutzung überhaupt erst ermöglicht und auf deren Einhaltung der Nutzer regelmäßig vertrauen darf), und zwar '
                + 'beschränkt auf den Ersatz des vorhersehbaren und typischen Schadens. In allen übrigen Fällen ist die Haftung vorbehaltlich der nachfolgenden Regelung ausgeschlossen. Soweit eine Haftung der ZGS ausgeschlossen oder eingeschränkt ist, gilt '
                + 'dies auch im Hinblick auf die persönliche Haftung ihrer Angestellten, Arbeitnehmer, Mitarbeiter, Vertreter und Erfüllungsgehilfen. Die Haftung der ZGS für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit oder nach dem '
                + 'Produkthaftungsgesetz bleibt von den vorstehenden Haftungsausschlüssen und -beschränkungen unberührt.<br/><br/>'
                + '2. Haftungsausschluss<br/><br/>'
                + 'Die ZGS haftet nicht für die Verfügbarkeit und Erreichbarkeit des Internetangebots auf <a href=\'https://www.schuelerhilfe.de/lerncenter\'>www.schuelerhilfe.de/lerncenter</a>. Die ZGS haftet nicht für das nicht mehr Vorhandensein '
                + 'eines bestimmten Dienstleistungsangebotes. Eine etwaige Haftung der ZGS aufgrund zwingender gesetzlicher Vorschriften bleibt hiervon unberührt.<br/><br/>',
            fifth: 'Die von der ZGS in Rahmen des Online-LernCenters zur Verfügung gestellten Fotos, Texte, Slogans, Zeichnungen, Bilder, Videos und sonstige Werke sowie Materialien sind urheberrechtlich geschützt und dürfen nicht – auch nicht auszugsweise – ohne '
                + 'Einwilligung der ZGS vervielfältigt, weitergegeben oder gewerblich genutzt werden. Die ZGS behält sich vor, die auf der Webseite www.schuelerhilfe.de/lerncenter angebotenen Dienstleistungen und Informationen jederzeit ohne Angabe von Gründen zu '
                + 'ändern sowie ganz oder teilweise aus dem Programm zu nehmen oder nicht mehr anzubieten. Der Nutzer erkennt dieses Recht ausdrücklich an. Der Nutzer wird im Voraus darüber informiert. Die ZGS schuldet in diesem Falle keinerlei Schadensersatz '
                + 'oder entgangenen Gewinn.<br/><br/>',
            sixth: 'In den von der ZGS verwendeten Texten und Formulierungen wird meist nur eine Geschlechtsform verwendet, um eine bessere Lesbarkeit zu gewährleisten. Davon unbeeinflusst beziehen sich alle Angaben auf alle Geschlechter.<br/><br/>',
            seventh: 'Die ZGS ist weder bereit noch verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.<br/><br/>',
            eight: 'Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung bereit. Diese finden Sie unter <a href=\'https://ec.europa.eu/consumers/odr\'>http://ec.europa.eu/consumers/odr</a>. Es besteht die Möglichkeit für Verbraucher, '
                + 'diese Plattform für die Beilegung etwaiger Streitigkeiten zu nutzen.<br/><br/>',
            ninth: '1. Es gilt ausschließlich das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts sowie unter Ausschluss der Anknüpfungsnormen des Internationalen Privatrechts.<br/><br/>'
                + '2. Nebenabreden bestehen nicht.<br/><br/>'
                + '3. Sollten sich einzelne Bestimmungen dieser Nutzungsbedingungen ganz oder teilweise als unwirksam oder undurchführbar erweisen oder in Folge Änderung der Gesetzgebung unwirksam oder undurchführbar werden, bleiben die übrigen Bestimmungen '
                + 'und die Wirksamkeit hiervon unberührt. An die Stelle der unwirksamen oder undurchführbaren Bestimmungen soll die wirksame und durchführbare Bestimmung treten, die dem Sinn und Zweck der nichtigen Bestimmung möglichst nahekommt. Dasselbe gilt im Falle einer Lücke.<br/><br/>',
        },
        center: {
            pageTitle: 'Online-LernCenter',
            navigationLabel: 'Route',
            messages: 'Nachrichten',
            monthlyProtocols: 'Monatsprotokolle',
            rateUs: 'Bewerten Sie uns!',
            openingTimes: 'Büro-Öffnungszeiten:',
            openingTimesHolidayReminder: 'Bitte beachten Sie, dass der Standort an gesetzlichen Feiertagen sowie '
                + 'zwischen Weihnachten und Neujahr geschlossen ist.',
            imageAlt: 'Online-LernCenter',
            till: 'bis',
            oclock: 'Uhr',
            closed: 'geschlossen',
            phoneLabel: 'Tel.:',
            phoneCallLabel: 'Anrufen',
        },
        weekDay: {
            monday: 'Montag',
            tuesday: 'Dienstag',
            wednesday: 'Mittwoch',
            thursday: 'Donnerstag',
            friday: 'Freitag',
            saturday: 'Samstag',
            sunday: 'Sonntag',
        },
        weekDayShort: {
            MO: 'Montag',
            TU: 'Dienstag',
            WE: 'Mittwoch',
            TH: 'Donnerstag',
            FR: 'Freitag',
            SA: 'Samstag',
            SU: 'Sonntag',
        },
        multiVideo: {
            more: 'Mehr anzeigen',
            videos: 'Videos',
        },
        homeworkHelp: {
            pageTitle: 'Online-Hausaufgabenhilfe - Online-LernCenter',
            title: 'Online-Hausaufgabenhilfe',
            linkBoxTitle: 'Online-Hausaufgabenhilfe',
            linkBoxText: 'Individuelle Unterstützung in Mathe, Deutsch und Englisch: täglich von 16 bis 20 Uhr.',
            description: 'Wir beantworten täglich (außer an Feiertagen) von 16 - 20 Uhr deine Fragen. Wähle einfach '
                       + 'deine Klasse, deine Schulform und das Fach aus und sende uns deine Frage zu. Wenn du '
                       + 'möchtest, kannst du ein Foto oder eine Datei anhängen.',
            closingTimeInfoText: 'Vom 15.12.2024 - 07.01.2025 findet keine Online-Hausaufgabenhilfe statt. '
                       + 'Wir wünschen dir und deiner Familie ein frohes Weihnachtsfest und einen guten Rutsch in das neue Jahr.',
            videoTitle: 'Online-Hausaufgabenhilfe Erklärungsvideo',
            request: {
                title: 'Jetzt Anfrage an einen Nachhilfelehrer stellen',
                form: {
                    class: 'Klasse',
                    subject: 'Fach',
                    schoolType: 'Schulform',
                    topic: 'Thema deiner Anfrage',
                    question: 'Deine Frage',
                    attachment: 'Foto oder Datei anhängen',
                    submit: 'Jetzt Anfrage einreichen',
                    required: 'Pflichtfeld',
                    furtherRequestTitle: 'Deine Nachricht',
                    furtherRequestButton: 'Rückfrage abschicken',
                },
                error: 'Etwas ist schiefgelaufen. Bitte versuche es erneut.',
            },
            overview: {
                title: 'Übersicht deiner Anfragen',
                showMore: 'Mehr anzeigen',
                empty: 'Du hast bisher noch keine Anfrage an Nachhilfelehrer gestellt.',
                noEntriesForFilter: 'Du hast noch keine Anfragen mit dem gewählten Status.',
                filter: {
                    status: {
                        title: 'Filtern nach Status:',
                        type: {
                            all: 'Alle',
                            open: 'Offen',
                            answered: 'Antwort',
                            resolved: 'Geklärt',
                        },
                    },
                    sort: {
                        title: 'Sortieren nach:',
                        type: {
                            date: 'Datum',
                            subject: 'Fach',
                        },
                    },
                },
                table: {
                    status: 'Status',
                    date: 'Datum',
                    schoolSubject: 'Fach',
                    subject: 'Betreff',
                },
                status: {
                    open: 'Offen',
                    answered: 'Antwort',
                    resolved: 'Geklärt',
                    unknown: 'Unbekannt',
                },
                buttons: {
                    ask: 'Rückfrage stellen',
                    cancel: 'Abbrechen',
                    markAsDone: 'Als geklärt markieren',
                    markAsOpen: 'Als offen markieren',
                },
            },
            footer: {
                title: 'Online-Hausaufgabenhilfe nutzen',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                        + 'Nulla elit nulla, porta at erat et, imperdiet hendrerit augue.'
                        + 'Pellentesque tellus quam, faucibus sed sodales vulputate, porttitor quis nisi.'
                        + 'Suspendisse in facilisis turpis.',
                step: {
                    one: 'Lorem ipsum dolor sit amet, consectetur adipiscing elitr',
                    two: 'Lorem ipsum dolor sit amet',
                    three: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
                    four: 'Lorem ipsum dolor sit amet, consectetur',
                    five: 'Lorem ipsum dolor sit amet',
                },
            },
            error: {
                request: 'Beim Versenden der Anfrage ist ein Fehler aufgetreten, bitte versuche es erneut.',
                requestOverview: 'Beim Laden deiner bestehenden Anfragen ist ein Fehler aufgetreten, bitte versuche es erneut.',
                additionalComment: 'Beim Versenden der Nachricht ist ein Fehler aufgetreten, bitte versuche es erneut.',
                loadSingleRequest: 'Beim Laden des Tickets ist ein Fehler aufgetreten, bitte versuche es erneut.',
                reopenRequest: 'Beim Öffnen des Tickets ist ein Fehler aufgetreten, bitte versuche es erneut.',
                closeRequest: 'Beim Schließen des Tickets ist ein Fehler aufgetreten, bitte versuche es erneut.',
                upload: 'Beim Hochladen deiner Dateien ist ein Fehler aufgetreten, bitte versuche es erneut.',
            },
            comment: {
                atTime: 'um',
                solved: 'Anfrage als geklärt markiert.',
                opened: 'Anfrage als offen markiert.',
            },
        },
        termination: {
            pageTitle: 'Kündigung - Online-LernCenter',
            title: 'Abos hier kündigen',
            description: 'Sie haben Probleme mit Ihrem LernCenter-Zugang oder wollen Verbesserungsvorschläge machen? Dann schreiben Sie uns gerne eine Mail an <a href="mailto:olc@schuelerhilfe.de">olc@schuelerhilfe.de</a> - wir helfen Ihnen gerne.'
                        + ' Wenn Sie Ihr Abonnement kündigen oder widerrufen möchten, füllen Sie bitte das untenstehende Formular aus. Das Kündigungsformular gilt <strong>nur</strong> für das <strong>Abonnement des Online-LernCenters</strong> und <strong>nicht</strong> für Ihren Nachhilfe-Vertrag.'
                        + ' Hierfür wenden Sie sich bitte an Ihre Schülerhilfe vor Ort.',
            legitimatelyHints: '<strong>Rechtlicher Hinweis:</strong><br/>Gemäß § 312k BGB sind wir verpflichtet, Ihnen auf unserer Website die Möglichkeit bereitzustellen, eine Erklärung zur Kündigung Ihres bei uns geschlossenen LernCenter-Abonnements über eine Kündigungsschaltfläche abzugeben. '
                            + 'Wenn Sie Ihr Abonnement kündigen möchten, füllen Sie bitte die folgenden Felder aus und schicken Sie Ihre Erklärung ab. '
                            + 'Wir werden Ihnen den Eingang der Erklärung unmittelbar bestätigen und Ihre Kündigung im Anschluss bearbeiten.',
            revocation: 'Wenn Sie den Vertrag widerrufen wollen, dann laden Sie bitte dieses Formular <a href={hrefAttribut} target="_blank" rel="noopener noreferrer" download="Widerrufsformular-Online-Lernen.pdf">( Widerrufsformular )</a> herunter, füllen Sie es bitte aus und senden Sie es per E-Mail, per Post oder per Fax zurück.'
                        + ' Wir weisen Sie darauf hin, dass der Widerruf nur in den ersten 14 Tagen möglich ist.',
            disclaimer: '* Wir verarbeiten die von Ihnen hier angegebenen Daten, um Ihr Abonnement wunschgemäß zu beenden. Rechtsgrundlage ist Art. 6 Abs. 1 lit. b, c DSGVO.',
            userEmailSulotion: 'Lieber {vorName} {nachName},',
            userEmailMesaageIntro: 'schade, dass Sie Ihr Online-Abonnement für das OnlineLernCenter beenden wollen. Wir haben Ihre Kündigung erhalten. Falls Sie Probleme mit der Nutzung des OLCs hatten, helfen wir Ihnen gerne. Antworten Sie einfach auf diese Mail.'
                                + ' Wenn wir nichts von Ihnen hören, endet Ihr Abonnement zum nächstmöglichen Zeitpunkt.',
            olcEmailSulotion: 'Lieber Online-LernCenter,',
            olcEmailMesaageIntro: 'hiermit kündige ich mein Online-Abonnement für das OnlineLernCenter zum nächstmöglichen Zeitpunkt.',
            signatur: 'Ihre Schülerhilfe',
            terminationSuccess: 'Schade, dass Sie Ihr LernCenter-Abonnement kündigen wollen. Eine Bestätigungsmail ist in diesem Moment an die von Ihnen hinterlegte Mail-Adresse gegangen. Wenn die Kündigung von uns bearbeitet wurde, erhalten Sie nochmals eine E-Mail.',
            form: {
                title: 'Kündigungsformular',
                introduction: 'Bitte beachten Sie, dass dieses Kündigungsformular <strong>nur</strong> für das <strong>Abonnement des Online LernCenters</strong> gilt und <strong>nicht</strong> für Ihren Nachhilfe-Vertrag.'
                            + '<br/><br/>Bitte geben Sie hier soweit möglich die Daten an, die Sie bei Ihrer Registrierung angegeben haben, damit wir Sie eindeutig identifizieren können.',
                aboLabel: 'Welches Abo haben Sie abgeschlossen? *',
                terminationType: 'Art der Kündigung:',
                ordinaryTermination: '<strong>Ordentliche Kündigung:</strong> Wenn Sie Ihr Abonnement zum Ende der Laufzeit kündigen wollen.',
                extraOrdinaryTermination: '<strong>Außerordentliche Kündigung:</strong> Nur bei Vorliegen eines wichtigen Grundes, wenn unter Berücksichtigung aller Umstände des Einzelfalls und unter Abwägung der beiderseitigen Interessen die Fortsetzung des Abonnements bis zum vereinbarten Laufzeitende nicht zugemutet werden kann.',
                userData: 'Nennen Sie uns bitte Ihre Nutzerdaten:',
                registraionEmail: 'Nennen Sie uns bitte Ihre Benutzer-Mail-Adresse (zwingend erforderlich, um Ihren Account zuordnen zu können): *',
                contactEmailLabel: 'Nennen Sie uns bitte Ihre Mail-Adresse für die Kontaktaufnahme im Anschluss an die Kündigung: *',
                emailLabel: 'E-Mail-Adresse *',
                firstnameLabel: 'Vorname *',
                lastnameLabel: 'Nachname *',
                cityLabel: 'Stadt ',
                streetAndHouseNumLabel: 'Straße & Hausnummer',
                postalcodeLabel: 'PLZ',
                terminationDateLabel: 'Kündigung gewünscht zum:',
                submitButton: 'Kündigung übermitteln',
                dateError: 'Wählen Sie bitte das Datum aus !',
                aboError: 'Wählen Sie bitte das Abo aus !',
                terminationTypeError: 'Wählen Sie bitte die Art der Kündigung aus!',
            },
        },
        onTermination: {
            pageTitle: 'Kündigung - Online-Nachhilfe-Laufzeitverträge',
            title: 'Abo-Kündigung Online-Nachhilfe',
            description: 'Sie wollen und verlassen? Das ist schade. Wenn Sie unzufrieden mit unserer Leistung sind, schreiben Sie uns gerne eine Mail an <a href="mailto:online.nachhilfe@schuelerhilfe.de">online.nachhilfe@schuelerhilfe.de</a> - wir sind gerne und sofort für Sie da.',
            legitimatelyHints: '<strong>Rechtlicher Hinweis:</strong><br/>Gemäß § 312k BGB sind wir verpflichtet, Ihnen auf unserer Website die Möglichkeit bereitzustellen, eine Erklärung zur Kündigung Ihres bei uns geschlossenen Online-Nachhilfe-Abonnements über eine Kündigungsschaltfläche abzugeben. '
                            + 'Wenn Sie Ihr Online-Nachhilfe-Abonnement kündigen möchten, füllen Sie bitte die folgenden Felder aus und schicken Sie Ihre Erklärung ab. '
                            + 'Wir werden Ihnen den Eingang der Erklärung unmittelbar bestätigen und Ihre Kündigung im Anschluss bearbeiten.',
            disclaimer: '* Wir verarbeiten die von Ihnen hier angegebenen Daten, um Ihr Abonnement wunschgemäß zu beenden. Rechtsgrundlage ist Art. 6 Abs. 1 lit. b, c DSGVO.',
            userEmailSulotion: 'Lieber {vorName} {nachName},',
            userEmailMesaageIntro: 'schade, dass Sie Ihr Online-Nachhilfe-Abonnements für das OnlineLernCenter beenden wollen. Wir haben Ihre Kündigung erhalten. Falls Sie Probleme mit der Nutzung des OLCs hatten, helfen wir Ihnen gerne. Antworten Sie einfach auf diese Mail.'
                                + ' Wenn wir nichts von Ihnen hören, endet Ihr Abonnement zum nächstmöglichen Zeitpunkt.',
            olcEmailSulotion: 'Lieber Online-LernCenter,',
            olcEmailMesaageIntro: 'hiermit kündige ich mein Online-Nachhilfe-Abonnements für das OnlineLernCenter zum nächstmöglichen Zeitpunkt.',
            signatur: 'Ihre Schülerhilfe',
            terminationSuccess: 'Schade, dass Sie Ihr Online-Nachhilfe-Abonnements kündigen wollen. Eine Bestätigungsmail ist in diesem Moment an die von Ihnen hinterlegte Mail-Adresse gegangen. Wenn die Kündigung von uns bearbeitet wurde, erhalten Sie nochmals eine E-Mail.',
            form: {
                title: 'Kündigungsformular',
                introduction: 'Bitte beachten Sie, dass dieses Kündigungsformular <strong>nur</strong> für <strong>Online-Nachhilfe-Verträge</strong> gilt.'
                            + '<br/><br/>Bitte geben Sie hier soweit möglich die Daten an, die Sie bei Ihrer Registrierung angegeben haben, damit wir Sie eindeutig identifizieren können.',
                terminationType: 'Art der Kündigung:',
                ordinaryTermination: '<strong>Ordentliche Kündigung:</strong> Wenn Sie Ihr Abonnement zum Ende der Laufzeit kündigen wollen.',
                extraOrdinaryTermination: '<strong>Außerordentliche Kündigung:</strong> Nur bei Vorliegen eines wichtigen Grundes, wenn unter Berücksichtigung aller Umstände des Einzelfalls und unter Abwägung der beiderseitigen Interessen die Fortsetzung des Abonnements bis zum vereinbarten Laufzeitende nicht zugemutet werden kann.',
                userData: 'Nennen Sie uns bitte Ihre Nutzerdaten:',
                registraionEmail: 'Nennen Sie uns bitte Ihre Benutzer-Mail-Adresse (zwingend erforderlich, um Ihren Account zuordnen zu können): *',
                contactEmailLabel: 'Nennen Sie uns bitte Ihre Mail-Adresse für die Kontaktaufnahme im Anschluss an die Kündigung: *',
                emailLabel: 'E-Mail-Adresse *',
                firstnameLabel: 'Vorname *',
                lastnameLabel: 'Nachname *',
                cityLabel: 'Stadt ',
                streetAndHouseNumLabel: 'Straße & Hausnummer',
                postalcodeLabel: 'PLZ',
                terminationDateLabel: 'Kündigung gewünscht zum:',
                submitButton: 'Kündigung übermitteln',
                dateError: 'Wählen Sie bitte das Datum aus !',
                aboError: 'Wählen Sie bitte das Abo aus !',
                terminationTypeError: 'Wählen Sie bitte die Art der Kündigung aus!',
            },
        },
        payment: {
            successHeader: 'Vielen Dank!',
            successTextHeader: 'Ihre Bestellung war erfolgreich! Wir wünschen Ihrem Kind viel Spaß beim Ausprobieren '
                             + 'und beim Lernen',
            successText: 'Lassen Sie sich das umfassende Lernkonzept der Schülerhilfe von unserem Lernplattform-'
                       + 'Experten erklären. Die Präsentation dauert nicht länger als 5 Minuten.<br/><br/>'
                       + 'Wir würden uns freuen, wenn wir Sie in den nächsten Stunden ganz unverbindlich und '
                       + 'natürlich kostenlos anrufen können. Hinterlassen Sie uns dazu Ihre Telefonnummer:',
            successImageAlt: 'Zahlung erfolgreich',
            pageTitleSuccess: 'Zahlung erfolgreich',
            failHeader: 'Oh, das hat nicht geklappt!',
            failTextHeader: 'Deine Zahlung war leider nicht erfolgreich.',
            failText: 'Probiere es bitte erneut oder kontaktiere uns mit unserem <a href=\'https://www.schuelerhilfe.de/lerncenter/feedback\'>Feedbackformular</a>.',
            failImageAlt: 'Zahlung nicht erfolgreich',
            pageTitleFail: 'Zahlung nicht erfolgreich',
            furtherQuestions: 'Haben Sie noch weitere Fragen?',
        },
        webinars: {
            mainHeading: 'Webinare - Online-LernCenter',
            descriptionText: 'Live mit Nachhilfelehrern zu vielen Themen online kommunizieren.',
            linkBoxTitle: 'Live-Webinar Kalender',
            linkBoxText: 'Online-Vorlesungen von echten Nachhilfelehrern zu vielen Themen mit Raum für deine Fragen.',
            registerWebinarLabel: 'Ich möchte am Webinar teilnehmen',
            unregisterWebinarLabel: 'Teilnahme am Webinar absagen',
            registerDisabled: 'Eine Anmeldung zu diesem Webinar ist nicht mehr möglich.',
            unregisterDisabled: 'Eine Abmeldung von diesem Webinar ist nicht mehr möglich.',
            registerSuccess: 'Du wurdest erfolgreich angemeldet.',
            unregisterSuccess: 'Du wurdest erfolgreich abgemeldet.',
            closingTimeInfoText: 'Vom 15.12.2024 - 07.01.2025 finden keine Webinare statt. Wir wünschen dir und deiner '
                               + 'Familie ein frohes Weihnachtsfest und einen guten Rutsch in das neue Jahr.',
        },
        feedback: {
            pageTitle: 'Kontakt zum Team des Schülerhilfe Online-LernCenters',
            title: 'Feedback',
            subtitle: 'Stelle uns hier deine Frage',
            description: 'Meinungen und Bewertungen zum Online-LernCenter oder Verbesserungsvorschläge und Kritik kannst du hier loswerden. Wir freuen uns über dein Feedback!',
            placeholder: {
                name: 'Name*',
                email: 'E-Mail-Adresse*',
                subject: 'Betreff*',
                message: 'Nachricht*',
            },
            mandatory: '*Pflichtfelder',
            checkbox: 'Die {agbLink} sowie die {dataLink} habe ich zur Kenntnis genommen und bin mit deren Inhalt einverstanden.',
            submit: 'Senden',
            boxes: {
                email: {
                    title: 'Schicke uns gerne eine E-Mail',
                    description: `Du kannst uns bei Fragen oder technischen Schwierigkeiten jederzeit eine E-Mail an ${process.env.REACT_APP_OLC_SUPPORT_EMAIL || 'olc@schuelerhilfe.de'} senden:`,
                    button: 'E-Mail senden',
                },
                faq: {
                    title: 'Hast du weitere Fragen?',
                    description: 'Hier findest du häufig gestellte Fragen und Antworten zum Online-LernCenter:',
                    button: 'Fragen & Antworten',
                },
            },
            error: 'Beim Absenden deines Feedbacks ist ein Fehler aufgetreten, bitte versuche es später erneut.',
            success: 'Vielen Dank für dein Feedback.',
            worksheet: 'Rückmeldung zu "{title}" - Arbeitsblatt Nr. {number}',
        },
        captcha: {
            error: 'Etwas ist mit dem Captcha schiefgelaufen, bitte versuche es später erneut.',
        },
        privacy: {
            pageTitle: 'Datenschutz - Online-LernCenter',
            metaDescription: 'Klär dich auf: Hier findest du die Datenschutzhinweise des Schülerhilfe Online-LernCenters.',
            privacyStatement: 'I. Datenschutzerklärung der ZGS Bildungs-GmbH für www.schuelerhilfe.de',
            locationPrivacyStatement: 'II. Datenschutzerklärung der ZGS Bildungs-GmbH für die Standortseiten unter www.schuelerhilfe.de (Gemeinsam für die Verarbeitung Verantwortliche i.S.v. Art. 26 DSGVO)',
            dataHandling: '1. Erhebung und Verarbeitung von Daten',
            personalData: '2. Nutzung und Weitergabe personenbezogener Daten',
        },
        bookcodeRegisterForm: {
            pageTitle: 'Buchcode aktivieren: Das Online-LernCenter der Schülerhilfe',
            mainHeading: 'Jetzt deinen Buchcode für das Online-LernCenter aktivieren',
            bookcode: 'Buchcode',
            registerSuccess: '<h4>Aktivierung erfolgreich!</h4>Bitte prüfe deinen Posteingang. Wir haben dir eine '
                           + 'E-Mail mit weiteren Schritten geschickt.',
        },
        entrycodeRegisterForm: {
            pageTitle: 'Zugangscode aktivieren: Das Online-LernCenter der Schülerhilfe',
            mainHeading: 'Jetzt deinen Zugangscode für das Online-LernCenter aktivieren',
            entrycode: 'Zugangscode',
            registerSuccess: '<h4>Aktivierung erfolgreich!</h4>Bitte prüfe deinen Posteingang. Wir haben dir eine '
                           + 'E-Mail mit weiteren Schritten geschickt.',
        },
        bookcodeExtendForm: {
            mainHeading: 'Buchcode verlängern',
            bookcode: 'Buchcode',
            centerNumber: 'Standort-Nummer',
            extendSuccess: '<h4>Verlängerung erfolgreich!</h4>Prima! Das hat geklappt. Wir überprüfen nun deine '
                         + 'Eingaben uns schalten dann die Verlängerung frei.',
            formDescriptionText: 'Bitte zunächst den individuellen Buchcode eintragen. Anschließend die '
                               + 'Standort-Nummer sowie das Standort Passwort eingeben und auf senden klicken.',
            requiredFieldsNotice: '*Pflichtfelder',
            extendImportantHint: '<h4>Wichtiger Hinweis:</h4>Sollte dein Account nach spätestens 24 Stunden nicht '
                               + 'verlängert worden sein, kontaktiere uns über unser '
                               + '<a href=\'/feedback\'>Feedbackformular</a>.',
            sendLabel: 'Senden',
        },
    },
    en: {
        abos: {
            pageTitle: 'Abos & Preise - Online-LernCenter',
            metaDescription: 'Abos und Preise im LernCenter der Schülerhilfe ➔ Wir haben günstige Abos und Direktkauf-Optionen ➔ Erhalte Zugriff zu allen Online-Nachhilfe-Angeboten!',
            mainHeading: 'Abos und Preise für das Schülerhilfe LernCenter',
            paymentOptions: 'Zahlungsoptionen',
            paymentOptionsHint: '* gilt nicht für Abo-Buchungen',
            guarantee: 'Wir garantieren Ihnen',
            secureTransmission: ' Verschlüsselte Übertragung aller Daten',
            securePayment: ' Sichere Zahlung über Bankeinzug, Kredikarte oder PayPal ',
            instantAccess: ' Sofortige Nutzung nach der Buchung',
            userData: 'Daten des Benutzers',
            userEmailHint: 'Die hier angegebene E-Mail-Adresse wird für das Online-LernCenter freigeschaltet.',
            paymentAddress: 'Rechnungsadresse',
            street: 'Straße',
            postalcode: 'Postleitzahl',
            city: 'Ort',
            country: 'Land',
            buyLabel: 'Weiter zur Zahlung',
            abortLabel: 'Abbrechen',
            paymentMethod: 'Zahlungsmethode',
            boxText: 'Fragen zur Buchung? Wir helfen gerne weiter, einfach hier klicken.',
            paymentFormTitle: 'Rechnungsadresse',
            paymentFormText: 'Sollten Sie nicht ihre 30-tägige Testphase kündigen, so erhalten Sie alle anfallenden Rechnungen per E-Mail.',
            paymentFormTextNoTrial: 'Sie erhalten alle anfallenden Rechnungen per E-Mail.',
            paymentFormInstruction: 'Geben Sie jetzt Ihre Zahlungsdaten ein, um ihren <b>kostenlosen 30-Tage-Test zu starten.</b>',
            paymentFormInstructionNoTrial: 'Geben Sie jetzt Ihre Zahlungsdaten ein, um ihr <b>Abonnement zu aktivieren.</b>',
            paymentExplanationHeader: 'Zahlungsart',
            paymentExplanationContentText: 'Wir benötigen diese Information um sicherzustellen, dass Sie eine echte Person sind und um Missbrauch zu verhindern. Keine Sorge:',
            paymentExplanationFactsFirst: '<b>Wir berechnen Ihnen nichts</b>, bis Ihre kostenlose Testphase endet.',
            paymentExplanationFactsSecond: '{text} geht schnell und einfach. {link}',
            paymentExplanationFactsThird: '<b>Sie haben volle 30 Tage Zeit</b>, das Online-LernCenter gemeinsam mit Ihrem Kind in Ruhe auszuprobieren.',
            paymentExplanationExtra: 'Wir werden ihr Zahlungsmittel nicht belasten, bis ihre 30-Tage-Testphase beendet ist.',
            noChosenProduct: 'Abo nicht ausgewählt. Bitte vorher auf https://www.schuelerhilfe.de ein Abo wählen!',
        },
        infoMessages: {
            courseStartPending: 'Der Link zum Unterrichtsraum ist 5 Minuten vor Kursbeginn verfügbar.',
        },
        errorMessages: {
            error: 'Fehler',
            invalidDate: 'Bitte geben Sie ein gültiges Datum im Format TT.MM.JJJJ ein.',
            networkError: 'Es ist ein unbekannter Netzwerk-Fehler aufgetreten.',
            saveOpinionError: 'Fehler beim Speichern des Beitrags. Bitte versuchen Sie es erneut.',
            addStudentToExaminationError: 'Fehler beim Verschicken des Fachchecks. Bitte versuchen Sie es erneut.',
            createOlatTeacherError: 'Fehler bei der Einrichtung des Zugangs zur Lernplattform.',
            addParticipantToWebinarError: 'Dieses Webinar ist bereits ausgebucht.',
            resetUserExamError: 'Fehler beim Zurücksetzen des Fachchecks. Bitte versuchen Sie es erneut.',
            getUserXOlatTokenError: 'Fehler bei der Authentifizierung des OpenOlat-Zugangs.',
            passwordConfirmation: 'Passwort und Passwort (Wiederholung) stimmen nicht überein.',
            passwordRules: 'Das gewählte Passwort entspricht nicht den Passwort-Regeln',
            requiredFields: 'Bitte füllen Sie alle Pflichtfelder aus.',
            serverError: 'Es ist ein unbekannter Fehler aufgetreten.',
            terms: 'Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen und die Datenschutzerklärung.',
            invalidPasswordConfirmation: 'Passwort und Passwort (Wiederholung) stimmen nicht überein.',
            noUserId: 'Anmeldeinformationen konnten nicht gelesen werden',
            invalidEmail: 'Diese E-Mail Adresse ist ungültig.',
            resultsPdfDownloadError: 'Die Datei konnte nicht gefunden werden.',
            userDataError: 'Benutzerdaten konnten nicht abgerufen werden.',
        },
        course: {
            title: 'Dein nächster Online-Unterricht:',
            allCompleted: 'Alle heutigen Kurse sind bereits beendet',
            courseLink: 'Jetzt zum Kurs',
            link: 'Kursübersicht',
            description: 'Der Link zum Unterrichtsraum ist 5 Minuten vor Kursbeginn in deiner Kursübersicht '
            + 'verfügbar. Gehe dazu auf „Kursübersicht" und Klicke zur Teilnahme auf den anstehenden Kurs.',
        },
        courses: {
            title: 'Deine Kursübersicht',
            subTitle: 'Der Link zum Unterrichtsraum ist frühstens 5 Minuten vor Start der Unterrichtsstunde verfügbar. '
            + 'Um teilzunehmen, klicke auf den anstehenden Kurs hier in der Übersicht.',
            noCoursesFound: 'Für den heutigen Tag wurden keine Online Kurse gefunden.',
        },
        loginForm: {
            mainHeading: 'Logge dich hier ein',
            formDescriptionText: 'Einfach E-Mail Adresse und Passwort eingeben und schon geht es los!',
            email: 'E-Mail-Adresse',
            password: 'Passwort',
            passwordLost: 'Passwort vergessen?',
            requiredFieldsNotice: '*Pflichtfelder',
            submitLabel: 'Einloggen',
            secureTransmission: ' Verschlüsselte Übertragung aller Daten',
        },
        activation: {
            successMessage: 'Your account has been activated!',
            errorMessage: 'An error occurred!',
            notActivatedMessage: 'Your account could not be activated.',
            alreadyActivatedMessage: 'Your account is already activated.',
            loginLabel: 'Continue to login',
        },
        authentication: {
            pageTitle: 'Online-LernCenter - Login',
            caption: 'Log in here',
            loginError: 'Error during login! Please check your entered data!',
            error: 'Error while connecting to the server.',
            emailLabel: 'E-Mail address',
            passwordLabel: 'Password',
            loginLabel: 'Login',
            stayLoggedInLabel: 'Stay logged in',
            passwordLostLabel: 'Password lost?',
            newHereLabel: 'New here?',
            registerLabel: 'Register now!',
            storageWarning: 'To use the Online-LernCenter you have to allow storing of website data. Currently this is '
                + 'blocked by your browser. Please change the settings accordingly.',
        },
        changePassword: {
            pageTitle: 'Online-LernCenter - Change password',
            infoText: 'To change your password, please enter the old password, the new password and the confirmation '
                    + 'of the new password.',
            oldPasswordLabel: 'Password',
            newPasswordLabel: 'New password',
            newPasswordConfirmationLabel: 'New password confirmation',
            submitLabel: 'Submit',
            passwordRules: 'The password must contain at least 8 characters and at least one upper case letter, '
                         + 'one lower case letter and one digit (0 to 9).',
            errorMessage: 'An error occurred while changing the password!',
            successMessage: 'Your password has been changed! Please login again using the changed password.',
        },
        common: {
            subject: {
                ALL: 'All subjects',
                ALE: 'Business Studies',
                BIO: 'Biology',
                CHE: 'Chemistry',
                DEU: 'German',
                ENG: 'English',
                FRZ: 'French',
                GES: 'History',
                ITA: 'Italian',
                LAT: 'Latin',
                MAT: 'Mathematics',
                POL: 'Politics',
                RUS: 'Russian',
                SPA: 'Spanish',
                WIR: 'Economics',
                AGR: 'Ancient Greek',
                DYK: 'Dyscalculia',
                ERD: 'Geography',
                INF: 'Computer Science',
                JAP: 'Japanese',
                LEG: 'Dyslexia',
                MUS: 'Music',
                NAW: 'Science',
                NED: 'Dutch',
                PHY: 'Physics',
                REW: 'Accounting',
                SAC: 'General Knowledge',
                TEC: 'Engineering',
            },
        },
        contactForm: {
            pageTitle: 'Online-LernCenter - Get in touch',
            kindOfRequest: 'Please select the kind of your request:',
            request: 'Request',
            excuseChild: 'Cancel class',
            catchUpLessons: 'Arrange catch up lessons',
            changeContractData: 'Change contract data',
            changeContactData: 'Change contact data',
            olcSupportRequest: 'Online-LernCenter support request',
            consultation: 'Arrange parents talk',
            feedback: 'Feedback',
            examination: 'Report exam',
            addMark: 'Add Mark',
            other: 'Other request',
            requestSubmittedMessage: 'Your request was successfully submitted.',
            requestCouldNotBeSubmittedMessage: 'There was an error submitting your request.',
            forms: {
                salutation: 'Dear Schülerhilfe administration',
                salutationOlc: 'Dear Support-Team',
                subjectLabel: 'Subject',
                submitLabel: 'Submit',
                excuseChild: {
                    from: 'from',
                    to: 'to',
                    cause: 'Cause',
                    description: 'Description',
                    illness: 'Illness',
                    schoolEvent: 'School event',
                    internship: 'Internship',
                    other: 'Other cause',
                    subject: 'Subject',
                    messageIntro: 'I hereby excuse my child {name} ({icasId}) for the following lessons',
                },
                catchUpLesson: {
                    infoText: 'Please call your local Schülerhilfe to arrange catch up lessons! Thank you!',
                    phoneCallLabel: 'Call now',
                },
                changeContractData: {
                    infoText: 'Please call your local Schülerhilfe to communicate the desired changes!',
                    phoneCallLabel: 'Call now',
                },
                changeContactData: {
                    infoText: 'Please describe the desired contact data changes in detail:',
                    messageIntro: 'hereby I want to inform you about the following contact data changes '
                                + 'for customer number {number}',
                },
                olcSupportRequest: {
                    infoText: 'Please describe the issue you need support with:',
                    messageIntro: 'I need your support with the following issue',
                },
                consultation: {
                    date: 'Desired date',
                    closureDaysInfo: 'Attention, the site is closed on the following days of the week:',
                    timeframe: 'Timeframe',
                    message: 'Message (optional)',
                    messageIntro: 'hereby I want to arrange a consultation regarding my child {name} ({icasId})',
                },
                feedback: {
                    infoText: 'Please describe the issue you want to give feedback on:',
                    messageIntro: 'I wanted to give you some feedback on the following issue',
                },
                reportExam: {
                    date: 'Date',
                    subject: 'Subject',
                    topic: 'Topic',
                    messageIntro: 'hereby I want to inform you about an examination for my child {name} ({icasId})',
                },
                addMark: {
                    date: 'Date',
                    mark: 'Mark',
                    kindOfMark: 'Kind of mark',
                    exam: 'Exam',
                    test: 'Test',
                    other: 'Other',
                    certificate: 'Certificate',
                    examination: 'Examination',
                    application: 'Application',
                    examinationKinds: 'Examination/Test',
                    subject: 'Subject',
                    topic: 'Topic',
                    messageIntro: 'hereby I want to inform you about a mark for my child {name} ({icasId})',
                    submitLabel: 'Add mark',
                },
                other: {
                    infoText: ' ',
                    messageStart: 'the following message was submitted via the Eltern-App',
                    messageEnd: '',
                },
            },
        },
        registerForm: {
            mainHeading: 'Try our Online Learning Center for free: Learn online for free until 11.04.2021!',
            mainHeadingSixtyDays: 'Exclusive offer for Lidl Plus customers:<br /> 2 months free online learning!',
            specialStateOfferMainHeadingForeign: 'Exclusive offer for students and teachers:<br /> Learn online for free until {summerVacationDate}!',
            specialStateOfferMainHeading: 'Exclusive offer for students and teachers in <span class="noWrapOnHyphen">{nationState}:</span> Learn online for free until {promotionEndDate}!',
            formDescritpionText: 'It\'s very simple: fill in the form with the user\'s data, submit it, click on the confirmation link in the email and log in. Done!',
            firstname: 'Firstname',
            lastname: 'Lastname',
            email: 'E-Mail-Address',
            password: 'Password',
            phone: 'Phone (mobile)',
            validationCode: 'Validation code',
            getValidationCode: 'Send me a code',
            requiredFieldsNotice: '*Mandatory fields',
            sendLabel: 'Next',
            forwardToLogin: 'To the login area',
            registerSuccess: '<h4>Registration successful!</h4>Please check your inbox. We have sent you an email with further steps.',
            registerImportantHint: '<h4>Important Note:</h4>If you haven\'t received an email after a few minutes, please make sure our email hasn\'t ended up in your spam folder and contact us using our <a href=\'https://www.schuelerhilfe.de/online-lernen/feedback\'>feedback form</a>.',
            terms: 'GTCs and data protection*',
            promotionTitle: 'Learning tips and promotional offers',
            promotion: 'I would like to receive learning tips and promotional offers by email.',
            accountAlreadyExists: 'Do you already have an account?',
        },
        testAccountRegisterForm: {
            mainHeading: 'Test the Online-LernCenter for 30 days without charge',
            formDescritpionText: 'It\'s simple: Fill your data into the form, send them, click the activation link in the e-mail and log in.',
            firstname: 'first name',
            lastname: 'last name',
            email: 'e-mail-address',
            requiredFieldsNotice: '*required fields',
            sendLabel: 'Next',
            registerSuccess: '<h4>Registration successful!</h4>Please check your e-mail inbox. We sent you an e-mail with further steps.',
            registerImportantHint: '<h4>Important Hint:</h4>If you dont\'t receive an e-mail within the next few minutes, please check your spam-folder and contact us via our <a href=\'https://www.schuelerhilfe.de/lerncenter/feedback\'>feedback form</a>.',
        },
        testAccountRegisterFormContainer: {
            pageTitle: '30 Tage gratis testen: Das Online-LernCenter der Schülerhilfe',
            metaDescription: '30 Tage gratis Mathe, Deutsch, Englisch, Latein und Französisch online lernen ➔ Einfach das Schülerhilfe LernCenter testen ✓ Leistungen durch das LernCenter verbessern ✓',
            hotlineBoxText: 'Fragen zur Buchung? Wir helfen gerne weiter, einfach hier klicken.',
        },
        chooseAbo: {
            title: 'Das Schülerhilfe Online-LernCenter<br>30 Tage kostenlos testen',
            badge: 'Meistgebucht',
            premium: 'Premium',
            basic: 'Basis',
            only: 'Nur',
            durationOfContract: '12 Monate',
            month: 'im Monat.',
            nonBindingTestPhase: 'Sichere und unverbindliche Testphase',
            directAccess: 'Sofort Zugriff erhalten auf:',
            forwardBasic: 'weiter mit dem Basis Abo',
            forwardPremium: 'weiter mit dem Premium Abo',
            benefitVideo: 'Lernvideos',
            benefitExercises: 'Übungsaufgaben',
            benefitWorksheet: 'Arbeitsblätter',
            benefitVocabularyTrainer: 'Vokabeltrainer Englisch & Französisch',
            benefitODH: 'Online-Hausaufgabenhilfe',
            benefitWebinars: 'Webinare',
        },
        dateInput: {
            dayLabel: 'Day',
            monthLabel: 'Month',
            yearLabel: 'Year',
            dayError: 'Please select a day!',
            monthError: 'Please select a month!',
            yearError: 'Please select a year!',
        },
        faq: {
            pageTitle: 'Häufig gestellte Fragen rund ums Online-LernCenter der Schülerhilfe',
            back: 'Back',
        },
        footer: {
            home: 'Home',
            center: 'Schülerhilfe',
            calendar: 'Calendar',
            grades: 'Progress',
            imprint: 'Imprint',
            more: 'More',
            privacy: 'Privacy',
            shLogoAlt: 'Schülerhilfe Logo',
            copyright: '&copy {year} Schülerhilfe',
            faq: 'FAQ',
            contact: 'Contact',
            downloads: 'Kündigung',
        },
        header: {
            start: 'Home',
            favourites: 'Favourites',
            account: 'Account',
            logout: 'Logout',
            calendar: 'Calendar',
            contactForm: 'Get in touch',
            contractDetails: 'Contract details',
            newCustomer: 'Not a customer of Schülerhilfe yet?',
            conversationDetails: 'Conversation Details',
            messages: 'Messages',
            deleteConversation: 'Delete Messages',
            more: 'More',
            profile: 'Profile',
            profileIconAlt: 'Profile',
            response: 'Respond',
            settings: 'Settings',
            shLogoAlt: 'Schülerhilfe logo',
            changePassword: 'Change Password',
            markProgress: 'Mark progress',
            center: 'Your Schülerhilfe',
            help: 'Help',
            olc: 'Online-LernCenter',
            imprint: 'Imprint',
            register: 'Register',
            requestPasswordReset: 'Password lost?',
            addContract: 'Add contract',
            activate: 'Activate your account',
            termsOfUse: 'Terms of use',
        },
        search: {
            search: 'Search',
            searchFor: 'Search for...',
            searchBarLabel: 'Search example: Lorem',
            placeholder: 'Enter your search term here',
        },
        imprint: {
            pageTitle: 'Online-LernCenter - Imprint',
            title: 'Imprint',
        },
        inbox: {
            pageTitle: 'Online-LernCenter - Messages',
            noConversationsText: 'No messages available',
            newMessageLabel: 'New message',
            getConversationsByUserIdError: 'An error occurred while loading your messages.',
        },
        languageSwitcher: {
            germanLabel: 'DE',
            englishLabel: 'EN',
        },
        library: {
            quickFinder: 'Quick-Finder',
            subjectLabel: '1. Select subject',
            gradeLabel: '2. Select grade',
            stateLabel: '3. Select state',
            mainTopicLabel: '3. Select main topic',
            betweenTopicLabel: '4. Select topic',
            underTopicLabel: '5. Select subtopic',
            exerciseLabel: 'Exercises',
            exerciseLevel: 'Level',
            exerciseNumber: 'Exercise-Number',
            examLabel: 'Interactive exercises',
            examDescription: 'Choose your interactive exercise. You will receive your result by e-mail.',
            downloadLabel: 'Download',
            showSolutionLabel: 'Show solution',
            showExerciseLabel: 'Show exercises',
            similarTopicsLabel: 'Similar topics',
            videosLabel: 'Videos',
            linkBoxType: {
                exam: 'Interactive exercise',
                topic: 'Topic',
            },
            searchHits: 'Search results',
            noSearchHits: 'We could not find any results matching your search term. Please try another search term or '
                        + 'search our library yourself.',
            noSearchHitsShort: 'No results found',
            noSearchHitsBackToLibButton: 'Back',
            showMore: 'Show more',
            showLess: 'Show less',
            feedback: 'Feedback',
            notLoggedInAppeal: 'Do a knowledge check now and test your learning level directly online.',
            notLoggedInHint: 'You can see this content, if you are logged in. You can find the login '
                           + '{loginLink}. If you do not have an account yet, you can register {registerLink}.',
            loginText: 'here',
            registerText: 'here',
            courseSupportContactButton: 'Do you have any trouble with courses? Contact support here.',
        },
        messageModal: {
            understoodLabel: 'Understood',
            closeLabel: 'Close',
        },
        modal: {
            closeLabel: 'Close',
            centerPhoneCallInfoModal: {
                introduction: 'You want to call your Schülerhilfe center?',
                centerPhoneLabel: 'Phone:',
                phoneCallLabel: 'Call',
                pleaseClickText: 'Please click on the following button:',
            },
            supportPhoneCallInfoModal: {
                introduction: 'You would like to get advice from us by phone?',
                phoneLabel: 'Phone:',
                phoneCallLabel: 'Call',
                pleaseClickText: 'Please click on the following button:',
            },
            addContractInfoModal: {
                introductionText: 'You can find the information here:',
                additionalIntroductionText: 'On advance notice please use the customer number from your welcoming '
                                          + 'letter.',
                helperMarkerImageAlt: 'Customer help for adding contracts',
                additionalHelperMarkerImageAlt: 'Customer help for adding contracts on advance notice',
            },
            registrationInfoModal: {
                introductionText: 'You can find the information here:',
                additionalIntroductionText: 'On advance notification you can find the e-mail-address of the claimant '
                                          + 'here:',
                helperMarkerImageAlt: 'Customer help for using correct e-mail-address',
                additionalHelperMarkerImageAlt: 'Customer help for using correct e-mail-address on advance '
                                              + 'notification',
            },
            registerCallToActionModal: {
                introduction: 'Get access to the Online Learning Center now!',
                additionalIntroductionText: 'Learn online starting at<br /><span className="bannerPrice">5 €</span><br />per month¹',
                labels: {
                    firstname: 'Firstname*',
                    lastname: 'Lastname*',
                    email: 'E-mail address*',
                    mandatory: '*Mandatory field',
                },
                links: {
                    agb: 'General Terms and Conditions',
                    data: 'Data Protection Policy',
                    login: 'Login now',
                },
                checkbox: 'I have taken note of the {agbLink} as well as the {dataLink} and I agree with its contents.',
                button: 'Free 30 day trial!',
                alreadyCustomer: 'Are you already a customer? {loginLink}.',
                footnote: '¹ The Base-Subscription is priced 5 Euro per month and the Premium-Subscription 9 Euro per '
                        + 'month.',
            },
            schulaktionOfferModal: {
                title: 'We give you 2 free tutoring hours in one of our 1,000 student help centers or for our online video tutoring with a personal tutor.',
                labels: {
                    phone: 'Please call me to make an appointment for the 2 free tutoring sessions',
                    phoneNumber: 'Phone number',
                    submitButton: 'Secure 2 free tutoring lessons now',
                    consents: 'I agree that Schülerhilfe (ZGS Bildungs-GmbH) may contact me and inform me about promotions.',
                },
            },
            courseSupportContactModal: {
                headline: 'Contact support',
                headlineDescription: 'Please send us a message using the contact form, we will contact you as soon as possible:',
                placeholder: {
                    subject: 'Subject',
                    message: 'Message',
                },
                mandatory: '*Mandatory field',
                submitBtnLabel: 'Contact support',
                success: 'Thank you for your request!',
                error: 'An error occured while sending your support request, please try again later.',
            },
        },
        moreMenu: {
            pageTitle: 'Online-LernCenter - More',
            news: 'News',
            facebook: 'Facebook',
            instagram: 'Instagram',
            olc: 'Online-LernCenter',
            settings: 'Settings',
            help: 'Help',
            imprint: 'Imprint',
            privacy: 'Privacy',
        },
        notFound: {
            message: 'Ooops, looks like there is a worm in there.',
            tryAgain: 'Try again',
            imgAlt: 'Image not found',
        },
        profile: {
            pageTitle: 'Konto - Online-LernCenter',
            profileDataContentBoxLabel: 'Persönliche Daten',
            profileDataContentBoxDescription: 'Hier kannst du alle Daten zu deinem Profil angeben oder verändern.',
            email: 'E-Mail',
            firstname: 'Vorname',
            lastname: 'Name',
            classLevel: 'Klassenstufe',
            typeOfSchool: 'Schulform',
            phone: 'Telefonnummer',
            postalCode: 'Postleitzahl',
            birthDate: 'Geburtsdatum',
            voucherCode: 'Aktions-Code',
            submitLabel: 'Speichern',
            passwordChangeContentBoxLabel: 'Passwort ändern',
            voucherContentBoxLabel: 'Aktions-Code einlösen',
            voucherContentBoxDescription: 'Wenn du einen Aktions-Code besitzt, kannst du ihn hier einlösen.',
            voucherSubmitLabel: 'Einlösen',
        },
        olcInfo: {
            pageTitle: 'Online-LernCenter - Online-LernCenter',
            olcLinkBtnLabel: 'To Online-LernCenter',
        },
        registration: {
            pageTitle: 'Online-LernCenter - Registration',
            infoText: 'Please enter your first name and last name for verification.<br/><br/>'
                    + 'Furthermore define an e-mail address and password for login. Please note, that the '
                    + 'e-mail-address has to be equal to the e-mail-address of the claimant used on contract signing.',
            dataLocation: 'Where can I find the e-mail-address of the claimant?',
            agbs: 'General terms and conditions',
            next: 'Next',
            back: 'Back',
            ageStepTitle: 'How old are You?',
            ltSixteen: 'I am younger than 16 years old',
            geSixteen: 'I am 16 years old or older',
            ageStepHint: 'PrivacyPrivacy is very important to us. If you are under 16 years old, we need your parents\' permission to use the online homework help of Schülerhilfe. Here you can find our {link1}.',
            phoneNumberStepTitle: 'Verification',
            phoneNumberStepHint: 'We will send you a code via SMS to verify your account. Please enter your mobile number.',
            phoneValidationStepTitle: 'Enter SMS code',
            phoneValidationStepHint: 'Please enter the code we sent you by SMS to the mobile number you entered.',
            personalDataStepTitle: 'Personal details',
            personalDataStepHint: 'We would like to address you personally.',
            loginDataStepTitle: 'Login details',
            loginDataStepHint: 'Here you specify your future login data.',
            registeredStepTitle: 'Your registration was successful!',
            registeredStepHint: 'Congratulations! Your registration was successful. Now you only need to confirm your e-mail address. To do so, please click on the link in the email we just sent you. Then you can start right away and use the Online-LernCenter 14 days for free!',
            firstnameLabel: 'First name',
            lastnameLabel: 'Last name',
            emailLabel: 'E-Mail address',
            emailConfirmationLabel: 'E-Mail address confirmation',
            passwordLabel: 'Password',
            passwordConfirmationLabel: 'Password confirmation',
            submitLabel: 'Submit',
            passwordRules: 'The password must contain at least 8 characters and at least one upper case letter, '
                         + 'one lower case letter and one digit (0 to 9).',
            activationMailSendMessage: 'An activation-mail has been send to the specified e-mail address!',
            alreadyRegisteredMessage: 'You are already registered!',
            alreadyRegisteredUnconfirmedMessage: 'Your data were already registered! Please activate your account!',
            policiesAcceptedLabel: 'I acknowledge the <a href={link1}>terms of use</a> as well as the '
                                   + '<a href={link2} target="_blank">privacy policy</a> and agree with their content.',
            contactAllowedLabel: 'I am over 18 years of age and agree, that the ZGS Bildungs-GmbH and / or the Schülerhilfe GmbH & Co. KG '
                                 + 'get in touch with me by e-mail and phone, to inform me about '
                                 + 'exclusive offers of the Schülerhilfe (promotion). For data processing '
                                 + 'our general <a href={link1} target="_blank">privacy policy</a> applies.',
        },
        emailVerification: {
            pageTitle: 'E-Mail verification.',
            title: 'E-Mail verification. Please wait..',
            verificationFailed: 'Verification failed!',
            verificationSucceeded: 'Successfully verificated!',
            moveToOlc: 'Move to Online-LearnCenter',
        },
        requestPasswordReset: {
            pageTitle: 'Online-LernCenter - Request password reset',
            infoText: '<p>Please enter the following data, so we can send you a link to reset your password.</p>',
            supportText: '<p>Should there be any questions, you can call us by the following phone number&nbsp; '
                + '<span>0209-123456789</span>.</p>',
            emailLabel: 'E-Mail address',
            submitLabel: 'Submit',
            successMessage: 'A link to reset your password has been send to the specified e-mail address.',
            errorMessage: 'An error occured while requesting the password reset',
        },
        resetPassword: {
            pageTitle: 'Online-LernCenter - New password',
            infoText: 'Please enter a new password. It must contain at least 8 characters and at least one '
                + 'upper case letter, one lower case letter and one digit (0 to 9).',
            passwordLabel: 'Password',
            passwordConfirmationLabel: 'Password confirmation',
            submitLabel: 'Submit',
            passwordRules: 'The password must contain at least 8 characters and at least one upper case letter, '
                         + 'one lower case letter and one digit (0 to 9).',
            errorMessage: 'An error occurred while resetting the password!',
            successMessage: 'Your password has been changed!',
        },
        initPassword: {
            pageTitle: 'Online-LernCenter - Set password',
            errorMessage: 'An error occurred while setting the password!',
            successMessage: 'Your password has been set!',
        },
        response: {
            pageTitle: 'Online-LernCenter - Respond',
            caption: 'Response',
            templateLabel: 'Template',
            submitLabel: 'Submit',
            getConversationByIdError: 'There was an error fetching the conversation.',
            submitSuccessMessage: 'Your response has successfully been submitted. You can close this page now.',
            responseCouldNotBeSubmittedMessage: 'There was an error submitting your response.',
        },
        sectionHeader: {
            showEverything: 'show more',
        },
        start: {
            pageTitle: 'Online-LernCenter',
            calendar: 'Calendar',
            excuse: 'Cancel class',
            grades: 'Grades progress',
            callTitle: 'Call your center',
            messagesTitle: 'Messages',
            contractDetailsTitle: 'Contract details',
            rateTitle: 'Rate us!',
            yourCenter: 'Your Schülerhilfe',
            centerImageAlt: 'Your Schülerhilfe',
            phoneCallLabel: 'Call',
            messageLabel: 'Messages',
            navigationLabel: 'Route',
            centerPhoneLabel: 'Phone:',
        },
        dashboard: {
            pageTitle: 'Schülerhilfe - Online-LernCenter',
            title: 'Use Online-LernCenter',
        },
        contentbox: {
            wikiTitle: 'Wikis (Explanations)',
            wikiImageAlt: 'Wikis (Explanations)',
            wikiText: 'For each topic, there are short and easy explanations with multiple examples.',
            videoTitle: 'Video lessons',
            videoImageAlt: 'Video lessons',
            videoText: 'For each topic, there are video lessons and for the most important topics there are multiple '
                    + 'versions.',
            odhTitle: 'Online-Direct-Help',
            odhImageAlt: 'Online-Direct-Help',
            odhText: 'Send questions to a tutor, whenever you like. You will receive the answer after an average of '
                    + '30 minutes.',
            exerciseTitle: 'Exercises',
            exerciseImageAlt: 'Exercises',
            exerciseText: 'Exercises with different difficulties, which you can download (solutions included).',
            similarTopicTitle: 'Recommendations to similar topics',
            similarTopicImageAlt: 'Recommendations to similar topics',
            similarTopicText: 'At the end of each topic, there are recommendations to similar topics.',
            webinarTitle: 'Webinars',
            webinarImageAlt: 'Webinars',
            webinarText: 'You can watch our live lectures for the most important topics and even participate '
                        + 'by asking questions in the chat. '
                        + 'You can find the date for the next live lectures in your online-calendar.',
            flipbooksTitle: 'Digital books',
            flipbooksImageAlt: 'Digital books',
            flipbooksText: 'Take a look at our "Good Grades" and "Abitur Preparation Series" - of the subjects Mathematics, German & English.',
            testsiegerImageAlt: 'Test the test winner: Catch up on learning content and close knowledge gaps - now with the test winner',
        },
        banner: {
            imageAlt: 'Summerdeal 2020',
        },
        termsOfUse: {
            pageTitle: 'Online-LernCenter - Terms of use',
            heading: 'Nutzungsbedingungen Eltern-App',
            first: 'Diese „Eltern-App“ (im Folgenden „App“) stellt eine Service-Leistung der ZGS Bildungs-GmbH '
                 + '(im  Folgenden  „ZGS“) gegenüber ihren jeweiligen Vertragspartnern bzw. den Vertragspartnern '
                 + 'der Schülerhilfe-Standortbetreiber (im Folgenden „Standortbetreiber“) dar.',
            second: 'Die Nutzung ist daher nur solchen Personen gestattet, welche mit der ZGS und / oder einem '
                  + 'Standortbetreiber wenigstens ein Vertragsverhältnis über die Erbringung von Nachhilfe- und '
                  + 'Bildungsleistungen unterhalten.',
            third: 'Durch die Nutzung dieser App wird kein neues Vertragsverhältnis begründet. Insbesondere ist die '
                 + 'ZGS nicht verpflichtet, den Betrieb dieser App aufrecht zu erhalten. Sie behält sich hiermit '
                 + 'ausdrücklich vor, die Funktionsweise der App zu verändern oder den Betrieb der App gänzlich '
                 + 'einzustellen.',
            fourth: 'Die Haftung der ZGS für Schäden, die aufgrund der Nutzung dieser App entstehen, wird hiermit '
                  + 'ausgeschlossen.<br/><br/>Dies gilt jedoch nicht für Ansprüche aufgrund der Verletzung des '
                  + 'Körpers, des Lebens oder der Gesundheit sowie für Ansprüche aufgrund einer vorsätzlichen oder '
                  + 'grob fahrlässigen Pflichtverletzung.',
        },
        center: {
            pageTitle: 'Online-LernCenter',
            navigationLabel: 'Route',
            messages: 'Messages',
            monthlyProtocols: 'Monthy protocols',
            rateUs: 'Rate Us',
            openingTimes: 'Opening times:',
            openingTimesHolidayReminder: 'Please note that the site is closed on public holidays and between '
                + 'Christmas and New Year.',
            imageAlt: 'Your Schülerhilfe',
            till: 'till',
            oclock: "o'clock",
            closed: 'closed',
            phoneLabel: 'Phone:',
            phoneCallLabel: 'Call',
        },
        weekDay: {
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            sunday: 'Sunday',
        },
        weekDayShort: {
            MO: 'Monday',
            TU: 'Tuesday',
            WE: 'Wednesday',
            TH: 'Thursday',
            FR: 'Friday',
            SA: 'Saturday',
            SU: 'Sunday',
        },
        multiVideo: {
            more: 'Show more',
            videos: 'Videos',
        },
        homeworkHelp: {
            pageTitle: 'Online Homework-Help - Online-LernCenter',
            title: 'Online Homework-Help',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                        + 'Nulla elit nulla, porta at erat et, imperdiet hendrerit augue.'
                        + 'Pellentesque tellus quam, faucibus sed sodales vulputate, porttitor quis nisi.'
                        + 'Suspendisse in facilisis turpis.',
            videoTitle: 'Online Homework-Help explanation video',
            request: {
                title: 'Make a request to a tutor now',
                form: {
                    class: 'Class',
                    subject: 'Subject',
                    schoolType: 'Type of school',
                    topic: 'Subject of your request',
                    question: 'Your Question*',
                    attachment: 'Attach a photo or file',
                    submit: 'Submit request now',
                    required: 'Required field',
                    furtherRequestTitle: 'Your message',
                    furtherRequestButton: 'Send inquiry',
                },
                error: 'Something went wrong. Please try again.',
            },
            overview: {
                title: 'Overview of your requests',
                showMore: 'Show more',
                empty: 'You have not yet made a request to a tutor.',
                noEntriesForFilter: 'You do not yet have any requests with the selected status.',
                filter: {
                    status: {
                        title: 'Filter by status:',
                        type: {
                            all: 'All',
                            open: 'Open',
                            answered: 'Answered',
                            resolved: 'Done',
                        },
                    },
                    sort: {
                        title: 'Sort by:',
                        type: {
                            date: 'Date',
                            subject: 'Subject',
                        },
                    },
                },
                table: {
                    status: 'Status',
                    date: 'Date',
                    schoolSubject: 'School subject',
                    subject: 'Subject',
                },
                status: {
                    open: 'Open',
                    answered: 'Answered',
                    resolved: 'Resolved',
                    unknown: 'Unknown',
                },
                buttons: {
                    ask: 'Ask',
                    cancel: 'Cancel',
                    markAsDone: 'Mark as done',
                    markAsOpen: 'Mark as open',
                },
            },
            footer: {
                title: 'Using online homework help',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                        + 'Nulla elit nulla, porta at erat et, imperdiet hendrerit augue.'
                        + 'Pellentesque tellus quam, faucibus sed sodales vulputate, porttitor quis nisi.'
                        + 'Suspendisse in facilisis turpis.',
                step: {
                    one: 'Lorem ipsum dolor sit amet, consectetur adipiscing elitr',
                    two: 'Lorem ipsum dolor sit amet',
                    three: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
                    four: 'Lorem ipsum dolor sit amet, consectetur',
                    five: 'Lorem ipsum dolor sit amet',
                },
            },
            error: {
                request: 'An error occurred while sending the request, please try again.',
                requestOverview: 'An error occurred while loading your existing requests, please try again.',
                additionalComment: 'An error occurred while sending the message, please try again.',
                loadSingleRequest: 'An error occurred while loading the ticket, please try again.',
                reopenRequest: 'An error occured while reopening the ticket, please try again.',
                closeRequest: 'An error occured while closing the ticket, please try again.',
                upload: 'An error occured while uploading your files, please try again.',
            },
            comment: {
                atTime: 'at',
                solved: 'Request marked as clarified.',
                opened: 'Request marked as open.',
            },
        },
        downloads: {
            title: 'Downloads',
            description: 'Here you can download the revocation or the termination form.',
            button: 'Download',
            revocation: {
                title: 'Revocation form',
                description: 'If you want to revoke the contract, please fill out this form and send it back by e-mail, mail or fax. Please note that the revocation is only possible within the first 14 days.',
            },
            termination: {
                title: 'Termination form',
                description: 'If you want to cancel your subscription contract, please fill out this form and send it back by e-mail, mail or fax. Please note that the period for cancelling a basic or premium subscription is 14 days to the end of the 6 or 12 month term of the basic or premium subscription.',
            },
        },
        payment: {
            successHeader: 'Vielen Dank!',
            successTextHeader: 'Ihre Bestellung war erfolgreich! Wir wünschen Ihrem Kind viel Spaß beim Ausprobieren '
                             + 'und beim Lernen',
            successText: 'Lassen Sie sich das umfassende Lernkonzept der Schülerhilfe von unserem Lernplattform-'
                       + 'Experten erklären. Die Präsentation dauert nicht länger als 5 Minuten.<br/><br/>'
                       + 'Wir würden uns freuen, wenn wir Sie in den nächsten Stunden ganz unverbindlich und '
                       + 'natürlich kostenlos anrufen können. Hinterlassen Sie uns dazu Ihre Telefonnummer:',
            successImageAlt: 'Zahlung erfolgreich',
            pageTitleSuccess: 'Zahlung erfolgreich',
            failHeader: 'Oh, das hat nicht geklappt!',
            failTextHeader: 'Deine Zahlung war leider nicht erfolgreich.',
            failText: 'Probiere es bitte erneut oder kontaktiere uns mit unserem <a href=\'https://www.schuelerhilfe.de/lerncenter/feedback\'>Feedbackformular</a>.',
            failImageAlt: 'Zahlung nicht erfolgreich',
            pageTitleFail: 'Zahlung nicht erfolgreich',
            furtherQuestions: 'Haben Sie noch weitere Fragen?',
        },
        feedback: {
            pageTitle: 'Kontakt zum Team des Schülerhilfe Online-LernCenters',
            title: 'Feedback',
            subtitle: 'Ask us your question here',
            description: 'Opinions and reviews about the Online Learning Center or suggestions for improvement and criticism can be posted here. We are looking forward to your message!',
            placeholder: {
                name: 'Name*',
                email: 'E-mail address*',
                subject: 'Subject*',
                message: 'Message*',
            },
            mandatory: '*Mandatory',
            checkbox: 'I have taken note of the {agbLink} and the {dataLink} and agree with its contents.',
            submit: 'Send',
            boxes: {
                email: {
                    title: 'Please send us an e-mail',
                    description: `If you have any questions or technical difficulties you can always send us an email to ${process.env.REACT_APP_OLC_SUPPORT_EMAIL || 'olc@schuelerhilfe.de'}:`,
                    button: 'Send e-mail',
                },
                faq: {
                    title: 'Do you have further questions?',
                    description: 'Here you will find frequently asked questions and answers about the Online Learning Center:',
                    button: 'Questions & Answers',
                },
            },
            error: 'An error occurred while sending your feedback, please try again later.',
            success: 'Thank you for your Feedback.',
            worksheet: 'Feedback on "{title}" - worksheet no. {number}',
        },
        captcha: {
            error: 'Something went wrong with the captcha, please try again later.',
        },
        privacy: {
            pageTitle: 'Datenschutz - Online-LernCenter',
            metaDescription: 'Klär dich auf: Hier findest du die Datenschutzhinweise des Schülerhilfe Online-LernCenters.',
            privacyStatement: 'I. Datenschutzerklärung der ZGS Bildungs-GmbH für www.schuelerhilfe.de',
            locationPrivacyStatement: 'II. Datenschutzerklärung der ZGS Bildungs-GmbH für die Standortseiten unter www.schuelerhilfe.de (Gemeinsam für die Verarbeitung Verantwortliche i.S.v. Art. 26 DSGVO)',
            dataHandling: '1. Erhebung und Verarbeitung von Daten',
            personalData: '2. Nutzung und Weitergabe personenbezogener Daten',
        },
    },
};

// Network fetch saga.

// Convention:
// Please never use window.fetch or fetchRemote directly.
// Use fetchSaga instead like this, so we can detect,
// react to and propagate network connectivity state:
// try {
//   const data = yield call(fetchSaga, ...);
// } catch (e) {
//   ...
// }

import { call, put } from 'redux-saga/effects';
import moment from 'moment';

// Constants.
import {
    CLIENT_ID_KEY,
    ERROR, IS_UPDATING_AUTH_KEY,
    LOGIN_TOKEN_KEY,
    REFRESH_TOKEN_KEY,
    TOKEN_EXPIRY_TS_KEY,
} from '../constants';

import { storage } from '../storage';

import { getExpiryTsFromToken } from '../utils';

import { addMessage } from '../../containers/MessageModal/actions';
import { logout } from '../../routes/Account/actions';

const getIdpBaseUrl = () => process.env.REACT_APP_API_IDP_URL;

const getHeaders = (setAuthorization = false) => {

    const header = {
        'Content-Type': 'application/json',
        'Accept-Language': 'de',
        'Cache-Control': 'no-cache, no-store',
    };

    if (setAuthorization) {
        header.Authorization = `Bearer ${storage.getItem('token')}`;
    }

    return new Headers(header);
};

function checkStatus(response) {
    if (response.status >= 400 && response.status < 500) {
        return response;
    }
    if (response.status >= 200 && response.status < 300) { // success
        if (response.status === 204) {
            return { status: response.status };
        }
        return response;
    }
    throw new Error(`server error ${response.status}`); // server error
}

function checkError(responseData) {
    if (responseData) {
        if ((responseData.status >= 400 && responseData.status < 500)
                || (responseData.code >= 400 && responseData.code < 500)) {
            throw responseData;
        }
    }

    return responseData;
}

function parseJson(response) {
    try {
        return response.json();
    } catch (e) {
        return response;
    }
}

export function fetchRemote(url, options) {
    if (options.method === 'HEAD') {
        return fetch(url, options)
            .then(checkStatus)
            .then(() => true);
    }

    return fetch(url, options)
        .then(checkStatus)
        .then(parseJson)
        .then(checkError);
}

export function* fetchSaga(url, options = {}) {
    try {
        // eslint-disable-next-line no-use-before-define
        yield checkRefreshAuthentication(options);

        return yield call(fetchRemote, url, options);
    } catch (e) {
        const status = e.status ? e.status : e.code;
        const message = e.message ? e.message : e.reason;

        if (status && !(status >= 400 && status < 500)) { // Server error
            const newErrorMessage = message.startsWith('server error') ? 'server error' : 'network error';
            yield put(addMessage(ERROR, newErrorMessage));
            throw new Error(newErrorMessage);
        } else {
            throw new Error(message);
        }
    }
}

function* checkRefreshAuthentication(options) {
    const clientId = storage.getItem(CLIENT_ID_KEY);
    const tokenExpiryTs = storage.getItem(TOKEN_EXPIRY_TS_KEY);
    const refreshToken = storage.getItem(REFRESH_TOKEN_KEY);
    const token = storage.getItem(LOGIN_TOKEN_KEY);
    const isUpdatingAuth = storage.getItem(IS_UPDATING_AUTH_KEY);

    if (!!clientId
        && !!refreshToken
        && !!tokenExpiryTs
        && options?.headers?.has('authorization')
        && !isUpdatingAuth) {

        const now = moment();

        // get new token if current token is valid for less than 30 more minutes
        const getNewTokenTs = moment(tokenExpiryTs * 1000, 'x').subtract(30, 'minutes');

        if (now.isAfter(getNewTokenTs) || !token) {
            // set flag saying that refresh token process is running
            storage.setItem(IS_UPDATING_AUTH_KEY, true);

            const baseUrl = getIdpBaseUrl();
            const url = `${baseUrl}/account/refresh`;

            const headers = getHeaders();

            const body = {
                refreshToken,
                clientId,
            };

            try {
                const data = yield call(fetchSaga, url,
                    { method: 'POST', headers, body: JSON.stringify(body) });

                if (data) {
                    storage.setItem(LOGIN_TOKEN_KEY, data.token);
                    storage.setItem(REFRESH_TOKEN_KEY, data.refresh_token);
                    storage.setItem(TOKEN_EXPIRY_TS_KEY, getExpiryTsFromToken(data.token));

                    // remove flag saying that refresh token process is running
                    storage.removeItem(IS_UPDATING_AUTH_KEY);

                    // reload page to use refreshed token
                    window.location.reload();
                }
            } catch (e) {
                console.error('Failed refreshing authentication!');

                // remove flag saying that refresh token process is running
                storage.removeItem(IS_UPDATING_AUTH_KEY);

                yield put(logout());
            }

            // remove flag saying that refresh token process is running
            storage.removeItem(IS_UPDATING_AUTH_KEY);
        }
    }
}

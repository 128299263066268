export const SCHULAKTION_OFFER_REQUESTED = 'views/Modal/SchulaktionOfferModal/schulaktionOfferRequested';
export const SCHULAKTION_OFFER_RESPONSE_OBTAINED = 'views/Modal/SchulaktionOfferModal/schulaktionOfferResponseObtained';

export function schulaktionOfferRequested({ firstname, lastname, phone, email, created }) {
    return {
        type: SCHULAKTION_OFFER_REQUESTED,
        payload: { firstname, lastname, phone, email, created },
    };
}

export function schulaktionOfferResponseObtained() {
    return {
        type: SCHULAKTION_OFFER_RESPONSE_OBTAINED,
    };
}

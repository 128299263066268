export const GET_USER = 'routes/Account/getUser';
export const GET_USER_BY_EMAIL = 'routes/Account/getUserByEmail';
export const SET_USER = 'routes/Account/setUser';
export const SAVE_USER = 'routes/Account/saveUser';
export const GET_USER_CENTERS = 'routes/Account/getUserCenters';
export const SET_USER_CENTERS = 'routes/Account/setUserCenters';
export const LOGIN = 'routes/Account/login';
export const SET_LOGIN_RESULT = 'routes/Account/setLoginResult';
export const LOGOUT = 'routes/Account/logout';
export const ACTIVATE = 'routes/Account/activate';
export const SET_AUTHENTICATION = 'routes/Account/setAuthentication';
export const GET_PAYMENT_DETAILS = 'routes/Account/getPaymentDetails';
export const SET_PAYMENT_DETAILS = 'routes/Account/setPaymentDetails';
export const CANCEL_SUBSCRIPTION = 'routes/Account/cancelSubscription';
export const REACTIVATE_SUBSCRIPTION = 'routes/Account/reactivateSubscription';
export const VIDIS_AUTHENTICATION = 'routes/Account/vidisAuthentication';
export const SHOW_LOADER = 'routes/Account/showLoader';

export const showLoader = (payload) => ({
    type: SHOW_LOADER,
    payload,
});

export const vidisAuthentication = (payload, history) => ({
    type: VIDIS_AUTHENTICATION,
    payload,
    history,
});

export const login = (username, password, permanentLogin, intl) => ({
    type: LOGIN,
    intl,
    payload: {
        username,
        password,
    },
    permanentLogin,
});

export const logout = () => ({
    type: LOGOUT,
});

export const setLoginResult = (loginSuccessful) => ({
    type: SET_LOGIN_RESULT,
    payload: {
        loginSuccessful,
    },
});

export const getUser = (userId, intl) => ({
    type: GET_USER,
    intl,
    payload: userId,
});

export function getUserByEmail(email, intl) {
    return {
        type: GET_USER_BY_EMAIL,
        payload: email,
        intl,
    };
}

export const getPaymentDetails = (tid) => ({
    type: GET_PAYMENT_DETAILS,
    payload: tid,
});

export const setPaymentDetails = (data) => ({
    type: SET_PAYMENT_DETAILS,
    payload: data,
});

export const cancelSubscription = (userData, tid, intl) => ({
    type: CANCEL_SUBSCRIPTION,
    payload: { userData, tid, intl },
});

export const reactivateSubscription = (userData, tid, intl) => ({
    type: REACTIVATE_SUBSCRIPTION,
    payload: { userData, tid, intl },
});

export const setUser = (user) => ({
    type: SET_USER,
    payload: user,
});

export const saveUser = (userId, userData, intl, showMessage = true) => ({
    type: SAVE_USER,
    userId,
    showMessage,
    payload: userData,
    intl,
});

export function getUserCenters(userId, intl) {
    return {
        type: GET_USER_CENTERS,
        payload: userId,
        intl,
    };
}

export function setUserCenters(userCenters) {
    return {
        type: SET_USER_CENTERS,
        payload: userCenters,
    };
}

export const activate = (userId, activationToken, intl) => ({
    type: ACTIVATE,
    intl,
    payload: {
        userId,
        activationToken,
    },
});

export const setAuthentication = (payload) => ({
    type: SET_AUTHENTICATION,
    payload,
});

import {
    REGISTER_USER_SUCCESS,
    SET_USER_DATA,
    SET_USER_ALREADY_EXISTS,
    SET_ABO_TO_USER_DATA,
} from './actions';

const initialState = {
    messages: [],
    showSuccessMessage: false,
    userData: null,
    userAlreadyExists: false,
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
    case REGISTER_USER_SUCCESS:
        return {
            ...state,
            userData: action.payload,
            showSuccessMessage: false,
            messages: [],
        };
    case SET_USER_DATA:
        return {
            ...state,
            userData: action.payload,
        };
    case SET_ABO_TO_USER_DATA:
        return {
            ...state,
            userData: {
                ...state.userData,
                abo: action.payload,
            },
        };
    case SET_USER_ALREADY_EXISTS:
        return {
            ...state,
            userAlreadyExists: true,
        };
    default:
        return state;
    }
};

export default reducer;

// external libraries
import { combineReducers } from 'redux';

import messageModal from '../containers/MessageModal/reducer';
import account from '../routes/Account/reducer';
import register from '../routes/Account/Registration/reducer';
import library from '../routes/Library/reducer';
import librarySearch from '../containers/LibrarySearch/reducer';
import abos from '../routes/AbosContainer/reducer';
import registerUser from '../routes/TestAccountRegisterFormContainer/reducer';
import homeworkHelp from '../routes/HomeworkHelp/reducer';
import exams from '../containers/Library/Exams/reducer';
import examinations from '../routes/Exams/reducer';
import webinars from '../routes/Webinars/reducer';
import dashboard from '../containers/Dashboard/reducer';
import feedback from '../routes/Feedback/reducer';
import captcha from '../utils/captcha/reducer';
import registerBookcode from '../routes/Account/RegisterBookcode/reducer';
import extendBookcode from '../routes/Account/ExtendBookcode/reducer';
import favorites from '../containers/FavoriteButton/reducer';
import specialRegisterUser from '../routes/SpecialRegisterFormContainer/reducer';
import employeeRegisterUser from '../routes/KnowledgeCheckRegisterFormContainer/reducer';
import audioReducer from '../views/Audio/reducer';
import stepwiseRegistration from '../routes/Account/StepwiseRegistration/reducer';
import emailVerification from '../routes/Account/Verification/reducer';
import courses from '../routes/Courses/reducer';
import termination from '../routes/Termination/reducer';
import lisa from '../routes/LisaRegisterFormContainer/reducer';
import specialFourteenDaysRegistration from '../routes/SpecialFourteenDaysRegistration/reducer';
import aiTutor from '../routes/AiTutor/reducer';

const rootReducer = combineReducers({
    messageModal,
    account,
    register,
    library,
    librarySearch,
    abos,
    registerUser,
    homeworkHelp,
    exams,
    examinations,
    webinars,
    dashboard,
    feedback,
    captcha,
    registerBookcode,
    extendBookcode,
    favorites,
    specialRegisterUser,
    employeeRegisterUser,
    audio: audioReducer,
    stepwiseRegistration,
    emailVerification,
    courses,
    termination,
    lisa,
    specialFourteenDaysRegistration,
    aiTutor,
});

export default rootReducer;

export const NEXT_REG_STEP_REQUESTED = 'routes/Account/StepwiseRegistration/nextRegStepRequested';
export const REGISTER_AND_MOVE_TO_NEXT_STEP = 'routes/Account/StepwiseRegistration/registerAndMoveToNextStep';
export const VALIDATION_CODE_REQUESTED = 'routes/Account/StepwiseRegistration/validationCodeRequested';
export const VALIDATION_CODE_OBTAINED = 'routes/Account/StepwiseRegistration/validationCodeObtained';

export function validationCodeRequested({ userData, nextStepNumber }) {
    return {
        type: VALIDATION_CODE_REQUESTED,
        payload: { userData, nextStepNumber },
    };
}

export function validationCodeObtained({ receivedValidationCode }) {
    return {
        type: VALIDATION_CODE_OBTAINED,
        payload: { receivedValidationCode },
    };
}

export function registerAndMoveToNextStep({ userData, nextStepNumber }) {
    return {
        type: REGISTER_AND_MOVE_TO_NEXT_STEP,
        payload: { userData, nextStepNumber },
    };
}

export function nextRegistrationStepRequested({ nextStepNumber }) {
    return {
        type: NEXT_REG_STEP_REQUESTED,
        payload: { nextStepNumber },
    };
}

// Favorite reducer

import {
    DELETE_FAVORITE,
    SET_FAVORITES_FOR_USER,
    SET_FAVORITE,
    RESET_STATE,
} from './actions';

const initialState = {
    items: [],
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case SET_FAVORITES_FOR_USER:
        return {
            ...state,
            items: action.payload,
        };
    case SET_FAVORITE:
        return {
            ...state,
            items: [
                ...state.items,
                action.payload,
            ],
        };
    case DELETE_FAVORITE:
        return {
            ...state,
            items: state.items.filter((item) => action.payload !== item.id),
        };
    case RESET_STATE:
        return initialState;
    default:
        return state;
    }
}

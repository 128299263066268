// react and external libraries
import { call, put, takeEvery } from 'redux-saga/effects';
// import queryString from 'query-string';
import { fetchSaga } from '../../../utils/network/fetch-saga';

// own components
import { getIdpBaseUrl, getHeaders, getTokenPayloadFromStorage } from '../../../utils/utils';

// constants
import { INFO, ERROR } from '../../../utils/constants';

import { RESET_PASSWORD } from './actions';
import { addMessage } from '../../../containers/MessageModal/actions';

function* resetPassword(action) {
    const { history, intl, password, reset } = action.payload;
    const url = `${getIdpBaseUrl()}/account/${reset ? 'recover-password' : 'init-password'}`;
    const headers = getHeaders(true);
    const tokenPayload = getTokenPayloadFromStorage();
    const locale = `${reset ? 'reset' : 'init'}Password`;

    if (!tokenPayload) {
        return yield put(addMessage(ERROR, intl.messages[`${locale}.errorMessage`]));
    }

    const { userid } = tokenPayload;

    try {
        const data = yield call(
            fetchSaga,
            url,
            { method: 'POST', headers, body: JSON.stringify({ password, userId: userid }) },
        );

        if (data) {
            yield put(addMessage(INFO, intl.messages[`${locale}.successMessage`]));
            return history.push('/login');
        }

        return yield put(addMessage(ERROR, intl.messages[`${locale}.errorMessage`]));
    } catch (e) {
        return yield put(addMessage(ERROR, intl.messages[`${locale}.errorMessage`]));
    }
}

function* watchUserResetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

export const ResetPasswordSaga = [
    watchUserResetPassword(),
];

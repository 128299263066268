export const CREATE_FEEDBACK = 'routes/Feedback/create';
export const SET_FEEDBACK_LOADING = 'routes/Feedback/setLoading';
export const SET_FEEDBACK_RESULT = 'routes/Feedback/setResult';

export const createFeedback = (request, intl) => ({
    type: CREATE_FEEDBACK,
    payload: { request, intl },
});

export const setFeedbackLoading = (loading) => ({
    type: SET_FEEDBACK_LOADING,
    payload: { loading },
});

export const setFeedbackResult = (result) => ({
    type: SET_FEEDBACK_RESULT,
    payload: { result },
});

// Exams action types and creators

export const GET_STUDENT_DATA = 'containers/Library/Exams/getStudentData';
export const GET_STUDENT_DATA_ERROR = 'containers/Library/Exams/getStudentDataError';
export const SET_STUDENT_EXISTS = 'containers/Library/Exams/setStudentExists';
export const ADD_USER_TO_EXAM = 'containers/Library/Exams/addUserToExam';
export const CREATE_STUDENT_AND_ADD_TO_EXAM = 'containers/Library/Exams/createStudentAndAddToExam';
export const CREATE_OLAT_STUDENT_AND_ADD_TO_EXAM = 'containers/Library/Exams/createOlatStudentAndAddToExam';
export const REDIRECT_TO_OPEN_OLAT = 'containers/Library/Exams/redirectToOpenOlat';

export const getStudentData = (email, intl) => (
    {
        type: GET_STUDENT_DATA,
        payload: email,
        intl,
    }
);

export const getStudentDataError = (message) => (
    {
        type: GET_STUDENT_DATA_ERROR,
        payload: message,
    }
);

export const setStudentExists = (studentExists) => (
    {
        type: SET_STUDENT_EXISTS,
        payload: studentExists,
    }
);

export const addUserToExam = (
    email,
    openOlatId,
    examination,
    isOpenOlatExam,
    intl,
    windowReference = null,
    isCourse,
) => (
    {
        type: ADD_USER_TO_EXAM,
        payload: {
            email: isOpenOlatExam ? null : email, // Only email: Old exam platform.
            userId: isOpenOlatExam ? openOlatId : null, // Only userId: OpenOlat exam platform.
            examination_id: examination,
            send_mails: false,
            isCourse,
        },
        openOlatUsername: email,
        isOpenOlatExam,
        windowReference,
        intl,
    }
);

export const createStudentAndAddToExam = (user, location, birthday, examination, intl) => (
    {
        type: CREATE_STUDENT_AND_ADD_TO_EXAM,
        payload: {
            email: user.email,
            firstname: user.firstname,
            lastname: user.lastname,
            schooltype: !!user.typeOfSchool ? user.typeOfSchool : 'Grundschule',
            classlevel: !!user.classlevel ? user.classlevel : '1',
            location,
            birthday,
            examination_id: examination,
        },
        intl,
    }
);

export const createOlatStudentAndAddToExam = (user, examId, intl, isCourse) => (
    {
        type: CREATE_OLAT_STUDENT_AND_ADD_TO_EXAM,
        payload: {
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            birthday: user.birthdate,
        },
        user,
        examId,
        intl,
        isCourse,
    }
);

export const redirectToOpenOlat = (payload) => (
    {
        type: REDIRECT_TO_OPEN_OLAT,
        payload,
    }
);

// Profile sagas

import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchSaga } from '../../../utils/network/fetch-saga';

import { getHeaders } from '../../../utils/utils';

// constants
import { INFO, ERROR } from '../../../utils/constants';

import {
    SAVE_PASSWORD_DATA,
    REDEEM_VOUCHER,
} from './actions';
import { addMessage } from '../../../containers/MessageModal/actions';

// Helpers
export function getIdpBaseUrl() {
    return process.env.REACT_APP_API_IDP_URL;
}

export function* savePasswordData(action) {
    const baseUrl = getIdpBaseUrl();
    const url = `${baseUrl}/account/change-password`;
    const headers = getHeaders(true);
    try {
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify(action.payload) });
        if (data) {
            yield put(addMessage(INFO, 'Passwort erfolgreich geändert!'));
        }
    } catch (e) {
        if (e.message === 'server error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (e.message === 'network error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else {
            yield put(addMessage(ERROR, e.message));
        }
    }
}

export function* redeemVoucher(action) {
    const baseUrl = getIdpBaseUrl();
    const url = `${baseUrl}/vouchers/${action.vouchercode}/redeem`;
    const headers = getHeaders(true);
    try {
        const data = yield call(fetchSaga, url, { method: 'PATCH', headers, body: JSON.stringify(action.payload) });
        if (data) {
            yield put(addMessage(INFO, 'Gutschein erfolgreich eingelöst!'));
        }
    } catch (e) {
        if (e.message === 'server error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (e.message === 'network error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else {
            yield put(addMessage(ERROR, e.message));
        }
    }
}

export function* waitForPasswordDataWasSaved() {
    yield takeEvery(SAVE_PASSWORD_DATA, savePasswordData);
}

export function* waitForVoucherWasRedeemed() {
    yield takeEvery(REDEEM_VOUCHER, redeemVoucher);
}

export const profileSaga = [
    waitForPasswordDataWasSaved(),
    waitForVoucherWasRedeemed(),
];

import { SET_FEEDBACK_RESULT, SET_FEEDBACK_LOADING } from './actions';

const initialState = {
    feedbackResult: null,
    feedbackLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_FEEDBACK_RESULT:
        return {
            ...state,
            feedbackResult: action.payload.result,
        };
    case SET_FEEDBACK_LOADING:
        return {
            ...state,
            feedbackLoading: action.payload.loading,
        };
    default:
        return state;
    }
};

export default reducer;

// Library action types and creators

export const CHOOSE_SUBJECT = 'containers/Library/chooseSubject';
export const CHOOSE_GRADE = 'containers/Library/chooseGrade';
export const CHOOSE_STATE = 'containers/Library/chooseState';
export const CHOOSE_MAIN_TOPIC = 'containers/Library/chooseMainTopic';
export const CHOOSE_BETWEEN_TOPIC = 'containers/Library/chooseBetweenTopic';
export const CHOOSE_UNDER_TOPIC = 'containers/Library/chooseUnderTopic';
export const CHOOSE_SEO_UNDER_TOPIC = 'containers/Library/chooseSeoUnderTopic';
export const FETCH_SUBJECTS = 'containers/Library/fetchSubjects';
export const FETCH_GRADES_OF_SUBJECT = 'containers/Library/fetchGradesOfSubject';
export const SET_GRADE_TO_SIMILAR_TOPICS = 'containers/Library/setGradeToSimilarTopics';
export const SET_PARENTS_TO_SIMILAR_TOPICS = 'containers/Library/setParentsToSimilarTopics';
export const SET_SUBJECTS = 'containers/Library/setSubjects';
export const SET_GRADES = 'containers/Library/setGrades';
export const SET_STATES = 'containers/Library/setStates';
export const SET_TOPICS = 'containers/Library/setTopics';
export const FETCH_SIMILAR_TOPICS = 'containers/Library/fetchSimilarTopics';
export const SET_SIMILAR_TOPICS = 'containers/Library/setSimilarTopics';
export const RESET_GRADES = 'containers/Library/resetGrades';
export const RESET_TOPICS = 'containers/Library/resetTopics';
export const SET_CONTENT_TYPE = 'containers/Library/setContentType';
export const SET_EXERCISES = 'containers/Library/setExercises';
export const SET_WIKIS = 'containers/Library/setWikis';
export const FETCH_VIDEO_BY_ID = 'containers/Library/fetchVideoById';
export const SET_VIDEOS = 'containers/Library/setVideos';
export const SET_EXAMS = 'containers/Library/setExams';
export const SET_SUBJECT_AND_GRADE = 'containers/Library/setSubjectAndGrade';
export const WIKI_DOWNLOAD = 'containers/Library/wikiDownload';
export const EXERCISE_DOWNLOAD = 'containers/Library/exerciseDownload';
export const SET_CANONICAL = 'containers/Library/setCanonical';
export const SHOW_LIBRARY = 'containers/Library/showLibrary';
export const HIDE_LIBRARY = 'containers/Library/hideLibrary';
export const SET_META_DESCRIPTION = 'containers/Library/setMetaDescription';
export const SET_TITLE = 'containers/Library/setTitle';
export const LOCATION_CHANGE = 'containers/Library/locationChange';
export const LOCATION_CHANGE_SEO = 'containers/Library/locationChangeSeo';
export const PARSING_IN_PROGRESS = 'containers/Library/parsingInProgress';
export const CONTENT_RENDERED = 'containers/Library/contentRendered';
export const SHOW_MORE_VIDEOS = 'containers/Library/showMoreVideos';
export const SET_REDIRECT_URL = 'containers/Library/setRedirectUrl';
export const SET_SEO_GRADES_TOPICS_STRUCTURE = 'containers/Library/seoGradesTopicStructure';
export const CHOOSE_SEO_TOPIC = 'containers/Library/chooseSeounderTopic';
export const ADD_VIDEO = 'containers/Library/addVideo';

export function chooseSeoTopic(topic) {
    return {
        type: CHOOSE_SEO_TOPIC,
        payload: topic,
    };
}

export function setSeoGradesTopicStructure(gradesTopicsStructure) {
    return {
        type: SET_SEO_GRADES_TOPICS_STRUCTURE,
        payload: gradesTopicsStructure,
    };
}

export function setRedirectUrl(url) {
    return {
        type: SET_REDIRECT_URL,
        payload: url,
    };
}

export function locationChange(location) {
    return {
        type: LOCATION_CHANGE,
        payload: location,
    };
}

export function locationChangeSeo(location) {
    return {
        type: LOCATION_CHANGE_SEO,
        payload: location,
    };
}

export function setParsingInProgress(parsingInProgress) {
    return {
        type: PARSING_IN_PROGRESS,
        payload: parsingInProgress,
    };
}

export function setContentRendered(contentRendered) {
    return {
        type: CONTENT_RENDERED,
        payload: contentRendered,
    };
}

export function setMetaDescription(description) {
    return {
        type: SET_META_DESCRIPTION,
        payload: description,
    };
}

export function setTitle(title) {
    return {
        type: SET_TITLE,
        payload: title,
    };
}

export function setCanonical(canonical) {
    return {
        type: SET_CANONICAL,
        payload: canonical,
    };
}

export function chooseSubject(id) {
    return {
        type: CHOOSE_SUBJECT,
        payload: id,
    };
}

export function chooseGrade(id) {
    return {
        type: CHOOSE_GRADE,
        payload: id,
    };
}

export function chooseState(id) {
    return {
        type: CHOOSE_STATE,
        payload: id,
    };
}

export function resetGrades() {
    return {
        type: RESET_GRADES,
    };
}

export function resetTopics() {
    return {
        type: RESET_TOPICS,
    };
}

export function chooseMainTopic(id) {
    return {
        type: CHOOSE_MAIN_TOPIC,
        payload: id,
    };
}

export function chooseBetweenTopic(id) {
    return {
        type: CHOOSE_BETWEEN_TOPIC,
        payload: id,
    };
}

export function chooseUnderTopic(id) {
    return {
        type: CHOOSE_UNDER_TOPIC,
        payload: id,
    };
}

export function chooseSeoUnderTopic(id) {
    return {
        type: CHOOSE_SEO_UNDER_TOPIC,
        payload: id,
    };
}

export function fetchSubjects() {
    return {
        type: FETCH_SUBJECTS,
    };
}

export function fetchGradesOfSubject(subjectId) {
    return {
        type: FETCH_GRADES_OF_SUBJECT,
        payload: subjectId,
    };
}

export function fetchVideoById(videoId) {
    return {
        type: FETCH_VIDEO_BY_ID,
        payload: videoId,
    };
}

export function addVideo(video) {
    return {
        type: ADD_VIDEO,
        payload: video,
    };
}

export function setGradeToSimilarTopics(similarTopics) {
    return {
        type: SET_GRADE_TO_SIMILAR_TOPICS,
        payload: similarTopics,
    };
}

export function setParentsToSimilarTopics(similarTopics) {
    return {
        type: SET_PARENTS_TO_SIMILAR_TOPICS,
        payload: similarTopics,
    };
}

export function setSubjects(subjects) {
    return {
        type: SET_SUBJECTS,
        payload: subjects,
    };
}

export function setGrades(grades) {
    return {
        type: SET_GRADES,
        payload: grades,
    };
}

export function setStates(states) {
    return {
        type: SET_STATES,
        payload: states,
    };
}

export function setTopics(topics) {
    return {
        type: SET_TOPICS,
        payload: topics,
    };
}

export function setSimilarTopics(similarTopics) {
    return {
        type: SET_SIMILAR_TOPICS,
        payload: similarTopics,
    };
}

export function setContentType(contentType) {
    return {
        type: SET_CONTENT_TYPE,
        payload: contentType,
    };
}

export function setExercises(exercises) {
    return {
        type: SET_EXERCISES,
        payload: exercises,
    };
}

export function setWikis(wikis) {
    return {
        type: SET_WIKIS,
        payload: wikis,
    };
}

export function setVideos(videos) {
    return {
        type: SET_VIDEOS,
        payload: videos,
    };
}

export function setExams(exams) {
    return {
        type: SET_EXAMS,
        payload: exams,
    };
}

export function setSubjectAndGrade(subjectId, gradeId) {
    return {
        type: SET_SUBJECT_AND_GRADE,
        subjectId,
        gradeId,
    };
}

export function wikiDownload(wikiId, handleLoadingButtons) {
    return {
        type: WIKI_DOWNLOAD,
        payload: wikiId,
        handleLoadingButtons,
    };
}

export function exerciseDownload(exerciseId, handleLoadingButtons) {
    return {
        type: EXERCISE_DOWNLOAD,
        payload: exerciseId,
        handleLoadingButtons,
    };
}

export function hideLibrary() {
    return {
        type: HIDE_LIBRARY,
    };
}

export function showLibrary() {
    return {
        type: SHOW_LIBRARY,
    };
}

export function showMoreVideos() {
    return {
        type: SHOW_MORE_VIDEOS,
    };
}

export const VERIFY_EMAIL_REQUESTED = 'routes/Account/Verification/verifyEmailRequested';
export const EMAIL_VERIFICATION_FAILED = 'routes/Account/Verification/emailVerificationFailed';
export const EMAIL_VERIFICATION_SUCCEEDED = 'routes/Account/Verification/emailVerificationSucceeded';

export function verifyEmailRequested() {
    return {
        type: VERIFY_EMAIL_REQUESTED,
    };
}

export function emailVerificationFailed() {
    return {
        type: EMAIL_VERIFICATION_FAILED,
    };
}

export function emailVerificationSucceeded() {
    return {
        type: EMAIL_VERIFICATION_SUCCEEDED,
    };
}

// Library reducer

import {
    CHOOSE_SUBJECT,
    CHOOSE_GRADE,
    CHOOSE_STATE,
    CHOOSE_MAIN_TOPIC,
    CHOOSE_BETWEEN_TOPIC,
    CHOOSE_UNDER_TOPIC,
    CHOOSE_SEO_UNDER_TOPIC,
    SET_SUBJECTS,
    SET_GRADES,
    SET_STATES,
    SET_TOPICS,
    SET_SIMILAR_TOPICS,
    SET_GRADE_TO_SIMILAR_TOPICS,
    SET_PARENTS_TO_SIMILAR_TOPICS,
    RESET_TOPICS,
    RESET_GRADES,
    SET_CONTENT_TYPE,
    SET_WIKIS,
    SET_EXERCISES,
    SET_VIDEOS,
    SET_EXAMS,
    SET_SUBJECT_AND_GRADE,
    WIKI_DOWNLOAD,
    EXERCISE_DOWNLOAD,
    SET_CANONICAL,
    SHOW_LIBRARY,
    HIDE_LIBRARY,
    SET_TITLE,
    SET_META_DESCRIPTION,
    SHOW_MORE_VIDEOS,
    PARSING_IN_PROGRESS,
    CONTENT_RENDERED,
    SET_REDIRECT_URL,
    SET_SEO_GRADES_TOPICS_STRUCTURE,
    CHOOSE_SEO_TOPIC,
    ADD_VIDEO,
} from './actions';

import { CHOOSE_SUGGESTION } from '../../containers/LibrarySearch/actions';

// dont make a new String everytime, because it will trigger renders
const emptyStringValue = '';

const initialState = {
    activeLibrary: true,
    subjects: [],
    chosenSubjectId: null,
    grades: [],
    chosenGradeId: null,
    states: [],
    chosenStateId: null,
    topics: [],
    similarTopics: [],
    chosenMainTopicId: null,
    chosenBetweenTopicId: null,
    chosenUnderTopicId: null,
    contentType: emptyStringValue,
    wikis: [],
    exercises: [],
    videos: [],
    exams: [],
    showVideoCount: 3,
    canonical: '',
    title: emptyStringValue,
    metaDescription: emptyStringValue,
    parsingInProgress: false,
    contentRendered: true,
    redirectUrl: null,
    seoGradesTopicsStructure: [],
    selectedSeoTopic: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case PARSING_IN_PROGRESS:
        return {
            ...state,
            parsingInProgress: action.payload,
        };
    case CONTENT_RENDERED:
        return {
            ...state,
            contentRendered: action.payload,
        };
    case SHOW_LIBRARY:
        return {
            ...state,
            activeLibrary: true,
        };
    case HIDE_LIBRARY:
        return {
            ...state,
            activeLibrary: false,
        };
    case WIKI_DOWNLOAD:
        return { ...state };
    case EXERCISE_DOWNLOAD:
        return { ...state };
    case SET_SUBJECTS:
        return {
            ...state,
            subjects: action.payload,
        };
    case SET_GRADES:
        return {
            ...state,
            grades: action.payload,
        };
    case SET_STATES:
        return {
            ...state,
            states: action.payload,
        };
    case SET_GRADE_TO_SIMILAR_TOPICS:
    case SET_PARENTS_TO_SIMILAR_TOPICS:
    case SET_SIMILAR_TOPICS:
        return {
            ...state,
            similarTopics: action.payload,
        };
    case SET_TOPICS:
        return {
            ...state,
            topics: action.payload,
        };
    case CHOOSE_SUBJECT:
        return {
            ...state,
            chosenSubjectId: action.payload,
        };
    case CHOOSE_GRADE:
        return {
            ...state,
            chosenGradeId: action.payload,
        };
    case CHOOSE_STATE:
        return {
            ...state,
            chosenStateId: action.payload,
        };
    case CHOOSE_MAIN_TOPIC:
        return {
            ...state,
            chosenMainTopicId: action.payload,
        };
    case CHOOSE_BETWEEN_TOPIC:
        return {
            ...state,
            chosenBetweenTopicId: action.payload,
        };
    case CHOOSE_UNDER_TOPIC:
    case CHOOSE_SEO_UNDER_TOPIC:
        return {
            ...state,
            chosenUnderTopicId: action.payload,
        };
    case SET_CONTENT_TYPE:
        return {
            ...state,
            contentType: action.payload,
        };
    case RESET_GRADES:
        return {
            ...state,
            grades: [],
        };
    case RESET_TOPICS:
        return {
            ...state,
            topics: [],
        };
    case SET_WIKIS:
        return {
            ...state,
            wikis: action.payload,
        };
    case SET_EXERCISES:
        return {
            ...state,
            exercises: action.payload,
        };
    case SET_VIDEOS:
        return {
            ...state,
            videos: action.payload,
        };
    case SET_EXAMS:
        return {
            ...state,
            exams: action.payload,
        };
    case CHOOSE_SUGGESTION:
    case SET_SUBJECT_AND_GRADE:
        return {
            ...state,
            chosenSubjectId: action.subjectId,
            chosenGradeId: action.gradeId,
        };
    case SET_CANONICAL:
        return {
            ...state,
            canonical: action.payload,
        };
    case SET_TITLE:
        return {
            ...state,
            title: action.payload,
        };
    case SET_META_DESCRIPTION:
        return {
            ...state,
            metaDescription: action.payload,
        };
    case SHOW_MORE_VIDEOS:
        return {
            ...state,
            showVideoCount: state.showVideoCount + 3,
        };
    case SET_REDIRECT_URL:
        return {
            ...state,
            redirectUrl: action.payload,
        };
    case SET_SEO_GRADES_TOPICS_STRUCTURE:
        return {
            ...state,
            seoGradesTopicsStructure: action.payload,
        };
    case CHOOSE_SEO_TOPIC:
        return {
            ...state,
            selectedSeoTopic: action.payload,
        };
    case ADD_VIDEO:
        return {
            ...state,
            videos: [...state.videos.filter((v) => v.id !== action.payload.id), action.payload],
        };
    default:
        return state;
    }
}

// external libraries
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './rootReducer';

// Build the middleware for intercepting and dispatching navigation actions
const sagaMiddleware = createSagaMiddleware();

const configureStore = (preloadedState) => createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(sagaMiddleware),
);

export const getSaga = () => sagaMiddleware;

export default configureStore;

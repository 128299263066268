// Library reducer

import {
    SET_SUGGESTIONS,
    RESET_SUGGESTIONS,
    CHOOSE_SUGGESTION,
    SHOW_SUGGESTION_NAVIGATION,
    HIDE_SUGGESTION_NAVIGATION,
    SHOW_SUGGESTIONS,
    HIDE_SUGGESTIONS,
    CHOOSE_SUGGESTION_SUBJECT,
} from './actions';

const initialState = {
    activeSuggestions: false,
    activeSuggestionNavigation: false,
    chosenSuggestionId: 0,
    chosenSuggestionSubjectId: 0,
    suggestions: {
        hits: [],
        totalHitsCount: 0,
    },
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case SET_SUGGESTIONS:
        return {
            ...state,
            suggestions: action.payload,
        };
    case RESET_SUGGESTIONS:
        return {
            ...state,
            suggestions: initialState.suggestions,
        };
    case CHOOSE_SUGGESTION:
        return {
            ...state,
            chosenSuggestionId: action.id,
        };
    case SHOW_SUGGESTION_NAVIGATION:
        return {
            ...state,
            activeSuggestionNavigation: true,
        };
    case HIDE_SUGGESTION_NAVIGATION:
        return {
            ...state,
            activeSuggestionNavigation: false,
        };
    case SHOW_SUGGESTIONS:
        return {
            ...state,
            activeSuggestions: true,
        };
    case HIDE_SUGGESTIONS:
        return {
            ...state,
            activeSuggestions: false,
        };
    case CHOOSE_SUGGESTION_SUBJECT:
        return {
            ...state,
            chosenSuggestionSubjectId: action.payload,
        };
    default:
        return state;
    }
}

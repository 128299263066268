import {
    SET_CAPTCHA_VALID,
} from './actions';

const initialState = {
    captchaValid: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_CAPTCHA_VALID:
        return {
            ...state,
            captchaValid: action.payload,
        };
    default:
        return state;
    }
};

export default reducer;

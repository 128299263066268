import uniq from 'lodash/uniq';
import remove from 'lodash/remove';
import isEqual from 'lodash/isEqual';
import {
    SET_REQUEST_RESULT,
    SET_REQUESTS,
    CLEAR_REQUESTS,
    SET_DETAILED_REQUEST,
    REMOVE_DETAILED_REQUEST,
    SET_DETAILED_REQUEST_STATUS,
    SET_LOADING,
    SET_ERROR,
    SET_CREATE_REQUEST_LOADING,
    SET_CREATE_REQUEST_ERROR,
    SET_ADDITIONAL_REQUEST_RESULT,
    SET_ADDITIONAL_REQUEST_LOADING,
    SET_ADDITIONAL_REQUEST_ERROR,
    SET_SHOULD_VALIDATE_USER,
    SET_UPLOADED_FILES,
    SET_UPLOAD_FILE_LOADING,
    SET_UPLOAD_FILE_ERROR,
    CLEAR_UPLOADED_FILES,
    REMOVE_UPLOADED_FILE,
} from './actions';

const initialState = {
    requestResult: null,
    requests: [],
    detailedRequests: [],
    detailedRequestStatus: [],
    createRequestLoading: false,
    createRequestError: false,
    loading: false,
    error: false,
    additionalRequestResult: {},
    additionalRequestLoading: false,
    additionalRequestError: false,
    shouldValidateUser: false,
    uploadedFiles: [],
    uploadFileLoading: false,
    uploadFileError: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_REQUEST_RESULT:
        return {
            ...state,
            requestResult: action.payload.requestResult,
        };
    case SET_REQUESTS: {
        const { requests } = action.payload;

        const existingRequestIndex = state.requests.findIndex((element) => element.page === requests.page);

        if (existingRequestIndex === -1) {
            return {
                ...state,
                requests: [...state.requests, requests],
            };
        }

        return {
            ...state,
            requests: [
                ...state.requests.slice(0, existingRequestIndex),
                requests,
                ...state.requests.slice(existingRequestIndex + 1),
            ],
        };
    }
    case CLEAR_REQUESTS:
        return {
            ...state,
            requests: [],
        };
    case SET_DETAILED_REQUEST: {
        const { detailedRequest } = action.payload;
        const { detailedRequests } = state;

        const detailIndex = detailedRequests.findIndex((element) => element.id === detailedRequest.id);

        return {
            ...state,
            detailedRequests: [
                ...detailedRequests.slice(0, detailIndex),
                detailedRequest,
                ...detailedRequests.slice(detailIndex + 1),
            ],
        };
    }
    case SET_DETAILED_REQUEST_STATUS: {
        const { requestId, loading, error } = action.payload;
        const { detailedRequestStatus } = state;

        const statusIndex = detailedRequestStatus.findIndex((element) => element.requestId === requestId);
        const status = { requestId, loading, error };

        return {
            ...state,
            detailedRequestStatus: [
                ...detailedRequestStatus.slice(0, statusIndex),
                status,
                ...detailedRequestStatus.slice(statusIndex + 1),
            ],
        };
    }
    case REMOVE_DETAILED_REQUEST:
        return {
            ...state,
            detailedRequests: state.detailedRequests.filter((request) => request.id !== action.payload.id),
        };
    case SET_CREATE_REQUEST_LOADING:
        return {
            ...state,
            createRequestLoading: action.payload.loading,
        };
    case SET_CREATE_REQUEST_ERROR:
        return {
            ...state,
            createRequestError: action.payload.error,
        };
    case SET_LOADING:
        return {
            ...state,
            loading: action.payload.loading,
        };
    case SET_ERROR:
        return {
            ...state,
            error: action.payload.error,
        };
    case SET_ADDITIONAL_REQUEST_RESULT:
        return {
            ...state,
            additionalRequestResult: action.payload.additionalRequestResult,
        };
    case SET_ADDITIONAL_REQUEST_LOADING:
        return {
            ...state,
            additionalRequestLoading: action.payload.loading,
        };
    case SET_ADDITIONAL_REQUEST_ERROR:
        return {
            ...state,
            additionalRequestError: action.payload.error,
        };
    case SET_SHOULD_VALIDATE_USER:
        return {
            ...state,
            shouldValidateUser: action.payload.shouldValidateUser,
        };
    case SET_UPLOAD_FILE_LOADING:
        return {
            ...state,
            uploadFileLoading: action.payload.loading,
        };
    case SET_UPLOAD_FILE_ERROR:
        return {
            ...state,
            uploadFileError: action.payload.error,
        };
    case SET_UPLOADED_FILES:
        return {
            ...state,
            uploadedFiles: uniq([...state.uploadedFiles, ...action.payload.uploadedFiles]),
        };
    case CLEAR_UPLOADED_FILES:
        return {
            ...state,
            uploadedFiles: [],
        };
    case REMOVE_UPLOADED_FILE:
        return {
            ...state,
            uploadedFiles: remove(state.uploadedFiles, (file) => !isEqual(file, action.payload.file)),
        };
    default:
        return state;
    }
};

export default reducer;

// ExtendBookcode reducer

import {
    EXTEND_BOOKCODE_SUCCESS,
    SET_CENTERS,
} from './actions';

const initialState = {
    showSuccessMessage: false,
    centers: [],
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case EXTEND_BOOKCODE_SUCCESS:
        return { ...state, showSuccessMessage: true };
    case SET_CENTERS:
        return { ...state, centers: action.payload };
    default:
        return state;
    }
}

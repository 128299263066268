// react and external libraries
import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchSaga } from '../../../utils/network/fetch-saga';

// own components
import { getIdpBaseUrl, getHeaders, getUserIdFromToken } from '../../../utils/utils';

import { storage } from '../../../utils/storage';

// constants
import { INFO, ERROR } from '../../../utils/constants';

import { CHANGE_PASSWORD } from './actions';
import { logout } from '../actions';
import { addMessage } from '../../../containers/MessageModal/actions';

function* changePassword(action) {
    const { payload } = action;

    const userId = getUserIdFromToken(storage.getItem('token'));

    if (!userId) {
        yield put(addMessage(ERROR, action.intl.messages['changePassword.errorMessage']));
        return;
    }

    const baseUrl = getIdpBaseUrl();
    const url = `${baseUrl}/account/change-password`;

    const headers = getHeaders(true);

    const body = {
        userId,
        ...payload,
    };

    try {
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify(body) });

        if (data) {

            yield put(logout());

            storage.clear();

            yield put(addMessage(INFO, action.intl.messages['changePassword.successMessage']));
        }
    } catch (e) {
        yield put(addMessage(ERROR, action.intl.messages['changePassword.errorMessage']));
    }
}

function* watchUserChangePassword() {
    yield takeEvery(CHANGE_PASSWORD, changePassword);
}

export const ChangePasswordSaga = [
    watchUserChangePassword(),
];

export const ADD_MESSAGE = 'containers/MessageModal/addMessage';
export const REMOVE_MESSAGE = 'containers/MessageModal/removeMessage';
export const CLEAR_MESSAGES = 'containers/MessageModal/clearMessages';

export const addMessage = (severity, body) => ({
    type: ADD_MESSAGE,
    payload: {
        severity,
        body,
    },
});

export const removeMessage = (id) => ({
    type: REMOVE_MESSAGE,
    payload: id,
});

export const clearMessages = () => ({
    type: CLEAR_MESSAGES,
});

export const CHANGE_PASSWORD = 'routes/Account/ChangePassword/changePassword';

export const changePassword = (oldPassword, newPassword, intl) => ({
    type: CHANGE_PASSWORD,
    intl,
    payload: {
        oldPassword,
        newPassword,
    },
});

import {
    SET_USERDATA_FOR_REGISTRATION,
    DELETE_REGISTRATION_USERDATA,
} from './actions';

const initialState = {

    userData: {
        firstname: '',
        lastname: '',
        email: '',
        emailConfirm: '',
        password: '',
        passwordConfirm: '',
        hasPoliciesAccepted: false,
        hasContactAllowed: false,
    },
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
    case SET_USERDATA_FOR_REGISTRATION:
        return {
            ...state,
            userData: action.payload,
        };
    case DELETE_REGISTRATION_USERDATA:
        return {
            ...state,
            userData: initialState.userData,
        };
    default:
        return state;
    }
};

export default reducer;

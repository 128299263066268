/* eslint-disable camelcase */
export const CREATE_REQUEST = 'routes/HomeworkHelp/request';
export const SET_REQUEST_RESULT = 'routes/HomeworkHelp/setRequestResult';
export const GET_REQUESTS = 'routes/HomeworkHelp/getRequests';
export const SET_REQUESTS = 'routes/HomeworkHelp/setResults';
export const CLEAR_REQUESTS = 'routes/HomeworkHelp/clearRequests';
export const GET_DETAILED_REQUEST = 'routes/HomeworkHelp/getDetailedRequest';
export const SET_DETAILED_REQUEST = 'routes/HomeworkHelp/setDetailedRequest';
export const SET_DETAILED_REQUEST_STATUS = 'routes/HomeworkHelp/setDetailedRequestStatus';
export const REMOVE_DETAILED_REQUEST = 'routes/HomeworkHelp/removeDetailedRequest';
export const SET_CREATE_REQUEST_LOADING = 'routes/HomeworkHelp/setCreateRequestLoading';
export const SET_CREATE_REQUEST_ERROR = 'routes/HomeworkHelp/setCreateRequestError';
export const SET_LOADING = 'routses/HomeworkHelp/setLoading';
export const SET_ERROR = 'routes/HomeworkHelp/setError';
export const CREATE_ADDITIONAL_REQUEST = 'routes/HomeworkHelp/createAdditionalRequest';
export const SET_ADDITIONAL_REQUEST_RESULT = 'routes/HomeworkHelp/setAdditionalRequestResult';
export const SET_ADDITIONAL_REQUEST_LOADING = 'routes/HomeworkHelp/setAdditionalRequestLoading';
export const SET_ADDITIONAL_REQUEST_ERROR = 'routes/HomeworkHelp/setAdditionalRequestError';
export const UPDATE_USER_ZENDESK_ID = 'routes/HomeworkHelp/updateUserZendeskId';
export const SET_SHOULD_VALIDATE_USER = 'routes/HomeworkHelp/setShouldValidateUser';
export const UPLOAD_FILES = 'routes/HomeworkHelp/uploadFiles';
export const SET_UPLOAD_FILE_LOADING = 'routes/HomeworkHelp/setUploadFileLoading';
export const SET_UPLOAD_FILE_ERROR = 'routes/HomeworkHelp/setUploadFileError';
export const SET_UPLOADED_FILES = 'routes/HomeworkHelp/setUploadedFiles';
export const CLEAR_UPLOADED_FILES = 'routes/HomeworkHelp/clearUploadedFiles';
export const REMOVE_UPLOADED_FILE = 'routes/HomeworkHelp/removeUploadedFile';

export const createRequest = (request, intl) => ({
    type: CREATE_REQUEST,
    payload: { request, intl },
});

export const setRequestResult = (requestResult) => ({
    type: SET_REQUEST_RESULT,
    payload: { requestResult },
});

export const getRequests = (page, pageSize, email, intl, clear) => ({
    type: GET_REQUESTS,
    payload: { page, pageSize, email, intl, clear },
});

export const setRequests = (requests) => ({
    type: SET_REQUESTS,
    payload: { requests },
});

export const clearRequests = () => ({
    type: CLEAR_REQUESTS,
});

export const getDetailedRequest = (requestId, email) => ({
    type: GET_DETAILED_REQUEST,
    payload: { requestId, email },
});

export const setDetailedRequest = (detailedRequest) => ({
    type: SET_DETAILED_REQUEST,
    payload: { detailedRequest },
});

export const setDetailedRequestStatus = (requestId, loading, error) => ({
    type: SET_DETAILED_REQUEST_STATUS,
    payload: { requestId, loading, error },
});

export const removeDetailedRequest = (id) => ({
    type: REMOVE_DETAILED_REQUEST,
    payload: { id },
});

export const setCreateRequestLoading = (loading) => ({
    type: SET_CREATE_REQUEST_LOADING,
    payload: { loading },
});

export const setCreateRequestError = (error) => ({
    type: SET_CREATE_REQUEST_ERROR,
    payload: { error },
});

export const setLoading = (loading) => ({
    type: SET_LOADING,
    payload: { loading },
});

export const setError = (error) => ({
    type: SET_ERROR,
    payload: { error },
});

export const createAdditionalRequest = (requestId, requester_email, comment, uploads, solved) => ({
    type: CREATE_ADDITIONAL_REQUEST,
    payload: { requestId, requester_email, comment, uploads, solved },
});

export const setAdditionalRequestResult = (additionalRequestResult) => ({
    type: SET_ADDITIONAL_REQUEST_RESULT,
    payload: { additionalRequestResult },
});

export const setAdditionalRequestLoading = (loading) => ({
    type: SET_ADDITIONAL_REQUEST_LOADING,
    payload: { loading },
});

export const setAdditionalRequestError = (error) => ({
    type: SET_ADDITIONAL_REQUEST_ERROR,
    payload: { error },
});

export const updateUserZendeskId = (user_id, zendesk_id, intl) => ({
    type: UPDATE_USER_ZENDESK_ID,
    payload: { user_id, zendesk_id, intl },
});

export const setShouldValidateUser = (shouldValidateUser) => ({
    type: SET_SHOULD_VALIDATE_USER,
    payload: { shouldValidateUser },
});

export const uploadFiles = (filesToUpload, email, intl) => ({
    type: UPLOAD_FILES,
    payload: { filesToUpload, email, intl },
});

export const setUploadFileLoading = (loading) => ({
    type: SET_UPLOAD_FILE_LOADING,
    payload: { loading },
});

export const setUploadFileError = (error) => ({
    type: SET_UPLOAD_FILE_ERROR,
    payload: { error },
});

export const setUploadedFiles = (uploadedFiles) => ({
    type: SET_UPLOADED_FILES,
    payload: { uploadedFiles },
});

export const clearUploadedFiles = () => ({
    type: CLEAR_UPLOADED_FILES,
});

export const removeUploadedFile = (file) => ({
    type: REMOVE_UPLOADED_FILE,
    payload: { file },
});

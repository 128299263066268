/* eslint-disable no-case-declarations */
import { AUDIO_OBTAINED } from './actions';

const initialState = {
    audios: {},
};

const audioReducer = (state = initialState, action) => {
    switch (action.type) {
    case AUDIO_OBTAINED:
        const { audio } = action.payload;
        return { ...state, audios: { ...state.audios, [audio.id]: audio } };
    default:
        return { ...state };
    }
};

export default audioReducer;

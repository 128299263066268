import {
    SET_LOGIN_RESULT,
    LOGOUT,
    SET_USER,
    SET_USER_CENTERS,
    SET_AUTHENTICATION,
    SET_PAYMENT_DETAILS,
    SHOW_LOADER,
} from './actions';

import { storage } from '../../utils/storage';

import { isTokenExpired } from '../../utils/utils';

const authToken = storage.getItem('token');

const initialState = {
    authenticated: !isTokenExpired(authToken),
    userRoles: [],
    user: {},
    userCenters: [],
    paymentDetails: undefined,
    showLoader: false,
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
    case SET_LOGIN_RESULT:
        return {
            ...state,
            authenticated: action.payload.loginSuccessful,
        };
    case LOGOUT:
        return {
            ...state,
            authenticated: false,
            user: {},
            userRoles: [],
        };
    case SET_USER:
        return {
            ...state,
            user: action.payload,
        };
    case SET_USER_CENTERS:
        return {
            ...state,
            userCenters: action.payload,
        };
    case SET_AUTHENTICATION:
        return {
            ...state,
            authenticated: action.payload,
        };
    case SET_PAYMENT_DETAILS: {
        return {
            ...state,
            paymentDetails: action.payload,
        };
    }
    case SHOW_LOADER: {
        return {
            ...state,
            showLoader: action.payload,
        };
    }
    default:
        return state;
    }
};

export default reducer;

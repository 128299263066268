// RegisterBookcode action types and creators

export const REGISTER_BOOKCODE = 'containers/RegisterBookcode/registerBookcode';
export const REGISTER_BOOKCODE_SUCCESS = 'containers/RegisterBookcode/registerBookcodeSuccess';

export function registerBookcode(userData) {
    return {
        type: REGISTER_BOOKCODE,
        payload: userData,
    };
}

export function registerBookcodeSuccess() {
    return {
        type: REGISTER_BOOKCODE_SUCCESS,
    };
}

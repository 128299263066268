// Portal reducer

import {
    SET_SHOW_PROFILE_DIALOG,
    SET_PROFILE_DIALOG_CLOSED,
    SET_KIRA_INTRODUCTION_VIDEO_DATA,
} from './actions';

const initialState = {
    showProfileDialog: false,
    profileDialogClosed: false,
    kiraIntroductionVideoData: {},
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case SET_SHOW_PROFILE_DIALOG:
        return {
            ...state,
            showProfileDialog: action.payload,
        };
    case SET_PROFILE_DIALOG_CLOSED:
        return {
            ...state,
            profileDialogClosed: action.payload,
        };
    case SET_KIRA_INTRODUCTION_VIDEO_DATA:
        return {
            ...state,
            kiraIntroductionVideoData: action.payload,
        };
    default:
        return state;
    }
}

// react and external libraries
import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchSaga } from '../../../utils/network/fetch-saga';

// own components
import { getHeaders, getIdpBaseUrl } from '../../../utils/utils';

// constants
import { INFO, ERROR } from '../../../utils/constants';

import { REGISTER, deleteRegistrationUserData } from './actions';
import { addMessage } from '../../../containers/MessageModal/actions';

function* register(action) {

    const { intl, payload, history } = action;

    const baseUrl = getIdpBaseUrl();
    const url = `${baseUrl}/account/register`;

    const headers = getHeaders();

    try {
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify(payload) });

        if (data) {

            yield put(addMessage(INFO, intl.messages['registration.activationMailSendMessage']));
            yield put(deleteRegistrationUserData());

            history.push('/login');
        }
    } catch (e) {
        if (e.message === 'user already exists') {
            yield put(addMessage(ERROR, intl.messages['registration.alreadyRegisteredMessage']));
        } else if (e.message === 'user not confirmed') {
            yield put(addMessage(ERROR, intl.messages['registration.alreadyRegisteredUnconfirmedMessage']));
        } else {
            // TODO: handle this case
        }
    }
}

function* watchUserRegister() {
    yield takeEvery(REGISTER, register);
}

export const RegistrationSaga = [
    watchUserRegister(),
];

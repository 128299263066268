import moment from 'moment';
import { passwordHasValidFormat, isBlank } from './validationUtils';

export const validationRules = {
    required(value) {
        return !isBlank(value);
    },
    requiredOnlyIfCondition(value, condition) {
        return (condition && !isBlank(value)) || !condition;
    },
    isInteger(value) {
        return Number.isInteger(parseInt(value, 10));
    },
    isDateTime(value) {
        return moment.isMoment(value);
    },
    isEmptyOrDateTime(value) {
        return ((value === '' || value === null || value === undefined) || moment.isMoment(value));
    },
    isValidBirthdate(value) {
        const momentObj = moment(value, 'DD.MM.YYYY');
        return (isBlank(value) || (moment.isMoment(momentObj) && momentObj.format('DD.MM.YYYY') !== 'Invalid date'));
    },
    isTrue(value) {
        return value === true;
    },
    isBoolean(value) {
        return value === true || value === false;
    },
    isEqualTo(value, compare) {
        return value === compare;
    },
    isValidEmail(value) {
        const re = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
        return re.test(String(value).toLowerCase().trim());
    },
    isValidPassword(value) {
        return passwordHasValidFormat(value);
    },
    hasLength(value, validationItem) {
        return !isBlank(value) && value.trim().length === validationItem.requiredLength;
    },
    isEqualOrAfter(value, compare) {
        if (!moment.isMoment(value) || !moment.isMoment(compare)) {
            return false;
        }
        return value.isSameOrAfter(compare);
    },
    isMaxLengthExceeded(value, validationItem) {
        return !value || value.trim().length <= validationItem.maxLength;
    },
    isValidMobilePhoneNumber(value) {
        return !isBlank(value) && /^\d{5,}$/.test(value.trim().replace(/\s/g, ''));
    },
};

export default validationRules;

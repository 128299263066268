import { call, put, takeEvery } from 'redux-saga/effects';
import { addMessage } from '../MessageModal/actions';
import { ERROR } from '../../utils/constants';
import { fetchSaga } from '../../utils/network/fetch-saga';
import { getBibBaseUrl, getHeaders, getIdpBaseUrl, getTokenPayloadFromStorage } from '../../utils/utils';
import { setKiraIntroductionVideoData, FETCH_KIRA_INTRODUCTION_VIDEO, UPDATE_KIRA_MODAL_SHOWN } from './actions';
import { setUser } from '../../routes/Account/actions';

function* fetchKiraIntroductionVideo(action) {
    try {
        const videoId = action.payload;
        const response = yield call(
            fetchSaga,
            `${getBibBaseUrl()}/videos/${videoId}`,
            {
                method: 'GET',
                headers: getHeaders(!!getTokenPayloadFromStorage()),
            },
        );
        yield put(setKiraIntroductionVideoData(response));
    } catch (err) {
        yield put(addMessage(ERROR, err.message));
    }
}

export function* handleUpdateUser(action) {
    const { userId, kiraModalWasShown, kiraFeedbackModalWasShown } = action.payload;

    const baseUrl = getIdpBaseUrl();
    let url = `${baseUrl}/users/${userId}/kiraModal`;

    if (!!kiraModalWasShown && !!kiraFeedbackModalWasShown) {
        url += `?kiraModalWasShown=${kiraModalWasShown}&kiraFeedbackModalWasShown=${kiraFeedbackModalWasShown}`;
    } else if (!!kiraModalWasShown) {
        url += `?kiraModalWasShown=${kiraModalWasShown}`;
    } else if (!!kiraFeedbackModalWasShown) {
        url += `?kiraFeedbackModalWasShown=${kiraFeedbackModalWasShown}`;
    }

    const headers = getHeaders(true);

    try {
        const data = yield call(fetchSaga, url, { method: 'PUT', headers });
        if (data) {
            yield put(setUser(data));
        }
    } catch (e) {
        yield put(addMessage(ERROR, e.message));
    }
}

function* watchAudioRequested() {
    yield takeEvery(FETCH_KIRA_INTRODUCTION_VIDEO, fetchKiraIntroductionVideo);
}

export function* waitForUserWasUpdated() {
    yield takeEvery(UPDATE_KIRA_MODAL_SHOWN, handleUpdateUser);
}

export const dashboardSaga = [
    watchAudioRequested(),
    waitForUserWasUpdated(),
];

export const faqData = [
    {
        id: '1',
        question: '1. Was finde ich im Online-LernCenter?',
        answer: 'Das Online-LernCenter der Schülerhilfe umfasst vier große Bereiche:<br /><br />'
            + '<strong>Die Lernvideos:</strong> Hier werden alle in der öffentlichen Schule vermittelten '
            + 'Themenbereiche visuell erklärt - für jeden leicht verständlich und nachvollziehbar.<br /><br />'
            + '<strong>Die Online-Hausaufgabenhilfe:</strong> Hier stehen Lehrer bereit, um dir innerhalb '
            + 'kürzester Zeit eine direkte Hilfestellung zu akuten Fragestellungen zu geben.<br /><br />'
            + '<strong>Die Bibliothek:</strong> Darin findest du Erklärungen, Videos, Aufgabenblätter und '
            + 'interaktive Aufgaben zu den einzelnen Themen und Klassenstufen.<br /><br />'
            + '<strong>Die Webinare:</strong> Diese sind eine Art Mini-Vorlesung. Ein Lehrer stellt dir ein '
            + 'bestimmtes Thema vor. Anschließend kannst du deine konkreten Fragen zum Thema stellen. Die '
            + 'Webinare beinhalten auch interaktive Parts – sodass du die Webinare selbst mitgestalten '
            + 'kannst.<br /><br />Außerdem gibt es einen Bereich "Online Sprachen lernen", '
            + 'in dem du nützliche Vokabeltrainer-Apps findest',
    },
    {
        id: '2',
        question: '2. Was sind Lernvideos?',
        answer: 'In den Lernvideos erklären wir dir alle in der Schule vermittelten Themen – anschaulich '
        + 'und leicht verständlich. Die Lernvideos gibt es in den Fächern Mathematik, Deutsch, Englisch, Französisch, '
        + 'Latein, Chemie, Physik und Biologie.',
    },
    {
        id: '3',
        question: '3. Kann ich die Lernvideos auch herunterladen?',
        answer: 'Nein, die Lernvideos kannst du dir nur im Online-LernCenter anschauen.',
    },
    {
        id: '4',
        question: '4. Kann ich die Aufgabenblätter herunterladen?',
        answer: 'Ja, die Aufgabenblätter kannst du dir im Online-LernCenter ansehen und '
        + 'auch herunterladen und ausdrucken.',
    },
    {
        id: '5',
        question: '5. Was sind interaktive Aufgaben?',
        answer: 'Interaktive Aufgaben sind Aufgaben, die du direkt online in unserem Online-LernCenter '
        + 'durchführen kannst. Du erhältst die Auswertung deiner Ergebnisse dann bequem per E-Mail '
        + 'und/oder kannst sie zusätzlich im Online-LernCenter unter dem Menüpunkt "interaktive Aufgaben" '
        + 'einsehen. Von dort kann dir auch dein Nachhilfelehrer gezielt interaktive Aufgaben zuweisen, '
        + 'die du dann online bearbeiten kannst.',
    },
    {
        id: '6',
        question: '6. Darf ich die Inhalte des Online-LernCenters auch an meine Mitschüler weitergeben?',
        answer: 'Nein, die Inhalte sind ausschließlich für deinen Gebrauch gedacht. Eine Weitergabe an '
        + 'deine Mitschüler ist ausdrücklich untersagt.',
    },
    {
        id: '7',
        question: '7. Warum sollte ich die Materialien aus dem Online-LernCenter nutzen?',
        answer: 'Das Material ist exakt an deinen Bedürfnissen orientiert. Es wurde von unseren '
        + 'Nachhilfelehrern direkt für dich entwickelt.',
    },
    {
        id: '8',
        question: '8. Was ist die "Online-Hausaufgabenhilfe" konkret?',
        answer: 'Die "Online-Hausaufgabenhilfe" bietet dir eine individuelle Unterstützung bei der '
        + 'Bearbeitung von Fragen im Fach Mathematik, Deutsch und Englisch. Du kannst täglich '
        + '(inkl. Wochenende) in der Zeit von <strong>16 - 20</strong> Uhr deine Fragen online stellen.<br />'
        + '<br />Folgende Vorgehensweise:<br />'
        + '<ul><li>Melde dich im Online-LernCenter an und klicke auf "Online-Hausaufgabenhilfe" '
        + 'auf der Startseite</li>'
        + '<li>Trage deine Klasse, das Fach und die Schulform ein und stelle unseren Nachhilfelehrern '
        + 'deine Frage </li>'
        + '<li>Mit "Jetzt Anfrage einreichen" verschickst du das Ganze</li>'
        + '<li>Deine Antwort erhältst du innerhalb von ca. 30 Minuten</li>'
        + '<li>Sollte dir die Antwort nicht ausreichen, besteht die Möglichkeit, eine erneute Anfrage '
        + 'an den Nachhilfelehrer zu stellen, indem du auf das gleiche Ticket antwortest.</li></ul>',
    },
    {
        id: '9',
        question: '9. Was ist die "Bibliothek" konkret?',
        answer: 'Die "Bibliothek" ist ein einfach nutzbares System, das dir die Möglichkeit bietet, '
        + 'exklusive Inhalte einzusehen. Du kannst dir z. B. fach- und themenspezifische Lernvideos, '
        + 'Aufgabenblätter oder Wikis (Online-Erklärungen) ansehen und/oder herunterladen.',
    },
    {
        id: '10',
        question: '10. Was sind die "Webinare"?',
        answer: 'Ein "Webinar" ist eine Online-Vorlesung, in der dir einer unserer Nachhilfelehrer ein '
        + 'bestimmtes Thema exemplarisch (in ca. 30 min) erklärt. Anschließend hast du die Möglichkeit, '
        + 'deine Fragen zu dem Thema zu stellen.',
    },
    {
        id: '11',
        question: '11. Wie kann ich einen Zugang zum Online-LernCenter bekommen?',
        answer: 'Die verschiedenen Abo-Varianten für unser Online-LernCenter findest du unter "Abos & Preise" '
        + 'auf der Webseite des Online-LernCenters. Dort kannst du zwischen dem Premium und Basis Abo '
        + 'auswählen. Nach der Registrierung erhältst du dann eine E-Mail mit der Bestätigung deiner Buchung, '
        + 'dem Vertrag und der Widerrufserklärung. Du kannst dich dann mit deiner registrierten E-Mailadresse '
        + 'sowie dem vergebenen Passwort anmelden.<br />'
        + 'Solltest du Kunde bei einem unserer Standort in Deutschland oder Österreich sein, erhältst du '
        + 'deinen Zugang zum Online-LernCenter über die Schülerhilfeleitung.',
    },
    {
        id: '12',
        question: '12. Ich habe keinen Aktivierungslink bekommen – was soll ich tun?',
        answer: 'Bitte schaue in deinem Spam-Ordner nach. Wenn du bereits Kunde der Schülerhilfe und durch die '
        + 'Schülerhilfeleitung angemeldet worden bist, dann gleiche bitte nochmal deine E-Mailadresse ab. '
        + 'Wenn alles nicht hilft: Schreibe uns einfach eine E-Mail an: '
        + '<a href="mailto:support-lerncenter@schuelerhilfe.de.">support-lerncenter@schuelerhilfe.de.</a>',
    },
    {
        id: '13',
        question: '13. Was soll ich tun, wenn ich mein Passwort vergessen habe?',
        answer: 'Bitte gehe auf die Startseite (Login-Seite) des Online-LernCenters und klicke auf '
        + '"Passwort vergessen" – nun bekommst du einen neuen Aktivierungslink per E-Mail zugeschickt. '
        + 'Folge dann einfach den Hinweisen.',
    },
    {
        id: '14',
        question: '14. Welche Fächer werden im Online-LernCenter der Schülerhilfe angeboten?',
        answer: 'Wir bieten Lerninhalte in den Fächern Mathematik, Deutsch, Englisch, Französisch, Latein, Biologie, '
        + 'Chemie und Physik an.',
    },
    {
        id: '15',
        question: '15. Kann ich alle Inhalte über den Testzugang sehen?',
        answer: 'Du kannst über den Testzugang alle Inhalte des jeweiligen Abos („Basic“ oder „Premium“) nutzen.',
    },
    {
        id: '16',
        question: '16. Welchen Nutzen bietet das Premium-Paket?',
        answer: 'Im Premium-Paket können alle Funktionen und Inhalte – inkl. der "Online-Hausaufgabenhilfe" sowie den '
        + '"Webinaren" uneingeschränkt genutzt werden.',
    },
    {
        id: '17',
        question: '17. Habe ich einen Ansprechpartner, wenn ich mit den Aufgaben nicht zurechtkomme?',
        answer: 'Im Bereich der "Online-Hausaufgabenhilfe" helfen dir unsere Nachhilfelehrer ganz gezielt – derzeit '
        + 'für die Fächer Mathematik, Deutsch und Englisch. Sie bekommen die Aufgaben von dir zugesandt und geben dir '
        + 'ganz individuelle und passgenaue Lösungsansätze und Lösungswege.',
    },
    {
        id: '18',
        question: '18. Gibt es auch einen Ansprechpartner für technische Probleme?',
        answer: 'Alle Anfragen technischer Art können am schnellsten an folgende Support-E-Mail-Adresse '
        + 'gestellt werden: '
        + '<a href="mailto:support-lerncenter@schuelerhilfe.de.">support-lerncenter@schuelerhilfe.de.</a>',
    },
    {
        id: '19',
        question: '19. An wen wende ich mich, wenn ich Fragen zum Produkt habe?',
        answer: 'Unter der Telefonnummer 0800 / 30 200 40 91 stehen wir für Fragen rund um das '
        + 'Online-LernCenter zur Verfügung.',
    },
    {
        id: '20',
        question: '20. Wie richtig und genau sind die Antworten der KI-Lernassistentin Kira?',
        answer: 'Insgesamt gibt Kira potenziell <strong>sehr genaue Antworten auf Fragen</strong>, vorausgesetzt, '
              + 'die Fragen sind klar formuliert. Die KI-Lernassistentin <strong>erklärt verständlich den '
              + 'Lösungsweg</strong> und gibt <strong>hilfreiche Tipps und Anhaltspunkte</strong>, um die Aufgabe '
              + '<strong>selbstständig lösen zu können.</strong> Außerdem kann Kira Aufgaben und Ergebnisse '
              + 'überprüfen und lernt ständig dazu.<br/><br/>'
              + 'Faktoren, die Kiras Genauigkeit bei Antworten beeinflussen können:<br/><br/>'
              + '<strong>Die Qualität der Fragestellung</strong><br/>'
              + 'Klar und präzise formulierte Fragen führen zu genaueren Antworten. Mehrdeutige Fragen können zu '
              + 'unpräzisen Antworten führen.<br/><br/>'
              + '<strong>Die Komplexität der Aufgabe oder des Problems</strong><br/>'
              + 'Bei einer Vielzahl von Aufgaben löst Kira die Verständnisprobleme sehr genau – es gibt aber auch '
              + 'Grenzen, bei sehr komplexen oder spezialisierten Fragestellungen.<br/><br/>'
              + '<strong>Die Qualität der Quellen</strong><br/>'
              + 'Kiras Wissen basiert auf den geprüften Inhalten des Online-LernCenters. Alle Fragen oder Themen, '
              + 'die über die Inhalte des OLCs oder des schulischen Wissens (Klasse 1-13) hinausgehen, wird Kira '
              + 'trotzdem möglichst genau beantworten – es kann aber auch passieren, dass Kira selbst noch etwas '
              + 'dazulernen muss.',
    },
];

import {
    SET_AI_TUTOR_LOGIN_TOKEN,
} from './actions';

const initialState = {
    aiTutorLoginToken: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case SET_AI_TUTOR_LOGIN_TOKEN:
        return {
            ...state,
            aiTutorLoginToken: action.payload,
        };
    default:
        return state;
    }
}

export const SEND_TERMINATION_EMAIL = 'routes/Account/Downloads/sendTerminationEmail';
export const TERMINATION_EMAIL_SUCCESS = 'routes/Account/Downloads/terminationEmailSuccess';

export const sendTerminationEmail = (formValues, intl) => ({
    type: SEND_TERMINATION_EMAIL,
    intl,
    payload: { ...formValues },
});

export const terminationEmailSuccess = (showSuccessMessage) => ({
    type: TERMINATION_EMAIL_SUCCESS,
    payload: showSuccessMessage,
});

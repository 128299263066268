export const SET_SUGGESTIONS = 'containers/Library/setSuggestions';
export const SEARCH_REQUEST = 'containers/Library/searchRequest';
export const RESET_SUGGESTIONS = 'containers/Library/resetSuggestions';
export const CHOOSE_SUGGESTION = 'containers/Library/chooseSuggestion';
export const SHOW_SUGGESTION_NAVIGATION = 'containers/Library/showSuggestionNavigation';
export const HIDE_SUGGESTION_NAVIGATION = 'containers/Library/hideSuggestionNavigation';
export const SHOW_SUGGESTIONS = 'containers/Library/showSuggestions';
export const HIDE_SUGGESTIONS = 'containers/Library/hideSuggestions';
export const CHOOSE_SUGGESTION_SUBJECT = 'containers/Library/chooseSuggestionSubject';

export function setSuggestions(suggestions) {
    return {
        type: SET_SUGGESTIONS,
        payload: suggestions,
    };
}

export function searchRequest(searchTerm, maxResult) {
    return {
        type: SEARCH_REQUEST,
        payload: searchTerm,
        maxResult,
    };
}

export function resetSuggestions() {
    return {
        type: RESET_SUGGESTIONS,
    };
}

export function chooseSuggestion(id, subjectId, gradeId, history) {
    return {
        type: CHOOSE_SUGGESTION,
        id,
        subjectId,
        gradeId,
        history,
    };
}

export function showSuggestionNavigation() {
    return {
        type: SHOW_SUGGESTION_NAVIGATION,
    };
}

export function hideSuggestionNavigation() {
    return {
        type: HIDE_SUGGESTION_NAVIGATION,
    };
}

export function showSuggestions() {
    return {
        type: SHOW_SUGGESTIONS,
    };
}

export function hideSuggestions() {
    return {
        type: HIDE_SUGGESTIONS,
    };
}

export function chooseSuggestionSubject(id) {
    return {
        type: CHOOSE_SUGGESTION_SUBJECT,
        payload: id,
    };
}

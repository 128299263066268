export const OPEN_OLAT_INSTANCE = Object.freeze({
    OPEN_OLAT: 'OPEN_OLAT',
    OPEN_OLAT_LANGUAGE_COURSES: 'OPEN_OLAT_LANGUAGE_COURSES',
});

export const COURSE_NAMES = Object.freeze({
    ONLINE_INTENSIVE_COURSE: 'Online Intensiv-Lernkurs',
    LANGUAGE_TRAINING: 'Sprachtraining',
    MATH_ABI: 'Abikurs Mathematik',
    ONLINE_TEST_FOR_SHL: 'Online-Test für SHL',
    ONLINE_TEST_FOR_SHL_FRANCHISE: 'Online-Test für SHL Franchise',
});

export const OLAT_EXAM_TYPE = Object.freeze({
    TEST: 'TEST',
    COURSE: 'COURSE',
});

export const BUTTON_VARIANT = Object.freeze({
    OUTLINE_BLUE: 'outline-blue',
    OUTLINE_BEIGE: 'outline-beige',
});

// Storage keys.
export const LOGIN_TOKEN_KEY = 'token';
export const REFRESH_TOKEN_KEY = 'refreshToken';
export const CLIENT_ID_KEY = 'clientId';
export const TOKEN_EXPIRY_TS_KEY = 'tokenExpiryTs';
export const IS_UPDATING_AUTH_KEY = 'isUpdatingAuth';

// appointment types
export const EVENT = 'EVENT';
export const COURSE = 'COURSE';

// message severities
export const INFO = 'INFO';
export const ERROR = 'ERROR';

// moment.js
export const INFINITY_MS = 1e15; // 33658-09-27

// german date format
export const DATE_FORMAT_DE = 'DD.MM.YYYY';

// known corporate subjects
export const corporateSubjects = ['BIO', 'CHE', 'DEU', 'ENG', 'FRZ', 'GES', 'ITA', 'LAT', 'MAT', 'POL', 'RUS', 'SPA',
    'WIR', 'AGR', 'DYK', 'ERD', 'INF', 'JAP', 'LEG', 'MUS', 'NAW', 'NED', 'PHY', 'REW', 'SAC', 'TEC'];

// subject colors
export const subjectColors = {
    BIO: '#29b95a', // Biologie
    CHE: '#44b794', // Chemie
    DEU: '#004f9f', // Deutsch
    ENG: '#3faad5', // Englisch
    FRZ: '#8f3d90', // Französisch
    GES: '#a9d427', // Geschichte
    ITA: '#00f35b', // Italienisch
    LAT: '#004f9f', // Latein
    MAT: '#dba001', // Mathematik
    POL: '#8bafeb', // Politik
    RUS: '#c80473', // Russisch
    SPA: '#cf3939', // Spanisch
    WIR: '#936802', // Wirtschaftskunde
    AGR: '#9e8870', // Altgriechisch
    DYK: '#fff59d', // Dyskalkulie
    ERD: '#70522a', // Erdkunde
    INF: '#192465', // Informatik
    JAP: '#ff9ad3', // Japanisch
    LEG: '#f42eed', // Legasthenie
    MUS: '#680094', // Musik
    NAW: '#005e16', // Naturwissenschaft
    NED: '#ff6600', // Niederländisch
    PHY: '#3334be', // Physik
    REW: '#1b70d2', // Rechnungswesen
    SAC: '#fff000', // Sachkunde
    TEC: '#43f4ff', // Technik
    OTHER: '#f18700', // other
};

// all locations that are on the Schuelerhilde DE page
export const locations = [
    {
        name: 'Weitere Standorte',
        link: 'https://www.schuelerhilfe.de/standorte/weitere-standorte/',
    },
    {
        name: 'Nachhilfe Dortmund',
        link: 'https://www.schuelerhilfe.de/standorte/dortmund-nachhilfe',
    },
    {
        name: 'Nachhilfe Leipzig',
        link: 'https://www.schuelerhilfe.de/standorte/leipzig-nachhilfe',
    },
    {
        name: 'Nachhilfe Berlin',
        link: 'https://www.schuelerhilfe.de/standorte/berlin-nachhilfe',
    },
    {
        name: 'Nachhilfe Dresden',
        link: 'https://www.schuelerhilfe.de/standorte/dresden-nachhilfe',
    },
    {
        name: 'Nachhilfe Lübeck',
        link: 'https://www.schuelerhilfe.de/standorte/luebeck-nachhilfe',
    },
    {
        name: 'Nachhilfe Bremen',
        link: 'https://www.schuelerhilfe.de/standorte/bremen-nachhilfe',
    },
    {
        name: 'Nachhilfe Duisburg',
        link: 'https://www.schuelerhilfe.de/standorte/duisburg-nachhilfe',
    },
    {
        name: 'Nachhilfe München',
        link: 'https://www.schuelerhilfe.de/standorte/muenchen-nachhilfe',
    },
    {
        name: 'Nachhilfe Hamburg',
        link: 'https://www.schuelerhilfe.de/standorte/hamburg-nachhilfe',
    },
    {
        name: 'Nachhilfe Düsseldorf',
        link: 'https://www.schuelerhilfe.de/standorte/duesseldorf-nachhilfe',
    },
    {
        name: 'Nachhilfe Neuss',
        link: 'https://www.schuelerhilfe.de/standorte/neuss-nachhilfe',
    },
    {
        name: 'Nachhilfe Saarland',
        link: 'https://www.schuelerhilfe.de/standorte/saarland-nachhilfe',
    },
    {
        name: 'Nachhilfe Essen',
        link: 'https://www.schuelerhilfe.de/standorte/essen-nachhilfe',
    },
    {
        name: 'Nachhilfe Nürnberg',
        link: 'https://www.schuelerhilfe.de/standorte/nuernberg-nachhilfe',
    },
    {
        name: 'Nachhilfe Aachen',
        link: 'https://www.schuelerhilfe.de/standorte/aachen-nachhilfe',
    },
    {
        name: 'Nachhilfe Frankfurt (Main)',
        link: 'https://www.schuelerhilfe.de/standorte/frankfurt-nachhilfe',
    },
    {
        name: 'Nachhilfe Regensburg',
        link: 'https://www.schuelerhilfe.de/standorte/regensburg-nachhilfe',
    },
    {
        name: 'Nachhilfe Augsburg',
        link: 'https://www.schuelerhilfe.de/standorte/augsburg-nachhilfe',
    },
    {
        name: 'Nachhilfe Hagen',
        link: 'https://www.schuelerhilfe.de/standorte/hagen-nachhilfe',
    },
    {
        name: 'Nachhilfe Stuttgart',
        link: 'https://www.schuelerhilfe.de/standorte/stuttgart-nachhilfe',
    },
    {
        name: 'Nachhilfe Bielefeld',
        link: 'https://www.schuelerhilfe.de/standorte/bielefeld-nachhilfe',
    },
    {
        name: 'Nachhilfe Hannover',
        link: 'https://www.schuelerhilfe.de/standorte/hannover-nachhilfe',
    },
    {
        name: 'Nachhilfe Bonn',
        link: 'https://www.schuelerhilfe.de/standorte/bonn-nachhilfe',
    },
    {
        name: 'Nachhilfe Köln',
        link: 'https://www.schuelerhilfe.de/standorte/koeln-nachhilfe',
    },
];

// Abo ids.
export const BASIC_ID = '4';
export const PREMIUM_ID = '8';
export const CAMPAIGN_ID_LIMANGO = '6';

// Abo names.
export const BASIC_NAME = 'basis';
export const PREMIUM_NAME = 'premium';
export const CAMPAIGN_NAME_LIMANGO = 'campaign (limango)';

export const ABO_NAMES = {
    [BASIC_ID]: BASIC_NAME,
    [BASIC_NAME]: BASIC_NAME,
    [PREMIUM_ID]: PREMIUM_NAME,
    [PREMIUM_NAME]: PREMIUM_NAME,
    [CAMPAIGN_ID_LIMANGO]: CAMPAIGN_NAME_LIMANGO,
    [CAMPAIGN_NAME_LIMANGO]: CAMPAIGN_NAME_LIMANGO,
};

export const ABO_IDS = {
    [BASIC_ID]: BASIC_ID,
    [BASIC_NAME]: BASIC_ID,
    [PREMIUM_ID]: PREMIUM_ID,
    [PREMIUM_NAME]: PREMIUM_ID,
    [CAMPAIGN_ID_LIMANGO]: CAMPAIGN_ID_LIMANGO,
    [CAMPAIGN_NAME_LIMANGO]: CAMPAIGN_ID_LIMANGO,
};

export const PAYMENT_METHOD_SEPA_ID = 37;

/**
 * Static google analytics event data.
 * According to the official document
 * (https://support.google.com/analytics/answer/1033068?hl=en)
 * categories and actions are static values for grouping events.
 * Labels provide more unique and case specific data.
 * Therefore, categories and actions can be set as static values.
*/
export const GA = Object.freeze({
    CATEGORIES: {
        ABO: 'abo',
        ABO_LIMANGO: 'abo limango',
        ACTIVATE_NOW: 'activate now',
        AUDIOS: 'audios',
        BBB: 'big blue button',
        ERROR: 'error',
        EXERCISE: 'exercise',
        NOT_FOUND: '404',
        ODH: 'ODH',
        OPEN_ACTIVATION: 'open activation',
        REGISTRATION_PROCESS_OLC: 'Registrierungsprozess OLC',
        SEARCH: 'search',
        TESTER: 'tester',
        TESTER_SMS: 'tester sms',
        USER: 'user',
        VIDEOS: 'videos',
        WIKI: 'wiki',
        WEBINAR: 'webinar',
    },

    GA4CATEGORIES: {
        FORM_SUBMISSION: 'form_submission',
        ABO: 'abo',
        ABO_LIMANGO: 'abo_limango',
        ACTIVATE_NOW: 'activate_now',
        AUDIOS: 'audios',
        BBB: 'big_blue_button',
        ERROR: 'error',
        CLICK_EXERCISE: 'click_exercise',
        NOT_FOUND: '404',
        ODH: 'odh',
        OPEN_ACTIVATION: 'open_activation',
        REGISTRATION_PROCESS_OLC: 'registrierungsprozess_olc',
        CLICK_SEARCH: ': click_suchen',
        CLICK_TESTER: 'click_testen',
        TESTER_SMS: 'tester_sms',
        CLICK_USER: 'click_button',
        VIDEOS_INTERAKTION: 'video_interaktion',
        CLICK_WIKI: 'click_wiki',
        WEBINAR: 'webinar',
    },

    ACTIONS: {
        AUDIO_PLAY_BUTTON_CLICK: 'play button clicked (Audio)',
        AUDIO_PAUSED: 'audio paused',
        AUDIO_LISTENED: 'audio listened completely',
        AUTHENTICATION: 'authentication',
        BBB_BUTTON_CLICK: 'bbb button clicked',
        BBB_COURSE_VIEW: 'bbb course rendered',
        CHANGE_PASSWORD_SUBMIT_CLICK: 'change password submit button click',
        DOWNLOAD_BUTTON_CLICKED: 'download button clicked',
        EXERCISE_SCROLL_THRESHOLD_PASSED: 'exercise scroll threshold passed',
        EXERCISE_SELECTED: 'exercise selected',
        FOOTER_BUTTON_CLICK: 'footer button click',
        JAVASCRIPT_ERROR: 'JavaScript error',
        LOGIN_BUTTON_CLICK: 'login button click',
        PROBESTUNDEN_BUTTON_CLICK: 'trial lessons button click',
        NEW_ACCOUNT_REGISTRATION: 'new account registration',
        NEW_ACCOUNT_REGISTRATION_SPECIAL: 'new account registration (special)',
        ODH_REQUEST: 'ODH requested',
        PAYMENT: 'payment',
        PAYMENT_PROCESS_BASIC_AND_PREMIUM: 'Paymentprozess Basis und Premium',
        PROFILE_DIALOG_SUBMITTED: 'profile dialog submitted',
        PLACEHOLDER_PLAY_BUTTON_CLICK: 'play button clicked (placeholder video)',
        REGISTRATION_BUTTON_CLICK: 'registration button click',
        SEARCH_TRIGGERED: 'search triggered',
        SHOW_FULL_WIKI_TRIGGERED: 'show full wiki triggered',
        SHOW_TASK_CONTENT: 'show task content triggered',
        SHOW_TASK_SOLUTION: 'show task solution triggered',
        SDN_VIDEO_PLAY_BUTTON_CLICK: 'play button clicked (SDN video)',
        SDN_VIDEO_PAUSED: 'video paused (SDN video)',
        SDN_VIDEO_DROPPED: 'video dropped/skipped (SDN video)',
        SDN_VIDEO_WATCHED: 'video watched completely (SDN video)',
        SMS_TESTER_PROCESS: 'Tester SMS Prozess',
        WEBINAR_REGISTERED: 'registered for a webinar',
        WEBINAR_UNREGISTERED: 'unregistered from a webinar',
        YT_VIDEO_PLAY_BUTTON_CLICK: 'play button clicked (YouTube video)',
        YT_VIDEO_PAUSED: 'video paused (YouTube video)',
        YT_VIDEO_DROPPED: 'video dropped/skipped (YouTube video)',
        YT_VIDEO_WATCHED: 'video watched completely (YouTube video)',
    },
    LABELS: {
        BASIC_ABO_CHOICE: 'Basis Abo Auswahl',
        ORDER_OVERVIEW_BASIS: 'Bestellübersicht Basis',
        ORDER_OVERVIEW_PREMIUM: 'Bestellübersicht Premium',
        PAYMENT_BASIS_FAILURE: 'Zahlung Basis fehlgeschlagen',
        PAYMENT_BASIS_SUCCESS: 'Zahlung Basis erfolgreich',
        PAYMENT_CAMPAIGN_LIMANGO: 'Zahlung Limango Campaign erfolgreich',
        PAYMENT_PREMIUM_FAILURE: 'Zahlung Premium fehlgeschlagen',
        PAYMENT_PREMIUM_SUCCESS: 'Zahlung Premium erfolgreich',
        PAYMENT_DATA_BASIS: 'Step Rechnungsdaten Basis',
        PAYMENT_DATA_PREMIUM: 'Step Rechnungsdaten Premium',
        PERSONAL_DATA: 'Persönliche Daten',
        PREMIUM_ABO_CHOICE: 'Premium Abo Auswahl',
    },
});

export const StatusCodes = Object.freeze({
    OK: 200,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500,
});

export const OLC_SESSION_ID_KEY = 'sessionID';

// Musterklausuren.
export const EXAMPLE_EXAMS_FLIPBOOKS_ID = -1;
// Abiturvorbereitungsbücher.
export const ABITUR_PREPARATION_FLIPBOOKS_ID = -2;
// Prüfungsvorbereitungsbücher.
export const TEST_PREPARATION_FLIPBOOKS_ID = -3;
// Grundschulbücher.
export const PRIMARY_SCHOOL_FLIPBOOKS_ID = -4;

export const nationStateRoutes = Object.freeze([
    '/nrw',
    '/bayern',
    '/brandenburg',
    '/hessen',
    '/schleswig-holstein',
    '/mecklenburg-vorpommern',
]);

export const knowledgeCheck = Object.freeze([
    '/beraten-und-verkaufen',
    '/fresh-up-beraten-und-verkaufen',
    '/einstellungstest',
    '/kunden-schueler-eltern-test',
    '/fresh-up-kundengespraeche',
    '/nhl-einstellung-einarbeitungstest',
    '/at-start-up-trainingstest',
    '/de-start-up-trainingstest',
]);

export const lisaRegisterRoutes = Object.freeze([
    '/lisa-sachsen-anhalt',
]);

export const otherRoutes = Object.freeze([
    '/OLC_gratis',
    '/whatsapp',
]);

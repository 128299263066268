// RegisterBookcode reducer

import {
    REGISTER_BOOKCODE_SUCCESS,
} from './actions';

const initialState = {
    showSuccessMessage: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case REGISTER_BOOKCODE_SUCCESS:
        return { ...state, showSuccessMessage: true };
    default:
        return state;
    }
}

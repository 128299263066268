// Global imports.
import moment from 'moment';
import uuid from 'uuid';
import { call, put, select, takeEvery } from 'redux-saga/effects';

// Utils, actions, sagas, etc. .
import {
    LOGIN,
    ACTIVATE,
    GET_USER,
    GET_USER_BY_EMAIL,
    GET_USER_CENTERS,
    GET_PAYMENT_DETAILS,
    CANCEL_SUBSCRIPTION,
    REACTIVATE_SUBSCRIPTION,
    SAVE_USER,
    setLoginResult,
    setUser,
    setUserCenters,
    setPaymentDetails,
    LOGOUT,
    VIDIS_AUTHENTICATION,
    showLoader,
} from './actions';
import {
    getIdpBaseUrl,
    getHeaders,
    createCookieStringFromToken,
    trackUserActivity,
    isTrackingAllowed,
    deleteOlcReportingSessionId,
    getPortalBaseUrl,
    getExpiryTsFromToken,
} from '../../utils/utils';
import {
    INFO,
    ERROR,
    GA,
    REFRESH_TOKEN_KEY,
    CLIENT_ID_KEY,
    TOKEN_EXPIRY_TS_KEY,
    LOGIN_TOKEN_KEY,
} from '../../utils/constants';
import { addMessage } from '../../containers/MessageModal/actions';
import { fetchSaga } from '../../utils/network/fetch-saga';
import { storage } from '../../utils/storage';

export function* refreshToken() {
    const baseUrl = getIdpBaseUrl();
    const url = `${baseUrl}/account/refresh-token`;
    const headers = getHeaders(true);
    try {
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify({}) });
        if (data) {
            storage.setItem('token', data.token);
            document.cookie = createCookieStringFromToken(data.token);
        }
    } catch (e) {
        if (e.message !== 'server error' && e.message !== 'network error') {
            // TODO: refactor error handling
        }
    }
}

export function* getPaymentDetails(action) {
    const baseUrl = getPortalBaseUrl();
    const tid = action.payload;
    const url = `${baseUrl}/payments/${tid}/details`;
    const headers = getHeaders(true);
    try {
        const data = yield call(fetchSaga, url, { method: 'GET', headers });
        if (data) {
            yield put(setPaymentDetails(data));
        }
    } catch (e) {
        // do nothing here
        // an error here can only accure when with the tid no novalnet subscription found
    }
}

export function* fetchUser(userId) {
    const url = `${getIdpBaseUrl()}/users/${userId}`;
    const headers = getHeaders(true);
    return yield call(fetchSaga, url, { method: 'GET', headers });
}

function* getUser(action) {
    const { intl, payload } = action;
    try {
        const user = yield call(fetchUser, payload);
        if (user) {
            yield put(setUser(user));
        }
    } catch (e) {
        yield put(addMessage(ERROR, intl.formatMessage({ id: 'errorMessages.userDataError' })));
    }
}

export function* getUserByEmail(action) {
    const baseUrl = getIdpBaseUrl();
    const url = `${baseUrl}/users/restricted-search?email=${action.payload}`;
    const headers = getHeaders(true);
    try {
        const data = yield call(fetchSaga, url, { method: 'GET', headers });
        if (data) {
            yield put(setUser(data));
        }
    } catch (e) {
        yield put(setUser({}));
        if (e.message === 'server error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (e.message === 'network error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else {
            yield put(addMessage(ERROR, e.message));
        }
    }
}

function* getUserCenters(action) {
    const baseUrl = getIdpBaseUrl();
    const url = `${baseUrl}/users/${action.payload}/centers`;
    const headers = getHeaders(true);
    try {
        const data = yield call(fetchSaga, url, { method: 'GET', headers });
        if (data) {
            yield put(setUserCenters(data));
        }
    } catch (e) {
        yield put(setUserCenters([]));
        if (e.message === 'server error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (e.message === 'network error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else {
            yield put(addMessage(ERROR, e.message));
        }
    }
}

export function* saveAndFetchUser(userId, userData) {
    const baseUrl = getIdpBaseUrl();
    const url = `${baseUrl}/users/${userId}`;
    const headers = getHeaders(true);
    return yield call(fetchSaga, url, { method: 'PUT', headers, body: JSON.stringify(userData) });
}

function* saveUser(action) {
    const { userId, payload } = action;

    try {
        const user = yield call(saveAndFetchUser, userId, payload);
        if (user) {
            yield call(refreshToken);
            if (action.showMessage) {
                yield put(addMessage(INFO, 'Daten erfolgreich gespeichert!'));
            }
            yield put(setUser(user));
        }
    } catch (e) {
        if (e.message === 'server error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (e.message === 'network error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else {
            yield put(addMessage(ERROR, e.message));
        }
    }
}

export function* cancelSubscription(action) {
    const { userData, tid } = action.payload;
    const baseUrl = getPortalBaseUrl();
    const url = `${baseUrl}/payments/cancel`;
    const headers = getHeaders(true);
    try {
        const body = JSON.stringify({ userId: userData.id, tid });
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body });
        if (data) {
            // update user
            const user = yield call(saveAndFetchUser, userData.id, userData);
            if (user) {
                yield call(refreshToken);
                yield put(addMessage(INFO, 'Ihr Abonnement wurde erfolgreich gekündigt!'));
                yield put(setUser(user));
            }

            // fetch paymentdetails
            const paymentDetailsPayload = { payload: tid };
            yield call(getPaymentDetails, paymentDetailsPayload);
        }
    } catch (e) {
        yield put(addMessage(ERROR, e.message));
    }
}

export function* reactivateSubscription(action) {
    const { userData, tid, intl } = action.payload;
    const baseUrl = getPortalBaseUrl();
    const url = `${baseUrl}/payments/reactivate`;
    const headers = getHeaders(true);
    try {
        const body = JSON.stringify({ userId: userData.id, tid });
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body });
        if (data) {
            // fetch paymentdetails
            const paymentDetailsPayload = { payload: tid };
            yield call(getPaymentDetails, paymentDetailsPayload);

            // update user
            const user = yield call(saveAndFetchUser, userData.id, userData);
            if (user) {
                yield call(refreshToken);
                const paymentDetails = yield select((state) => state.account.paymentDetails);
                if (!!paymentDetails?.nextCycleDate) {
                    const successMessage = intl.formatMessage({ id: 'activate.reactivateSuccessMessage' })
                        .replace('{DATUM}', moment(paymentDetails.nextCycleDate).format('DD.MM.YYYY'))
                        .replace('{UHRZEIT}', moment(paymentDetails.nextCycleDate).format('HH:MM'));
                    yield put(addMessage(INFO, successMessage));
                } else {
                    const fallbackSuccessMessage = intl.formatMessage({
                        id: 'activate.reactivateSuccessMessageFallBack',
                    });
                    yield put(addMessage(INFO, fallbackSuccessMessage));
                }
                yield put(setUser(user));
            }
        }
    } catch (e) {
        yield put(addMessage(ERROR, e.message));
    }
}

function* vidisAutentication(action) {
    const { payload, history } = action;

    const url = `${getIdpBaseUrl()}/account/authenticate/vidis`;
    const headers = getHeaders();

    try {
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify(payload) });

        if (data) {
            storage.removeItem('uc_settings');
            storage.removeItem('uc_user_interaction');
            storage.removeItem('uc_ui_version');

            storage.setItem('token', data.token);
            document.cookie = createCookieStringFromToken(data.token);

            yield put(setLoginResult(true));
            yield put(showLoader(false));

            history.push('/login');
        }
    } catch (e) {
        history.push('/login');

        console.error('Failed authenticate VIDIS user');
        yield put(addMessage(ERROR, 'Bei der Authentifizierung ist ein Fehler aufgetreten.'));

        yield put(setLoginResult(false, -1));
        yield put(showLoader(false));
    }
}

function* login(action) {

    const { intl, payload, permanentLogin } = action;

    const baseUrl = getIdpBaseUrl();
    const url = `${baseUrl}/account/authenticate`;

    const headers = getHeaders();

    // use version 4 UUID
    const clientId = uuid.v4();

    if (!!permanentLogin) {
        payload.client_id = clientId;
    }

    try {
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify(payload) });

        if (data) {
            storage.setItem('token', data.token);

            if (!!permanentLogin) {
                storage.setItem(REFRESH_TOKEN_KEY, data.refresh_token);
                storage.setItem(CLIENT_ID_KEY, clientId);
                storage.setItem(TOKEN_EXPIRY_TS_KEY, getExpiryTsFromToken(data.token));
            }

            document.cookie = createCookieStringFromToken(data.token);
            if (isTrackingAllowed()) {
                trackUserActivity({
                    category: GA.CATEGORIES.USER,
                    action: GA.ACTIONS.AUTHENTICATION,
                    label: 'success',
                });
            }

            yield put(setLoginResult(true));
        }
    } catch (e) {
        console.error(`Failed authentication for user '${payload.email}': ${e.message}`);

        if (e.message === 'Login Daten falsch') {
            yield put(addMessage(ERROR, intl.messages['authentication.loginError']));
        } else {
            yield put(addMessage(ERROR, intl.messages['authentication.error']));
        }

        yield put(setLoginResult(false, -1));
    }
}

function* activate(action) {

    const { payload, intl } = action;

    const url = `${getIdpBaseUrl()}/account/activate`;
    const headers = getHeaders();

    try {
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify(payload) });

        if (data) {
            yield put(addMessage(INFO, intl.messages['activation.successMessage']));
        }
    } catch (e) {
        if (e.message === 'user already activated') {
            yield put(addMessage(INFO, intl.messages['activation.alreadyActivatedMessage']));
        } else {
            yield put(addMessage(ERROR, intl.messages['activation.notActivatedMessage']));
        }
    }
}

function* logout() {
    storage.removeItem(LOGIN_TOKEN_KEY);
    storage.removeItem(REFRESH_TOKEN_KEY);
    storage.removeItem(CLIENT_ID_KEY);
    storage.removeItem(TOKEN_EXPIRY_TS_KEY);

    yield deleteOlcReportingSessionId();
}

function* watchVidisAuthentication() {
    yield takeEvery(VIDIS_AUTHENTICATION, vidisAutentication);
}

function* watchUserLogin() {
    yield takeEvery(LOGIN, login);
}

function* watchGetUser() {
    yield takeEvery(GET_USER, getUser);
}

function* watchGetUserByEmail() {
    yield takeEvery(GET_USER_BY_EMAIL, getUserByEmail);
}

function* watchGetUserCenters() {
    yield takeEvery(GET_USER_CENTERS, getUserCenters);
}

function* watchSaveUser() {
    yield takeEvery(SAVE_USER, saveUser);
}

function* watchUserActivate() {
    yield takeEvery(ACTIVATE, activate);
}

function* watchUserLogout() {
    yield takeEvery(LOGOUT, logout);
}

function* watchGetPaymentDetails() {
    yield takeEvery(GET_PAYMENT_DETAILS, getPaymentDetails);
}

function* watchCancelSubscription() {
    yield takeEvery(CANCEL_SUBSCRIPTION, cancelSubscription);
}

function* watchReactivateUser() {
    yield takeEvery(REACTIVATE_SUBSCRIPTION, reactivateSubscription);
}

export const AccountSaga = [
    watchVidisAuthentication(),
    watchUserLogin(),
    watchGetUser(),
    watchGetUserByEmail(),
    watchGetUserCenters(),
    watchSaveUser(),
    watchUserActivate(),
    watchUserLogout(),
    watchGetPaymentDetails(),
    watchCancelSubscription(),
    watchReactivateUser(),
];

// Favorites sagas

import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchSaga } from '../../utils/network/fetch-saga';
import { getPortalBaseUrl, getHeaders } from '../../utils/utils';

// constants
import { ERROR } from '../../utils/constants';

import {
    setFavoritesForUser,
    setFavorite,
    GET_FAVORITES_FOR_USER,
    CREATE_FAVORITE,
    GET_FAVORITE,
    DELETE_FAVORITE,
} from './actions';
import { addMessage } from '../MessageModal/actions';

export function* handleGetFavoriteForUser(action) {
    const baseUrl = getPortalBaseUrl();
    const url = `${baseUrl}/favorites?userId=${action.payload}`;
    const headers = getHeaders(true);

    try {
        let response = yield call(fetchSaga, url, { method: 'GET', headers });

        if (response) {
            let { items } = response;

            while (response.page && response.page_count && response.page < response.page_count) {
                response = yield call(fetchSaga, `${url}&page=${response.page + 1}`, { method: 'GET', headers });

                if (response) {
                    items = [...items, ...response.items];
                }
            }

            yield put(setFavoritesForUser(items));
        }
    } catch (error) {
        if (error.message === 'server error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (error.message === 'network error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else {
            yield put(addMessage(ERROR, error.message));
        }
        yield put(setFavoritesForUser([]));
    }
}

export function* handleGetFavorite(action) {
    const baseUrl = getPortalBaseUrl();
    const url = `${baseUrl}/favorites/${action.payload}`;
    const headers = getHeaders(true);

    try {
        const response = yield call(fetchSaga, url, { method: 'GET', headers });
        if (response) {
            yield put(setFavorite(response));
        }
    } catch (error) {
        if (error.message === 'server error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (error.message === 'network error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else {
            yield put(addMessage(ERROR, error.message));
        }
    }
}

export function* handleCreateFavorite(action) {
    const baseUrl = getPortalBaseUrl();
    const url = `${baseUrl}/favorites`;
    const headers = getHeaders(true);

    try {
        const response = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify(action.payload) });
        if (response) {
            yield put(setFavorite(response));
        }
    } catch (error) {
        if (error.message === 'server error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (error.message === 'network error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else {
            yield put(addMessage(ERROR, error.message));
        }
    }
}

export function* handleDeleteFavorite(action) {
    const baseUrl = getPortalBaseUrl();
    const url = `${baseUrl}/favorites/${action.payload}`;
    const headers = getHeaders(true);

    try {
        yield call(fetchSaga, url, { method: 'DELETE', headers });
    } catch (error) {
        if (error.message === 'server error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (error.message === 'network error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else {
            yield put(addMessage(ERROR, error.message));
        }
    }
}

export function* waitForFavoriteDataWasFetched() {
    yield takeEvery(GET_FAVORITES_FOR_USER, handleGetFavoriteForUser);
}

export function* waitForFavoriteWasFetched() {
    yield takeEvery(GET_FAVORITE, handleGetFavorite);
}

export function* waitForFavoriteWasCreated() {
    yield takeEvery(CREATE_FAVORITE, handleCreateFavorite);
}

export function* waitForFavoriteWasDeleted() {
    yield takeEvery(DELETE_FAVORITE, handleDeleteFavorite);
}

export const favoriteSaga = [
    waitForFavoriteDataWasFetched(),
    waitForFavoriteWasFetched(),
    waitForFavoriteWasCreated(),
    waitForFavoriteWasDeleted(),
];

export const REGISTER = 'routes/Account/Registration/register';
export const SET_USERDATA_FOR_REGISTRATION = 'routes/Account/Registration/setUserDataForRegistration';
export const DELETE_REGISTRATION_USERDATA = 'routes/Account/Registration/deleteRegistrationUserData';

export const register = (values, intl, history) => ({
    type: REGISTER,
    intl,
    history,
    payload: values,
});

export const setUserDataForRegistration = (userData) => ({
    type: SET_USERDATA_FOR_REGISTRATION,
    payload: userData,
});

export const deleteRegistrationUserData = () => ({
    type: DELETE_REGISTRATION_USERDATA,
});

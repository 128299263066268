import { all } from 'redux-saga/effects';

import { AccountSaga } from '../routes/Account/sagas';
import { ChangePasswordSaga } from '../routes/Account/ChangePassword/sagas';
import { RegistrationSaga } from '../routes/Account/Registration/sagas';
import { RequestPasswordResetSaga } from '../routes/Account/RequestPasswordReset/sagas';
import { ResetPasswordSaga } from '../routes/Account/ResetPassword/sagas';
import { LibrarySaga } from '../routes/Library/sagas';
import { LibrarySagaSeo } from '../routes/Library/sagasSeo';
import { abosSaga } from '../routes/AbosContainer/sagas';
import { registerSaga } from '../routes/TestAccountRegisterFormContainer/sagas';
import { LibrarySearchSaga } from '../containers/LibrarySearch/sagas';
import { HomeworkHelpSaga } from '../routes/HomeworkHelp/sagas';
import { examsSaga } from '../containers/Library/Exams/sagas';
import { examinationsSaga } from '../routes/Exams/sagas';
import { webinarsSaga } from '../routes/Webinars/sagas';
import { FeedbackSaga } from '../routes/Feedback/sagas';
import { CaptchaSaga } from '../utils/captcha/sagas';
import { profileSaga } from '../routes/Account/Profile/sagas';
import { registerBookcodeSaga } from '../routes/Account/RegisterBookcode/sagas';
import { extendBookcodeSaga } from '../routes/Account/ExtendBookcode/sagas';
import { favoriteSaga } from '../containers/FavoriteButton/sagas';
import { specialRegisterSaga } from '../routes/SpecialRegisterFormContainer/sagas';
import { knowledgeCheckRegisterSaga } from '../routes/KnowledgeCheckRegisterFormContainer/sagas';
import { schulaktionOfferModalSaga } from '../views/Modal/SchulaktionOfferModal/sagas';
import { audioSagas } from '../views/Audio/sagas';
import { stepwiseRegistrationSagas } from '../routes/Account/StepwiseRegistration/sagas';
import { verificationSagas } from '../routes/Account/Verification/sagas';
import { coursesSaga } from '../routes/Courses/sagas';
import { TerminationSaga } from '../routes/Termination/sagas';
import { lisaRegisterSaga } from '../routes/LisaRegisterFormContainer/sagas';
import { specialFourteenDaysRegisterSaga } from '../routes/SpecialFourteenDaysRegistration/sagas';
import { aiTutorSaga } from '../routes/AiTutor/sagas';
import { dashboardSaga } from '../containers/Dashboard/sagas';

export default function* rootSaga() {
    yield all([
        ...AccountSaga,
        ...ChangePasswordSaga,
        ...RegistrationSaga,
        ...RequestPasswordResetSaga,
        ...ResetPasswordSaga,
        ...LibrarySaga,
        ...abosSaga,
        ...registerSaga,
        ...LibrarySearchSaga,
        ...LibrarySagaSeo,
        ...HomeworkHelpSaga,
        ...examsSaga,
        ...examinationsSaga,
        ...webinarsSaga,
        ...FeedbackSaga,
        ...CaptchaSaga,
        ...profileSaga,
        ...registerBookcodeSaga,
        ...extendBookcodeSaga,
        ...favoriteSaga,
        ...specialRegisterSaga,
        ...knowledgeCheckRegisterSaga,
        ...schulaktionOfferModalSaga,
        ...audioSagas,
        ...stepwiseRegistrationSagas,
        ...verificationSagas,
        ...coursesSaga,
        ...TerminationSaga,
        ...lisaRegisterSaga,
        ...specialFourteenDaysRegisterSaga,
        ...aiTutorSaga,
        ...dashboardSaga,
    ]);
}

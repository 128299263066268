import {
    SET_NOVALNET_DATA,
} from './actions';

const initialState = {
    chosenProductId: null,
    novalnetData: null,
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
    case SET_NOVALNET_DATA:
        return {
            ...state,
            novalnetData: action.payload,
        };
    default:
        return state;
    }
};

export default reducer;

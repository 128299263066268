
export const VERIFY_CAPTCHA = 'utils/captcha/verify';
export const SET_CAPTCHA_VALID = 'utils/captcha/setValid';

export const verifyCaptcha = (response, intl) => ({
    type: VERIFY_CAPTCHA,
    payload: { response, intl },
});

export const setCaptchaValid = (valid) => ({
    type: SET_CAPTCHA_VALID,
    payload: valid,
});

/* eslint-disable camelcase */
import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchSaga } from '../../utils/network/fetch-saga';
import { getHeaders, getPortalBaseUrl } from '../../utils/utils';

import { ERROR, INFO } from '../../utils/constants';

import { CREATE_FEEDBACK, setFeedbackLoading, setFeedbackResult } from './actions';

import { addMessage } from '../../containers/MessageModal/actions';

export function* sendFeedback(action) {
    const { request, intl } = action.payload;

    const url = `${getPortalBaseUrl()}/zendesk`;
    const headers = getHeaders();

    try {
        yield put(setFeedbackLoading(true));

        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify(request) });

        if (data) {
            yield put(setFeedbackResult(true));
            yield put(addMessage(INFO, intl.formatMessage({ id: 'feedback.success' })));
        } else {
            yield put(addMessage(ERROR, intl.formatMessage({ id: 'feedback.error' })));
        }
    } catch (e) {
        yield put(addMessage(ERROR, intl.formatMessage({ id: 'feedback.error' })));
    }

    yield put(setFeedbackLoading(false));
}

function* watchSendFeedback() {
    yield takeEvery(CREATE_FEEDBACK, sendFeedback);
}

export const FeedbackSaga = [
    watchSendFeedback(),
];

/* eslint-disable camelcase */
import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchSaga } from '../../utils/network/fetch-saga';
import {
    getHeaders,
    getPortalBaseUrl,
    getIdpBaseUrl,
    getUploadHeaders,
    trackUserEvent,
} from '../../utils/utils';

import { ERROR, GA } from '../../utils/constants';

import {
    CREATE_REQUEST,
    GET_REQUESTS,
    GET_DETAILED_REQUEST,
    CREATE_ADDITIONAL_REQUEST,
    UPDATE_USER_ZENDESK_ID,
    UPLOAD_FILES,
    setRequestResult,
    setRequests,
    clearRequests,
    setDetailedRequest,
    setDetailedRequestStatus,
    setCreateRequestLoading,
    setLoading,
    setError,
    setAdditionalRequestResult,
    setAdditionalRequestLoading,
    setAdditionalRequestError,
    setShouldValidateUser,
    setUploadedFiles,
    setUploadFileLoading,
    setUploadFileError,
} from './actions';
import { setUser } from '../Account/actions';

import { addMessage } from '../../containers/MessageModal/actions';

export function* sendRequest(action) {
    const { request, intl } = action.payload;
    const { school_class, school_subject, question, type_of_school } = request;
    const headers = getHeaders(true);

    try {
        yield put(setCreateRequestLoading(true));

        const data = yield call(
            fetchSaga,
            `${getPortalBaseUrl()}/zendesk/odh`,
            { method: 'POST', headers, body: JSON.stringify(request) },
        );

        if (data) {
            const { id, url } = data;

            trackUserEvent({
                trackUA: true,
                trackGA: false,
                category: GA.CATEGORIES.ODH,
                action: GA.ACTIONS.ODH_REQUEST,
                label: `class: "${school_class}", school subject: "${school_subject}", `
                + `type of school: "${type_of_school}"`,
                value: `question: "${question}", id: ${id}, url: ${url}`,
            });

            yield put(setRequestResult(data));
        } else {
            yield put(addMessage(ERROR, intl.formatMessage({ id: 'homeworkHelp.request.error' })));
        }
    } catch (e) {
        yield put(addMessage(ERROR, intl.formatMessage({ id: 'homeworkHelp.request.error' })));
    }

    yield put(setCreateRequestLoading(false));
}

export function* getRequests(action) {
    const { page, pageSize, email, intl, clear } = action.payload;
    const url = `${getPortalBaseUrl()}/zendesk/odh/request/all?page=${page}&pageSize=${pageSize}&email=${email}`;
    const headers = getHeaders(true);

    try {
        yield put(setLoading(true));

        const data = yield call(fetchSaga, url, { method: 'GET', headers });

        if (data) {
            if (clear) {
                yield put(clearRequests());
            }

            yield put(setRequests(data));
        } else {
            yield put(setError(true));
        }
    } catch (e) {
        yield put(setError(true));
        if (e.message.includes('Status 401')) {
            yield put(setShouldValidateUser(true));
        }
        if (e.message.includes('server error 500')) {
            yield put(addMessage(ERROR, intl.formatMessage({ id: 'homeworkHelp.request.error' })));
        }
    }

    yield put(setLoading(false));
}

export function* getDetailedRequest(action) {
    const { requestId, email } = action.payload;
    const url = `${getPortalBaseUrl()}/zendesk/odh/${requestId}?email=${email}`;
    const headers = getHeaders(true);

    try {
        yield put(setDetailedRequestStatus(requestId, true, false));

        const data = yield call(fetchSaga, url, { method: 'GET', headers });

        if (data) {
            yield put(setDetailedRequest(data));
        }

        yield put(setDetailedRequestStatus(requestId, false, false));
    } catch (e) {
        yield put(setDetailedRequestStatus(requestId, false, true));
    }
}

export function* sendAdditionalRequest(action) {
    const { requestId, requester_email, comment, uploads, solved } = action.payload;

    const url = `${getPortalBaseUrl()}/zendesk/odh/${requestId}`;
    const headers = getHeaders(true);

    try {
        yield put(setAdditionalRequestLoading(true));

        const data = yield call(fetchSaga, url, {
            method: 'PUT',
            headers,
            body: JSON.stringify({ requester_email, comment, uploads, solved }),
        });

        if (data) {
            yield put(setAdditionalRequestResult(data));
        }

    } catch (e) {
        yield put(setAdditionalRequestError(true));
    }

    yield put(setAdditionalRequestLoading(false));
}

export function* updateZendeskId(action) {
    const { user_id, zendesk_id, intl } = action.payload;

    const url = `${getIdpBaseUrl()}/users/${user_id}/zendeskId?zendeskId=${zendesk_id}`;
    const headers = getHeaders(true);

    try {
        const data = yield call(fetchSaga, url, { method: 'PUT', headers });

        if (data) {
            yield put(setUser(data));
            yield put(setShouldValidateUser(false));
        }
    } catch (e) {
        yield put(addMessage(ERROR, intl.formatMessage({ id: 'homeworkHelp.request.error' })));
    }
}

export function* uploadFilesToZendesk(action) {
    const { filesToUpload, email, intl } = action.payload;

    const url = `${getPortalBaseUrl()}/zendesk/odh/upload?email=${email}`;
    const headers = getUploadHeaders(true);

    try {
        yield put(setUploadFileLoading(true));
        yield put(setUploadFileError(false));
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: filesToUpload });

        if (data) {
            yield put(setUploadedFiles(data));
        }
    } catch (e) {
        yield put(setUploadFileError(true));
        yield put(addMessage(ERROR, intl.formatMessage({ id: 'homeworkHelp.error.upload' })));
    }

    yield put(setUploadFileLoading(false));
}

function* watchSendRequest() {
    yield takeEvery(CREATE_REQUEST, sendRequest);
}

function* watchGetRequests() {
    yield takeEvery(GET_REQUESTS, getRequests);
}

function* watchGetDetailedRequest() {
    yield takeEvery(GET_DETAILED_REQUEST, getDetailedRequest);
}

function* watchSendAdditonalRequest() {
    yield takeEvery(CREATE_ADDITIONAL_REQUEST, sendAdditionalRequest);
}

function* watchUpdateZendeskId() {
    yield takeEvery(UPDATE_USER_ZENDESK_ID, updateZendeskId);
}

function* watchUploadFilesToZendesk() {
    yield takeEvery(UPLOAD_FILES, uploadFilesToZendesk);
}

export const HomeworkHelpSaga = [
    watchSendRequest(),
    watchGetRequests(),
    watchGetDetailedRequest(),
    watchSendAdditonalRequest(),
    watchUpdateZendeskId(),
    watchUploadFilesToZendesk(),
];

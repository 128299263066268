// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faCode } from '@fortawesome/free-solid-svg-icons/faCode';
import { faCoffee } from '@fortawesome/free-solid-svg-icons/faCoffee';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faHamburger } from '@fortawesome/free-solid-svg-icons/faHamburger';
import { faHeadphones } from '@fortawesome/free-solid-svg-icons/faHeadphones';
import { faHighlighter } from '@fortawesome/free-solid-svg-icons/faHighlighter';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons/faPaperclip';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { faPrint } from '@fortawesome/free-solid-svg-icons/faPrint';
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons/faRedoAlt';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faUndo } from '@fortawesome/free-solid-svg-icons/faUndo';
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo';
import { faChalkboard } from '@fortawesome/free-solid-svg-icons/faChalkboard';
import { faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons/faChalkboardTeacher';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass';

// imports all regular icons
// to get one regular icon use ['far', 'star']
import { faClock } from '@fortawesome/free-regular-svg-icons/faClock';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons/faStar';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons/faTrashAlt';

library.add(
    faBars,
    faCheck,
    faCheckSquare,
    faChevronDown,
    faChevronUp,
    faChevronRight,
    faChevronLeft,
    faClock,
    faCode,
    faCoffee,
    faDownload,
    faExclamationTriangle,
    faHamburger,
    faHeadphones,
    faHighlighter,
    faHome,
    faList,
    faMapMarkerAlt,
    faPaperclip,
    faPen,
    faPrint,
    faRedoAlt,
    faSignInAlt,
    faSignOutAlt,
    faSpinner,
    faStar,
    faStarRegular,
    faTimes,
    faTrashAlt,
    faUndo,
    faUser,
    faUsers,
    faVideo,
    faChalkboard,
    faChalkboardTeacher,
    faMagnifyingGlass,
);

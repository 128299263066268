// polyfills
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';

import React, { lazy, Suspense } from 'react';
import { render } from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { addLocaleData, IntlProvider } from 'react-intl';
import * as Sentry from '@sentry/browser';
import TagManager from 'react-server-side-gtm';
import queryString from 'query-string';

import en from 'react-intl/locale-data/en';
import de from 'react-intl/locale-data/de';

import history from './store/history';

import 'moment/locale/de';

import localeData from './utils/localeData';

import { storage } from './utils/storage';

import { getSaga } from './store/configureStore';
import store from './store/storeHolder';
import rootSaga from './store/rootSaga';

// css styles
import './index.css';
import './fontawesome';
import 'primeicons/primeicons.css';

import {
    flattenMessages,
    getUserLanguage,
    getQueryToken,
    isTokenPayloadValid,
    getLongestValidToken,
    isTokenExpired,
    inDevelopment,
    shouldRedirectToOlcStartPage,
    loadedInIframe,
    createCookieStringFromToken,
    componentLoader,
    isUserExternal,
    shouldRedirectToVidisLogin,
} from './utils/utils';
import { REFRESH_TOKEN_KEY } from './utils/constants';

const App = lazy(() => componentLoader(() => import('./App')));

const runApp = () => {

    if (!inDevelopment()) {
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            environment: process.env.REACT_APP_SENTRY_ENV,
        });
    }

    addLocaleData([...de, ...en]);

    /* Define user's language. Different browsers have the user locale defined
       on different fields on the `navigator` object, so we make sure to account
       for these different by checking all of them.
       Additionally beforehand we check if the user selected another language via
       the language switcher and saved it to local storage. */
    const language = getUserLanguage();

    // Split locales with a region code
    const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

    // Try full locale, fallback to locale without region code, fallback to de
    const messages = localeData[languageWithoutRegionCode] || localeData[language] || localeData.de;

    const saga = getSaga();

    saga.run(rootSaga);

    // redirect Novalnet callback out of iframe
    if (loadedInIframe()) {
        window.top.location.href = window.location.href;
    }

    // Check for an auth token in the url query parameters and local storage and auto login, if a valid token is present
    const queryToken = getQueryToken();
    const localToken = storage.getItem('token');

    const reLogin = () => {
        document.cookie = createCookieStringFromToken(storage.getItem('token'), true);
        storage.removeItem('token');
        // TODO: Clearify what behaviour is expected on token removal and implement it here
        // store.dispatch(openModal('LOGIN'));
    };

    if ((!!queryToken && isTokenPayloadValid(queryToken))
        && (!!localToken && isTokenPayloadValid(localToken))) {

        const longestValidToken = getLongestValidToken(localToken, queryToken);
        if (isTokenExpired(longestValidToken) && !storage.getItem(REFRESH_TOKEN_KEY)) {
            reLogin();
        } else {
            storage.setItem('token', longestValidToken);
            document.cookie = createCookieStringFromToken(longestValidToken);
            window.location = window.location.pathname;
            return;
        }

    } else if ((!!queryToken && isTokenPayloadValid(queryToken))) {
        if (isTokenExpired(queryToken) && !storage.getItem(REFRESH_TOKEN_KEY)) {
            reLogin();
        } else {
            storage.setItem('token', queryToken);
            document.cookie = createCookieStringFromToken(queryToken);
            window.location = window.location.pathname;
            return;
        }
    } else if (!!localToken && isTokenPayloadValid(localToken)) {
        if (isTokenExpired(localToken) && !storage.getItem(REFRESH_TOKEN_KEY)) {
            reLogin();
        }
    }

    // check if user is logged in and redirect to olc-start-page if needed
    const token = storage.getItem('token');
    if (!token || (isTokenExpired(token) && !storage.getItem(REFRESH_TOKEN_KEY))) {
        // check if user is redirected from vidis, vidis sends request with code parameter
        const params = queryString.parse(history.location.search);
        if (shouldRedirectToOlcStartPage(false) && !params.code) {
            window.location.href = window.location.href.includes('.at/')
                ? process.env.REACT_APP_TYPO3_START_PAGE_URL.replace('.de/', '.at/')
                : process.env.REACT_APP_TYPO3_START_PAGE_URL;
        }
    }

    if (shouldRedirectToVidisLogin(!!token && !isTokenExpired(token))) {
        window.location.href = process.env.REACT_APP_VIDIS_LOGIN_URL;
    }

    if (!isUserExternal()) {
        TagManager.initialize({
            gtmId: 'GTM-P5GX7DQ',
        });
        TagManager.initialize({
            gtmId: 'GTM-WP99DT4',
            src: 'https://x.schuelerhilfe.de',
        });
    }

    render(
        <IntlProvider locale={language} messages={flattenMessages(messages)}>
            <Provider store={store}>
                <Router history={history}>
                    <Suspense fallback={<div />}>
                        <App />
                    </Suspense>
                </Router>
            </Provider>
        </IntlProvider>,
        document.getElementById('root'),
    );
};

runApp();

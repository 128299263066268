// Global imports.
import { call, put, takeEvery } from 'redux-saga/effects';

// Utils, actions, sagas, etc. .
import {
    GET_STUDENT_DATA,
    ADD_USER_TO_EXAM,
    CREATE_STUDENT_AND_ADD_TO_EXAM,
    CREATE_OLAT_STUDENT_AND_ADD_TO_EXAM,
    setStudentExists,
    getStudentDataError,
    addUserToExam,
    REDIRECT_TO_OPEN_OLAT,
} from './actions';
import {
    getPortalBaseUrl,
    getExamsBaseUrl,
    getOlatExamsBaseUrl,
    getLanguageCourseBaseUrl,
    getHeaders,
    getUserData,
    handleUserWithoutEmailAddress,
} from '../../../utils/utils';
import { ERROR, OPEN_OLAT_INSTANCE } from '../../../utils/constants';
import { addMessage } from '../../MessageModal/actions';
import { fetchSaga } from '../../../utils/network/fetch-saga';
import { saveUser } from '../../../routes/Account/actions';

// Exams sagas

export function* authenticateOlatUser(username, userId, olatInstance) {
    const baseUrl = getPortalBaseUrl();
    const url = `${baseUrl}/openolat/authenticate`;
    const headers = getHeaders(true);

    const email = handleUserWithoutEmailAddress(username);

    const body = JSON.stringify({
        username: email,
        userId,
        olatInstance,
    });

    const response = yield call(fetchSaga, url, { method: 'POST', headers, body });
    return response.value; // X-OLAT-TOKEN
}

export function* handleGetStudentData(action) {
    const baseUrl = getPortalBaseUrl();
    const url = `${baseUrl}/exams/student?email=${action.payload}`;
    const headers = getHeaders(true);

    try {
        const data = yield call(fetchSaga, url, { method: 'GET', headers });
        if (data) {
            yield put(setStudentExists(true));
        }
    } catch (e) {
        yield put(setStudentExists(false));
        if (e.message === 'server error') {
            yield put(getStudentDataError(action.intl.messages['errorMessages.serverError']));
        } else if (e.message === 'network error') {
            yield put(getStudentDataError(action.intl.messages['errorMessages.networkError']));
        }
    }
}

export function* handleAddUserToExam(action) {
    const baseUrl = getPortalBaseUrl();
    const url = `${baseUrl}/exams/`;
    const headers = getHeaders(true);
    try {
        const windowReference = action.windowReference || window.open();
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify(action.payload) });
        if (data) {
            if (action.isOpenOlatExam) {
                const { openOlatUsername, payload: { userId } } = action;
                const xOlatToken = yield call(authenticateOlatUser, openOlatUsername, userId);
                windowReference.location = `${getOlatExamsBaseUrl()}/${data.hash}?X-OLAT-TOKEN=${xOlatToken}`;
            } else {
                windowReference.location = `${getExamsBaseUrl()}/${data.hash}`;
            }
        }
    } catch (e) {
        if (!e.message) {
            yield put(addMessage(ERROR,
                action.intl.formatMessage({ id: 'errorMessages.addStudentToExaminationError' })));
        } else if (e.message === 'server error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (e.message === 'network error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else if (!(e.message.startsWith('ERROR'))) {
            yield put(addMessage(ERROR, e.message));
        }
    }
}

export function* handleRedirectToOpenOlat(action) {
    const { openOlatId, email, olatInstance, examId, windowReference } = action.payload;
    const xOlatToken = yield call(authenticateOlatUser, email, openOlatId, olatInstance);
    const openOlatBaseUrl = OPEN_OLAT_INSTANCE.OPEN_OLAT_LANGUAGE_COURSES === olatInstance
        ? getLanguageCourseBaseUrl()
        : getOlatExamsBaseUrl();
    windowReference.location = `${openOlatBaseUrl}/${examId}?X-OLAT-TOKEN=${xOlatToken}`;
}

export function* handleCreateStudentAndAddToExamination(action) {
    const baseUrl = getPortalBaseUrl();
    const url = `${baseUrl}/exams/student`;
    const headers = getHeaders(true);
    const studentData = {
        email: action.payload.email,
        firstname: action.payload.firstname,
        lastname: action.payload.lastname,
        schooltype: action.payload.schooltype,
        classlevel: action.payload.classlevel,
        location: action.payload.location,
        properties: [
            {
                name: 'birthDay',
                value: action.payload.birthday,
            },
        ],
    };

    try {
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify(studentData) });
        if (data) {
            yield put(setStudentExists(true));
            yield put(addUserToExam(action.payload.email, action.payload.examination_id,
                action.intl.messages['examsAdmin.addStudentSuccess'], false));
        }
    } catch (e) {
        if (!e.message) {
            yield put(addMessage(ERROR,
                action.intl.formatMessage({ id: 'errorMessages.addStudentToExaminationError' })));
        } else if (e.message === 'server error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (e.message === 'network error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else if (!(e.message.startsWith('ERROR'))) {
            yield put(addMessage(ERROR, e.message));
        }
    }
}

export function* handleCreateOlatStudentAndAddToExam(action) {
    const baseUrl = getPortalBaseUrl();
    const url = `${baseUrl}/exams/olatStudent`;
    const headers = getHeaders(true);
    const { user, examId, intl, isCourse } = action;
    let { payload: { email, firstname, lastname } } = action;
    const { payload: { birthday } } = action;
    try {
        const windowReference = window.open();
        if (!email.includes('@') && email.startsWith('lisa-nutzer')) {
            email = `${email}@lisa-olc.de`;
            firstname = 'Lisa';
            lastname = 'Nutzer';
        } else if (!email.includes('@') && email.startsWith('vidis-nutzer')) {
            email = `${email}@vidis-olc.de`;
            firstname = 'Vidis';
            lastname = 'Nutzer';
        }

        const properties = [
            {
                name: 'birthDay',
                value: birthday,
            },
        ];

        const body = JSON.stringify({ email, firstname, lastname, properties });
        const openOlatId = yield call(fetchSaga, url, { method: 'POST', headers, body });
        if (openOlatId) {
            const userData = { ...getUserData(user), openOlatId };
            yield put(saveUser(user.id, userData, intl, false));
            yield put(addUserToExam(email, openOlatId, examId, true, intl, windowReference, isCourse));
        }
    } catch (e) {
        if (!e.message) {
            yield put(addMessage(ERROR, intl.formatMessage({ id: 'errorMessages.addStudentToExaminationError' })));
        } else if (e.message === 'server error') {
            yield put(addMessage(ERROR, intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (e.message === 'network error') {
            yield put(addMessage(ERROR, intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else if (!(e.message.startsWith('ERROR'))) {
            yield put(addMessage(ERROR, e.message));
        }
    }
}

export function* waitForStudentDataWasFetched() {
    yield takeEvery(GET_STUDENT_DATA, handleGetStudentData);
}

export function* waitForUserWasAddedToExam() {
    yield takeEvery(ADD_USER_TO_EXAM, handleAddUserToExam);
}

export function* waitForUserWasCreatedAndAddedToExam() {
    yield takeEvery(CREATE_STUDENT_AND_ADD_TO_EXAM, handleCreateStudentAndAddToExamination);
}

export function* waitForOlatUserWasCreatedAndAddedToExam() {
    yield takeEvery(CREATE_OLAT_STUDENT_AND_ADD_TO_EXAM, handleCreateOlatStudentAndAddToExam);
}

export function* waitForRedirectToOpenOlat() {
    yield takeEvery(REDIRECT_TO_OPEN_OLAT, handleRedirectToOpenOlat);
}

export const examsSaga = [
    waitForStudentDataWasFetched(),
    waitForUserWasAddedToExam(),
    waitForUserWasCreatedAndAddedToExam(),
    waitForOlatUserWasCreatedAndAddedToExam(),
    waitForRedirectToOpenOlat(),
];

const storageAvailable = (type) => {
    try {
        const storage = type === 'local' ? localStorage : sessionStorage;
        storage.setItem('test', 'test');
        storage.removeItem('test');
        return true;
    } catch (e) {
        return false;
    }
};

class StorageWrapper {

    constructor() {
        this.storageAvailable = false;
        this.activeStorage = storageAvailable('local') ? localStorage : null;
        if (!this.activeStorage) {
            this.activeStorage = storageAvailable('session') ? sessionStorage : null;
        }

        if (!!this.activeStorage) {
            this.storageAvailable = true;
        }
    }

    isAvailable = () => (
        this.storageAvailable
    )

    setItem = (name, value) => {
        if (this.storageAvailable) {
            this.activeStorage.setItem(name, value);
        }
    };

    getItem = (name) => {
        if (this.storageAvailable) {
            return this.activeStorage.getItem(name);
        }

        return null;
    };

    removeItem = (name) => {
        if (this.storageAvailable) {
            this.activeStorage.removeItem(name);
        }
    };

    clear = () => {
        if (this.storageAvailable) {
            this.activeStorage.clear();
        }
    }

}

export const storage = new StorageWrapper();

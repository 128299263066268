// react and external libraries
import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchSaga } from '../../../utils/network/fetch-saga';

// own components
import { getIdpBaseUrl, getHeaders } from '../../../utils/utils';

// constants
import { INFO, ERROR } from '../../../utils/constants';

import { REQUEST_PASSWORD_RESET } from './actions';
import { addMessage } from '../../../containers/MessageModal/actions';

function* requestPasswordReset(action) {
    const { history, intl, email } = action.payload;
    const url = `${getIdpBaseUrl()}/account/send-recover-password-mail`;
    const headers = getHeaders();

    try {
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify({ email }) });

        if (data) {
            yield put(addMessage(INFO, intl.messages['requestPasswordReset.successMessage']));
            history.push('/login');
        }
    } catch (e) {
        yield put(addMessage(ERROR, intl.messages['requestPasswordReset.errorMessage']));
    }
}

function* watchUserRequestPasswordReset() {
    yield takeEvery(REQUEST_PASSWORD_RESET, requestPasswordReset);
}

export const RequestPasswordResetSaga = [
    watchUserRequestPasswordReset(),
];

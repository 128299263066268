export const REGISTER_USER = 'routes/SpecialFourteenDaysRegistration/registerUser';
export const REGISTER_USER_SUCCESS = 'routes/SpecialFourteenDaysRegistration/registerUserSuccess';
export const RELEASE_BUTTON = 'routes/SpecialFourteenDaysRegistration/releaseButton';

export const registerUser = (userData) => ({
    type: REGISTER_USER,
    payload: userData,
});

export const registerUserSuccess = (value) => ({
    type: REGISTER_USER_SUCCESS,
    payload: value,
});

export const releaseButton = (value) => ({
    type: RELEASE_BUTTON,
    payload: value,
});

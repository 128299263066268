// Exams reducer

import {
    SET_EXAMS_ADMIN,
    GET_USER_EXAM_DATA,
    GET_USER_EXAM_DATA_ERROR,
    SET_USER_EXAM_DATA,
    SET_USER_ASSESSMENT_ENTRIES,
    SET_USER_HAS_NO_EXAM_DATA,
    SET_IS_USER_EXAM_DATA_BEING_FETCHED,
    GET_EXAMINATION_DATA,
    GET_EXAMINATION_DATA_ERROR,
    SET_EXAMINATION_DATA,
    GET_EXAMINATION_PREVIEW_LINK,
    GET_EXAMINATION_PREVIEW_LINK_ERROR,
    SET_EXAMINATION_PREVIEW_LINK,
    RESET_EXAMINATION_PREVIEW_LINK,
    GET_STUDENT_DATA,
    GET_STUDENT_DATA_ERROR,
    SET_STUDENT_EXISTS,
    GET_USER_CENTERS,
    GET_USER_CENTERS_ERROR,
    SET_USER_CENTERS,
    GET_CENTER_USERS,
    GET_CENTER_USERS_ERROR,
    SET_CENTER_USERS,
    SET_SUBJECT_FILTER,
    SET_CLASSLEVEL_FILTER,
    SET_LEVEL_FILTER,
    SET_SHOW_LOADING_MODAL,
    SET_USER_X_OLAT_TOKEN,
    SET_IS_USER_X_OLAT_TOKEN_BEING_FETCHED,
    SET_USER_LANUAGE_COURSE_EXAM_DATA,
    SET_USER_INTENSIVE_COURSE_EXAM_DATA,
    GET_USER_CENTERS_CENTER_SELECTED,
    SET_COURSES,
    ADD_USER_TO_EXAM,
    SET_ADD_USER_TO_EXAM_REQUEST_RUNNING,
} from './actions';

const initialState = {
    examsAdmin: {},
    exams: [],
    assessmentEntries: {},
    languageCourseExams: [],
    onlineIntensiveCourseExams: [],
    userHasNoExamData: false,
    isUserExamDataBeingFetched: false,
    examinations: [],
    userCenters: [],
    centerUsers: [],
    subjectFilter: '',
    classlevelFilter: '',
    levelFilter: '',
    messages: [],
    examinationPreviewLink: null,
    isOpenOlatExam: null,
    studentExists: false,
    centerUsersRequestRunning: false,
    centerSelected: false,
    showLoadingModal: false,
    userXOlatToken: '',
    isUserXOlatTokenBeingFetched: false,
    courses: [],
    addUserToExamRequestRunning: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case SET_EXAMS_ADMIN:
        return {
            ...state,
            examsAdmin: action.examsAdmin,
        };
    case GET_USER_EXAM_DATA:
        return state;
    case GET_USER_EXAM_DATA_ERROR:
    case GET_EXAMINATION_DATA_ERROR:
    case GET_EXAMINATION_PREVIEW_LINK_ERROR:
    case GET_STUDENT_DATA_ERROR:
    case GET_USER_CENTERS_ERROR:
        return {
            ...state,
            messages: [{
                severity: 'error',
                summary: 'Fehler',
                detail: action.payload,
            }],
        };
    case SET_USER_EXAM_DATA:
        return {
            ...state,
            exams: action.payload,
        };
    case SET_USER_ASSESSMENT_ENTRIES:
        return {
            ...state,
            assessmentEntries: {
                ...state.assessmentEntries,
                [action.payload.repoEntryKey]: action.payload.assessmentEntries,
            },
        };
    case SET_USER_LANUAGE_COURSE_EXAM_DATA:
        return {
            ...state,
            languageCourseExams: action.payload,
        };
    case SET_USER_INTENSIVE_COURSE_EXAM_DATA:
        return {
            ...state,
            onlineIntensiveCourseExams: action.payload,
        };
    case SET_USER_HAS_NO_EXAM_DATA:
        return {
            ...state,
            userHasNoExamData: action.payload,
        };
    case SET_IS_USER_EXAM_DATA_BEING_FETCHED:
        return {
            ...state,
            isUserExamDataBeingFetched: action.payload,
        };
    case SET_ADD_USER_TO_EXAM_REQUEST_RUNNING:
        return {
            ...state,
            addUserToExamRequestRunning: action.payload,
        };
    case SET_COURSES:
        return {
            ...state,
            courses: action.payload,
        };
    case GET_EXAMINATION_DATA:
        return state;
    case SET_EXAMINATION_DATA:
        return {
            ...state,
            examinations: action.payload,
        };
    case GET_EXAMINATION_PREVIEW_LINK:
        return state;
    case SET_EXAMINATION_PREVIEW_LINK:
        return {
            ...state,
            examinationPreviewLink: action.examinationPreviewLink,
            isOpenOlatExam: action.isOpenOlatExam,
        };
    case RESET_EXAMINATION_PREVIEW_LINK:
        return {
            ...state,
            examinationPreviewLink: null,
            isOpenOlatExam: null,
        };
    case GET_STUDENT_DATA:
        return state;
    case SET_STUDENT_EXISTS:
        return {
            ...state,
            studentExists: action.payload,
        };
    case GET_USER_CENTERS:
        return state;
    case SET_USER_CENTERS:
        return {
            ...state,
            userCenters: action.payload,
        };
    case GET_USER_CENTERS_CENTER_SELECTED:
        return {
            ...state,
            centerSelected: action.payload !== null,
        };
    case GET_CENTER_USERS:
        return {
            ...state,
            centerUsersRequestRunning: true,
        };
    case GET_CENTER_USERS_ERROR:
        return {
            ...state,
            messages: [{
                severity: 'error',
                summary: 'Fehler',
                detail: action.payload,
            }],
            centerUsersRequestRunning: false,
        };
    case SET_CENTER_USERS:
        return {
            ...state,
            centerUsers: action.payload,
            centerUsersRequestRunning: false,
        };
    case SET_SUBJECT_FILTER:
        return {
            ...state,
            subjectFilter: action.payload,
        };
    case SET_CLASSLEVEL_FILTER:
        return {
            ...state,
            classlevelFilter: action.payload,
        };
    case SET_LEVEL_FILTER:
        return {
            ...state,
            levelFilter: action.payload,
        };
    case SET_SHOW_LOADING_MODAL:
        return {
            ...state,
            showLoadingModal: action.payload,
        };
    case SET_USER_X_OLAT_TOKEN:
        return {
            ...state,
            userXOlatToken: action.payload,
        };
    case SET_IS_USER_X_OLAT_TOKEN_BEING_FETCHED:
        return {
            ...state,
            isUserXOlatTokenBeingFetched: action.payload,
        };
    case ADD_USER_TO_EXAM:
        return {
            ...state,
            addUserToExamRequestRunning: true,
        };
    default:
        return state;
    }
}

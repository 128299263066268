// ExtendBookcode sagas

import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchSaga } from '../../../utils/network/fetch-saga';
import { getIdpBaseUrl, getHeaders } from '../../../utils/utils';

// constants
import { ERROR } from '../../../utils/constants';

import {
    EXTEND_BOOKCODE,
    extendBookcodeSuccess,
    GET_CENTERS,
    setCenters,
} from './actions';
import { addMessage } from '../../../containers/MessageModal/actions';

// Workers
export function* extendBookcode(action) {
    const baseUrl = getIdpBaseUrl();
    const url = `${baseUrl}/account/bookcode`;
    const headers = getHeaders();
    try {
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify(action.payload) });
        if (data) {
            yield put(extendBookcodeSuccess());
        }
    } catch (e) {
        if (e.message === 'server error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (e.message === 'network error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else {
            yield put(addMessage(ERROR, e.message));
        }
    }
}

export function* getCenters(action) {
    const baseUrl = getIdpBaseUrl();
    let url = `${baseUrl}/centers?pageSize=1000`;
    const headers = getHeaders();
    try {
        let data = yield call(fetchSaga, url, { method: 'GET', headers });
        if (data) {
            let centers = data.items;
            while (data.page && data.page_count && data.page < data.page_count) {
                url = `${baseUrl}/centers?page=${data.page + 1}&pageSize=1000`;
                data = yield call(fetchSaga, url, { method: 'GET', headers });
                if (data) {
                    centers = [...centers, ...data.items];
                }
            }
            yield put(setCenters(centers));
        }
    } catch (e) {
        if (e.message === 'server error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (e.message === 'network error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else {
            yield put(addMessage(ERROR, e.message));
        }
    }
}

// Watchers
export function* waitForBookcodeWasExtended() {
    yield takeEvery(EXTEND_BOOKCODE, extendBookcode);
}

export function* waitForCentersWereFetched() {
    yield takeEvery(GET_CENTERS, getCenters);
}

export const extendBookcodeSaga = [
    waitForCentersWereFetched(),
    waitForBookcodeWasExtended(),
];

// Exams action types and creators
export const INITIALIZE_EXAMS_ROUTE = 'routes/Exams/initializeExamsRoute';
export const GET_USER_EXAM_DATA = 'routes/Exams/getUserExamData';
export const GET_USER_EXAM_DATA_ERROR = 'routes/Exams/getUserExamDataError';
export const GET_USER_ASSESSMENT_ENTRIES = 'routes/Exams/getUserAssessmentEntries';
export const SET_USER_ASSESSMENT_ENTRIES = 'routes/Exams/setUserAssessmentEntries';
export const SET_USER_EXAM_DATA = 'routes/Exams/setUserExamData';
export const SET_USER_LANUAGE_COURSE_EXAM_DATA = 'routes/Exams/setUserLanguageCourseExamData';
export const SET_USER_INTENSIVE_COURSE_EXAM_DATA = 'routes/Exams/setUserOnlineIntensiveCourseExamData';
export const SET_USER_HAS_NO_EXAM_DATA = 'routes/Exams/setUserHasNoExamData';
export const SET_IS_USER_EXAM_DATA_BEING_FETCHED = 'routes/Exams/setIsUserExamDataBeingFetched';
export const SET_ADD_USER_TO_EXAM_REQUEST_RUNNING = 'routes/Exams/setAddUserToExamRequestRunning';
export const GET_EXAMINATIONS_FOR_SUBJECT = 'routes/Exams/getExaminationsForSubject';
export const GET_EXAMINATION_DATA = 'routes/Exams/getExaminationData';
export const GET_EXAMINATION_DATA_ERROR = 'routes/Exams/getExaminationDataError';
export const SET_EXAMINATION_DATA = 'routes/Exams/setExaminationData';
export const GET_EXAMINATION_PREVIEW_LINK = 'routes/Exams/getExaminationPreviewLink';
export const GET_EXAMINATION_PREVIEW_LINK_ERROR = 'routes/Exams/getExaminationPreviewLinkError';
export const SET_EXAMINATION_PREVIEW_LINK = 'routes/Exams/setExaminationPreviewLink';
export const RESET_EXAMINATION_PREVIEW_LINK = 'routes/Exams/resetExaminationPreviewLink';
export const OPEN_EXAMINATION_PREVIEW_LINK = 'routes/Exams/openExaminationPreviewLink';
export const GET_STUDENT_DATA = 'routes/Exams/getStudentData';
export const GET_STUDENT_DATA_ERROR = 'routes/Exams/getStudentDataError';
export const SET_STUDENT_EXISTS = 'routes/Exams/setStudentExists';
export const GET_USER_CENTERS_CENTER_SELECTED = 'routes/Exams/getUserCentersCenterSelected';
export const GET_USER_CENTERS = 'routes/Exams/getUserCenters';
export const GET_USER_CENTERS_ERROR = 'routes/Exams/getUserCentersError';
export const SET_USER_CENTERS = 'routes/Exams/setUserCenters';
export const GET_CENTER_USERS = 'routes/Exams/getCenterUsers';
export const GET_CENTER_USERS_ERROR = 'routes/Exams/getCenterUsersError';
export const SET_CENTER_USERS = 'routes/Exams/setCentersUsers';
export const SET_SUBJECT_FILTER = 'routes/Exams/setSubjectFilter';
export const SET_CLASSLEVEL_FILTER = 'routes/Exams/setClasslevelFilter';
export const SET_LEVEL_FILTER = 'routes/Exams/setLevelFilter';
export const ADD_USER_TO_EXAM = 'routes/Exams/addUserToExam';
export const CREATE_STUDENT_AND_ADD_TO_EXAM = 'routes/Exams/createStudentAndAddToExam';
export const CREATE_OLAT_STUDENT_AND_ADD_TO_EXAM = 'routes/Exams/createOlatStudentAndAddToExam';
export const RESET_USER_EXAM = 'routes/Exams/resetUserExam';
export const SET_SHOW_LOADING_MODAL = 'containers/Library/Exams/setShowLoadingModal';
export const GET_USER_X_OLAT_TOKEN = 'containers/Library/Exams/getUserXOlatToken';
export const SET_USER_X_OLAT_TOKEN = 'containers/Library/Exams/setUserXOlatToken';
export const SET_IS_USER_X_OLAT_TOKEN_BEING_FETCHED = 'containers/Library/Exams/setIsUserXOlatTokenBeingFetched';
export const DOWNLOAD_RESULTS_PDF = 'routes/Exams/downloadResultsPdf';
export const CHECK_RESULTS_EXPORTS = 'routes/Exams/checkResultsExports';
export const CREATE_OLAT_TEACHER = 'routes/Exams/createOlatTeacher';
export const GET_EXAMS_ADMIN = 'routes/Exams/getExamsAdmin';
export const SET_EXAMS_ADMIN = 'routes/Exams/setExamsAdmin';
export const SET_COURSES = 'routes/Exams/setCourses';

export function initializeExamsRoute(user, userId, isUserExamsAdmin, subject, intl) {
    return {
        type: INITIALIZE_EXAMS_ROUTE,
        user,
        userId,
        isUserExamsAdmin,
        subject,
        intl,
    };
}

export function getUserExamData(
    email,
    openOlat,
    category,
    subjects,
    intl,
    isForDashboard,
    isOnlineInensiveCourse,
    olatExamType,
) {
    return {
        type: GET_USER_EXAM_DATA,
        payload: {
            email,
            openOlat,
            category,
            subjects,
            isForDashboard,
            isOnlineInensiveCourse,
            olatExamType,
        },
        intl,
    };
}

export function getUserExamDataError(message) {
    return {
        type: GET_USER_EXAM_DATA_ERROR,
        payload: message,
    };
}

export function setUserExamData(examData) {
    return {
        type: SET_USER_EXAM_DATA,
        payload: examData,
    };
}

export function getUserAssessmentEntries(openOlatInstance, repoEntryKey, studentKey) {
    return {
        type: GET_USER_ASSESSMENT_ENTRIES,
        payload: {
            openOlatInstance,
            repoEntryKey,
            studentKey,
        },
    };
}

export function setUserAssessmentEntries(repoEntryKey, assessmentEntries) {
    return {
        type: SET_USER_ASSESSMENT_ENTRIES,
        payload: {
            repoEntryKey,
            assessmentEntries,
        },
    };
}

export function setUserLanguageCourseExamData(examData) {
    return {
        type: SET_USER_LANUAGE_COURSE_EXAM_DATA,
        payload: examData,
    };
}

export function setUserOnlineIntensiveCourseExamData(examData) {
    return {
        type: SET_USER_INTENSIVE_COURSE_EXAM_DATA,
        payload: examData,
    };
}

export function setUserHasNoExamData(userHasNoExamData) {
    return {
        type: SET_USER_HAS_NO_EXAM_DATA,
        payload: userHasNoExamData,
    };
}

export function setIsUserExamDataBeingFetched(isUserExamDataBeingFetched) {
    return {
        type: SET_IS_USER_EXAM_DATA_BEING_FETCHED,
        payload: isUserExamDataBeingFetched,
    };
}

export function setAddUserToExamRequestRunning(isAddUserToExamRequestRunning) {
    return {
        type: SET_ADD_USER_TO_EXAM_REQUEST_RUNNING,
        payload: isAddUserToExamRequestRunning,
    };
}

export function getExaminationsForSubject(productShort, subject, intl) {
    return {
        type: GET_EXAMINATIONS_FOR_SUBJECT,
        productShort,
        subject,
        intl,
    };
}

export function getExaminationData(intl) {
    return {
        type: GET_EXAMINATION_DATA,
        intl,
    };
}

export function getExaminationDataError(message) {
    return {
        type: GET_EXAMINATION_DATA_ERROR,
        payload: message,
    };
}

export function setExaminationData(examinationData) {
    return {
        type: SET_EXAMINATION_DATA,
        payload: examinationData,
    };
}

export function getExaminationPreviewLink(examinationId, intl) {
    return {
        type: GET_EXAMINATION_PREVIEW_LINK,
        payload: examinationId,
        intl,
    };
}

export function getExaminationPreviewLinkError(message) {
    return {
        type: GET_EXAMINATION_PREVIEW_LINK_ERROR,
        payload: message,
    };
}

export function setExaminationPreviewLink(examinationPreviewLink, isOpenOlatExam) {
    return {
        type: SET_EXAMINATION_PREVIEW_LINK,
        examinationPreviewLink,
        isOpenOlatExam,
    };
}

export function resetExaminationPreviewLink() {
    return {
        type: RESET_EXAMINATION_PREVIEW_LINK,
    };
}

export function openExaminationPreviewLink(repoEntryKey, olatUsername, olatUserId, intl) {
    return {
        type: OPEN_EXAMINATION_PREVIEW_LINK,
        repoEntryKey,
        olatUsername,
        olatUserId,
        intl,
    };
}

export function getStudentData(email, intl) {
    return {
        type: GET_STUDENT_DATA,
        payload: email,
        intl,
    };
}

export function getStudentDataError(message) {
    return {
        type: GET_STUDENT_DATA_ERROR,
        payload: message,
    };
}

export function setStudentExists(studentExists) {
    return {
        type: SET_STUDENT_EXISTS,
        payload: studentExists,
    };
}

export function getUserCenters(userId, intl) {
    return {
        type: GET_USER_CENTERS,
        payload: userId,
        intl,
    };
}

export function getUserCentersError(message) {
    return {
        type: GET_USER_CENTERS_ERROR,
        payload: message,
    };
}

export function setUserCenters(userCenters) {
    return {
        type: SET_USER_CENTERS,
        payload: userCenters,
    };
}

export function getUserCentersCenterSelected(centerNr) {
    return {
        type: GET_USER_CENTERS_CENTER_SELECTED,
        payload: centerNr,
    };
}

export function getCenterUsers(centerId, payload, intl) {
    return {
        type: GET_CENTER_USERS,
        centerId,
        payload,
        intl,
    };
}

export function getCenterUsersError(message) {
    return {
        type: GET_CENTER_USERS_ERROR,
        payload: message,
    };
}

export function setCenterUsers(centerUsers) {
    return {
        type: SET_CENTER_USERS,
        payload: centerUsers,
    };
}

export function setSubjectFilter(subjectFilter) {
    return {
        type: SET_SUBJECT_FILTER,
        payload: subjectFilter,
    };
}

export function setClasslevelFilter(classlevelFilter) {
    return {
        type: SET_CLASSLEVEL_FILTER,
        payload: classlevelFilter,
    };
}

export function setLevelFilter(levelFilter) {
    return {
        type: SET_LEVEL_FILTER,
        payload: levelFilter,
    };
}

export function addUserToExam(email, openOlatId, examination, isOpenOlatExam, intl, isCourse, olatInstance,
    showMessage) {
    return {
        type: ADD_USER_TO_EXAM,
        payload: {
            email: isOpenOlatExam ? null : email, // Only email: Old exam platform.
            userId: isOpenOlatExam ? openOlatId : null, // Only userId: OpenOlat exam platform.
            examination_id: examination,
            send_mails: false,
            isCourse,
            olatInstance,
        },
        showMessage,
        intl,
    };
}

export function createStudentAndAddToExam(email, firstname, lastname, schooltype, classlevel, location, birthday,
    examination, intl) {
    return {
        type: CREATE_STUDENT_AND_ADD_TO_EXAM,
        payload: {
            email,
            firstname,
            lastname,
            schooltype: !!schooltype ? schooltype : 'Grundschule',
            classlevel: !!classlevel ? classlevel : '1',
            location,
            birthday,
            examination_id: examination,
        },
        intl,
    };
}

export function createOlatStudentAndAddToExam(user, examId, intl, isCourse, olatInstance, showMessage) {
    return {
        type: CREATE_OLAT_STUDENT_AND_ADD_TO_EXAM,
        payload: {
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            birthday: user.birthdate,
            olatInstance,
        },
        user,
        examId,
        intl,
        isCourse,
        showMessage,
    };
}

export function createOlatTeacher(user, intl) {
    return {
        type: CREATE_OLAT_TEACHER,
        payload: {
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            birthday: user.birthdate,
        },
        user,
        intl,
    };
}

export function resetUserExam(email, hash, subject, intl) {
    return {
        type: RESET_USER_EXAM,
        payload: {
            email,
            hash,
            subject,
        },
        intl,
    };
}

export function setShowLoadingModal(showLoadingModal) {
    return {
        type: SET_SHOW_LOADING_MODAL,
        payload: showLoadingModal,
    };
}

export function getUserXOlatToken(username, userId, openOlatInstance) {
    return {
        type: GET_USER_X_OLAT_TOKEN,
        payload: {
            username,
            userId,
            openOlatInstance,
        },
    };
}

export function setUserXOlatToken(xOlatToken) {
    return {
        type: SET_USER_X_OLAT_TOKEN,
        payload: xOlatToken,
    };
}

export function setIsUserXOlatTokenBeingFetched(isUserXOlatTokenBeingFetched) {
    return {
        type: SET_IS_USER_X_OLAT_TOKEN_BEING_FETCHED,
        payload: isUserXOlatTokenBeingFetched,
    };
}

export function downloadResultsPdf(originalFilename, downloadFilename, intl) {
    return {
        type: DOWNLOAD_RESULTS_PDF,
        originalFilename,
        downloadFilename,
        intl,
    };
}

export function checkResultsExports(exams) {
    return {
        type: CHECK_RESULTS_EXPORTS,
        exams,
    };
}

export function getExamsAdmin(userId, intl) {
    return {
        type: GET_EXAMS_ADMIN,
        userId,
        intl,
    };
}

export function setExamsAdmin(user) {
    return {
        type: SET_EXAMS_ADMIN,
        examsAdmin: user,
    };
}

export function setCourses(courses) {
    return {
        type: SET_COURSES,
        payload: courses,
    };
}

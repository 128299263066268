// TestAccountRegisterFormContainer sagas

import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchSaga } from '../../../utils/network/fetch-saga';

import { getHeaders } from '../../../utils/utils';

// constants
import { ERROR } from '../../../utils/constants';

import {
    REGISTER_BOOKCODE,
    registerBookcodeSuccess,
} from './actions';
import { addMessage } from '../../../containers/MessageModal/actions';

// Helpers
export function getIdpBaseUrl() {
    return process.env.REACT_APP_API_IDP_URL;
}

// Workers
export function* registerBookcode(action) {
    const baseUrl = getIdpBaseUrl();
    const url = `${baseUrl}/account/register-bookcode`;
    const headers = getHeaders();
    try {
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify(action.payload) });
        if (data) {
            yield put(registerBookcodeSuccess());
        }
    } catch (e) {
        if (e.message === 'server error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (e.message === 'network error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else {
            yield put(addMessage(ERROR, e.message));
        }
    }
}

// Watchers
export function* waitForBookcodeWasRegistered() {
    yield takeEvery(REGISTER_BOOKCODE, registerBookcode);
}

export const registerBookcodeSaga = [
    waitForBookcodeWasRegistered(),
];

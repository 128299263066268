// SpecialRegisterFormContainer sagas

import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchSaga } from '../../utils/network/fetch-saga';
import { trackUserEvent, isTrackingAllowed, getGA4Data } from '../../utils/utils';

import { addMessage } from '../../containers/MessageModal/actions';

import { ERROR, GA } from '../../utils/constants';

import {
    REGISTER_USER,
    registerUserSuccess,
    releaseButton,
} from './actions';

// Helpers
export function getIdpBaseUrl() {
    return process.env.REACT_APP_API_IDP_URL;
}

// Workers
export function* registerUser(action) {
    yield put(releaseButton(false));
    const baseUrl = getIdpBaseUrl();
    const url = `${baseUrl}/account/register-trial`;
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept-Language', 'de');
    const ga4Data = getGA4Data();

    const userData = {
        firstname: action.payload.firstname,
        lastname: action.payload.lastname,
        email: action.payload.email.trim(),
        center: action.payload.center,
        registrationType: action.payload.registrationType,
    };

    try {
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify(userData) });

        if (data) {
            if (isTrackingAllowed()) {
                trackUserEvent({
                    category: GA.CATEGORIES.TESTER,
                    action: GA.ACTIONS.NEW_ACCOUNT_REGISTRATION_SPECIAL,
                    label: window.location.ref,
                });

                trackUserEvent({
                    trackGA: false,
                    trackGA4: true,
                    category: GA.GA4CATEGORIES.CLICK_TESTER,
                    ga4Data: {
                        form_type: GA.ACTIONS.NEW_ACCOUNT_REGISTRATION_SPECIAL,
                        click_text: window.location.ref,
                        page_path: ga4Data.page_path,
                        date: ga4Data.date,
                        time: ga4Data.time,
                    },
                });
            }

            yield put(registerUserSuccess());
        }
    } catch (e) {
        if (isTrackingAllowed()) {
            trackUserEvent({
                category: GA.CATEGORIES.TESTER,
                action: GA.ACTIONS.NEW_ACCOUNT_REGISTRATION_SPECIAL,
                label: `Error: ${e.message}`,
            });
            trackUserEvent({
                trackGA: false,
                trackGA4: true,
                category: GA.GA4CATEGORIES.CLICK_TESTER,
                ga4Data: {
                    form_type: GA.ACTIONS.NEW_ACCOUNT_REGISTRATION_SPECIAL,
                    click_text: `Error: ${e.message}`,
                    page_path: ga4Data.page_path,
                    date: ga4Data.date,
                    time: ga4Data.time,
                },
            });
        }
        yield put(releaseButton(true));
        if (e.message === 'server error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (e.message === 'network error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else if (e.message === 'Die Daten können nicht gespeichert werden, da sie eine Einschränkung verletzen!') {
            // This error message means that the user already exists.
            yield put(addMessage(ERROR, 'Das hat leider nicht funktioniert.'
                                    + ' Möglicherweise ist Ihre E-Mailadresse bereits in unserem System hinterlegt.'
                                    + ' Bitte versuchen Sie es mit einer anderen E-Mailadresse!'));
        } else {
            yield put(addMessage(ERROR, e.message));
        }
    }
}

// Watchers
export function* waitForUserWasRegistered() {
    yield takeEvery(REGISTER_USER, registerUser);
}

export const specialRegisterSaga = [
    waitForUserWasRegistered(),
];

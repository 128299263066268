// Global imports.
import { select, put, call, takeEvery, all } from 'redux-saga/effects';

// Utils, actions, sagas, etc. .
import {
    FETCH_SUBJECTS,
    FETCH_GRADES_OF_SUBJECT,
    LOCATION_CHANGE_SEO,
    CHOOSE_SEO_UNDER_TOPIC,
    setSubjects,
    setSimilarTopics,
    setWikis,
    setExercises,
    setVideos,
    setGrades,
    chooseSubject,
    chooseGrade,
    chooseSeoUnderTopic,
    resetGrades,
    resetTopics,
    setContentType,
    setCanonical,
    setMetaDescription,
    setTitle,
    setParsingInProgress,
    setRedirectUrl,
    setSeoGradesTopicStructure,
    chooseSeoTopic,
    addVideo,
    FETCH_SIMILAR_TOPICS,
    FETCH_VIDEO_BY_ID,
} from './actions';
import {
    getBibBaseUrl,
    isTrackingAllowed,
    slugify,
    isEmpty,
    setReactGAPageView,
    redirectToNotFoundPage,
    getHeaders,
    isBlank,
} from '../../utils/utils';
import { NOT_FOUND, SEO_GRADE, SEO_TOPICS, PAGE, SEO_PAGE } from './constants';
import { fetchSaga } from '../../utils/network/fetch-saga';
import { selectChosenSubject, selectChosenGrade } from './selectors';

// Workers
export function* fetchSubjects() {
    const bibBaseUrl = getBibBaseUrl();
    const url = `${bibBaseUrl}/subjects`;
    const data = yield call(fetchSaga, url);
    if (data) {
        yield put(setSubjects(data));
    }
}

export function* fetchGradesOfSubject(action) {
    const subjectId = action.payload;
    if (subjectId === null || subjectId === '') return;
    const bibBaseUrl = getBibBaseUrl();
    const url = `${bibBaseUrl}/subjects/${subjectId}/grades`;

    try {
        const data = yield call(fetchSaga, url);
        if (data) {
            yield put(setGrades(data));
        }
    } catch (e) {
        // TODO: implement proper error handling
        // console.log(e.message);
    }
}

export function* fetchVideoById(action) {
    const bibBaseUrl = getBibBaseUrl();
    const url = `${bibBaseUrl}/videos/${action.payload}`;
    const headers = getHeaders(false);

    const data = yield call(fetchSaga, url, { method: 'GET', headers });
    if (data) {
        yield put(addVideo(data));
    }
}

export function* fetchAllUnderTopics() {
    const subjectId = yield select((state) => state.library.chosenSubjectId);
    const url = `${getBibBaseUrl()}/subjects/${subjectId}/topics`;
    const data = yield call(fetchSaga, url);

    try {
        if (data) {
            yield put(setSeoGradesTopicStructure(data));
        }
    } catch (e) {
        // TODO: implement proper error handling
        // console.log(e.message);
    }
}

export function* fetchPage(action) {
    const underTopicId = action.payload;
    if (underTopicId === null || underTopicId === '') return;
    const bibBaseUrl = getBibBaseUrl();
    const options = {
        method: 'GET',
    };

    try {
        // correct, effects will get executed in parallel
        const [exercises, wikis, videos] = yield all([
            call(fetchSaga, `${bibBaseUrl}/exercises?topicId=${underTopicId}`, options),
            call(fetchSaga, `${bibBaseUrl}/wikis?topicId=${underTopicId}`, options),
            call(fetchSaga, `${bibBaseUrl}/videos?topicId=${underTopicId}`, options),
        ]);
        yield put(setWikis(wikis));
        yield put(setExercises(exercises));
        yield put(setVideos(videos));

    } catch (e) {
        // TODO: implement proper error handling
        // console.log(e.message);
    }
}

export function* fetchTopic(topicId) {
    const url = `${getBibBaseUrl()}/topics/${topicId}`;
    try {
        const data = yield call(fetchSaga, url);
        if (data) {
            yield put(chooseSeoTopic(data));
        }
    } catch (e) {
        // TODO: imlement proper error handling
        // console.log(e.message);
    }
}

export function* fetchSimilarTopics(action) {
    const topicId = action.payload;
    if (topicId === null || topicId === '') return;

    const foundGrade = (yield select((state) => state.library.grades))
        .find((grade) => grade.id === parseInt(topicId, 10));
    if (!!foundGrade) return;

    const url = `${getBibBaseUrl()}/topics/${topicId}/children`;
    try {
        const data = yield call(fetchSaga, url);
        if (data) {
            yield put(setSimilarTopics(data));
        }
    } catch (e) {
        // TODO: implement proper error handling
        // console.log(e.message);
    }
}

export function* fetchRedirectUrl(id) {
    if (id === null || id === '') return;
    const url = `${getBibBaseUrl()}/topics/${id}/redirect?seo=true`;

    const redirectUrl = yield call(fetchSaga, url, { method: 'GET' });
    if (!!redirectUrl) {
        yield put(setRedirectUrl(redirectUrl.url));
    } else {
        yield put(setRedirectUrl(null));
    }
}

export function* parseUrlAndFetchLibraryForNotLoggedInUser(action) {
    const location = action.payload;

    yield put(setParsingInProgress(true));

    if (isTrackingAllowed()) {
        setReactGAPageView(process.env.REACT_APP_BASENAME + location);
    }

    const locationMatch = location.match(/\/\d+-/g);

    // to prevent null pointer exception
    if (locationMatch === null && location !== '/404') {
        return;
    }

    // reset all on home
    if (location === '/404') {

        // reset grades and topics
        yield put(resetGrades());
        yield put(resetTopics());

        // reset ContentType
        if (location === '/404') {
            yield put(setContentType(NOT_FOUND));
        } else {
            // no match and not on start page -> redirect to not found page
            yield put(setParsingInProgress(false));
            redirectToNotFoundPage();

            return;
        }

        // reset all
        yield put(chooseSubject(null));
        yield put(chooseGrade(null));
        yield put(chooseSeoUnderTopic(null));
        yield put(chooseSeoTopic(null));
        yield put(setRedirectUrl(null));

        // set meta description
        yield put(setMetaDescription('Online lernen |SCHÜLERHILFE'));

        const subjects = yield select((state) => state.library.subjects);
        if (subjects === undefined || subjects.length === 0) {
            yield call(fetchSubjects);
        }

        yield put(setParsingInProgress(false));

        return;
    }

    const splitPathName = location.split('/').filter((name) => !!name);
    const locationMatchCleaned = locationMatch.map((match) => match.replace('/', '').replace('-', ''));
    const subjectId = locationMatchCleaned[0];
    let gradeOrTopicId = '';
    if (locationMatchCleaned.length > 1) {
        gradeOrTopicId = locationMatchCleaned[locationMatchCleaned.length - 1];
    }

    // after the redirect reset the redirect reset the url in redux
    // because otherwise every url will be redirected to this url
    const redirectUrl = yield select((state) => state.library.redirectUrl);
    if (!!redirectUrl) {
        yield put(setRedirectUrl(null));
    }

    const subjects = yield select((state) => state.library.subjects);
    if (subjects === undefined || subjects.length === 0) {
        yield call(fetchSubjects);
    }

    /* >>>>>> For the old urls <<<<<< */
    // if the location match length is larger then 2, then it is a url from old format
    // find the searched topic (page) if not found then redirect to subject page other wise rediect to page
    // the grades are needed for the similartopics and to check if it is old url
    try {
        if (locationMatchCleaned.length > 2) {
            // there can be a topic with the same id as a grade
            yield call(fetchTopic, gradeOrTopicId);
            const chosenTopic = yield select((state) => state.library.selectedSeoTopic);

            yield put(chooseSubject(parseInt(subjectId, 10)));
            const chosenSubject = yield select(selectChosenSubject);

            // if the searched topic is not a page then it is a main or between topic then just redirect to subject
            // the redirect will be done in App.js throw the Redirect component
            if (!!chosenTopic && chosenTopic.page) {
                if (!!chosenTopic?.redirectTo) {
                    // fetch here the url from backend because otherwise the redirected topic must be fetched here
                    // and the url will then created here
                    // in this or in the other way one call to the backend will be happen
                    yield call(fetchRedirectUrl, chosenTopic.redirectTo);
                } else {
                    const subjectUrl = `${chosenSubject.id}-${slugify(chosenSubject.name)}`;
                    const topicUrl = `${chosenTopic.id}-${slugify(chosenTopic.name)}`;

                    yield put(setRedirectUrl(`/${subjectUrl}/${topicUrl}`));
                }
            } else {
                yield put(setRedirectUrl(`/${chosenSubject.id}-${slugify(chosenSubject.name)}`));
            }
            return;
        }
    } catch (error) {
        // TODO: implement proper error handling
        yield put(setParsingInProgress(false));
        redirectToNotFoundPage();
    }

    const isSubjectMatch = !!subjectId;
    const isGradeTopicMatch = !!gradeOrTopicId;

    try {
        if (locationMatchCleaned.length < 3) {
            // only is Subject
            if (isSubjectMatch && !isGradeTopicMatch) {
                // reset chosenUnderTopicId
                yield put(chooseSeoUnderTopic(null));
                // do not set similar topics to null otherwise the selector will thorw null pointer exception
                // reset similar topics and chosen seo topic
                yield put(setSimilarTopics([]));
                yield put(chooseSeoTopic(null));

                // reset topics
                yield put(resetTopics());

                // get all under topics
                const topics = yield select((state) => state.library.seoGradesTopicsStructure);
                const prevSubjectId = yield select((state) => state.library.chosenSubjectId);

                // when the user goes from the page back to the subjects
                // then topics are already there so reloading is not necessary
                // if the user switches subject then reload topics and set subject
                if (isEmpty(topics) || (!isEmpty(topics) && parseInt(prevSubjectId, 10) !== parseInt(subjectId, 10))) {
                    // set subject
                    yield put(chooseSubject(parseInt(subjectId, 10)));
                    yield call(fetchAllUnderTopics);
                }

                yield put(setContentType(SEO_TOPICS));

                // set canonical
                const chosenSubject = yield select(selectChosenSubject);
                const subjectUrl = `${chosenSubject.id}-${slugify(chosenSubject.name)}`;
                yield put(setCanonical(`/${subjectUrl}`));
                yield put(setTitle(`${chosenSubject.name} online lernen |SCHÜLERHILFE`));
                yield put(setMetaDescription(`${chosenSubject.name} online lernen |SCHÜLERHILFE`));
            }

            // subject and under topics
            if (isSubjectMatch && isGradeTopicMatch) {
                yield put(chooseSubject(parseInt(subjectId, 10)));
                let chosenSubject = yield select(selectChosenSubject);
                if (chosenSubject === undefined) {
                    yield put(chooseSubject(parseInt(subjectId, 10)));
                    chosenSubject = yield select(selectChosenSubject);
                }

                // do not set similar topics to null otherwise the selector will throw null pointer exception
                // reset similar topics, wikis, exercises, videos
                yield put(setSimilarTopics([]));
                yield put(setWikis([]));
                yield put(setExercises([]));
                yield put(setVideos([]));

                yield call(fetchGradesOfSubject, { payload: parseInt(subjectId, 10) });
                yield put(chooseGrade(parseInt(gradeOrTopicId, 10)));
                const chosenGrade = yield select(selectChosenGrade);
                if (!!chosenGrade) {
                    const subjectUrl = `${chosenSubject.id}-${slugify(chosenSubject.name)}`;
                    const gradeUrl = `${chosenGrade.id}-${slugify(chosenGrade.name)}`;
                    const canonicalUrl = `/${subjectUrl}/${gradeUrl}`;

                    const incommingSubjectUrl = splitPathName[0];
                    const incommingGradeUrl = splitPathName[1];

                    // check for subject and grade name misspellings
                    if (incommingSubjectUrl !== subjectUrl || incommingGradeUrl !== gradeUrl) {
                        yield put(setRedirectUrl(canonicalUrl));
                    } else {
                        yield put(setContentType(SEO_GRADE));

                        // check here if the topics are already there
                        // if not then fetch them
                        const topics = yield select((state) => state.library.seoGradesTopicsStructure);
                        if (isEmpty(topics)) {
                            yield put(chooseSubject(parseInt(subjectId, 10)));
                            yield call(fetchAllUnderTopics);
                        }

                        yield put(setCanonical(canonicalUrl));
                        yield put(setTitle(`${chosenGrade.name} online lernen |SCHÜLERHILFE`));
                        yield put(setMetaDescription(`${chosenGrade.name} online lernen |SCHÜLERHILFE`));
                    }
                } else {
                    let chosenTopic = yield select((state) => state.library.selectedSeoTopic);
                    if (!chosenTopic || chosenTopic?.id !== gradeOrTopicId) {
                        yield call(fetchTopic, gradeOrTopicId);
                        chosenTopic = yield select((state) => state.library.selectedSeoTopic);
                    }
                    // if the user trys to get a topic from other subject (url manipulation)
                    // just show 404 page
                    if (chosenTopic.subjectId !== chosenSubject.id) {
                        redirectToNotFoundPage();
                    }

                    // redirect to the redirected topic when redirectTo is set
                    // when redirectTo is not set and topic is a page then show page
                    // otherwiese redirect to subject page
                    if (!!chosenTopic.seoRedirectTo && !!chosenTopic?.page) {
                        yield call(fetchRedirectUrl, chosenTopic.seoRedirectTo);
                    } else if (!!chosenTopic.redirectTo && !!chosenTopic?.page) {
                        yield call(fetchRedirectUrl, chosenTopic.redirectTo);
                    } else if (!!chosenTopic?.page && !chosenTopic.redirectTo && !chosenTopic.seoRedirectTo) {
                        const subjectUrl = `${chosenSubject.id}-${slugify(chosenSubject.name)}`;
                        const topicUrl = `${chosenTopic.id}-${slugify(chosenTopic.name)}`;
                        const canonicalUrl = `/${subjectUrl}/${topicUrl}`;

                        const incommingSubjectUrl = splitPathName[0];
                        const incommingTopicUrl = splitPathName[1];

                        // check for subject and topic name misspellings
                        if (incommingSubjectUrl !== subjectUrl || incommingTopicUrl !== topicUrl) {
                            yield put(setRedirectUrl(canonicalUrl));
                        } else if (!!chosenTopic.seoPage) {
                            // if chosen topic contains seo page data -> set respective content type
                            yield put(setContentType(SEO_PAGE));
                            const similarTopics = yield select((state) => state.library.similarTopics);
                            if (isEmpty(similarTopics)) {
                                yield call(fetchSimilarTopics, { payload: chosenTopic.id });
                            }
                            // this must be set here otherwise in seo page index js the canonical cant be removed
                            // when it is noindex page (because the timing is littlebit strange)
                            // with this simple hack it will be removed afterwords or the new one is set
                            // in case of no canonical url is set in seopage set the topic canonical url
                            if (!!chosenTopic.seoPage.canonicalUrl) {
                                yield put(setCanonical(chosenTopic.seoPage.canonicalUrl));
                            } else {
                                yield put(setCanonical(canonicalUrl));
                            }
                        } else {
                            yield put(setContentType(PAGE));

                            yield put(chooseSeoUnderTopic(parseInt(gradeOrTopicId, 10)));

                            yield put(setCanonical(canonicalUrl));
                            yield put(setTitle(!isBlank(chosenTopic.titleSeo)
                                ? chosenTopic.titleSeo
                                : chosenTopic.title));
                            yield put(setMetaDescription(chosenTopic.description));
                        }
                    } else {
                        yield put(setRedirectUrl(`/${chosenSubject.id}-${slugify(chosenSubject.name)}`));
                    }
                }
            }
        }

        yield put(setParsingInProgress(false));
    } catch (error) {
        // TODO: implement proper error handling
        yield put(setParsingInProgress(false));
        redirectToNotFoundPage();
    }
}

export function* waitForSubjectsWereFetched() {
    yield takeEvery(FETCH_SUBJECTS, fetchSubjects);
}

export function* waitForGradesOfSubjectsWereFetched() {
    yield takeEvery(FETCH_GRADES_OF_SUBJECT, fetchGradesOfSubject);
}

export function* waitForLocationChangeNotLoggedIn() {
    yield takeEvery(LOCATION_CHANGE_SEO, parseUrlAndFetchLibraryForNotLoggedInUser);
}

export function* waitForSimilarTopics() {
    yield takeEvery(FETCH_SIMILAR_TOPICS, fetchSimilarTopics);
}

export function* waitForVideosWereFetched() {
    yield takeEvery(FETCH_VIDEO_BY_ID, fetchVideoById);
}

export function* waitForSeoUnderTopicWasChosen() {
    yield takeEvery(CHOOSE_SEO_UNDER_TOPIC, fetchPage);
    yield takeEvery(CHOOSE_SEO_UNDER_TOPIC, fetchSimilarTopics);
}

export const LibrarySagaSeo = [
    waitForSubjectsWereFetched(),
    waitForGradesOfSubjectsWereFetched(),
    waitForLocationChangeNotLoggedIn(),
    waitForSeoUnderTopicWasChosen(),
    waitForSimilarTopics(),
    waitForVideosWereFetched(),
];

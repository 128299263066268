// Course action types and creators.
export const GET_USER_COURSE_DATA = 'routes/Courses/getUserCourseData';
export const SET_COURSE_DATA = 'routes/Courses/setCourseData';
export const GET_COURSE_DATA_ERROR = 'routes/Courses/getCoursenDataError';
export const SEND_COURSE_SUPPORT_CONTACT_FORM = 'routes/Courses/sendCourseSupportContactForm';
export const SET_SHOW_COURSE_CONTACT_SUPPORT_MODAL = 'routes/Courses/setShowCourseContactSupportModal';
export const SET_COURSE_CONTACT_SUPPORT_FORM_LOADING = 'routes/Courses/setCourseContactSupportFormLoading';

export function getUserCourseData(icasId, intl) {
    return {
        type: GET_USER_COURSE_DATA,
        params: {
            icasId,
        },
        intl,
    };
}

export function getCourseDataError(message) {
    return {
        type: GET_COURSE_DATA_ERROR,
        payload: message,
    };
}

export function setCourseData(courseData) {
    return {
        type: SET_COURSE_DATA,
        payload: courseData,
    };
}

export const sendCourseSupportContactForm = (payload, intl) => ({
    type: SEND_COURSE_SUPPORT_CONTACT_FORM,
    payload,
    intl,
});

export function setShowCourseContactSupportModal(showCourseContactSupportModal) {
    return {
        type: SET_SHOW_COURSE_CONTACT_SUPPORT_MODAL,
        payload: showCourseContactSupportModal,
    };
}

export const setCourseContactSupportFormLoading = (loading) => ({
    type: SET_COURSE_CONTACT_SUPPORT_FORM_LOADING,
    payload: { loading },
});

// Exams reducer

import {
    GET_STUDENT_DATA_ERROR,
    SET_STUDENT_EXISTS,
} from './actions';

const initialState = {
    messages: [],
    studentExists: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case GET_STUDENT_DATA_ERROR:
        return {
            ...state,
            messages: [{
                severity: 'error',
                summary: 'Fehler',
                detail: action.payload,
            }],
        };
    case SET_STUDENT_EXISTS:
        return {
            ...state,
            studentExists: action.payload,
        };
    default:
        return state;
    }
}

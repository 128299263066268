// TestAccountRegisterFormContainer action types and creators

export const REGISTER_USER = 'routes/TestAccountRegisterFormContainer/registerUser';
export const REGISTER_USER_SUCCESS = 'routes/TestAccountRegisterFormContainer/registerUserSuccess';
export const GET_USER_DATA = 'routes/TestAccountRegisterFormContainer/getUserData';
export const SET_USER_DATA = 'routes/TestAccountRegisterFormContainer/setUserData';
export const SET_ABO_TO_USER_DATA = 'routes/TestAccountRegisterFormContainer/setAboToUserData';
export const SET_USER_ALREADY_EXISTS = 'routes/TestAccountRegisterFormContainer/setUserAlreadyExists';

export const registerUser = (userData, history) => ({
    type: REGISTER_USER,
    payload: userData,
    history,
});

export const registerUserSuccess = (userData) => ({
    type: REGISTER_USER_SUCCESS,
    payload: userData,
});

export const getUserData = (token, aboId, history) => ({
    type: GET_USER_DATA,
    payload: token,
    aboId,
    history,
});

export const setUserData = (userData) => ({
    type: SET_USER_DATA,
    payload: userData,
});

export const setAboToUserData = (aboId) => ({
    type: SET_ABO_TO_USER_DATA,
    payload: aboId,
});

export const setUserAlreadyExists = () => ({
    type: SET_USER_ALREADY_EXISTS,
});

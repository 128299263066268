// Webinars reducer

import {
    GET_WEBINARS,
    GET_WEBINARS_SUCCESS,
} from './actions';

const initialState = {
    events: [],
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case GET_WEBINARS:
        return state;
    case GET_WEBINARS_SUCCESS:
        return { ...state, events: action.payload, messages: [] };
    default:
        return state;
    }
}

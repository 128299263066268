// react and external libraries
import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchSaga } from '../../utils/network/fetch-saga';

// own components
import { getIdpBaseUrl, getHeaders } from '../../utils/utils';

// constants
import { ERROR } from '../../utils/constants';

import {
    SEND_TERMINATION_EMAIL,
    terminationEmailSuccess,
} from './actions';
import { addMessage } from '../../containers/MessageModal/actions';

function* sendTerminationEmail(action) {

    const { payload } = action;
    const url = `${getIdpBaseUrl()}/account/termination-mail`;
    const headers = getHeaders();

    try {
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify(payload) });

        if (data) {
            yield put(terminationEmailSuccess(true));
        }

    } catch (e) {
        if (e.message === 'Der Benutzer existiert nicht!') {
            yield put(addMessage(ERROR, 'Das hat leider nicht funktioniert.'
                    + ' Möglicherweise ist Ihre Benutzer-Mail-Adresse nicht in unserem System hinterlegt.'
                    + ' Bitte versuchen Sie es mit Ihrer E-Mailadresse des Online LernCenters!'));
        } else if (e.message === 'Failed to fetch') {
            yield put(addMessage(ERROR, 'Server Error!'));
        } else {
            yield put(addMessage(ERROR, e.message));
        }
    }
}

function* watchSendTerminationEmail() {
    yield takeEvery(SEND_TERMINATION_EMAIL, sendTerminationEmail);
}

export const TerminationSaga = [
    watchSendTerminationEmail(),
];

export const AUDIO_REQUESTED = 'views/Audio/audioRequested';
export const AUDIO_OBTAINED = 'views/Audio/audioObtained';

export function audioRequested({ audioId }) {
    return {
        type: AUDIO_REQUESTED,
        payload: { audioId },
    };
}

export function audioObtained({ audio }) {
    return {
        type: AUDIO_OBTAINED,
        payload: { audio },
    };
}

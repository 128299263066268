import { call, put, takeEvery } from 'redux-saga/effects';
import { addMessage } from '../../../containers/MessageModal/actions';
import { ERROR } from '../../../utils/constants';
import { fetchSaga } from '../../../utils/network/fetch-saga';
import { getHeaders, getIdpBaseUrl, inDevelopment } from '../../../utils/utils';
import {
    nextRegistrationStepRequested,
    REGISTER_AND_MOVE_TO_NEXT_STEP,
    validationCodeObtained,
    VALIDATION_CODE_REQUESTED,
} from './actions';

export function* registerNewUserAndMoveToNextStep(action) {
    try {
        const { userData, nextStepNumber } = action.payload;
        const url = `${getIdpBaseUrl()}/account/register-trial`;
        yield call(
            fetchSaga,
            url,
            {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(userData),
            },
        );
        yield put(nextRegistrationStepRequested({ nextStepNumber }));
    } catch (err) {
        yield put(addMessage(ERROR, err.message));
    }
}

export function* fetchValidationCode(action) {
    try {
        const { userData, nextStepNumber } = action.payload;
        const url = 'https://idp-api-stag.schuelerhilfe.de/v1/account/phone-validation-code?'
            + `phoneNumber=${userData.phone}&debug=${inDevelopment() ? 1 : 0}`;
        const response = yield call(
            fetchSaga,
            url,
            {
                method: 'GET',
                headers: getHeaders(),
            },
        );
        const { code } = response;
        if (!!code) {
            yield put(validationCodeObtained({ receivedValidationCode: code }));
            yield put(nextRegistrationStepRequested({ nextStepNumber }));
        } else {
            yield put(addMessage(ERROR, 'no validation code received'));
        }
    } catch (err) {
        yield put(addMessage(ERROR, err.message));
    }
}

export function* watchValidationCodeRequested() {
    yield takeEvery(VALIDATION_CODE_REQUESTED, fetchValidationCode);
}

export function* watchRegistrationRequested() {
    yield takeEvery(REGISTER_AND_MOVE_TO_NEXT_STEP, registerNewUserAndMoveToNextStep);
}

export const stepwiseRegistrationSagas = [
    watchRegistrationRequested(),
    watchValidationCodeRequested(),
];

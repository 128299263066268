/* eslint-disable no-case-declarations */
import { NEXT_REG_STEP_REQUESTED, VALIDATION_CODE_OBTAINED } from './actions';

const initialState = {
    stepNumber: 0,
    receivedValidationCode: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case NEXT_REG_STEP_REQUESTED:
        const { nextStepNumber } = action.payload;
        return { ...state, stepNumber: nextStepNumber };
    case VALIDATION_CODE_OBTAINED:
        const { receivedValidationCode } = action.payload;
        return { ...state, receivedValidationCode: `${receivedValidationCode}` };
    default: return state;
    }
}

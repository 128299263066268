import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchSaga } from '../../utils/network/fetch-saga';
import { getHeaders, getIdpBaseUrl } from '../../utils/utils';
import { addMessage } from '../../containers/MessageModal/actions';
import { ERROR } from '../../utils/constants';
import { REGISTER_USER, registerUserSuccess, releaseButton } from './actions';

export function* registerUser(action) {
    yield put(releaseButton(false));
    const url = `${getIdpBaseUrl()}/account/register-trial`;
    const headers = getHeaders();

    const userData = {
        firstname: action.payload.firstname,
        lastname: action.payload.lastname,
        email: action.payload.email,
        registrationType: action.payload.registrationType,
    };

    try {
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify(userData) });

        if (data) {
            yield put(registerUserSuccess(true));
        }
    } catch (e) {
        yield put(releaseButton(true));
        if (e.message === 'server error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (e.message === 'network error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else if (e.message === 'Die Daten können nicht gespeichert werden, da sie eine Einschränkung verletzen!') {
            // This error message means that the user already exists.
            yield put(addMessage(ERROR, 'Das hat leider nicht funktioniert.'
                                    + ' Möglicherweise ist Ihre E-Mailadresse bereits in unserem System hinterlegt.'
                                    + ' Bitte versuchen Sie es mit einer anderen E-Mailadresse!'));
        } else {
            yield put(addMessage(ERROR, e.message));
        }
    }
}

export function* waitForUserWasRegistered() {
    yield takeEvery(REGISTER_USER, registerUser);
}

export const specialFourteenDaysRegisterSaga = [
    waitForUserWasRegistered(),
];

// TestAccountRegisterFormContainer sagas

import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { fetchSaga } from '../../utils/network/fetch-saga';
import { isTrackingAllowed,
    getHeaders, trackUserEvent,
    createCookieStringFromToken,
    getGA4Data,
} from '../../utils/utils';
import { BASIC_ID, BASIC_NAME, PREMIUM_NAME, ERROR, GA } from '../../utils/constants';

import {
    REGISTER_USER,
    GET_USER_DATA,
    registerUserSuccess,
    setUserData,
    setUserAlreadyExists,
} from './actions';

import {
    setAuthentication,
} from '../Account/actions';

import {
    addMessage,
} from '../../containers/MessageModal/actions';

// Helpers
export function getIdpBaseUrl() {
    return process.env.REACT_APP_API_IDP_URL;
}

function* getToken(username, password) {
    const baseUrl = getIdpBaseUrl();
    const url = `${baseUrl}/account/authenticate`;
    const headers = getHeaders();

    const payload = {
        username,
        password,
    };
    try {
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify(payload) });
        if (data) {
            localStorage.setItem('token', data.token);
            document.cookie = createCookieStringFromToken(data.token);
            yield put(setAuthentication(true));
        }
    } catch (e) {
        // TODO: Implement if Portal gets implemented
        // yield put(loginUserError(e.message));
    }
}

export function* registerUser(action) {
    const baseUrl = getIdpBaseUrl();
    const url = `${baseUrl}/account/register-trial`;
    const headers = getHeaders();
    const ga4Data = getGA4Data();

    const userData = {
        firstname: action.payload.firstname,
        lastname: action.payload.lastname,
        email: action.payload.email.trim(),
        password: action.payload.password,
        hasPromotionAllowed: action.payload.hasPromotionAllowed,
    };

    try {
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify(userData) });

        if (data) {
            if (isTrackingAllowed()) {
                trackUserEvent({
                    category: GA.CATEGORIES.TESTER,
                    action: GA.ACTIONS.NEW_ACCOUNT_REGISTRATION,
                    label: window.location.ref,
                });

                trackUserEvent({
                    trackGA: false,
                    trackGA4: true,
                    category: GA.GA4CATEGORIES.CLICK_TESTER,
                    ga4Data: {
                        form_type: GA.ACTIONS.NEW_ACCOUNT_REGISTRATION,
                        click_text: window.location.ref,
                        page_path: ga4Data.page_path,
                        date: ga4Data.date,
                        time: ga4Data.time,
                    },
                });
            }

            const email = userData.email.toLowerCase();

            yield put(registerUserSuccess(action.payload));
            yield call(getToken, userData.email, userData.password);

            if (email.endsWith('@schuelerhilfe.de')
                || email.endsWith('@schuelerhilfe.com')
                || email.endsWith('@schuelerhilfe-online.de')) {
                yield action.history.push('/');
                return;
            }

            if (!!action.payload.chooseAbo) {
                yield action.history.push('/abo-auswaehlen');
            } else {
                const aboName = (action.payload.abo === BASIC_ID ? BASIC_NAME : PREMIUM_NAME);

                if (isTrackingAllowed()) {
                    trackUserEvent({
                        category: GA.CATEGORIES.ABO,
                        action: GA.ACTIONS.PAYMENT,
                        label: `${aboName} - step 1`,
                        value: action.payload.abo,
                    });

                    trackUserEvent({
                        trackGA: false,
                        trackGA4: true,
                        category: GA.GA4CATEGORIES.FORM_SUBMISSION,
                        ga4Data: {
                            form_type: GA.ACTIONS.PAYMENT,
                            interaction_type: `${aboName} - step 1`,
                            name: action.payload.abo,
                            page_path: ga4Data.page_path,
                            date: ga4Data.date,
                            time: ga4Data.time,
                        },
                    });
                }
                yield action.history.push('/abos-und-preise');
            }

        }
    } catch (e) {
        if (isTrackingAllowed()) {
            trackUserEvent({
                category: GA.CATEGORIES.TESTER,
                action: GA.ACTIONS.NEW_ACCOUNT_REGISTRATION,
                label: `Error: ${e.message}`,
            });

            trackUserEvent({
                trackGA: false,
                trackGA4: true,
                category: GA.GA4CATEGORIES.CLICK_TESTER,
                ga4Data: {
                    form_type: GA.ACTIONS.NEW_ACCOUNT_REGISTRATION,
                    click_text: `Error: ${e.message}`,
                    page_path: ga4Data.page_path,
                    date: ga4Data.date,
                    time: ga4Data.time,
                },
            });
        }

        if (e.message === 'server error') {
            yield put(addMessage(ERROR, action.intl.messages['errorMessages.serverError']));
        } else if (e.message === 'network error') {
            yield put(addMessage(ERROR, action.intl.messages['errorMessages.networkError']));
        } else if (e.message === 'Die Daten können nicht gespeichert werden, da sie eine Einschränkung verletzen!') {
            // This error message means that the user already exists.
            yield put(addMessage(ERROR, 'Diese E-Mail-Adresse wird leider schon verwendet!'));
            yield put(setUserAlreadyExists());
        } else {
            yield put(addMessage(ERROR, e.message));
        }
    }
}

function* getUserData(action) {
    const baseUrl = getIdpBaseUrl();
    const url = `${baseUrl}/users/${action.payload.userid}`;
    const headers = getHeaders(true);
    try {
        const data = yield call(fetchSaga, url, { method: 'GET', headers });
        if (data) {
            const userData = {
                firstname: data.firstname,
                lastname: data.lastname,
                email: data.email,
                abo: action.aboId,
            };

            yield put(setUserData(userData));
            action.history.push('/abos-und-preise');
        }
    } catch (e) {
        // TODO: Refactor error handling
        if (e.message === 'Kein Objekt zur angefragten ID gefunden!') {
            document.cookie = createCookieStringFromToken(localStorage.getItem('token'), true);
            localStorage.removeItem('token');
            yield put(setAuthentication(false));
        } else {
            yield put(push('/logout'));
        }
    }
}

// Watchers
export function* waitForUserWasRegistered() {
    yield takeEvery(REGISTER_USER, registerUser);
}

export function* waitForGetUserData() {
    yield takeEvery(GET_USER_DATA, getUserData);
}

export const registerSaga = [
    waitForUserWasRegistered(),
    waitForGetUserData(),
];

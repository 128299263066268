// Profile action types and creators

export const SAVE_PASSWORD_DATA = 'containers/Profile/savePasswordData';
export const REDEEM_VOUCHER = 'containers/Profile/redeemVoucher';

export function savePasswordData(passwordData, intl) {
    return {
        type: SAVE_PASSWORD_DATA,
        payload: passwordData,
        intl,
    };
}

export function redeemVoucher(vouchercode, voucherData, intl) {
    return {
        type: REDEEM_VOUCHER,
        vouchercode,
        payload: voucherData,
        intl,
    };
}

import { EMAIL_VERIFICATION_FAILED, EMAIL_VERIFICATION_SUCCEEDED, VERIFY_EMAIL_REQUESTED } from './actions';

const initialState = {
    verificationStarted: false,
    verificationFinished: false,
    verificationSucceeded: false,
};

const verificationReducer = (state = initialState, action) => {
    switch (action.type) {
    case VERIFY_EMAIL_REQUESTED:
        return { ...state, verificationStarted: true };
    case EMAIL_VERIFICATION_FAILED:
        return { ...state, verificationFinished: true, verificationSucceeded: false };
    case EMAIL_VERIFICATION_SUCCEEDED:
        return { ...state, verificationFinished: true, verificationSucceeded: true };
    default: return { ...state };
    }
};

export default verificationReducer;

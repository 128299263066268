import { call, put, takeEvery } from 'redux-saga/effects';
import { addMessage } from '../../containers/MessageModal/actions';
import { ERROR } from '../../utils/constants';
import { fetchSaga } from '../../utils/network/fetch-saga';
import { getBibBaseUrl, getHeaders, getTokenPayloadFromStorage } from '../../utils/utils';
import { audioObtained, AUDIO_REQUESTED } from './actions';

function* fetchAudio(action) {
    try {
        const { audioId } = action.payload;
        const response = yield call(
            fetchSaga,
            `${getBibBaseUrl()}/videos/${audioId}`,
            {
                method: 'GET',
                headers: getHeaders(!!getTokenPayloadFromStorage()),
            },
        );
        yield put(audioObtained({ audio: response }));
    } catch (err) {
        yield put(addMessage(ERROR, err.message));
    }
}

function* watchAudioRequested() {
    yield takeEvery(AUDIO_REQUESTED, fetchAudio);
}

export const audioSagas = [
    watchAudioRequested(),
];

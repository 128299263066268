import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchSaga } from '../../../utils/network/fetch-saga';
import { getBibBaseUrl, getHeaders } from '../../../utils/utils';
import { schulaktionOfferResponseObtained, SCHULAKTION_OFFER_REQUESTED } from './actions';

function* submitSchulaktionOffer(action) {
    try {
        const baseUrl = getBibBaseUrl();
        const response = yield call(
            fetchSaga,
            `${baseUrl}/form/add-olc-appointment-in-service-center`,
            {
                method: 'POST',
                body: JSON.stringify(action.payload),
                headers: getHeaders(true),
            },
        );
        if (response) {
            yield put(schulaktionOfferResponseObtained());
        }
    } catch (e) {
        yield 0;
    }
}

function* watchSchulaktionOfferSubmitted() {
    yield takeEvery(SCHULAKTION_OFFER_REQUESTED, submitSchulaktionOffer);
}

export const schulaktionOfferModalSaga = [
    watchSchulaktionOfferSubmitted(),
];

export const GRADES = 'grades';
export const STATES = 'states';
export const MAIN_TOPICS = 'mainTopics';
export const BETWEEN_AND_UNDER_TOPICS = 'betweenAndUnderTopics';
export const PAGE = 'page';
export const SEO_PAGE = 'seoPage';
export const EXERCISE = 'EXERCISE';
export const NOT_FOUND = 'NOT_FOUND';
export const SEO_TOPICS = 'seoTopics';
export const SEO_GRADE = 'seoGrade';

import mathDegree4To5 from './cover_maths_4_5_degree.jpg';
import mathDegree5To6 from './cover_maths_5_6_degree.jpg';
import mathDegree7To8 from './cover_maths_7_8_degree.jpg';
import mathDegree9To10 from './cover_maths_9_10_degree.jpg';
import mathAnalysisImage from './cover_abi_mathe_analysis.jpg';
import mathAlgebraImage from './cover_abi_mathe_lineare_algebra_stochastik.jpg';

import germanDegree4To5 from './cover_ger_4_5.jpg';
import germanDegree5To6 from './cover_ger_5_6.jpg';
import germanDegree7To8 from './cover_ger_7_8.jpg';
import germanDegree9To10 from './cover_ger_9_10.jpg';
import germanAbiImage from './cover_abi_deutsch.jpg';

import englishDegree5To6 from './cover_eng_5_6.jpg';
import englishDegree7To8 from './cover_eng_7_8.jpg';
import englishDegree9To10 from './cover_eng_9_10.jpg';
import englishAbiImage from './cover_abi_englisch.jpg';

// regular expressions for grades
const regexGrades4to5 = /grundschule|klasse(\s|-)5/i;
const regexGrades5to6 = /klasse(\s|-)(5|6)/i;
const regexGrades7to8 = /klasse(\s|-)(7|8)/i;
const regexGrades9to10 = /klasse(\s|-)(9|10)|10er-abschluss/i;
const regexGradesAbitur = /qualifikation|abitur|klasse(\s|-)1\d+/i;

// flipbooks group titles generator, e.g. "Digitale Bücher Klasse 4-5"
const createGroupTitleForGrades = (...gradeNumbers) => `Digitale Bücher Klasse ${gradeNumbers.join(' - ')}`;

// creates a title for an explicit flipbook with given subject
const createFlipbookTitleForGrades = (subjectName, ...gradeNumbers) => `${
    subjectName
}: Gute Noten mit der Schülerhilfe. Stufe${
    (gradeNumbers.length > 1) ? 'n' : ''
} ${
    gradeNumbers.slice(0, -1).join(', ')
} ${
    (gradeNumbers.length > 1) ? 'und' : ''
} ${gradeNumbers.reverse()[0]}`;

// returns a flipbook index.html url for the passed flipbook folder name
const createFlipbookURL = (flipbookFolderName) => [
    'https://www.schuelerhilfe.de/online-lernen/flipbooks/',
    flipbookFolderName,
    '/index.html',
].join('');

const flipbookIdGenerator = (function* generator() {
    let id = 1;
    yield id;
    while (true) {
        id += 1;
        yield id;
    }
}());

const nextFlipbookId = () => flipbookIdGenerator.next();

const subjects = [
    { name: 'MAT', value: 'math' },
    { name: 'ENG', value: 'english' },
    { name: 'DEU', value: 'german' },
    { name: 'BIO', value: 'biology' },
    { name: 'PHY', value: 'physics' },
    { name: 'INF', value: 'informatics' },
    { name: 'AGR', value: 'ancient greek' },
    { name: 'CHE', value: 'chemistry' },
    { name: 'DYK', value: 'dyscalculia' },
    { name: 'ERD', value: 'geography' },
    { name: 'FRZ', value: 'french' },
    { name: 'GES', value: 'history' },
    { name: 'ITA', value: 'italian' },
    { name: 'JPA', value: 'japanese' },
    { name: 'KOM', value: 'communication' },
    { name: 'LAT', value: 'latin' },
    { name: 'LEG', value: 'dyslexia' },
    { name: 'MUS', value: 'music' },
    { name: 'NAW', value: 'science' },
    { name: 'NED', value: 'dutch' },
    { name: 'POL', value: 'politics' },
    { name: 'REW', value: 'accounting' },
    { name: 'REL', value: 'religion' },
    { name: 'RUS', value: 'russian' },
    { name: 'SAC', value: 'expertise' },
    { name: 'SPL', value: 'schuelerhilfe plus' },
    { name: 'SPA', value: 'spanish' },
    { name: 'TEC', value: 'technology' },
    { name: 'WIR', value: 'economics' },
];

const getSubject = (name) => subjects.find((subject) => name === subject.name);

const flipbookData = {
    math: [
        { // Grundschule & Stufe 5
            gradeRegexp: regexGrades4to5,
            grades: [4, 5],
            subject: 'math',
            title: createGroupTitleForGrades(4, 5),
            flipbooks: [
                {
                    id: nextFlipbookId(),
                    title: createFlipbookTitleForGrades('Mathe', 4, 5),
                    url: createFlipbookURL('mathe4-5'),
                    urlWithPrint: createFlipbookURL('mathe4-5-p'),
                    img: mathDegree4To5,
                },
            ],
        },
        { // Stufen 5 - 6
            gradeRegexp: regexGrades5to6,
            grades: [5, 6],
            subject: 'math',
            title: createGroupTitleForGrades(5, 6),
            flipbooks: [
                {
                    id: nextFlipbookId(),
                    title: createFlipbookTitleForGrades('Mathe', 5, 6),
                    url: createFlipbookURL('mathe5-6'),
                    urlWithPrint: createFlipbookURL('mathe5-6-p'),
                    img: mathDegree5To6,
                },
            ],
        },
        { // Stufen 7 - 8
            gradeRegexp: regexGrades7to8,
            grades: [7, 8],
            subject: 'math',
            title: createGroupTitleForGrades(7, 8),
            flipbooks: [
                {
                    id: nextFlipbookId(),
                    title: createFlipbookTitleForGrades('Mathe', 7, 8),
                    url: createFlipbookURL('mathe7-8'),
                    urlWithPrint: createFlipbookURL('mathe7-8-p'),
                    img: mathDegree7To8,
                },
            ],
        },
        { // Stufen 9 - 10
            gradeRegexp: regexGrades9to10,
            grades: [9, 10],
            subject: 'math',
            title: createGroupTitleForGrades(9, 10),
            flipbooks: [
                {
                    id: nextFlipbookId(),
                    title: createFlipbookTitleForGrades('Mathe', 9, 10),
                    url: createFlipbookURL('mathe9-10'),
                    urlWithPrint: createFlipbookURL('mathe9-10-p'),
                    img: mathDegree9To10,
                },
            ],
        },
        { // abitur
            gradeRegexp: regexGradesAbitur,
            grades: [12, 13],
            subject: 'math',
            title: 'Abiturvorbereitungsbücher',
            flipbooks: [
                {
                    id: nextFlipbookId(),
                    title: 'Abitur: Analysis',
                    url: createFlipbookURL('mathe-analysis'),
                    urlWithPrint: createFlipbookURL('mathe-analysis-p'),
                    img: mathAnalysisImage,
                },
                {
                    id: nextFlipbookId(),
                    title: 'Abitur: Lineare Algebra + Stochastik',
                    url: createFlipbookURL('mathe-algebra'),
                    urlWithPrint: createFlipbookURL('mathe-algebra-p'),
                    img: mathAlgebraImage,
                },
            ],
        },
    ],
    german: [
        { // Grundschule & Stufe 5
            gradeRegexp: regexGrades4to5,
            grades: [4, 5],
            subject: 'german',
            title: createGroupTitleForGrades(4, 5),
            flipbooks: [
                {
                    id: nextFlipbookId(),
                    title: createFlipbookTitleForGrades('Deutsch', 4, 5),
                    url: createFlipbookURL('deutsch-gn-4-5'),
                    urlWithPrint: createFlipbookURL('deutsch-gn-4-5-p'),
                    img: germanDegree4To5,
                },
            ],
        },
        { // Stufen 5 - 6
            gradeRegexp: regexGrades5to6,
            grades: [5, 6],
            subject: 'german',
            title: createGroupTitleForGrades(5, 6),
            flipbooks: [
                {
                    id: nextFlipbookId(),
                    title: createFlipbookTitleForGrades('Deutsch', 5, 6),
                    url: createFlipbookURL('deutsch-gn-5-6'),
                    urlWithPrint: createFlipbookURL('deutsch-gn-5-6-p'),
                    img: germanDegree5To6,
                },
            ],
        },
        { // Stufen 7 - 8
            gradeRegexp: regexGrades7to8,
            grades: [7, 8],
            subject: 'german',
            title: createGroupTitleForGrades(7, 8),
            flipbooks: [
                {
                    id: nextFlipbookId(),
                    title: createFlipbookTitleForGrades('Deutsch', 7, 8),
                    url: createFlipbookURL('deutsch-gn-7-8'),
                    urlWithPrint: createFlipbookURL('deutsch-gn-7-8-p'),
                    img: germanDegree7To8,
                },
            ],
        },
        { // Stufen 9 - 10
            gradeRegexp: regexGrades9to10,
            grades: [9, 10],
            subject: 'german',
            title: createGroupTitleForGrades(9, 10),
            flipbooks: [
                {
                    id: nextFlipbookId(),
                    title: createFlipbookTitleForGrades('Deutsch', 9, 10),
                    url: createFlipbookURL('deutsch-gn-9-10'),
                    urlWithPrint: createFlipbookURL('deutsch-gn-9-10-p'),
                    img: germanDegree9To10,
                },
            ],
        },
        { // abitur
            gradeRegexp: regexGradesAbitur,
            grades: [12, 13],
            subject: 'german',
            title: 'Abiturvorbereitungsbücher',
            flipbooks: [
                {
                    id: nextFlipbookId(),
                    title: 'Abitur: Deutsch',
                    url: createFlipbookURL('deutsch-abi'),
                    urlWithPrint: createFlipbookURL('deutsch-abi-p'),
                    img: germanAbiImage,
                },
            ],
        },
    ],
    english: [
        { // Stufen 5 - 6
            gradeRegexp: regexGrades5to6,
            grades: [5, 6],
            subject: 'english',
            title: createGroupTitleForGrades(5, 6),
            flipbooks: [
                {
                    id: nextFlipbookId(),
                    title: createFlipbookTitleForGrades('Englisch', 5, 6),
                    url: createFlipbookURL('englisch5-6'),
                    urlWithPrint: createFlipbookURL('englisch5-6-p'),
                    img: englishDegree5To6,
                },
            ],
        },
        { // Stufen 7 - 8
            gradeRegexp: regexGrades7to8,
            grades: [7, 8],
            subject: 'english',
            title: createGroupTitleForGrades(7, 8),
            flipbooks: [
                {
                    id: nextFlipbookId(),
                    title: createFlipbookTitleForGrades('Englisch', 7, 8),
                    url: createFlipbookURL('englisch7-8'),
                    urlWithPrint: createFlipbookURL('englisch7-8-p'),
                    img: englishDegree7To8,
                },
            ],
        },
        { // Stufen 9 - 10
            gradeRegexp: regexGrades9to10,
            grades: [9, 10],
            subject: 'english',
            title: createGroupTitleForGrades(9, 10),
            flipbooks: [
                {
                    id: nextFlipbookId(),
                    title: createFlipbookTitleForGrades('Englisch', 9, 10),
                    url: createFlipbookURL('englisch9-10'),
                    urlWithPrint: createFlipbookURL('englisch9-10-p'),
                    img: englishDegree9To10,
                },
            ],
        },
        { // abitur
            gradeRegexp: regexGradesAbitur,
            grades: [12, 13],
            subject: 'english',
            title: 'Abiturvorbereitungsbücher',
            flipbooks: [
                {
                    id: nextFlipbookId(),
                    title: 'Abitur: Englisch',
                    url: createFlipbookURL('englisch-abi'),
                    urlWithPrint: createFlipbookURL('englisch-abi-p'),
                    img: englishAbiImage,
                },
            ],
        },
    ],
};

export const getFlipBooksByUsersSubjects = (usersSubjects, classLevel) => {
    const allBooks = flipbookData;

    if (!!usersSubjects && !!classLevel) {
        return usersSubjects.map((usersSubject) => {
            const subject = getSubject(usersSubject);
            if (!!subject) {
                return allBooks[subject.value]?.map((book) => {
                    if (book.grades.includes(classLevel)) {
                        return book;
                    }
                    return undefined;
                }).filter((n) => !!n).flat(1);
            }
            return undefined;
        }).filter((n) => !!n).flat(1);
    }

    return undefined;
};

export default flipbookData;

// Network.
import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchSaga } from '../../utils/network/fetch-saga';
import { getPortalBaseUrl, getHeaders } from '../../utils/utils';

import { ERROR, INFO } from '../../utils/constants';

// Actions.
import {
    GET_USER_COURSE_DATA,
    SEND_COURSE_SUPPORT_CONTACT_FORM,
    setCourseContactSupportFormLoading,
    setShowCourseContactSupportModal,
    setCourseData,
    getCourseDataError,
} from './actions';
import { addMessage } from '../../containers/MessageModal/actions';

export function* handleGetCourseData(action) {
    const baseUrl = getPortalBaseUrl();
    const url = `${baseUrl}/icas/meeting-member?pupilContactId=${action.params.icasId}`;
    const headers = getHeaders(true);

    try {
        const data = yield call(fetchSaga, url, { method: 'GET', headers });
        if (data && data.items) {
            yield put(setCourseData(data.items));
        }
    } catch (e) {
        yield put(setCourseData([]));

        if (e.message === 'server error') {
            yield put(getCourseDataError(action.intl.messages['errorMessages.serverError']));
        } else if (e.message === 'network error') {
            yield put(getCourseDataError(action.intl.messages['errorMessages.networkError']));
        }
    }
}

export function* handleSendCourseSupportContactForm(action) {
    const baseUrl = getPortalBaseUrl();
    const url = `${baseUrl}/forms/contactCourseSupport`;
    const headers = getHeaders(true);

    const { payload, intl } = action;

    try {
        yield put(setCourseContactSupportFormLoading(true));

        const response = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify(payload) });
        if (response) {
            yield put(addMessage(INFO, intl.formatMessage({ id: 'modal.courseSupportContactModal.success' })));
            yield put(setShowCourseContactSupportModal(false));
        } else {
            yield put(addMessage(ERROR, intl.formatMessage({ id: 'modal.courseSupportContactModal.error' })));
        }
    } catch (e) {
        yield put(addMessage(ERROR, intl.formatMessage({ id: 'modal.courseSupportContactModal.error' })));
    }

    yield put(setCourseContactSupportFormLoading(false));
}

export function* waitForUserCourseDataWasFetched() {
    yield takeEvery(GET_USER_COURSE_DATA, handleGetCourseData);
}

export function* waitForCourseSupportContactFormWasSent() {
    yield takeEvery(SEND_COURSE_SUPPORT_CONTACT_FORM, handleSendCourseSupportContactForm);
}

export const coursesSaga = [
    waitForUserCourseDataWasFetched(),
    waitForCourseSupportContactFormWasSent(),
];

import { call, put, takeEvery } from 'redux-saga/effects';
import { GA } from '../../../utils/constants';
import { fetchSaga } from '../../../utils/network/fetch-saga';
import { storage } from '../../../utils/storage';
import { getHeaders,
    trackUserEvent,
    pushToDataLayer,
    createCookieStringFromToken,
} from '../../../utils/utils';
import { getIdpBaseUrl } from '../../AbosContainer/sagas';
import { emailVerificationFailed, emailVerificationSucceeded, VERIFY_EMAIL_REQUESTED } from './actions';

function* verifyEmail() {
    try {
        const response = yield call(
            fetchSaga,
            `${getIdpBaseUrl()}/account/verify-email`,
            {
                method: 'POST',
                headers: getHeaders(true),
            },
        );

        const { token } = response;
        storage.setItem('token', token);
        document.cookie = createCookieStringFromToken(token);

        yield trackUserEvent({
            trackGA: true,
            trackUA: true,
            category: GA.CATEGORIES.TESTER_SMS,
            action: GA.ACTIONS.NEW_ACCOUNT_REGISTRATION,
        });

        pushToDataLayer({
            event: 'gaEvent',
            eventCategory: GA.CATEGORIES.REGISTRATION_PROCESS_OLC,
            eventAction: GA.ACTIONS.SMS_TESTER_PROCESS,
            eventLabel: 'Erfolgreich verifiziert',
        });

        yield put(emailVerificationSucceeded());

    } catch (err) {
        document.cookie = createCookieStringFromToken(storage.getItem('token'), true);
        storage.removeItem('token');
        yield put(emailVerificationFailed(err.message));
    }
}

function* watchVerifyEmailRequested() {
    yield takeEvery(VERIFY_EMAIL_REQUESTED, verifyEmail);
}

export const verificationSagas = [
    watchVerifyEmailRequested(),
];

// Webinars action types and creators

export const GET_WEBINARS = 'containers/Webinars/getWebinars';
export const GET_WEBINARS_SUCCESS = 'containers/Webinars/getWebinarsSuccess';
export const REGISTER_PARTICIPANT = 'containers/Webinars/registerParticipant';
export const UNREGISTER_PARTICIPANT = 'containers/Webinars/unregisterParticipant';

export function getWebinars(subject, intl) {
    return {
        type: GET_WEBINARS,
        payload: subject,
        intl,
    };
}

export function getWebinarsSuccess(webinars) {
    return {
        type: GET_WEBINARS_SUCCESS,
        payload: webinars,
    };
}

export function registerParticipant({
    email,
    firstname,
    intl,
    lastname,
    webinarDate,
    webinarId,
    subject,
    title,
}) {
    return {
        type: REGISTER_PARTICIPANT,
        payload: {
            email,
            firstname,
            intl,
            lastname,
            webinarDate,
            webinarId,
            subject,
            title,
        },
    };
}

export function unregisterParticipant({ userData, intl, webinarId, webinarDate, subject, title }) {
    return {
        type: UNREGISTER_PARTICIPANT,
        payload: { userData, intl, webinarId, webinarDate, subject, title },
    };
}

// Global imports.
import sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';

// Utils, actions, sagas, etc.
import flipbookData from '../Flipbooks/flipbooksData';
import {
    ABITUR_PREPARATION_FLIPBOOKS_ID,
    EXAMPLE_EXAMS_FLIPBOOKS_ID,
    PRIMARY_SCHOOL_FLIPBOOKS_ID,
    TEST_PREPARATION_FLIPBOOKS_ID,
} from '../../utils/constants';

// Library selectors
export const selectSortedExercises = createSelector(
    (state) => state.library.exercises,
    (exercises) => sortBy(exercises, 'level'),
);

export const selectSortedSubjects = createSelector(
    (state) => state.library.subjects,
    (subjects) => sortBy(subjects, 'orderKey'),
);

export const selectSortedGrades = createSelector(
    (state) => state.library.grades,
    (grades) => sortBy(grades, 'ordinal'),
);

export const selectSortedTopics = createSelector(
    (state) => state.library.topics,
    (topics) => sortBy(topics, 'name'),
);

export const selectChosenSubject = createSelector(
    (state) => state.library.subjects,
    (state) => state.library.chosenSubjectId,
    (subjects, chosenSubjectId) => subjects.find((subject) => subject.id === chosenSubjectId),
);

export const selectChosenGrade = createSelector(
    (state) => state.library.grades,
    (state) => state.library.chosenGradeId,
    (grades, chosenGradeId) => grades.find((grade) => grade.id === chosenGradeId),
);

export const selectSortedStates = createSelector(
    (state) => state.library.states,
    selectChosenGrade,
    selectChosenSubject,
    (states, chosenGrade, chosenSubject) => {
        const results = sortBy(states, 'name');

        if (!!chosenSubject && (chosenSubject.identifier === 'math' || chosenSubject.identifier === 'german'
            || chosenSubject.identifier === 'english')) {
            if (!!chosenGrade) {
                if (/abiturvorbereitung/i.test(chosenGrade.name)) {
                    results.push({
                        id: EXAMPLE_EXAMS_FLIPBOOKS_ID,
                        name: 'Musterklausuren',
                        ordinal: 98,
                        gradeId: -1,
                        subjectId: -1,
                    });
                    results.push({
                        id: ABITUR_PREPARATION_FLIPBOOKS_ID,
                        name: 'Abiturvorbereitungsbücher',
                        ordinal: 97,
                        gradeId: -1,
                        subjectId: -1,
                    });
                } else if (/10er-abschluss/i.test(chosenGrade.name)) {
                    results.push({
                        id: TEST_PREPARATION_FLIPBOOKS_ID,
                        name: 'Prüfungsvorbereitungsbücher',
                        ordinal: 96,
                        gradeId: -1,
                        subjectId: -1,
                    });
                }
            }
        }

        return results;
    },
);

export const selectChosenState = createSelector(
    (state) => state.library.states,
    (state) => state.library.chosenStateId,
    (states, chosenStateId) => states.find((state) => state.id === chosenStateId),
);

export const selectChosenMainTopic = createSelector(
    (state) => state.library.topics,
    (state) => state.library.chosenMainTopicId,
    (topics, chosenMainTopicId) => topics.find((topic) => topic.id === chosenMainTopicId),
);

export const selectChosenBetweenTopic = createSelector(
    (state) => state.library.topics,
    (state) => state.library.chosenBetweenTopicId,
    (topics, chosenBetweenTopicId) => topics.find((topic) => topic.id === chosenBetweenTopicId),
);

export const selectChosenUnderTopic = createSelector(
    (state) => state.library.topics,
    (state) => state.library.chosenUnderTopicId,
    (topics, chosenUnderTopicId) => topics.find((topic) => topic.id === chosenUnderTopicId),
);

// Topics are always related to a grade and subject
export const selectMainTopics = createSelector(
    selectSortedTopics,
    selectChosenSubject,
    selectChosenGrade,
    (topics, subject, chosenGrade) => {
        const results = topics.filter((topic) => topic.parentId === null && topic.redirectTo === null);
        if (!!chosenGrade && /klasse\s\d+|grundschule|qualifik/i.test(chosenGrade.name)) {
            if (Object.keys(flipbookData).includes(subject?.identifier)) {
                results.push({
                    id: PRIMARY_SCHOOL_FLIPBOOKS_ID,
                    gradeId: -1,
                    subjectId: -1,
                    name: 'Digitale Bücher',
                    ordinal: 95,
                });
            }
        }
        return results;
    },
);

export const selectAllBetweenAndUnderTopics = createSelector(
    selectSortedTopics,
    (topics) => topics.filter((topic) => topic.parentId !== null && topic.redirectTo === null),
);

export const selectBetweenAndUnderTopics = createSelector(
    selectSortedTopics,
    (state) => state.library.chosenMainTopicId,
    (topics, chosenMainTopicId) => topics.filter((topic) => topic.parentId === chosenMainTopicId
        && topic.redirectTo === null),
);

export const selectBetweenTopics = createSelector(
    selectBetweenAndUnderTopics,
    (topics) => topics.filter((topic) => topic.page === false && topic.redirectTo === null),
);

export const selectUnderTopics = createSelector(
    selectBetweenAndUnderTopics,
    (topics) => topics.filter((topic) => topic.page === true && topic.redirectTo === null),
);

export const selectBetweenUnderTopics = createSelector(
    selectSortedTopics,
    (state) => state.library.chosenBetweenTopicId,
    (topics, chosenBetweenTopicId) => topics.filter((topic) => topic.parentId === chosenBetweenTopicId
        && topic.redirectTo === null),
);

export const selectVideos = createSelector(
    (state) => state.library.videos,
    (state) => state.library.showVideoCount,
    (videos) => videos
        .filter((video) => !video.isAudio),
);

export const selectAllYoutubeVideos = createSelector(
    selectVideos,
    (videos) => videos.filter((video) => !!video.youtubeId),
);

export const selectAllSDNVideos = createSelector(
    selectVideos,
    (videos) => videos.filter((video) => !!video.videoId),
);

export const selectSeoSimilarTopics = createSelector(
    (state) => state.library.similarTopics,
    (state) => state.library.chosenUnderTopicId,
    (similarTopics, chosenUnderTopic) => similarTopics.filter((similarTopic) => similarTopic.id !== chosenUnderTopic),
);

export const selectSeoUnderTopics = createSelector(
    (state) => state.library.seoGradesTopicsStructure,
    (seoGradesTopicsStructure) => seoGradesTopicsStructure.flatMap((gradeTopics) => gradeTopics.topics
        .flatMap((mainTopic) => mainTopic.underTopics)),
);

export const selectAllUsersSubjects = createSelector(
    (state) => state.account?.user?.subjects,
    (subjects) => subjects?.map((items) => items?.name).filter((n) => !!n),
);

export const selectLanguageCourseExams = createSelector(
    (state) => state.examinations.languageCourseExams,
    (locationChangeExams) => locationChangeExams.filter((languageCourseExam) => !languageCourseExam.finished),
);

export const selectProps = createSelector(
    // TODO: put this back in, when librarySearch is refactored and uses state outside the removed form component
    // (state) => state.form.librarySearch.search,
    (state) => state.account.authenticated,
    selectAllUsersSubjects,
    (state) => state.account?.user?.classlevel,
    (state) => state.librarySearch.activeSuggestions,
    (state) => state.librarySearch.chosenSuggestionSubjectId,
    (state) => state.library.parsingInProgress,
    (state) => state.library.contentRendered,
    (state) => state.library.activeTopicDropdownId,
    (state) => state.library.activeLibrary,
    (state) => state.library.canonical,
    (state) => state.library.chosenSubjectId,
    (state) => state.library.chosenGradeId,
    (state) => state.library.chosenStateId,
    (state) => state.library.chosenMainTopicId,
    (state) => state.library.chosenBetweenTopicId,
    (state) => state.library.chosenUnderTopicId,
    (state) => state.library.contentType,
    (state) => state.library.metaDescription,
    (state) => state.library.title,
    (state) => state.library.wikis,
    selectVideos,
    (state) => state.library.exams,
    (state) => state.library.topics,
    (state) => state.library.similarTopics,
    selectSeoSimilarTopics,
    (state) => state.favorites.items,
    (state) => state.courses.showCourseContactSupportModal,
    (state) => state.courses.courseContactSupportFormLoading,
    (state) => state.library.seoGradesTopicsStructure,
    (state) => state.library.selectedSeoTopic,
    selectSortedSubjects,
    selectSortedGrades,
    selectSortedStates,
    selectMainTopics,
    selectAllBetweenAndUnderTopics,
    selectBetweenTopics,
    selectUnderTopics,
    selectSeoUnderTopics,
    selectSortedExercises,
    selectChosenSubject,
    selectChosenGrade,
    selectChosenState,
    selectChosenMainTopic,
    selectChosenBetweenTopic,
    selectChosenUnderTopic,
    selectBetweenUnderTopics,
    selectAllYoutubeVideos,
    selectAllSDNVideos,
    selectLanguageCourseExams,
    (state) => state.examinations.onlineIntensiveCourseExams,
    (state) => state.account.user,
    (
        loggedIn,
        usersSubjects,
        classlevel,
        activeSuggestions,
        chosenSuggestionSubjectId,
        parsingInProgress,
        contentRendered,
        activeTopicDropdownId,
        activeLibrary,
        canonical,
        chosenSubjectId,
        chosenGradeId,
        chosenStateId,
        chosenMainTopicId,
        chosenBetweenTopicId,
        chosenUnderTopicId,
        contentType,
        metaDescription,
        title,
        wikis,
        videos,
        exams,
        topics,
        similarTopics,
        seoSimilarTopics,
        favorites,
        showCourseContactSupportModal,
        courseContactSupportFormLoading,
        seoGradesTopicsStructure,
        chosenSeoUnderTopic,
        sortedSubjects,
        sortedGrades,
        sortedStates,
        mainTopics,
        allBetweenAndUnderTopics,
        betweenTopics,
        underTopics,
        seoUnderTopics,
        sortedExercises,
        chosenSubject,
        chosenGrade,
        chosenState,
        chosenMainTopic,
        chosenBetweenTopic,
        chosenUnderTopic,
        betweenUnderTopics,
        allYoutubeVideos,
        allSDNVideos,
        allLanguageTrainingExamData,
        onlineIntensiveCourseExams,
        user,
    ) => ({
        loggedIn,
        usersSubjects,
        classlevel,
        activeSuggestions,
        chosenSuggestionSubjectId,
        parsingInProgress,
        contentRendered,
        activeTopicDropdownId,
        activeLibrary,
        canonical,
        chosenSubjectId,
        chosenGradeId,
        chosenStateId,
        chosenMainTopicId,
        chosenBetweenTopicId,
        chosenUnderTopicId,
        contentType,
        metaDescription,
        title,
        wikis,
        videos,
        exams,
        topics,
        similarTopics,
        seoSimilarTopics,
        favorites,
        showCourseContactSupportModal,
        courseContactSupportFormLoading,
        seoGradesTopicsStructure,
        chosenSeoUnderTopic,
        sortedSubjects,
        sortedGrades,
        sortedStates,
        mainTopics,
        allBetweenAndUnderTopics,
        betweenTopics,
        underTopics,
        seoUnderTopics,
        sortedExercises,
        chosenSubject,
        chosenGrade,
        chosenState,
        chosenMainTopic,
        chosenBetweenTopic,
        chosenUnderTopic,
        betweenUnderTopics,
        allYoutubeVideos,
        allSDNVideos,
        allLanguageTrainingExamData,
        onlineIntensiveCourseExams,
        user,
    }),
);

// Webinars sagas
/* IMPORTANT: This needs core-js/fn/string/includes to work */

import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchSaga } from '../../utils/network/fetch-saga';

import { getHeaders, getPortalBaseUrl, trackUserEvent, getGA4Data } from '../../utils/utils';

import { ERROR, INFO, GA } from '../../utils/constants';

import {
    GET_WEBINARS,
    REGISTER_PARTICIPANT,
    UNREGISTER_PARTICIPANT,
    getWebinarsSuccess,
} from './actions';
import { addMessage } from '../../containers/MessageModal/actions';

function processWebinarData(webinars) {
    if (webinars && webinars.length > 0) {
        webinars.forEach((webinar) => {
            const currentWebinar = webinar;
            currentWebinar.color = '#004f9f';

            // If user is participant in this webinar.
            if (currentWebinar.isParticipant) {
                // Add registered label to webinar title.
                currentWebinar.title += ' (angemeldet)';
            }
        });
    }
    return webinars;
}

// Workers.
export function* getWebinars(action) {
    const baseUrl = getPortalBaseUrl();
    const url = `${baseUrl}/webinars?subject=${action.payload}`;
    const headers = getHeaders(true);
    try {
        const data = yield call(fetchSaga, url, { method: 'GET', headers });
        if (data) {
            yield put(getWebinarsSuccess(processWebinarData(data)));
        }
    } catch (e) {
        if (e.message === 'server error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (e.message === 'network error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else {
            yield put(addMessage(ERROR, e.message));
        }
    }
}

export function* registerParticipant(action) {
    const {
        email,
        firstname,
        intl,
        lastname,
        webinarDate,
        webinarId,
        subject,
        title,
    } = action.payload;

    const baseUrl = getPortalBaseUrl();
    const url = `${baseUrl}/webinars/${webinarId}/participants`;
    const ga4Data = getGA4Data();
    const headers = getHeaders(true);
    try {
        const data = yield call(fetchSaga, url, { method: 'POST',
            headers,
            body: JSON.stringify({
                firstname, lastname, email, webinarDate,
            }) });
        if (data) {
            yield getWebinars({
                payload: subject,
                intl,
            });
            trackUserEvent({
                trackGA: false,
                trackUA: true,
                category: GA.CATEGORIES.WEBINAR,
                action: GA.ACTIONS.WEBINAR_REGISTERED,
                label: title,
                value: webinarDate,
            });

            trackUserEvent({
                trackGA: false,
                trackGA4: true,
                category: GA.GA4CATEGORIES.FORM_SUBMISSION,
                ga4Data: {
                    form_type: GA.ACTIONS.WEBINAR_REGISTERED,
                    webinar_date: webinarDate,
                    page_path: ga4Data.page_path,
                    date: ga4Data.date,
                    time: ga4Data.time,
                },
            });
            yield put(addMessage(INFO, intl.formatMessage({ id: 'webinars.registerSuccess' })));
        }
    } catch (e) {
        if (e.message === 'server error') {
            yield put(addMessage(ERROR, intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (e.message === 'network error') {
            yield put(addMessage(ERROR, intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else if (e.message === 'Webinar has no free slots left.') {
            yield put(addMessage(INFO,
                intl.formatMessage({ id: 'errorMessages.addParticipantToWebinarError' })));
        } else {
            yield put(addMessage(ERROR, e.message));
        }
    }
}

export function* unregisterParticipant(action) {
    const { userData, intl, webinarId, webinarDate, subject, title } = action.payload;
    const { email } = userData;

    const url = `${getPortalBaseUrl()}/webinars/${webinarId}/participants`;
    const ga4Data = getGA4Data();
    const headers = getHeaders(true);
    try {
        const data = yield call(fetchSaga, url, { method: 'DELETE', headers, body: JSON.stringify({ email }) });
        if (data) {
            yield getWebinars({
                payload: subject,
                intl,
            });
            trackUserEvent({
                trackGA: false,
                trackUA: true,
                category: GA.CATEGORIES.WEBINAR,
                action: GA.ACTIONS.WEBINAR_UNREGISTERED,
                label: title,
                value: webinarDate,
            });

            trackUserEvent({
                trackGA: false,
                trackGA4: true,
                category: GA.GA4CATEGORIES.FORM_SUBMISSION,
                ga4Data: {
                    form_type: GA.ACTIONS.WEBINAR_UNREGISTERED,
                    webinar_date: webinarDate,
                    page_path: ga4Data.page_path,
                    date: ga4Data.date,
                    time: ga4Data.time,
                },
            });
            yield put(addMessage(INFO, intl.formatMessage({ id: 'webinars.unregisterSuccess' })));
        }
    } catch (e) {
        if (e.message === 'server error') {
            yield put(addMessage(ERROR, intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (e.message === 'network error') {
            yield put(addMessage(ERROR, intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else {
            yield put(addMessage(ERROR, e.message));
        }
    }
}

// Watchers.
export function* waitForWebinarsWereFetched() {
    yield takeEvery(GET_WEBINARS, getWebinars);
}

export function* waitParticipantWasRegistered() {
    yield takeEvery(REGISTER_PARTICIPANT, registerParticipant);
}

export function* waitParticipantWasUnregistered() {
    yield takeEvery(UNREGISTER_PARTICIPANT, unregisterParticipant);
}

export const webinarsSaga = [
    waitForWebinarsWereFetched(),
    waitParticipantWasRegistered(),
    waitParticipantWasUnregistered(),
];

// LibrarySearch sagas

import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import { fetchSaga } from '../../utils/network/fetch-saga';
import { slugify, getBibBaseUrl, isTrackingAllowed, trackUserEvent, getGA4Data } from '../../utils/utils';
import { fetchSubjects } from '../../routes/Library/sagas';

import {
    SEARCH_REQUEST,
    CHOOSE_SUGGESTION,
    setSuggestions,
    resetSuggestions,
    hideSuggestions,
    chooseSuggestionSubject,
} from './actions';

import {
    showLibrary,
    hideLibrary,
    setGrades,
    setTopics,
} from '../../routes/Library/actions';

import {
    selectSubjectsWithSuggestions,
    selectSubjectSuggestionLengthObj,
} from './selectors';

import {
    selectChosenSubject,
} from '../../routes/Library/selectors';
import { GA } from '../../utils/constants';

// Workers
export function* fetchSuggestions(action) {
    const searchTerm = action.payload;
    const bibBaseUrl = getBibBaseUrl();
    const maxResultQuery = !!action.maxResult ? `&m=${action.maxResult}` : '';
    const url = `${bibBaseUrl}/search?q=${encodeURIComponent(searchTerm)}*&t=topic${maxResultQuery}`;
    const ga4Data = getGA4Data();

    if (isTrackingAllowed()) {
        trackUserEvent({
            trackUA: true, // since only logged in users can see a search bar
            category: GA.CATEGORIES.SEARCH,
            action: GA.ACTIONS.SEARCH_TRIGGERED,
            label: searchTerm,
        });

        trackUserEvent({
            trackGA: false,
            trackGA4: true,
            category: GA.GA4CATEGORIES.CLICK_SEARCH,
            ga4Data: {
                suchbegriff: searchTerm,
                page_path: ga4Data.page_path,
                date: ga4Data.date,
                time: ga4Data.time,
            },
        });
    }

    const data = yield call(fetchSaga, url);
    if (data) {
        yield put(setSuggestions(data));
        const subjectsWithSuggestions = yield select(selectSubjectsWithSuggestions);
        const subjectSuggestionLengthObj = yield select(selectSubjectSuggestionLengthObj);

        const maxValue = Math.max(
            subjectSuggestionLengthObj.math,
            subjectSuggestionLengthObj.german,
            subjectSuggestionLengthObj.english,
            subjectSuggestionLengthObj.latin,
            subjectSuggestionLengthObj.french,
        );
        if (maxValue && subjectsWithSuggestions.length) {
            const highestSubject = subjectsWithSuggestions.find(
                (subject) => subjectSuggestionLengthObj[subject.identifier] === maxValue,
            );
            yield put(chooseSuggestionSubject(highestSubject.id));
        }
    }
}

export function* fetchSuggestionRelations(action) {
    const { id, subjectId, gradeId, history } = action;
    const bibBaseUrl = getBibBaseUrl();

    const subjects = yield select((state) => state.library.subjects);
    if (subjects === undefined || subjects.length === 0) {
        yield call(fetchSubjects);
    }

    // Hide Library to completely set the new state
    yield put(hideLibrary());
    // correct, effects will get executed in parallel
    const [grades, topics] = yield all([
        call(fetchSaga, `${bibBaseUrl}/subjects/${subjectId}/grades`),
        call(fetchSaga, `${bibBaseUrl}/subjects/${subjectId}/grades/${gradeId}/topics`),
    ]);
    yield put(setGrades(grades));
    yield put(setTopics(topics));

    // write selectors.
    const chosenSubject = yield select(selectChosenSubject);
    const underTopic = topics.find((topic) => topic.id === id);
    const betweenTopic = topics.find((topic) => (topic.id === underTopic.parentId) && (topic.parentId !== null));
    const chosenGrade = grades.find((grade) => grade.id === gradeId);
    yield put(showLibrary());
    yield put(hideSuggestions());

    const subjectUrl = `${chosenSubject.id}-${slugify(chosenSubject.name)}`;
    const gradeUrl = `${chosenGrade.id}-${slugify(chosenGrade.name)}`;
    const underTopicUrl = `${underTopic.id}-${slugify(underTopic.name)}`;

    if (!!betweenTopic) {
        const betweenMainTopic = topics.find((topic) => topic.id === betweenTopic.parentId);
        const mainTopicUrl = `${betweenMainTopic.id}-${slugify(betweenMainTopic.name)}`;
        const betweenUrl = `${betweenTopic.id}-${slugify(betweenTopic.name)}`;

        history.push(`/${subjectUrl}/${gradeUrl}/${mainTopicUrl}/${betweenUrl}/${underTopicUrl}`);

    } else {
        const mainTopic = topics.find((topic) => topic.id === underTopic.parentId);
        const mainTopicUrl = `${mainTopic.id}-${slugify(mainTopic.name)}`;

        history.push(`/${subjectUrl}/${gradeUrl}/${mainTopicUrl}/${underTopicUrl}`);
    }
    yield put(resetSuggestions());
}

// Watchers
export function* waitForSearchRequest() {
    yield takeEvery(SEARCH_REQUEST, fetchSuggestions);
}

export function* waitForSuggestionWasChosen() {
    yield takeEvery(CHOOSE_SUGGESTION, fetchSuggestionRelations);
}

export const LibrarySearchSaga = [
    waitForSearchRequest(),
    waitForSuggestionWasChosen(),
];

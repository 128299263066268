// Dashboard action types and creators

export const SET_SHOW_PROFILE_DIALOG = 'containers/Dashboard/setShowProfileDialog';
export const SET_PROFILE_DIALOG_CLOSED = 'containers/Dashboard/setProfileDialogClosed';
export const FETCH_KIRA_INTRODUCTION_VIDEO = 'containers/Dashboard/fetchKiraIntroductionVideo';
export const SET_KIRA_INTRODUCTION_VIDEO_DATA = 'containers/Dashboard/setKiraIntroductionVideoData';
export const UPDATE_KIRA_MODAL_SHOWN = 'containers/Dashboard/updateKiraModalShown';

export const setShowProfileDialog = (showProfileDialog) => (
    {
        type: SET_SHOW_PROFILE_DIALOG,
        payload: showProfileDialog,
    }
);

export const setProfileDialogClosed = (profileDialogClosed) => (
    {
        type: SET_PROFILE_DIALOG_CLOSED,
        payload: profileDialogClosed,
    }
);

export const fetchKiraIntroductionVideo = (videoId) => (
    {
        type: FETCH_KIRA_INTRODUCTION_VIDEO,
        payload: videoId,
    }
);

export const setKiraIntroductionVideoData = (videoData) => (
    {
        type: SET_KIRA_INTRODUCTION_VIDEO_DATA,
        payload: videoData,
    }
);

export const updateKiraModalShown = (userId, kiraModalWasShown, kiraFeedbackModalWasShown, intl) => ({
    type: UPDATE_KIRA_MODAL_SHOWN,
    payload: {
        userId,
        kiraModalWasShown,
        kiraFeedbackModalWasShown,
    },
    intl,
});

// Favorites action types and creators

export const GET_FAVORITES_FOR_USER = 'views/Favorite/getFavoritesForUser';
export const GET_FAVORITE = 'views/Favorite/getFavorite';
export const CREATE_FAVORITE = 'views/Favorite/createFavorite';
export const DELETE_FAVORITE = 'views/Favorite/deleteFavorite';
export const SET_FAVORITES_FOR_USER = 'views/Favorite/setFavoritesFromUser';
export const SET_FAVORITE = 'views/Favorite/setFavorite';
export const RESET_STATE = 'views/favorite/cleanState';

export const getFavoritesForUser = (userId) => ({
    type: GET_FAVORITES_FOR_USER,
    payload: userId,
});

export const getFavorite = (favoriteId) => ({
    type: GET_FAVORITE,
    payload: favoriteId,
});

export const setFavorite = (favorite) => ({
    type: SET_FAVORITE,
    payload: favorite,
});

export const setFavoritesForUser = (favorites) => ({
    type: SET_FAVORITES_FOR_USER,
    payload: favorites,
});

export const createFavorite = (url, type, name, userId, objectId, subject, grade) => ({
    type: CREATE_FAVORITE,
    payload: {
        url,
        type,
        name,
        userId,
        objectId,
        subject,
        grade,
    },
});

export const deleteFavorite = (favoriteId) => ({
    type: DELETE_FAVORITE,
    payload: favoriteId,
});

export const cleanFavoriteState = () => ({
    type: RESET_STATE,
});

// LibrarySearch selectors

import { createSelector } from 'reselect';
import { selectSortedSubjects } from '../../routes/Library/selectors';

export const selectMathSuggestions = createSelector(
    (state) => state.librarySearch.suggestions.hits,
    (suggestions) => suggestions.filter((suggestion) => suggestion.fields.subjectIdentifier === 'math'),
);

export const selectGermanSuggestions = createSelector(
    (state) => state.librarySearch.suggestions.hits,
    (suggestions) => suggestions.filter((suggestion) => suggestion.fields.subjectIdentifier === 'german'),
);

export const selectEnglishSuggestions = createSelector(
    (state) => state.librarySearch.suggestions.hits,
    (suggestions) => suggestions.filter((suggestion) => suggestion.fields.subjectIdentifier === 'english'),
);

export const selectLatinSuggestions = createSelector(
    (state) => state.librarySearch.suggestions.hits,
    (suggestions) => suggestions.filter((suggestion) => suggestion.fields.subjectIdentifier === 'latin'),
);

export const selectFrenchSuggestions = createSelector(
    (state) => state.librarySearch.suggestions.hits,
    (suggestions) => suggestions.filter((suggestion) => suggestion.fields.subjectIdentifier === 'french'),
);

export const selectChosenSuggestionSubject = createSelector(
    (state) => state.librarySearch.chosenSuggestionSubjectId,
    selectSortedSubjects,
    (chosenSuggestionSubjectId, subjects) => subjects.find((subject) => subject.id === chosenSuggestionSubjectId),
);

export const selectSubjectSuggestionLengthObj = createSelector(
    selectMathSuggestions,
    selectGermanSuggestions,
    selectEnglishSuggestions,
    selectLatinSuggestions,
    selectFrenchSuggestions,
    (mathSuggestions, germanSuggestions, englishSuggestions, latinSuggestions, frenchSuggestions) => ({
        math: mathSuggestions.length,
        german: germanSuggestions.length,
        english: englishSuggestions.length,
        latin: latinSuggestions.length,
        french: frenchSuggestions.length,
    }),
);

export const selectSubjectsWithSuggestions = createSelector(
    selectSortedSubjects,
    selectMathSuggestions,
    selectGermanSuggestions,
    selectEnglishSuggestions,
    selectLatinSuggestions,
    selectFrenchSuggestions,
    (subjects, mathSuggestions, germanSuggestions, englishSuggestions, latinSuggestions,
        frenchSuggestions) => subjects.filter((subject) => (mathSuggestions.length > 0 && subject.identifier === 'math')
            || (germanSuggestions.length > 0 && subject.identifier === 'german')
            || (englishSuggestions.length > 0 && subject.identifier === 'english')
            || (latinSuggestions.length > 0 && subject.identifier === 'latin')
            || (frenchSuggestions.length > 0 && subject.identifier === 'french')),
);

// selectProps...
export const selectLibrarySearchProps = createSelector(
    (state) => state.librarySearch,
    (state) => state.librarySearch.activeSuggestionNavigation,
    (form, activeSuggestionNavigation) => ({
        form,
        activeSuggestionNavigation,
    }),
);

export const selectLibrarySuggestionsProps = createSelector(
    (state) => state.librarySearch.suggestions.totalHitsCount,
    selectChosenSuggestionSubject,
    selectMathSuggestions,
    selectGermanSuggestions,
    selectEnglishSuggestions,
    selectLatinSuggestions,
    selectFrenchSuggestions,
    selectSubjectsWithSuggestions,
    (suggestionsHits, chosenSuggestionSubject, mathSuggestions, germanSuggestions, englishSuggestions, latinSuggestions,
        frenchSuggestions, subjectsWithSuggestions) => ({
        suggestionsHits,
        chosenSuggestionSubject,
        mathSuggestions,
        germanSuggestions,
        englishSuggestions,
        latinSuggestions,
        frenchSuggestions,
        subjectsWithSuggestions,
    }),
);

export const selectSuggestionsProps = createSelector(
    (state) => state.librarySearch.suggestions.totalHitsCount,
    selectMathSuggestions,
    selectGermanSuggestions,
    selectEnglishSuggestions,
    selectLatinSuggestions,
    selectFrenchSuggestions,
    (suggestionsHitsCount, mathSuggestions, germanSuggestions, englishSuggestions, latinSuggestions,
        frenchSuggestions) => ({
        suggestionsHitsCount,
        mathSuggestions,
        germanSuggestions,
        englishSuggestions,
        latinSuggestions,
        frenchSuggestions,
    }),
);

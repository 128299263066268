import { ADD_MESSAGE, REMOVE_MESSAGE, CLEAR_MESSAGES } from './actions';

const initialState = {
    messageIdSequence: 0,
    messages: [],
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
    case ADD_MESSAGE: {
        return {
            ...state,
            messageIdSequence: state.messageIdSequence + 1,
            messages: [
                ...state.messages,
                {
                    id: state.messageIdSequence,
                    ...action.payload,
                },
            ],
        };
    }
    case REMOVE_MESSAGE: {
        return {
            ...state,
            messages: [
                ...state.messages.filter((m) => m.id !== action.payload),
            ],
        };
    }
    case CLEAR_MESSAGES: {
        return initialState;
    }
    default:
        return state;
    }
};

export default reducer;

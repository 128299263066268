// EmployeeTestRegisterFormContainer action types and creators

export const REGISTER_USER = 'routes/KnowledgeCheckRegisterFormContainer/registerUser';
export const REGISTER_USER_SUCCESS = 'routes/KnowledgeCheckRegisterFormContainer/registerUserSuccess';
export const RELEASE_BUTTON = 'routes/KnowledgeCheckRegisterFormContainer/releaseButton';

export function registerUser(userData) {
    return {
        type: REGISTER_USER,
        payload: userData,
    };
}

export function registerUserSuccess(userData) {
    return {
        type: REGISTER_USER_SUCCESS,
        payload: userData,
    };
}

export function releaseButton(value) {
    return {
        type: RELEASE_BUTTON,
        payload: value,
    };
}

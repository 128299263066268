// LisaRegisterFormContainer action types and creators

export const SET_RENDER_LISA_PAGE = 'routes/LisaRegisterFormContainer/setRenderLisaPage';
export const GET_RENDER_LISA_PAGE = 'routes/LisaRegisterFormContainer/getRenderLisaPage';
export const REGISTER_USER = 'routes/LisaRegisterFormContainer/registerUser';
export const SET_LISA_USER = 'routes/LisaRegisterFormContainer/setLisaUser';
export const SUBMIT_BUTTON = 'routes/LisaRegisterFormContainer/submitButton';
export const REGISTER_USER_SUCCESS = 'routes/LisaRegisterFormContainer/registerUserSuccess';

export function setRenderLisaPage(render) {
    return {
        type: SET_RENDER_LISA_PAGE,
        payload: render,
    };
}

export function getRenderLisaPage() {
    return {
        type: GET_RENDER_LISA_PAGE,
    };
}

export function registerUser(userData) {
    return {
        type: REGISTER_USER,
        payload: userData,
    };
}

export function setLisaUser(user) {
    return {
        type: SET_LISA_USER,
        payload: user,
    };
}

export function submitButton(value) {
    return {
        type: SUBMIT_BUTTON,
        payload: value,
    };
}

export function registerUserSuccess(state) {
    return {
        type: REGISTER_USER_SUCCESS,
        payload: state,
    };
}

// Abos sagas

import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { fetchSaga } from '../../utils/network/fetch-saga';
import { isTrackingAllowed, getHeaders, trackUserEvent, pushToDataLayer, getGA4Data } from '../../utils/utils';
import { BASIC_ID, BASIC_NAME, GA, PREMIUM_NAME } from '../../utils/constants';

import {
    INITIALIZE_PAYMENT,
    setNovalnetData,
    SET_ABO_ID,
} from './actions';

import { setAboToUserData } from '../TestAccountRegisterFormContainer/actions';

// Helpers
export function getPortalBaseUrl() {
    return process.env.REACT_APP_API_PORTAL_URL;
}

export function getIdpBaseUrl() {
    return process.env.REACT_APP_API_IDP_URL;
}

export function* createWeborder(paymentData) {
    const baseUrl = getIdpBaseUrl();
    const url = `${baseUrl}/account/add-weborder`;
    const headers = getHeaders();
    // generate body from payment data
    const webOrderData = {
        user_firstname: paymentData.user_firstname,
        user_lastname: paymentData.user_lastname,
        user_email: paymentData.user_email,
        invoice_firstname: paymentData.first_name,
        invoice_lastname: paymentData.last_name,
        invoice_street: paymentData.street,
        invoice_postalcode: paymentData.zip,
        invoice_city: paymentData.city,
        invoice_country: paymentData.country_code,
        invoice_email: paymentData.email,
    };

    try {
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify(webOrderData) });
        if (data) {
            const cleanPaymentData = paymentData;

            // Cleanup payload.
            delete cleanPaymentData.user_firstname;
            delete cleanPaymentData.user_lastname;
            delete cleanPaymentData.user_email;

            // Set invoice and order number to received web order numbers.
            cleanPaymentData.invoice_ref = 'DIG'.concat(data.invoice_number.toString().padStart(7, '0'));
            cleanPaymentData.order_no = 'ONR'.concat(data.id.toString().padStart(7, '0'));
            // ok

            const { isAboBasic } = paymentData;
            pushToDataLayer({
                event: 'gaEvent',
                eventCategory: GA.CATEGORIES.REGISTRATION_PROCESS_OLC,
                eventAction: GA.ACTIONS.PAYMENT_PROCESS_BASIC_AND_PREMIUM,
                eventLabel: !!isAboBasic
                    ? GA.LABELS.PAYMENT_DATA_BASIS
                    : GA.LABELS.PAYMENT_DATA_PREMIUM,
            });
            // Set novalnet data to load iframe.
            yield put(setNovalnetData(cleanPaymentData));
        }
    } catch (e) {
        if (e.message !== 'server error' && e.message !== 'network error') {
            console.error(e.message);
        }
    }
}

// Workers
export function* initializePayment(action) {
    const baseUrl = getPortalBaseUrl();
    const url = `${baseUrl}/payments/${action.productId}`;
    const headers = getHeaders();
    const ga4Data = getGA4Data();

    try {
        const data = yield call(fetchSaga, url, { method: 'GET', headers });
        if (data) {
            const isAboBasic = action.productId === BASIC_ID;
            const aboName = (isAboBasic ? BASIC_NAME : PREMIUM_NAME);

            if (isTrackingAllowed()) {
                trackUserEvent({
                    trackUA: true,
                    category: GA.CATEGORIES.ABO,
                    action: GA.ACTIONS.PAYMENT,
                    label: `${aboName} - step 2`,
                    value: action.productId,
                });

                trackUserEvent({
                    trackGA: false,
                    trackGA4: true,
                    category: GA.GA4CATEGORIES.FORM_SUBMISSION,
                    ga4Data: {
                        form_type: GA.ACTIONS.PAYMENT,
                        interaction_type: `${aboName} - step 2`,
                        name: action.productId,
                        page_path: ga4Data.page_path,
                        date: ga4Data.date,
                        time: ga4Data.time,
                    },
                });
            }

            // call saga to create web order
            yield call(createWeborder, { ...data, ...action.payload, isAboBasic });
        }
    } catch (e) {
        if (e.message !== 'server error' && e.message !== 'network error') {
            console.error(e.message);
        }
    }
}

export function* setAboId(action) {
    yield put(setAboToUserData(action.payload.aboId));
    yield put(push('abos-und-preise'));
}

// Watchers
export function* waitForPaymentWasInitialized() {
    yield takeEvery(INITIALIZE_PAYMENT, initializePayment);
}

export function* waitForSetAbo() {
    yield takeEvery(SET_ABO_ID, setAboId);
}

export const abosSaga = [
    waitForPaymentWasInitialized(),
    waitForSetAbo(),
];

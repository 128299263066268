/* eslint-disable camelcase */

import { all, call, put, takeEvery } from 'redux-saga/effects';
import { fetchSaga } from '../../utils/network/fetch-saga';
import store from '../../store/storeHolder';

import { fetchUser, saveAndFetchUser } from '../Account/sagas';
import { authenticateOlatUser } from '../../containers/Library/Exams/sagas';
import {
    GET_EXAMS_ADMIN,
    GET_USER_EXAM_DATA,
    GET_USER_ASSESSMENT_ENTRIES,
    GET_EXAMINATION_DATA,
    GET_EXAMINATION_PREVIEW_LINK,
    GET_STUDENT_DATA,
    GET_USER_CENTERS,
    GET_CENTER_USERS,
    ADD_USER_TO_EXAM,
    CREATE_OLAT_TEACHER,
    CREATE_STUDENT_AND_ADD_TO_EXAM,
    CREATE_OLAT_STUDENT_AND_ADD_TO_EXAM,
    RESET_USER_EXAM,
    GET_USER_X_OLAT_TOKEN,
    DOWNLOAD_RESULTS_PDF,
    CHECK_RESULTS_EXPORTS,
    OPEN_EXAMINATION_PREVIEW_LINK,
    INITIALIZE_EXAMS_ROUTE,
    setExamsAdmin,
    getUserExamData,
    setUserExamData,
    setUserAssessmentEntries,
    setUserHasNoExamData,
    setIsUserExamDataBeingFetched,
    getExaminationDataError,
    getExaminationPreviewLinkError,
    setUserCenters,
    getUserCentersError,
    setCenterUsers,
    getCenterUsersError,
    setExaminationData,
    setExaminationPreviewLink,
    setStudentExists,
    getStudentDataError,
    addUserToExam,
    setShowLoadingModal,
    getUserXOlatToken,
    setUserXOlatToken,
    setIsUserXOlatTokenBeingFetched,
    checkResultsExports,
    createOlatTeacher,
    setUserLanguageCourseExamData,
    setUserOnlineIntensiveCourseExamData,
    setCourses,
    GET_EXAMINATIONS_FOR_SUBJECT,
    setAddUserToExamRequestRunning,
} from './actions';
import { getUserCenters, setUser, saveUser } from '../Account/actions';
import { addMessage } from '../../containers/MessageModal/actions';

// Helpers
import { ERROR, OLAT_EXAM_TYPE, OPEN_OLAT_INSTANCE, StatusCodes } from '../../utils/constants';
import { storage } from '../../utils/storage';
import {
    getPortalBaseUrl,
    getIdpBaseUrl,
    getBibBaseUrl,
    getOlatExamsBaseUrl,
    getHeaders,
    isEmpty,
    objectEmpty,
    getUserData,
    handleUserWithoutEmailAddress,
} from '../../utils/utils';

export function* handleGetExamsAdmin(action) {
    const { userId, intl } = action;
    try {
        const user = yield call(fetchUser, userId);
        yield put(setExamsAdmin(user));
    } catch (e) {
        yield put(addMessage(ERROR, intl.formatMessage({ id: 'errorMessages.userDataError' })));
    }
}

export function* handleGetStudentData(action) {
    const baseUrl = getPortalBaseUrl();
    const url = `${baseUrl}/exams/student?email=${action.payload}`;
    const headers = getHeaders(true);
    try {
        const data = yield call(fetchSaga, url, { method: 'GET', headers });
        if (data) {
            yield put(setStudentExists(true));
        }
    } catch (e) {
        yield put(setStudentExists(false));
        // TODO: refactor error handling to use addMessage
        if (e.message === 'server error') {
            yield put(getStudentDataError(action.intl.messages['errorMessages.serverError']));
        } else if (e.message === 'network error') {
            yield put(getStudentDataError(action.intl.messages['errorMessages.networkError']));
        }
    }
}

const getExamDataQueryString = (action, page, isOpenOlatExamPlatform) => {
    const { email, openOlatId, category, subject, openOlatInstance, olatExamType } = action.payload;
    const olatExamTypeQueryString = !!olatExamType ? `olatExamType=${olatExamType}` : '';
    return isOpenOlatExamPlatform
        ? `?olatInstance=${openOlatInstance}&userId=${openOlatId}&subject=${subject}`
            + `&${olatExamTypeQueryString}&page=${page}&pageSize=1000`
        : `?studentEmail=${email}&category=${category}&subject=${subject}&page=${page}&pageSize=1000`;
};

function* fetchUserExamData(action, isOpenOlatExamPlatform) {
    try {
        const endpoint = `${getPortalBaseUrl()}/exams`;
        const headers = getHeaders(true);
        let url = `${endpoint}${getExamDataQueryString(action, 1, isOpenOlatExamPlatform)}`;
        let exams = [];
        let response = yield call(fetchSaga, url, { method: 'GET', headers });
        if (response) {
            exams = response.items;
            while (response.page && response.page_count && response.page < response.page_count) {
                url = `${endpoint}${getExamDataQueryString(action, response.page + 1, isOpenOlatExamPlatform)}`;
                response = yield call(fetchSaga, url, { method: 'GET', headers });
                if (response) {
                    exams = [...exams, ...response.items];
                }
            }
        }

        return exams;
    } catch (e) {
        return null;
    }
}

export function* handleGetUserExamData(action) {
    yield put(setIsUserExamDataBeingFetched(true));

    try {
        const {
            openOlat,
            subjects,
            category,
            isForDashboard,
            isOnlineInensiveCourse,
            olatExamType,
        } = action.payload;
        // const oldExams = !!email ? yield call(fetchUserExamData, action, false) : [];

        const openOlatExams = [];
        if (!!openOlat?.openOlat && openOlat?.openOlat?.id !== null) {
            // eslint-disable-next-line no-restricted-syntax
            for (const subject of subjects) {
                const openOlatAction = {
                    payload: {
                        openOlatInstance: openOlat?.openOlat?.instance,
                        openOlatId: openOlat?.openOlat?.id,
                        olatExamType,
                        subject,
                        category,
                    },
                };
                const openOlatResponse = yield call(fetchUserExamData, openOlatAction, true);
                openOlatExams.push(...openOlatResponse || []);
            }
        }

        const languageCourseExams = [];
        if (!!openOlat?.languageCourseOpenOlat && openOlat?.languageCourseOpenOlat?.id !== null) {
            // eslint-disable-next-line no-restricted-syntax
            for (const subject of subjects) {
                const openOlatLanguageCourseAction = {
                    payload: {
                        openOlatInstance: openOlat?.languageCourseOpenOlat?.instance,
                        openOlatId: openOlat?.languageCourseOpenOlat?.id,
                        olatExamType,
                        subject,
                        category,
                    },
                };
                const LanguageCourseResponse = yield call(fetchUserExamData, openOlatLanguageCourseAction, true);
                languageCourseExams.push(...LanguageCourseResponse || []);
            }
        }

        const allExams = [];
        // if (!!oldExams && !isEmpty(oldExams)) {
        //  allExams.push(...oldExams);
        // }
        if (!!openOlatExams && !isEmpty(openOlatExams)) {
            allExams.push(...openOlatExams);
        }
        if (!!languageCourseExams && !isEmpty(languageCourseExams)) {
            allExams.push(...languageCourseExams);
        }
        const userHasNoExamData = allExams.length === 0;

        if (isForDashboard && isOnlineInensiveCourse) {
            yield put(setUserOnlineIntensiveCourseExamData(allExams.filter((exam) => exam.name.startsWith('Abikurs'))));
        } else if (isForDashboard) {
            yield put(setUserLanguageCourseExamData(allExams));
        } else {
            yield put(setUserExamData(allExams));
        }
        yield put(setUserHasNoExamData(userHasNoExamData));

        // on dashboard there is no pdf download button
        if (!isEmpty(openOlatExams) && !isForDashboard) {
            yield put(checkResultsExports(allExams));
        }
        if (!isEmpty(languageCourseExams) && !isForDashboard) {
            yield put(checkResultsExports(allExams));
        }
    } catch (e) {
        yield put(setUserExamData([]));

        if (e.reason === 'server error') {
            yield put(addMessage(ERROR, action.intl.messages['errorMessages.serverError']));
        } else if (e.reason === 'network error') {
            yield put(addMessage(ERROR, action.intl.messages['errorMessages.networkError']));
        }
    }

    yield put(setIsUserExamDataBeingFetched(false));
}

function* checkOlatResultsExport(exam) {
    const { repoEntryKey, studentKey, assessmentKey } = exam;

    if (!studentKey || !assessmentKey) {
        return { ...exam, hasPdfExport: false };
    }

    let filename = `Testergebnisse_${repoEntryKey}_${studentKey}_${assessmentKey}`;
    if (!!exam?.olatInstance && OPEN_OLAT_INSTANCE.OPEN_OLAT_LANGUAGE_COURSES === exam.olatInstance) {
        filename = `SLK_${filename}`;
    }

    const endpoint = `${getBibBaseUrl()}/results/check`;
    const url = `${endpoint}?filename=${filename}`;
    const headers = getHeaders(true);

    try {
        yield call(fetchSaga, url, { method: 'GET', headers });
        return { ...exam, hasPdfExport: true };
    } catch (e) {
        // Response 404 (not found) is not an error in this case.
        return { ...exam, hasPdfExport: false };
    }
}

export function* handleGetUserAssessmentEntries(action) {
    const { openOlatInstance, repoEntryKey, studentKey } = action.payload;
    try {
        const endpoint = `${getPortalBaseUrl()}/exams`;
        const headers = getHeaders(true);
        const urlParameters = `?olatInstance=${openOlatInstance}&studentKey=${studentKey}&repoEntryKey=${repoEntryKey}`;
        const url = `${endpoint}/course-entries${urlParameters}`;
        let assessmentEntries = [];
        const updatedAssessmentEntries = [];
        const response = yield call(fetchSaga, url, { method: 'GET', headers });

        if (response) {
            assessmentEntries = response;

            // eslint-disable-next-line no-restricted-syntax
            for (const assessmentEntry of assessmentEntries) {
                if (assessmentEntry.type === 'iqtest') {
                    updatedAssessmentEntries.push(yield call(
                        checkOlatResultsExport, {
                            ...assessmentEntry,
                            olatInstance: OPEN_OLAT_INSTANCE.OPEN_OLAT_LANGUAGE_COURSES,
                        },
                    ));
                } else {
                    updatedAssessmentEntries.push({ ...assessmentEntry, hasPdfExport: false });
                }
            }
        }

        yield put(setUserAssessmentEntries(repoEntryKey, updatedAssessmentEntries));
    } catch (e) {
        yield put(setUserAssessmentEntries(repoEntryKey, null));
    }
}

function* handleCheckResultsExports(action) {
    const { exams } = action;
    const updatedExams = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const exam of exams) {
        if (!!exam.repoEntryKey) {
            updatedExams.push(yield call(checkOlatResultsExport, exam));
        } else {
            updatedExams.push({ ...exam });
        }
    }

    yield put(setUserExamData(updatedExams));
}

export function* handleGetExaminationsForSubject(action) {
    const baseUrl = getBibBaseUrl();
    const { productShort, subject } = action;
    const isEntryCheck = (productShort !== 'SPT');
    const isOnlineIntensiveCourse = (productShort !== 'SPT');
    let url = `${baseUrl}/exams?pageSize=1000&isEntryCheck=${isEntryCheck}`
        + `&isOnlineIntensiveCourse=${isOnlineIntensiveCourse}&productShort=${productShort}&subjectShort=${subject}`;
    const headers = getHeaders(true);
    try {
        let data = yield call(fetchSaga, url, { method: 'GET', headers });
        if (data) {
            let courses = data.items;
            while (data.page && data.page_count && data.page < data.page_count) {
                url = `${url}&page=${data.page + 1}`;
                data = yield call(fetchSaga, url, { method: 'GET', headers });
                if (data) {
                    courses = [...courses, ...data.items];
                }
            }
            yield put(setCourses(courses));
        }
    } catch (e) {
        yield put(setExaminationData([]));
        // TODO: refactor error handling to use addMessage
        if (e.message === 'server error') {
            yield put(getExaminationDataError(action.intl.messages['errorMessages.serverError']));
        } else if (e.message === 'network error') {
            yield put(getExaminationDataError(action.intl.messages['errorMessages.networkError']));
        } else {
            yield put(addMessage('error', action.intl.messages['errorMessages.error'], e.message));
        }
    }
}

export function* handleGetExaminationData(action) {
    const baseUrl = getBibBaseUrl();
    let url = `${baseUrl}/exams?pageSize=1000`;
    const headers = getHeaders(true);
    try {
        let data = yield call(fetchSaga, url, { method: 'GET', headers });
        if (data) {
            let examinations = data.items;
            while (data.page && data.page_count && data.page < data.page_count) {
                url = `${baseUrl}/exams?page=${data.page + 1}&pageSize=1000`;
                data = yield call(fetchSaga, url, { method: 'GET', headers });
                if (data) {
                    examinations = [...examinations, ...data.items];
                }
            }
            yield put(setExaminationData(examinations));
        }
    } catch (e) {
        yield put(setExaminationData([]));
        // TODO: refactor error handling to use addMessage
        if (e.message === 'server error') {
            yield put(getExaminationDataError(action.intl.messages['errorMessages.serverError']));
        } else if (e.message === 'network error') {
            yield put(getExaminationDataError(action.intl.messages['errorMessages.networkError']));
        } else {
            yield put(addMessage('error', action.intl.messages['errorMessages.error'], e.message));
        }
    }
}

export function* handleGetExaminationPreviewLink(action) {
    const { payload, intl } = action;
    const url = `${getPortalBaseUrl()}/exams/${payload}`;
    const headers = getHeaders(true);
    try {
        const data = yield call(fetchSaga, url, { method: 'GET', headers });
        if (data) {
            yield put(setExaminationPreviewLink(data.testReportLink));
        }
    } catch (e) {
        // TODO: refactor error handling to use addMessage
        if (e.message === 'server error') {
            yield put(getExaminationPreviewLinkError(intl.messages['errorMessages.serverError']));
        } else if (e.message === 'network error') {
            yield put(getExaminationPreviewLinkError(intl.messages['errorMessages.networkError']));
        } else {
            yield put(addMessage('error', intl.messages['errorMessages.error'], e.message));
        }
    }
}

export function* handleGetUserCenters(action) {
    const baseUrl = getIdpBaseUrl();
    const url = `${baseUrl}/users/${action.payload}/centers`;
    const headers = getHeaders(true);
    try {
        const data = yield call(fetchSaga, url, { method: 'GET', headers });
        if (data) {
            yield put(setUserCenters(data));
        }
    } catch (e) {
        yield put(setUserCenters([]));
        // TODO: refactor error handling to use addMessage
        if (e.message === 'server error') {
            yield put(getUserCentersError(action.intl.messages['errorMessages.serverError']));
        } else if (e.message === 'network error') {
            yield put(getUserCentersError(action.intl.messages['errorMessages.networkError']));
        } else {
            yield put(addMessage('error', action.intl.messages['errorMessages.error'], e.message));
        }
    }
}

export function* handleGetCenterUsers(action) {
    const { payload, centerId } = action;
    const baseUrl = getIdpBaseUrl();
    let url = `${baseUrl}/centers/${centerId}/users?active=true&pageSize=1000`
        .concat(`&firstname=${payload}&lastname=${payload}&icasId=${payload}&email=${payload}&isFullTextSearch=true`);
    const headers = getHeaders(true);
    try {
        let data = yield call(fetchSaga, url, { method: 'GET', headers });
        if (data) {
            let centerUsers = data.items;
            while (data.page && data.page_count && data.page < data.page_count) {
                url = `${baseUrl}/centers/${action.payload}/users?active=true&page=${data.page + 1}&pageSize=1000`
                    .concat(`&firstname=${payload}&lastname=${payload}&icasId=${payload}&isFullTextSearch=true`)
                    .concat(`&email=${payload}&isFullTextSearch='true'`);
                data = yield call(fetchSaga, url, { method: 'GET', headers });
                if (data) {
                    centerUsers = [...centerUsers, ...data.items];
                }
            }
            yield put(setCenterUsers(centerUsers));
        }
    } catch (e) {
        yield put(setCenterUsers([]));
        // TODO: refactor error handling to use addMessage
        if (e.message === 'server error') {
            yield put(getCenterUsersError(action.intl.messages['errorMessages.serverError']));
        } else if (e.message === 'network error') {
            yield put(getCenterUsersError(action.intl.messages['errorMessages.networkError']));
        } else {
            yield put(addMessage('error', action.intl.messages['errorMessages.error'], e.message));
        }
    }
}

export function* handleAddUserToExam(action) {
    const baseUrl = getPortalBaseUrl();
    const url = `${baseUrl}/exams/`;
    const headers = getHeaders(true);
    try {
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify(action.payload) });
        if (data) {
            const { showMessage } = action;
            if (!!showMessage) {
                yield put(addMessage('success', action.intl.messages['examsAdmin.addStudentSuccess']));
            } else {
                yield put(setAddUserToExamRequestRunning(false));
            }
        }
    } catch (e) {
        if (!e.message) {
            yield put(addMessage(ERROR,
                action.intl.formatMessage({ id: 'errorMessages.addStudentToExaminationError' })));
        } else if (e.message === 'server error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (e.message === 'network error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else if (!(e.message.startsWith('ERROR'))) {
            yield put(addMessage(ERROR, e.message));
        }
    }
}

export function* handleCreateStudentAndAddToExamination(action) {
    const baseUrl = getPortalBaseUrl();
    const url = `${baseUrl}/exams/student`;
    const headers = getHeaders(true);
    const studentData = {
        email: action.payload.email,
        firstname: action.payload.firstname,
        lastname: action.payload.lastname,
        schooltype: action.payload.schooltype,
        classlevel: action.payload.classlevel,
        location: action.payload.location,
        properties: [
            {
                name: 'birthDay',
                value: action.payload.birthday,
            },
        ],
    };

    try {
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify(studentData) });
        if (data) {
            const { payload: { email, examination_id }, intl } = action;
            yield put(addUserToExam(email, null, examination_id, false, intl, false, null));
        }
    } catch (e) {
        if (!e.message) {
            yield put(addMessage('error', action.intl.messages['errorMessages.error'],
                action.intl.messages['errorMessages.addStudentToExaminationError']));
        } else if (e.message === 'server error') {
            yield put(addMessage('error', action.intl.messages['errorMessages.error'],
                action.intl.messages['errorMessages.serverError']));
        } else if (e.message === 'network error') {
            yield put(addMessage('error', action.intl.messages['errorMessages.error'],
                action.intl.messages['errorMessages.networkError']));
        } else if (!(e.message.startsWith('ERROR'))) {
            yield put(addMessage('error', action.intl.messages['errorMessages.error'], e.message));
        }
    }
}

function* createOlatUser(payload) {
    const baseUrl = getPortalBaseUrl();
    const url = `${baseUrl}/exams/olatStudent`; // Despite the name also used for creating teachers.
    const headers = getHeaders(true);
    const email = handleUserWithoutEmailAddress(payload.email);

    const properties = [
        {
            name: 'birthDay',
            value: payload.birthday,
        },
    ];

    const body = JSON.stringify({
        firstname: payload.firstname,
        lastname: payload.lastname,
        email,
        properties,
        olatInstance: payload.olatInstance,
    });

    // Returns the OpenOlat ID of the newly created user.
    return yield call(fetchSaga, url, { method: 'POST', headers, body });
}

export function* handleCreateOlatStudentAndAddToExam(action) {
    yield put(setShowLoadingModal(true));

    const { user, examId, payload, intl, isCourse, showMessage } = action;
    const { email } = payload;

    try {
        const openOlatId = yield call(createOlatUser, payload);
        if (openOlatId) {
            let userData;
            if (payload.olatInstance === OPEN_OLAT_INSTANCE.OPEN_OLAT_LANGUAGE_COURSES) {
                userData = { ...getUserData(user), openOlatLanguageCourseId: openOlatId };
            } else {
                userData = { ...getUserData(user), openOlatId };
            }
            yield call(saveAndFetchUser, user.id, userData);
            yield put(addUserToExam(email, openOlatId, examId, true, intl, isCourse, payload.olatInstance,
                showMessage));
        }
    } catch (e) {
        if (!e.message) {
            yield put(addMessage(ERROR,
                action.intl.formatMessage({ id: 'errorMessages.addStudentToExaminationError' })));
        } else if (e.message === 'server error') {
            yield put(addMessage(ERROR, intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (e.message === 'network error') {
            yield put(addMessage(ERROR, intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else if (!(e.message.startsWith('ERROR'))) {
            yield put(addMessage(ERROR, e.message));
        }
    }

    yield put(setShowLoadingModal(false));
}

export function* handleCreateOlatTeacher(action) {
    const { user, payload, intl } = action;
    try {
        const openOlatId = yield call(createOlatUser, payload);
        const userData = { ...getUserData(user), openOlatId };
        yield put(saveUser(user.id, userData, intl, false));
    } catch (e) {
        if (!e.message) {
            yield put(addMessage(ERROR, intl.formatMessage({ id: 'errorMessages.createOlatTeacherError' })));
        } else if (e.message === 'server error') {
            yield put(addMessage(ERROR, intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (e.message === 'network error') {
            yield put(addMessage(ERROR, intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else if (!(e.message.startsWith('ERROR'))) {
            yield put(addMessage(ERROR, e.message));
        }
    }
}

export function* resetUserExam(action) {
    const { payload, intl } = action;
    const { hash, email, subject } = payload;
    const baseUrl = getPortalBaseUrl();
    const url = `${baseUrl}/exams/${hash}`;
    const headers = getHeaders(true);
    try {
        const data = yield call(fetchSaga, url, { method: 'PATCH', headers });
        if (data) {
            // refresh exam data
            yield put(getUserExamData(email, 2, subject));
            yield put(addMessage('success', action.intl.messages['exams.resetSuccess']));
        }
    } catch (e) {
        if (!e.message) {
            yield put(addMessage('error', intl.messages['errorMessages.resetUserExamError']));
        } else if (e.message === 'server error') {
            yield put(addMessage('error', intl.messages['errorMessages.serverError']));
        } else if (e.message === 'network error') {
            yield put(addMessage('error', intl.messages['errorMessages.networkError']));
        } else if (!(e.message.startsWith('ERROR'))) {
            yield put(addMessage('error', intl.messages['errorMessages.error']));
        }
    }
}

export function* handleGetUserXOlatToken(action) {
    const { payload, intl } = action;
    const baseUrl = getPortalBaseUrl();
    const url = `${baseUrl}/openolat/authenticate`;
    const headers = getHeaders(true);

    const email = !payload.username.includes('@') ? `${payload.username}@lisa-olc.de` : payload.username;

    const body = JSON.stringify({ username: email, userId: payload.userId, olatInstance: payload.openOlatInstance });

    yield put(setIsUserXOlatTokenBeingFetched(true));

    try {
        const response = yield call(fetchSaga, url, { method: 'POST', headers, body });

        if (response) {
            yield put(setUserXOlatToken(response.value));
        }
    } catch (e) {
        if (!e.message) {
            yield put(addMessage(ERROR, intl.formatMessage({ id: 'errorMessages.getUserXOlatTokenError' })));
        } else if (e.message === 'server error') {
            yield put(addMessage(ERROR, intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (e.message === 'network error') {
            yield put(addMessage(ERROR, intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else {
            yield put(addMessage(ERROR, intl.messages['errorMessages.error']));
        }
    }

    yield put(setIsUserXOlatTokenBeingFetched(false));
}

export function* downloadResultsPdf(action) {
    const { originalFilename, downloadFilename, intl } = action;
    const url = `${getBibBaseUrl()}/results/download?filename=${originalFilename}`;

    const request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'blob';
    request.setRequestHeader('Authorization', `Bearer ${storage.getItem('token')}`);
    request.setRequestHeader('Accept', 'application/octet-stream');

    request.onreadystatechange = () => {
        const { readyState, status } = request;

        if (readyState === XMLHttpRequest.DONE) {
            if (status === StatusCodes.OK) {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(request.response);
                link.download = downloadFilename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }

            if (status === StatusCodes.NOT_FOUND) {
                store.dispatch(addMessage(ERROR, intl.messages['errorMessages.resultsPdfDownloadError']));
            }

            if (status === StatusCodes.INTERNAL_SERVER_ERROR) {
                store.dispatch(addMessage(ERROR, intl.messages['errorMessages.serverError']));
            }
        }
    };

    yield request.send();
}

export function* openExaminationPreviewLink(action) {
    const { repoEntryKey, olatUsername, olatUserId, intl } = action;
    const newWindow = window.open();

    try {
        const xOlatToken = yield call(authenticateOlatUser, olatUsername, olatUserId);
        newWindow.location = `${getOlatExamsBaseUrl()}/${repoEntryKey}/Infos/0?X-OLAT-TOKEN=${xOlatToken}`;
    } catch (e) {
        if (!e.message) {
            yield put(addMessage(ERROR, intl.formatMessage({ id: 'errorMessages.getUserXOlatTokenError' })));
        } else if (e.message === 'server error') {
            yield put(addMessage(ERROR, intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (e.message === 'network error') {
            yield put(addMessage(ERROR, intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else if (!(e.message.startsWith('ERROR'))) {
            yield put(addMessage(ERROR, e.message));
        }
    }
}

export function* handleInitializeExamsRoute(action) {
    yield all([
        put(setUserExamData([])),
        put(setUserAssessmentEntries({})),
        put(setUserHasNoExamData(false)),
        put(setUserXOlatToken('')),
    ]);

    const { userId, isUserExamsAdmin, subject, intl } = action;
    let { user } = action;

    try {
        if (objectEmpty(user) || userId !== user.id) {
            user = yield call(fetchUser, userId);
            yield put(setUser(user));
        }

        if (isUserExamsAdmin && window.location.href.includes('/interaktive-aufgaben')) {
            if (!user.openolat_id) {
                yield put(createOlatTeacher(user, intl));
            }
            yield put(getUserCenters(userId, intl));
            yield put(setCenterUsers([]));
        }

        if (!isUserExamsAdmin || window.location.href.includes('/digitale-lernpfade')) {
            const { email, openolat_id, openolat_language_course_id } = user;
            const isOnlineIntensiveCourse = subject.startsWith('Online Intensiv-Lernkurs')
                || subject.startsWith('Abikurs');
            if (!isOnlineIntensiveCourse && !!user.openolat_id) {
                yield put(getUserXOlatToken(email, openolat_id, OPEN_OLAT_INSTANCE.OPEN_OLAT));
            } else if (!!isOnlineIntensiveCourse && !!user.openolat_language_course_id) {
                yield put(getUserXOlatToken(email, openolat_language_course_id,
                    OPEN_OLAT_INSTANCE.OPEN_OLAT_LANGUAGE_COURSES));
            }

            const openOlat = {
                openOlat: {
                    id: openolat_id,
                    instance: OPEN_OLAT_INSTANCE.OPEN_OLAT,
                },
                languageCourseOpenOlat: {
                    id: user.openolat_language_course_id,
                    instance: OPEN_OLAT_INSTANCE.OPEN_OLAT_LANGUAGE_COURSES,
                },
            };
            const olatExamType = isOnlineIntensiveCourse ? OLAT_EXAM_TYPE.COURSE : OLAT_EXAM_TYPE.TEST;
            yield put(getUserExamData(email, openOlat, 2, [subject], intl, false,
                isOnlineIntensiveCourse, olatExamType));
        }
    } catch (e) {
        if (e.message === 'server error') {
            yield put(addMessage(ERROR, intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (e.message === 'network error') {
            yield put(addMessage(ERROR, intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else {
            yield put(addMessage(ERROR, intl.messages['errorMessages.error']));
        }
    }
}

export function* waitForGetExamsAdmin() {
    yield takeEvery(GET_EXAMS_ADMIN, handleGetExamsAdmin);
}

export function* waitForUserExamDataWasFetched() {
    yield takeEvery(GET_USER_EXAM_DATA, handleGetUserExamData);
}

export function* waitForUserAssessmentEntriesWereFetched() {
    yield takeEvery(GET_USER_ASSESSMENT_ENTRIES, handleGetUserAssessmentEntries);
}

export function* waitForExaminationDataWasFetched() {
    yield takeEvery(GET_EXAMINATION_DATA, handleGetExaminationData);
}

export function* waitForExaminationForSubjectWasFetched() {
    yield takeEvery(GET_EXAMINATIONS_FOR_SUBJECT, handleGetExaminationsForSubject);
}

export function* waitForExaminationPreviewLinkWasFetched() {
    yield takeEvery(GET_EXAMINATION_PREVIEW_LINK, handleGetExaminationPreviewLink);
}

export function* waitForStudentDataWasFetched() {
    yield takeEvery(GET_STUDENT_DATA, handleGetStudentData);
}

export function* waitForUserCentersWereFetched() {
    yield takeEvery(GET_USER_CENTERS, handleGetUserCenters);
}

export function* waitForCenterUsersWereFetched() {
    yield takeEvery(GET_CENTER_USERS, handleGetCenterUsers);
}

export function* waitForUserWasAddedToExam() {
    yield takeEvery(ADD_USER_TO_EXAM, handleAddUserToExam);
}

export function* waitForUserWasCreatedAndAddedToExam() {
    yield takeEvery(CREATE_STUDENT_AND_ADD_TO_EXAM, handleCreateStudentAndAddToExamination);
}

export function* waitForCreateOlatUserAndAddToExam() {
    yield takeEvery(CREATE_OLAT_STUDENT_AND_ADD_TO_EXAM, handleCreateOlatStudentAndAddToExam);
}

export function* waitForCreateOlatTeacher() {
    yield takeEvery(CREATE_OLAT_TEACHER, handleCreateOlatTeacher);
}

export function* waitForUserExamWasReset() {
    yield takeEvery(RESET_USER_EXAM, resetUserExam);
}

export function* waitForGetUserXOlatToken() {
    yield takeEvery(GET_USER_X_OLAT_TOKEN, handleGetUserXOlatToken);
}

export function* waitForDownloadResultsPdf() {
    yield takeEvery(DOWNLOAD_RESULTS_PDF, downloadResultsPdf);
}

export function* waitForCheckResultsExports() {
    yield takeEvery(CHECK_RESULTS_EXPORTS, handleCheckResultsExports);
}

export function* waitForOpenExaminationPreviewLink() {
    yield takeEvery(OPEN_EXAMINATION_PREVIEW_LINK, openExaminationPreviewLink);
}

export function* waitForInitializeExamsRoute() {
    yield takeEvery(INITIALIZE_EXAMS_ROUTE, handleInitializeExamsRoute);
}

export const examinationsSaga = [
    waitForGetExamsAdmin(),
    waitForUserExamDataWasFetched(),
    waitForUserAssessmentEntriesWereFetched(),
    waitForExaminationForSubjectWasFetched(),
    waitForExaminationDataWasFetched(),
    waitForExaminationPreviewLinkWasFetched(),
    waitForStudentDataWasFetched(),
    waitForUserCentersWereFetched(),
    waitForCenterUsersWereFetched(),
    waitForUserWasAddedToExam(),
    waitForUserWasCreatedAndAddedToExam(),
    waitForCreateOlatUserAndAddToExam(),
    waitForCreateOlatTeacher(),
    waitForUserExamWasReset(),
    waitForGetUserXOlatToken(),
    waitForDownloadResultsPdf(),
    waitForCheckResultsExports(),
    waitForOpenExaminationPreviewLink(),
    waitForInitializeExamsRoute(),
];

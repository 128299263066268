import { call, put, takeEvery } from 'redux-saga/effects';

import { fetchSaga } from '../network/fetch-saga';
import { getPortalBaseUrl } from '../utils';
import { ERROR } from '../constants';

import { addMessage } from '../../containers/MessageModal/actions';

import {
    VERIFY_CAPTCHA,
    setCaptchaValid,
} from './actions';

export function* verifyCaptcha(action) {
    const { response, intl } = action.payload;

    const url = `${getPortalBaseUrl()}/recaptcha/verify?responseToken=${response}`;

    try {
        const data = yield call(fetchSaga, url, { method: 'POST' });

        if (data && data.success) {
            yield put(setCaptchaValid(true));
        } else {
            yield put(setCaptchaValid(false));
            yield put(addMessage(ERROR, intl.formatMessage({ id: 'captcha.error' })));
        }
    } catch (e) {
        yield put(setCaptchaValid(false));
        yield put(addMessage(ERROR, intl.formatMessage({ id: 'captcha.error' })));
    }
}

export function* watchVerifyCaptcha() {
    yield takeEvery(VERIFY_CAPTCHA, verifyCaptcha);
}

export const CaptchaSaga = [
    watchVerifyCaptcha(),
];

import {
    SET_COURSE_DATA,
    SET_SHOW_COURSE_CONTACT_SUPPORT_MODAL,
    SET_COURSE_CONTACT_SUPPORT_FORM_LOADING,
} from './actions';

const initialState = {
    courses: [],
    showCourseContactSupportModal: false,
    courseContactSupportFormLoading: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case SET_COURSE_DATA:
        return {
            ...state,
            courses: action.payload,
        };
    case SET_SHOW_COURSE_CONTACT_SUPPORT_MODAL:
        return {
            ...state,
            showCourseContactSupportModal: action.payload,
        };
    case SET_COURSE_CONTACT_SUPPORT_FORM_LOADING:
        return {
            ...state,
            courseContactSupportFormLoading: action.payload.loading,
        };
    default:
        return state;
    }
}

import {
    REGISTER_USER_SUCCESS,
    SET_LISA_USER,
    SUBMIT_BUTTON,
    SET_RENDER_LISA_PAGE,
} from './actions';

const initialState = {
    user: {},
    messages: [],
    showSuccessMessage: false,
    submitButton: false,
    renderLisaPage: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case REGISTER_USER_SUCCESS:
        return { ...state, showSuccessMessage: true, messages: [] };
    case SET_LISA_USER:
        return { ...state, user: action.payload };
    case SUBMIT_BUTTON:
        return { ...state, submitButton: action.payload };
    case SET_RENDER_LISA_PAGE:
        return { ...state, renderLisaPage: action.payload };
    default:
        return state;
    }
}

import {
    TERMINATION_EMAIL_SUCCESS,
} from './actions';

const initialState = {
    showSuccessMessage: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case TERMINATION_EMAIL_SUCCESS:
        return { ...state, showSuccessMessage: action.payload };
    default:
        return state;
    }
}

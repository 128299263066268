export const isBlank = (value) => value === null || value === undefined || value.toString().trim() === '';

const containsLowerCase = (value) => !!value && value.toUpperCase() !== value;

const containsUpperCase = (value) => !!value && value.toLowerCase() !== value;

const containsNumber = (value) => /\d/.test(value);

const hasMinLength = (value, minLength) => !!value && value.length >= minLength;

export const passwordHasValidFormat = (enteredPassword) => (
    containsLowerCase(enteredPassword)
    && containsUpperCase(enteredPassword)
    && containsNumber(enteredPassword)
    && hasMinLength(enteredPassword, 8)
);

// ExtendBookcode action types and creators

export const EXTEND_BOOKCODE = 'containers/BookcodeExtendFormContainer/extendBookcode';
export const EXTEND_BOOKCODE_SUCCESS = 'containers/BookcodeExtendFormContainer/extendBookcodeSuccess';
export const GET_CENTERS = 'containers/BookcodeExtendFormContainer/getCentersBookcode';
export const SET_CENTERS = 'containers/BookcodeExtendFormContainer/setCenters';

export const extendBookcode = (userData, intl) => ({
    type: EXTEND_BOOKCODE,
    intl,
    payload: userData,
});

export const extendBookcodeSuccess = () => ({
    type: EXTEND_BOOKCODE_SUCCESS,
});

export const getCenters = (intl) => ({
    type: GET_CENTERS,
    intl,
});

export const setCenters = (data) => ({
    type: SET_CENTERS,
    payload: data,
});

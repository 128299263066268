// SpecialRegisterFormContainer reducer

import {
    REGISTER_USER_SUCCESS,
    RELEASE_BUTTON,
} from './actions';

const initialState = {
    messages: [],
    showSuccessMessage: false,
    releaseButton: true,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case REGISTER_USER_SUCCESS:
        return { ...state, showSuccessMessage: true, messages: [] };
    case RELEASE_BUTTON:
        return { ...state, releaseButton: action.payload };
    default:
        return state;
    }
}

// LisaRegisterFormContainer sagas

import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchSaga } from '../../utils/network/fetch-saga';

import { addMessage } from '../../containers/MessageModal/actions';

import { ERROR } from '../../utils/constants';

import { getIdpBaseUrl, getHeaders, getQueryParameters } from '../../utils/utils';

import {
    GET_RENDER_LISA_PAGE,
    REGISTER_USER,
    registerUserSuccess,
    submitButton,
    setLisaUser,
    setRenderLisaPage,
} from './actions';

// Workers
export function* renderLisaPage(action) {
    const baseUrl = getIdpBaseUrl();
    const url = `${baseUrl}/account/authenticate/lisa`;
    const params = getQueryParameters();
    const headers = getHeaders();

    const paramsBody = {
        schoolID: params.sid,
        shortToken: params.cs,
    };

    try {
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify(paramsBody) });

        if (data) {
            yield put(setRenderLisaPage(data.authenticated));
        }

        if (!data.authenticated) {
            yield put(addMessage(ERROR, 'invalid Token'));
        }

    } catch (e) {
        if (e.message === 'server error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (e.message === 'network error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else {
            yield put(addMessage(ERROR, e.message));
        }
    }
}

// Workers
export function* registerLisaUser(action) {
    yield put(submitButton(false));
    const baseUrl = getIdpBaseUrl();
    const url = `${baseUrl}/account/register-trial`;
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept-Language', 'de');

    const formValues = {
        firstname: ' ',
        lastname: ' ',
        email: ' ',
        password: action.payload.password,
        registrationType: action.payload.registrationType,
    };

    try {
        const data = yield call(fetchSaga, url, { method: 'POST', headers, body: JSON.stringify(formValues) });

        if (data) {
            yield put(registerUserSuccess());
            yield put(setLisaUser(data));
        }

    } catch (e) {
        yield put(submitButton(false));
        if (e.message === 'server error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.serverError' })));
        } else if (e.message === 'network error') {
            yield put(addMessage(ERROR, action.intl.formatMessage({ id: 'errorMessages.networkError' })));
        } else {
            yield put(addMessage(ERROR, 'Fehler! Bitte versuche dich neu zu registrieren.'));
        }
    }
}

// Watchers
export function* waitForRegisterLisaUser() {
    yield takeEvery(REGISTER_USER, registerLisaUser);
}

// Watchers
export function* waitForRenderLisaPage() {
    yield takeEvery(GET_RENDER_LISA_PAGE, renderLisaPage);
}

export const lisaRegisterSaga = [
    waitForRegisterLisaUser(),
    waitForRenderLisaPage(),
];

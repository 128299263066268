// Global imports.
import { call, put, select, takeEvery, all } from 'redux-saga/effects';

// Utils, actions, sagas, etc. .
import {
    CHOOSE_UNDER_TOPIC,
    WIKI_DOWNLOAD,
    EXERCISE_DOWNLOAD,
    LOCATION_CHANGE,
    setSimilarTopics,
    setGradeToSimilarTopics,
    setParentsToSimilarTopics,
    setSubjects,
    setGrades,
    setStates,
    setTopics,
    setExercises,
    setWikis,
    setVideos,
    setExams,
    chooseSubject,
    chooseGrade,
    chooseState,
    chooseMainTopic,
    chooseBetweenTopic,
    chooseUnderTopic,
    resetGrades,
    resetTopics,
    setContentType,
    setSubjectAndGrade,
    setCanonical,
    setMetaDescription,
    setTitle,
    setParsingInProgress,
    setRedirectUrl,
} from './actions';
import {
    getBibBaseUrl,
    slugify,
    isTrackingAllowed,
    isTokenExpired,
    setReactGAPageView,
    redirectToNotFoundPage,
    createCookieStringFromToken,
    getUserTypeFromToken,
} from '../../utils/utils';
import {
    selectChosenSubject,
    selectChosenGrade,
    selectChosenState,
    selectChosenMainTopic,
    selectChosenBetweenTopic,
    selectChosenUnderTopic,
} from './selectors';
import { GRADES, STATES, MAIN_TOPICS, BETWEEN_AND_UNDER_TOPICS, PAGE, NOT_FOUND } from './constants';
import { cleanFavoriteState } from '../../containers/FavoriteButton/actions';
import { fetchSaga } from '../../utils/network/fetch-saga';
import { logout } from '../Account/actions';
import { storage } from '../../utils/storage';

// Library sagas

// Helpers
export function getAuthHeaders() {
    return new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.getItem('token')}`,
    });
}

// Workers
export function* fetchSubjects() {
    const bibBaseUrl = getBibBaseUrl();
    const url = `${bibBaseUrl}/subjects`;
    const data = yield call(fetchSaga, url);
    if (data) {
        yield put(setSubjects(data));
    }
}

export function* fetchGradesOfSubject(action) {
    const subjectId = action.payload;
    if (subjectId === null || subjectId === '') return;
    const bibBaseUrl = getBibBaseUrl();
    const url = `${bibBaseUrl}/subjects/${subjectId}/grades`;
    const data = yield call(fetchSaga, url);
    if (data) {
        yield put(setGrades(data));
    }
}

export function* fetchStatesOfGrade(action) {
    const gradeId = action.payload;
    if (gradeId === null || gradeId === '') return;
    const subjectId = yield select((state) => state.library.chosenSubjectId);
    const bibBaseUrl = getBibBaseUrl();
    const url = `${bibBaseUrl}/subjects/${subjectId}/grades/${gradeId}/states`;
    const data = yield call(fetchSaga, url);
    if (data) {
        yield put(setStates(data));
    }
}

export function* fetchGradeOfSimilarTopics() {
    const similarTopics = yield select((state) => state.library.similarTopics);
    // JSON.parse(JSON.stringify(similarTopics)) creates a copy of similarTopics
    // to prevent changing the state as a reference.
    const newSimilarTopics = JSON.parse(JSON.stringify(similarTopics));
    for (let i = 0; i < newSimilarTopics.length; i++) {
        const { subjectId, gradeId } = newSimilarTopics[i];
        const simTopicId = newSimilarTopics[i].id;
        const url = `${getBibBaseUrl()}/subjects/${subjectId}/grades/${gradeId}`;
        const data = yield call(fetchSaga, url);
        if (data) {
            const newSimilarTopic = newSimilarTopics.find((topic) => topic.id === simTopicId);
            newSimilarTopic.gradeName = data.name;
        }
    }
    yield put(setGradeToSimilarTopics(newSimilarTopics));
}

export function* fetchParentOfSimilarTopic(action) {
    const { subjectId, gradeId } = action.similarTopic;
    const topicId = action.similarTopic.parentId;
    const similarTopics = action.newSimilarTopics;
    const url = `${getBibBaseUrl()}/subjects/${subjectId}/grades/${gradeId}/topics/${topicId}`;
    const data = yield call(fetchSaga, url);
    if (data) {
        const { similarTopic } = action;
        similarTopic.parent = data;
    }
    if (!!action.similarTopic.parent.parentId) {
        const greatParentTopicData = {
            isGreatParent: true,
            newSimilarTopics: similarTopics,
            similarTopic: action.similarTopic.parent,
        };
        yield call(fetchParentOfSimilarTopic, greatParentTopicData);
    }
}

export function* getParentsOfSimilarTopics() {
    const similarTopics = yield select((state) => state.library.similarTopics);
    // JSON.parse(JSON.stringify(similarTopics)) creates a copy of similarTopics
    // to prevent changing the state as a reference.
    const newSimilarTopics = JSON.parse(JSON.stringify(similarTopics));
    for (let i = 0; i < newSimilarTopics.length; i++) {
        const parentTopicData = {
            newSimilarTopics,
            similarTopic: newSimilarTopics[i],
        };
        yield call(fetchParentOfSimilarTopic, parentTopicData);
    }
    yield put(setParentsToSimilarTopics(newSimilarTopics));
}

export function* fetchSimilarTopics(action) {
    const topicId = action.payload;
    if (topicId === null || topicId === '') return;
    const selectedTopic = yield select(selectChosenUnderTopic);
    if (!!selectedTopic && selectedTopic.redirectTo === null) {
        const subjectId = yield select((state) => state.library.chosenSubjectId);
        const gradeId = yield select((state) => state.library.chosenGradeId);
        const stateId = yield select((state) => state.library.chosenStateId);
        let url = `${getBibBaseUrl()}/subjects/${subjectId}/grades/${gradeId}/topics/${topicId}/similar`;
        if (!!stateId) {
            url = `${getBibBaseUrl()}/subjects/${subjectId}/grades/`
                + `${gradeId}/states/${stateId}/topics/${topicId}/similar`;
        }
        const data = yield call(fetchSaga, url);
        if (data) {
            yield put(setSimilarTopics(data.filter((topic) => topic.id !== selectedTopic.id)));
        }
        yield call(getParentsOfSimilarTopics);
        yield call(fetchGradeOfSimilarTopics);
    }
}

export function* fetchTopicsOfGrade(action) {
    const gradeId = action.payload;
    if (gradeId === null || gradeId === '') return;
    const subjectId = yield select((state) => state.library.chosenSubjectId);
    const url = `${getBibBaseUrl()}/subjects/${subjectId}/grades/${gradeId}/topics`;
    const options = {
        method: 'GET',
        headers: getAuthHeaders(),
    };
    const data = yield call(fetchSaga, url, options);
    if (data) {
        yield put(setTopics(data));
    }
}

export function* fetchTopicsOfState(action) {
    const stateId = action.payload;
    if (stateId === null || stateId === '') return;
    const subjectId = yield select((state) => state.library.chosenSubjectId);
    const gradeId = yield select((state) => state.library.chosenGradeId);
    const url = `${getBibBaseUrl()}/subjects/${subjectId}/grades/${gradeId}/states/${stateId}/topics`;
    const data = yield call(fetchSaga, url);
    if (data) {
        yield put(setTopics(data));
    }
}

export const examRecentTimestamp = (exam) => new Date(
    (exam.created_at || '0')
        .replace('T', ' '),
);

export const examNiveau = (exam) => +(exam.name?.match(/niveau\s+\d+/i)?.[0]?.split(/\s+/)[1] || '0');

export const sortExams = (exams) => {
    // Sort via timestamps first.
    exams.sort((exam1, exam2) => -Math.sign(
        examRecentTimestamp(exam1) - examRecentTimestamp(exam2),
    ));
    // Sort via niveau next.
    exams.sort((exam1, exam2) => Math.sign(examNiveau(exam1) - examNiveau(exam2)));
};

export function* fetchPage(action) {
    const underTopicId = action.payload;
    if (underTopicId === null || underTopicId === '') return;

    const selectedTopic = yield select(selectChosenUnderTopic);
    if (!!selectedTopic && selectedTopic.redirectTo === null) {
        const bibBaseUrl = getBibBaseUrl();
        const options = {
            method: 'GET',
            headers: getAuthHeaders(),
        };
        try {
            // correct, effects will get executed in parallel
            const [exercises, wikis, videos] = yield all([
                call(fetchSaga, `${bibBaseUrl}/exercises?topicId=${underTopicId}`, options),
                call(fetchSaga, `${bibBaseUrl}/wikis?topicId=${underTopicId}`, options),
                call(fetchSaga, `${bibBaseUrl}/videos?topicId=${underTopicId}`, options),
            ]);
            yield put(setWikis(wikis));
            yield put(setExercises(exercises));
            yield put(setVideos(videos));

            const token = storage.getItem('token');
            if (!!token && !isTokenExpired(token)) {
                const [exams] = yield all([
                    call(fetchSaga, `${bibBaseUrl}/exams?topicId=${underTopicId}`, options),
                ]);

                sortExams(exams?.items || []);
                yield put(setExams(exams.items));
            }

        } catch (e) {
            // TODO: implement proper error handling
            // console.log(e.message);
        }
    }
}

export function* fetchWikiDownload(action) {
    const wikiId = action.payload;
    const bibBaseUrl = getBibBaseUrl();
    const wikis = yield select((state) => state.library.wikis);
    const chosenWiki = wikis.find((wiki) => wiki.id === wikiId);
    const userType = getUserTypeFromToken(storage.getItem('token'));
    action.handleLoadingButtons(wikiId);
    const req = new XMLHttpRequest();
    if (userType === 'SA') {
        req.open('GET', `${bibBaseUrl}/wikis/${wikiId}?pdf=true&lisa=true`, true);
    } else {
        req.open('GET', `${bibBaseUrl}/wikis/${wikiId}?pdf=true`, true);
    }
    req.responseType = 'blob';
    req.setRequestHeader('Authorization', `Bearer ${storage.getItem('token')}`);
    req.onreadystatechange = () => {
        if (req.readyState === 4 && req.status === 200) {
            action.handleLoadingButtons(wikiId);
            const filename = `${chosenWiki.name}-${new Date().getTime()}.pdf`;
            if (typeof window.chrome !== 'undefined' || navigator.userAgent.toLowerCase().indexOf('safari/') > -1) {
                // Chrome or Safari version
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(req.response);
                link.download = `${chosenWiki.name}-${new Date().getTime()}.pdf`;
                link.click();
            } else if (typeof window.navigator.msSaveBlob !== 'undefined') {
                // IE version
                const blob = new Blob([req.response], { type: 'application/pdf' });
                window.navigator.msSaveBlob(blob, filename);
            } else {
                // Firefox version
                const file = new File([req.response], filename, { type: 'application/force-download' });
                window.open(URL.createObjectURL(file));
            }
        }
    };
    yield req.send();
}

export function* fetchExerciseDownload(action) {
    const exerciseId = action.payload;
    action.handleLoadingButtons(exerciseId);
    const bibBaseUrl = getBibBaseUrl();
    const exercises = yield select((state) => state.library.exercises);
    const chosenExercise = exercises.find((exercise) => exercise.id === exerciseId);
    const userType = getUserTypeFromToken(storage.getItem('token'));

    const req = new XMLHttpRequest();

    if (userType === 'SA') {
        req.open('GET', `${bibBaseUrl}/exercises/${exerciseId}?pdf=true&lisa=true`, true);
    } else {
        req.open('GET', `${bibBaseUrl}/exercises/${exerciseId}?pdf=true`, true);
    }
    req.responseType = 'blob';
    req.setRequestHeader('Authorization', `Bearer ${storage.getItem('token')}`);
    req.onreadystatechange = () => {
        if (req.readyState === 4 && req.status === 200) {
            action.handleLoadingButtons(exerciseId);
            const filename = `${chosenExercise.name}-${new Date().getTime()}.pdf`;
            if (typeof window.chrome !== 'undefined' || navigator.userAgent.toLowerCase().indexOf('safari/') > -1) {
                // Chrome or Safari version
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(req.response);
                link.download = `${chosenExercise.name}-${new Date().getTime()}.pdf`;
                link.click();
            } else if (typeof window.navigator.msSaveBlob !== 'undefined') {
                // IE version
                const blob = new Blob([req.response], { type: 'application/pdf' });
                window.navigator.msSaveBlob(blob, filename);
            } else {
                // Firefox version
                const file = new File([req.response], filename, { type: 'application/force-download' });
                window.open(URL.createObjectURL(file));
            }
        }
    };
    yield req.send();
}

export function* redirectToTopic(id) {
    if (id === null || id === '') return;
    const url = `${getBibBaseUrl()}/topics/${id}/redirect`;

    const redirectUrl = yield call(fetchSaga, url, { method: 'GET' });
    if (!!redirectUrl?.url) {
        yield put(setRedirectUrl(redirectUrl.url));
    } else {
        yield put(setRedirectUrl(null));
    }
}

export function* fetchTopic(topicId) {
    const url = `${getBibBaseUrl()}/topics/${topicId}?onlyPage=true`;
    try {
        const data = yield call(fetchSaga, url);
        if (data) {
            yield put(chooseUnderTopic(data.id));
        }
    } catch (e) {
        // TODO: imlement proper error handling
        // console.log(e.message);
    }
}

export function* parseUrlAndFetchLibrary(action) {
    const location = action.payload;

    yield put(setParsingInProgress(true));

    if (isTrackingAllowed()) {
        setReactGAPageView(process.env.REACT_APP_BASENAME + location);
    }

    if (location.match(/\/logout/g)) {
        document.cookie = createCookieStringFromToken(storage.getItem('token'), true);
        storage.removeItem('token');
        yield put(cleanFavoriteState());
        yield put(logout());
    }

    const locationMatch = location.match(/\/\d+-/g);

    // reset similar topics
    yield put(setSimilarTopics([]));

    // reset all on home
    if (locationMatch === null || location === '/404') {

        // reset grades and topics
        yield put(resetGrades());
        yield put(resetTopics());

        // reset ContentType
        if (location === '/404') {
            yield put(setContentType(NOT_FOUND));
        } else if (location === '/') {
            yield put(setContentType(''));
            yield put(setCanonical('/'));
        } else {
            // no match and not on start page -> redirect to not found page
            yield put(setParsingInProgress(false));
            redirectToNotFoundPage();

            return;
        }

        // reset all
        yield put(chooseSubject(null));
        yield put(chooseGrade(null));
        yield put(chooseState(null));
        yield put(chooseMainTopic(null));
        yield put(chooseBetweenTopic(null));
        yield put(chooseUnderTopic(null));

        // set meta description
        yield put(setMetaDescription('Online lernen |SCHÜLERHILFE'));

        const subjects = yield select((state) => state.library.subjects);
        if (subjects === undefined || subjects.length === 0) {
            yield call(fetchSubjects);
        }

        yield put(setParsingInProgress(false));

        return;
    }

    // reset the redirect url in redux because otherwise every url will be redirected to this url
    // if the redirectUrl is not null
    const redirectUrl = yield select((state) => state.library.redirectUrl);
    if (!!redirectUrl) {
        yield put(setRedirectUrl(null));
    }

    const splitPathName = location.split('/').filter((name) => !!name);
    const locationMatchCleaned = locationMatch.map((match) => match.replace('/', '').replace('-', ''));
    const subjectId = locationMatchCleaned[0];
    const gradeId = locationMatchCleaned[1];

    yield call(fetchGradesOfSubject, { payload: parseInt(subjectId, 10) });
    yield put(chooseGrade(parseInt(gradeId, 10)));
    const chosenGrade = yield select(selectChosenGrade);

    /* >>>>>>>>> Form new structure to old structure <<<<<<<<<<< */
    if (!chosenGrade) {
        if (locationMatchCleaned.length === 2) {
            try {
                yield call(redirectToTopic, gradeId);
                return;
            } catch (error) {
                // TODO: implement proper error handling
                yield put(setParsingInProgress(false));
                redirectToNotFoundPage();
            }
        }
    }

    const stateId = !!chosenGrade?.workPackage ? locationMatchCleaned[2] : null;
    const mainTopicId = !!chosenGrade?.workPackage ? locationMatchCleaned[3] : locationMatchCleaned[2];
    const betweenOrUnderTopicId = !!chosenGrade?.workPackage ? locationMatchCleaned[4] : locationMatchCleaned[3];
    const betweenUnderTopicId = !!chosenGrade?.workPackage ? locationMatchCleaned[5] : locationMatchCleaned[4];

    const isSubjectMatch = !!subjectId;
    const isGradeMatch = !!gradeId;
    const isStateMatch = !!stateId;
    const isMainTopicMatch = !!mainTopicId;
    const isBetweenOrUnderTopicMatch = !!betweenOrUnderTopicId;
    const isBetweenUnderTopicMatch = !!betweenUnderTopicId;

    const subjects = yield select((state) => state.library.subjects);
    if (subjects === undefined || subjects.length === 0) {
        yield call(fetchSubjects);
    }

    try {
        // only is Subject
        if (isSubjectMatch && !isGradeMatch && !isStateMatch && !isMainTopicMatch && !isBetweenOrUnderTopicMatch
            && !isBetweenUnderTopicMatch) {

            // set subject
            yield put(chooseSubject(parseInt(subjectId, 10)));
            yield put(setContentType(GRADES));

            // reset chosenGradeId, chosenStateId, chosenMainTopicId, chosenBetweenTopicId, chosenUnderTopicId
            yield put(chooseGrade(null));
            yield put(chooseState(null));
            yield put(chooseMainTopic(null));
            yield put(chooseBetweenTopic(null));
            yield put(chooseUnderTopic(null));

            // reset topics
            yield put(resetTopics());

            // set canonical
            const chosenSubject = yield select(selectChosenSubject);
            const subjectUrl = `${chosenSubject.id}-${slugify(chosenSubject.name)}`;
            yield put(setCanonical(`/${subjectUrl}`));
            yield put(setTitle(`${chosenSubject.name} online lernen |SCHÜLERHILFE`));
            yield put(setMetaDescription(`${chosenSubject.name} online lernen |SCHÜLERHILFE`));
        }

        // subject and grade
        if (isSubjectMatch && isGradeMatch && !isStateMatch && !isMainTopicMatch && !isBetweenOrUnderTopicMatch
            && !isBetweenUnderTopicMatch) {

            // reset chosenStateId, chosenMainTopicId, chosenBetweenTopicId, chosenUnderTopicId
            yield put(chooseState(null));
            yield put(chooseMainTopic(null));
            yield put(chooseBetweenTopic(null));
            yield put(chooseUnderTopic(null));

            yield put(setSubjectAndGrade(parseInt(subjectId, 10), parseInt(gradeId, 10)));

            // check whether to load states or topics
            if (!!chosenGrade.workPackage) {
                yield call(fetchStatesOfGrade, { payload: parseInt(gradeId, 10) });

                // set contentType
                yield put(setContentType(STATES));
            } else {
                yield call(fetchTopicsOfGrade, { payload: parseInt(gradeId, 10) });
                // set contentType
                yield put(setContentType(MAIN_TOPICS));
            }

            const chosenSubject = yield select(selectChosenSubject);

            const subjectUrl = `${chosenSubject.id}-${slugify(chosenSubject.name)}`;
            const gradeUrl = `${chosenGrade.id}-${slugify(chosenGrade.name)}`;

            // get subject and grade url (with id)
            const incommingSubjectUrl = splitPathName[0];
            const incommingGradeUrl = splitPathName[1];

            // on misspellings redirect to the right names
            if (incommingSubjectUrl !== subjectUrl || gradeUrl !== incommingGradeUrl) {
                yield put(setRedirectUrl(`/${subjectUrl}/${gradeUrl}`));
            }

            // set canonical
            yield put(setCanonical(`/${subjectUrl}/${gradeUrl}`));
            yield put(setTitle(`${chosenGrade.name} online lernen |SCHÜLERHILFE`));
            yield put(setMetaDescription(`${chosenGrade.name} online lernen |SCHÜLERHILFE`));
        }

        // subject and grade and state
        if (isSubjectMatch && isGradeMatch && isStateMatch && !isMainTopicMatch && !isBetweenOrUnderTopicMatch
            && !isBetweenUnderTopicMatch) {

            // reset chosenMainTopicId, chosenBetweenTopicId, chosenUnderTopicId
            yield put(chooseMainTopic(null));
            yield put(chooseBetweenTopic(null));
            yield put(chooseUnderTopic(null));

            yield put(setSubjectAndGrade(parseInt(subjectId, 10), parseInt(gradeId, 10)));
            yield call(fetchStatesOfGrade, { payload: parseInt(gradeId, 10) });

            yield put(chooseState(parseInt(stateId, 10)));
            yield call(fetchTopicsOfState, { payload: parseInt(stateId, 10) });

            // set contentType
            yield put(setContentType(MAIN_TOPICS));

            // set canonical
            const chosenSubject = yield select(selectChosenSubject);
            const chosenState = yield select(selectChosenState);

            const subjectUrl = `${chosenSubject.id}-${slugify(chosenSubject.name)}`;
            const gradeUrl = `${chosenGrade.id}-${slugify(chosenGrade.name)}`;
            const stateUrl = `${chosenState.id}-${slugify(chosenState.name)}`;

            // get subject, grade and state url (with id)
            const incommingSubjectUrl = splitPathName[0];
            const incommingGradeUrl = splitPathName[1];
            const incommingStateUrl = splitPathName[2];

            // on misspellings redirect to the right names
            if (incommingSubjectUrl !== subjectUrl || gradeUrl !== incommingGradeUrl
                || incommingStateUrl !== stateUrl) {
                yield put(setRedirectUrl(`/${subjectUrl}/${gradeUrl}/${stateUrl}`));
            }

            yield put(setCanonical(`/${subjectUrl}/${gradeUrl}/${stateUrl}`));
            yield put(setTitle(`${chosenGrade.name} für ${chosenState.name} online lernen |SCHÜLERHILFE`));
            yield put(setMetaDescription(`${chosenGrade.name} für ${chosenState.name} online lernen |SCHÜLERHILFE`));
        }

        // subject, grade and mainTopic
        if (isSubjectMatch && isGradeMatch && isMainTopicMatch && !isBetweenOrUnderTopicMatch
            && !isBetweenUnderTopicMatch) {

            // reset chosenBetweenTopicId, chosenUnderTopicId and state if needed
            if (!isStateMatch) {
                yield put(chooseState(null));
            }
            yield put(chooseBetweenTopic(null));
            yield put(chooseUnderTopic(null));
            yield put(setSubjectAndGrade(parseInt(subjectId, 10), parseInt(gradeId, 10)));

            if (chosenGrade.workPackage) {
                yield call(fetchStatesOfGrade, { payload: parseInt(gradeId, 10) });
                yield put(chooseState(parseInt(stateId, 10)));
                yield call(fetchTopicsOfState, { payload: parseInt(stateId, 10) });
            } else {
                yield call(fetchTopicsOfGrade, { payload: parseInt(gradeId, 10) });
            }

            yield put(chooseMainTopic(parseInt(mainTopicId, 10)));

            const chosenSubject = yield select(selectChosenSubject);
            const chosenState = yield select(selectChosenState);
            const chosenMainTopic = yield select(selectChosenMainTopic);

            // redirect to the new url
            // to prevent that the content of this redirected topic is showen for some seconds (milli seconds)
            // put the rest of the logic in a else block so that it cant be rendered
            if (!!chosenMainTopic?.redirectTo) {
                yield call(redirectToTopic, chosenMainTopic.redirectTo);
            } else {

                // set contentType
                yield put(setContentType(BETWEEN_AND_UNDER_TOPICS));

                const subjectUrl = `${chosenSubject.id}-${slugify(chosenSubject.name)}`;
                const gradeUrl = `${chosenGrade.id}-${slugify(chosenGrade.name)}`;

                let canonicalUrl = `/${subjectUrl}/${gradeUrl}`;
                let stateUrl = '';
                if (chosenGrade.workPackage) {
                    stateUrl = `${chosenState.id}-${slugify(chosenState.name)}`;
                    canonicalUrl = `/${subjectUrl}/${gradeUrl}/${stateUrl}`;
                }

                // get subject, grade, state and main topic url (with id)
                const incommingSubjectUrl = splitPathName[0];
                const incommingGradeUrl = splitPathName[1];
                const incommingStateUrl = chosenGrade.workPackage ? splitPathName[2] : '';
                const incommingMainTopicUrl = chosenGrade.workPackage ? splitPathName[3] : splitPathName[2];

                if (chosenMainTopic.canonicalId) {
                    const topics = yield select((state) => state.library.topics);
                    const canonicalTopic = topics.find((topic) => topic.id === chosenMainTopic.canonicalId);
                    const canonicalTopicUrl = `${canonicalTopic.id}-${slugify(canonicalTopic.name)}`;
                    yield put(setCanonical(`${canonicalUrl}/${canonicalTopicUrl}`));
                    yield put(setTitle(canonicalTopic.title));
                    yield put(setMetaDescription(canonicalTopic.description));
                } else {
                    const mainTopicUrl = `${chosenMainTopic.id}-${slugify(chosenMainTopic.name)}`;

                    // on misspellings redirect to the right names
                    if (incommingSubjectUrl !== subjectUrl || gradeUrl !== incommingGradeUrl
                        || incommingStateUrl !== stateUrl || incommingMainTopicUrl !== mainTopicUrl) {
                        yield put(setRedirectUrl(`${canonicalUrl}/${mainTopicUrl}`));
                    }

                    yield put(setCanonical(`${canonicalUrl}/${mainTopicUrl}`));
                    yield put(setTitle(chosenMainTopic.title));
                    yield put(setMetaDescription(chosenMainTopic.description));
                }
            }
        }

        // subject, grade, mainTopic, betweenOrUnderTopic
        if (isSubjectMatch && isGradeMatch && isMainTopicMatch && isBetweenOrUnderTopicMatch
            && !isBetweenUnderTopicMatch) {

            yield all([
                put(chooseSubject(parseInt(subjectId, 10))),
                put(chooseGrade(parseInt(gradeId, 10))),
                put(chooseMainTopic(parseInt(mainTopicId, 10))),
            ]);

            if (chosenGrade.workPackage) {
                yield call(fetchStatesOfGrade, { payload: parseInt(gradeId, 10) });
                yield put(chooseState(parseInt(stateId, 10)));
                yield call(fetchTopicsOfState, { payload: parseInt(stateId, 10) });
            } else {
                // reset state
                yield put(chooseState(null));
                yield call(fetchTopicsOfGrade, { payload: parseInt(gradeId, 10) });
            }
            const topics = yield select((state) => state.library.topics);

            const chosenSubject = yield select(selectChosenSubject);
            const chosenState = yield select(selectChosenState);
            const chosenMainTopic = topics.find((topic) => topic.id === parseInt(mainTopicId, 10)
                && topic.page === false);
            const subjectUrl = `${chosenSubject.id}-${slugify(chosenSubject.name)}`;
            const gradeUrl = `${chosenGrade.id}-${slugify(chosenGrade.name)}`;
            const mainTopicUrl = `${chosenMainTopic.id}-${slugify(chosenMainTopic.name)}`;

            let canonicalUrl = `/${subjectUrl}/${gradeUrl}/${mainTopicUrl}`;
            let stateUrl = '';
            if (chosenGrade.workPackage) {
                stateUrl = !!chosenState ? `${chosenState.id}-${slugify(chosenState.name)}` : null;
                canonicalUrl = `/${subjectUrl}/${gradeUrl}/${stateUrl}/${mainTopicUrl}`;
            }

            // get subject, grade, state, main topic and between or under topic url (with id)
            const incommingSubjectUrl = splitPathName[0];
            const incommingGradeUrl = splitPathName[1];
            const incommingStateUrl = chosenGrade.workPackage ? splitPathName[2] : '';
            const incommingMainTopicUrl = chosenGrade.workPackage ? splitPathName[3] : splitPathName[2];
            const incommingBetweenOrUnderTopicUrl = chosenGrade.workPackage ? splitPathName[4] : splitPathName[3];

            const betweenTopic = topics.find((topic) => topic.id === parseInt(betweenOrUnderTopicId, 10)
                && topic.page === false);

            if (!!betweenTopic) {
                // reset chosen under topic
                yield put(chooseUnderTopic(null));

                const betweenUrl = `${betweenTopic.id}-${slugify(betweenTopic.name)}`;
                // on misspellings redirect to the right names
                if (incommingSubjectUrl !== subjectUrl || gradeUrl !== incommingGradeUrl
                    || incommingStateUrl !== stateUrl || incommingMainTopicUrl !== mainTopicUrl
                    || incommingBetweenOrUnderTopicUrl !== betweenUrl) {
                    const url = `${canonicalUrl}/${betweenUrl}`;
                    yield put(setRedirectUrl(url));
                }

                yield put(chooseBetweenTopic(parseInt(betweenOrUnderTopicId, 10)));

                const chosenBetweenTopic = yield select(selectChosenBetweenTopic);

                // redirect to the new url
                // to prevent that the content of this redirected topic is showen for some seconds (milli seconds)
                // put the rest of the logic in a else block so that it cant be rendered
                if (!!chosenBetweenTopic?.redirectTo) {
                    yield call(redirectToTopic, chosenBetweenTopic.redirectTo);
                } else {

                    yield put(setContentType(BETWEEN_AND_UNDER_TOPICS));

                    if (chosenBetweenTopic.canonicalId) {
                        const canonicalTopic = topics.find((topic) => topic.id === chosenBetweenTopic.canonicalId);
                        const canonicalTopicUrl = `${canonicalTopic.id}-${slugify(canonicalTopic.name)}`;
                        yield put(setCanonical(`${canonicalUrl}/${canonicalTopicUrl}`));
                        yield put(setTitle(chosenBetweenTopic.title));
                        yield put(setMetaDescription(chosenBetweenTopic.description));
                    } else {
                        const betweenTopicUrl = `${chosenBetweenTopic.id}-${slugify(chosenBetweenTopic.name)}`;
                        yield put(setCanonical(`${canonicalUrl}/${betweenTopicUrl}`));
                        yield put(setTitle(chosenBetweenTopic.title));
                        yield put(setMetaDescription(chosenBetweenTopic.description));
                    }
                }
            } else {
                yield put(chooseBetweenTopic(null));
                yield put(chooseUnderTopic(parseInt(betweenOrUnderTopicId, 10)));
                const chosenUnderTopic = yield select(selectChosenUnderTopic);

                // redirect to the new url
                // to prevent that the content of this redirected topic is showen for some seconds (milli seconds)
                // put the rest of the logic in a else block so that it cant be rendered
                if (!!chosenUnderTopic?.redirectTo) {
                    yield call(redirectToTopic, chosenUnderTopic.redirectTo);
                } else {
                    yield put(setContentType(PAGE));

                    if (chosenUnderTopic.canonicalId) {
                        const stateTopics = yield select((state) => state.library.topics);
                        const canonicalTopic = stateTopics.find((topic) => topic.id === chosenUnderTopic.canonicalId);
                        const canonicalTopicUrl = `${canonicalTopic.id}-${slugify(canonicalTopic.name)}`;
                        yield put(setCanonical(`${canonicalUrl}/${canonicalTopicUrl}`));
                        yield put(setTitle(canonicalTopic.title));
                        yield put(setMetaDescription(canonicalTopic.description));
                    } else {
                        const underTopicUrl = `${chosenUnderTopic.id}-${slugify(chosenUnderTopic.name)}`;

                        // on misspellings redirect to the right names
                        if (incommingSubjectUrl !== subjectUrl || gradeUrl !== incommingGradeUrl
                            || incommingStateUrl !== stateUrl || incommingMainTopicUrl !== mainTopicUrl
                            || incommingBetweenOrUnderTopicUrl !== underTopicUrl) {
                            const url = `${canonicalUrl}/${underTopicUrl}`;
                            yield put(setRedirectUrl(url));
                        }
                        yield put(setCanonical(`${canonicalUrl}/${underTopicUrl}`));
                        yield put(setTitle(chosenUnderTopic.title));
                        yield put(setMetaDescription(chosenUnderTopic.description));
                    }
                }

            }
        }

        // subject, grade, mainTopic, betweenOrUnderTopic, betweenUnderTopic
        if (isSubjectMatch && isGradeMatch && isMainTopicMatch && isBetweenOrUnderTopicMatch
            && isBetweenUnderTopicMatch) {

            // reset grades, topics and state if needed
            if (!isStateMatch) {
                yield put(chooseState(null));
            }
            yield put(setSubjectAndGrade(parseInt(subjectId, 10), parseInt(gradeId, 10)));

            if (chosenGrade.workPackage) {
                yield call(fetchStatesOfGrade, { payload: parseInt(gradeId, 10) });
                yield put(chooseState(parseInt(stateId, 10)));
                yield call(fetchTopicsOfState, { payload: parseInt(stateId, 10) });
            } else {
                yield call(fetchTopicsOfGrade, { payload: parseInt(gradeId, 10) });
            }

            yield put(chooseGrade(parseInt(gradeId, 10)));
            yield put(chooseMainTopic(parseInt(mainTopicId, 10)));
            yield put(chooseBetweenTopic(parseInt(betweenOrUnderTopicId, 10)));
            yield put(chooseUnderTopic(parseInt(betweenUnderTopicId, 10)));

            const chosenSubject = yield select(selectChosenSubject);
            const chosenState = yield select(selectChosenState);
            const chosenMainTopic = yield select(selectChosenMainTopic);
            const chosenBetweenTopic = yield select(selectChosenBetweenTopic);
            const chosenUnderTopic = yield select(selectChosenUnderTopic);

            // redirect to the new url
            // to prevent that the content of this redirected topic is showen for some seconds (milli seconds)
            // put the rest of the logic in a else block so that it cant be rendered
            if (!!chosenUnderTopic?.redirectTo) {
                yield call(redirectToTopic, chosenUnderTopic.redirectTo);
            } else {
                // set contentType
                yield put(setContentType(PAGE));

                const subjectUrl = `${chosenSubject.id}-${slugify(chosenSubject.name)}`;
                const gradeUrl = `${chosenGrade.id}-${slugify(chosenGrade.name)}`;
                const mainTopicUrl = `${chosenMainTopic.id}-${slugify(chosenMainTopic.name)}`;
                const betweenTopicUrl = `${chosenBetweenTopic.id}-${slugify(chosenBetweenTopic.name)}`;
                const underTopicUrl = `${chosenUnderTopic.id}-${slugify(chosenUnderTopic.name)}`;

                let canonicalUrl = `/${subjectUrl}/${gradeUrl}/${mainTopicUrl}/${betweenTopicUrl}`;
                let stateUrl = '';
                if (chosenGrade.workPackage) {
                    stateUrl = `${chosenState.id}-${slugify(chosenState.name)}`;
                    canonicalUrl = `/${subjectUrl}/${gradeUrl}/${stateUrl}/${mainTopicUrl}/${betweenTopicUrl}`;
                }

                // get subject, grade, state, main topic, between topic, under topic url (with id)
                const incommingSubjectUrl = splitPathName[0];
                const incommingGradeUrl = splitPathName[1];
                const incommingStateUrl = chosenGrade.workPackage ? splitPathName[2] : '';
                const incommingMainTopicUrl = chosenGrade.workPackage ? splitPathName[3] : splitPathName[2];
                const incommingBetweenTopicUrl = chosenGrade.workPackage ? splitPathName[4] : splitPathName[3];
                const incommingUnderTopicUrl = chosenGrade.workPackage ? splitPathName[5] : splitPathName[4];

                // on misspellings redirect to the right names
                if (incommingSubjectUrl !== subjectUrl || gradeUrl !== incommingGradeUrl
                    || incommingStateUrl !== stateUrl || incommingMainTopicUrl !== mainTopicUrl
                    || incommingBetweenTopicUrl !== betweenTopicUrl || incommingUnderTopicUrl !== underTopicUrl) {
                    const url = `${canonicalUrl}/${underTopicUrl}`;
                    yield put(setRedirectUrl(url));
                }

                if (chosenUnderTopic.canonicalId) {
                    const topics = yield select((state) => state.library.topics);
                    const canonicalTopic = topics.find((topic) => topic.id === chosenUnderTopic.canonicalId);
                    const canonicalTopicUrl = `${canonicalTopic.id}-${slugify(canonicalTopic.name)}`;
                    yield put(setCanonical(`${canonicalUrl}/${canonicalTopicUrl}`));
                    yield put(setTitle(canonicalTopic.title));
                    yield put(setMetaDescription(canonicalTopic.description));
                } else {
                    yield put(setCanonical(`${canonicalUrl}/${underTopicUrl}`));
                    yield put(setTitle(chosenUnderTopic.title));
                    yield put(setMetaDescription(chosenUnderTopic.description));
                }
            }

        }
        yield put(setParsingInProgress(false));
    } catch (error) {
        // TODO: implement proper error handling
        yield put(setParsingInProgress(false));
        redirectToNotFoundPage();
    }
}

// Watchers
export function* waitForLocationChange() {
    yield takeEvery(LOCATION_CHANGE, parseUrlAndFetchLibrary);
}

export function* waitForUnderTopicWasChosen() {
    yield takeEvery(CHOOSE_UNDER_TOPIC, fetchPage);
    yield takeEvery(CHOOSE_UNDER_TOPIC, fetchSimilarTopics);
}

export function* waitForWikiDownload() {
    yield takeEvery(WIKI_DOWNLOAD, fetchWikiDownload);
}

export function* waitForExerciseDownload() {
    yield takeEvery(EXERCISE_DOWNLOAD, fetchExerciseDownload);
}

export const LibrarySaga = [
    waitForLocationChange(),
    waitForUnderTopicWasChosen(),
    waitForWikiDownload(),
    waitForExerciseDownload(),
];
